import { forwardRef } from 'react';
import { useFormikContext } from 'formik';
import { Box, TextField, MenuItem } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import NumberFormat from 'react-number-format';

import { DropzoneField } from './DropzoneField';
import { PlaceAutocomplete } from './PlaceAutocomplete';
import { BankModalCard } from './BankModalCard';
import { SquareModalCard } from './SquareModalCard';
import { MetrcModalCard } from './MetrcModalCard';
import { AccountingModalCard } from './AccountingModalCard';

function createNumberFormat({ format, mask, thousandSeparator, prefix }) {
    return forwardRef(({ onChange, ...props }, ref) => (
        <NumberFormat
            {...props}
            getInputRef={ref}
            onValueChange={values => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            format={format}
            mask={mask}
            isNumericString
            thousandSeparator={thousandSeparator}
            prefix={prefix}
        />
    ));
}

export const PhoneFormat = createNumberFormat({ format: '+1 (###) ###-####', mask: '_' });
export const EINFormat = createNumberFormat({ format: '##-#######', mask: '_' });
export const PercentageFormat = createNumberFormat({ format: '###%', mask: '' });
export const CurrencyFormat = createNumberFormat({ thousandSeparator: true, prefix: '$' });
export const SSNFormat = createNumberFormat({ format: '###-##-####', mask: '_' });
export const IntegerFormat = createNumberFormat({ thousandSeparator: true });

export const fieldFormatters = {
    phone: PhoneFormat,
    EIN: EINFormat,
    percentage: PercentageFormat,
    currency: CurrencyFormat,
    SSN: SSNFormat,
    integer: IntegerFormat,
};

export const modalCardTypes = {
    square: SquareModalCard,
    bank: BankModalCard,
    metrc: MetrcModalCard,
    accounting: AccountingModalCard,
};

//TODO: field should be defined on the BE
// const accountingField = {
//     children: [],
//     field_name: "accounting",
//     field_subtype: "accounting",
//     field_type: "modal",
//     field_value: null,
//     helperText: null,
//     label: "Accounting",
//     placeholder: null,
//     required: false,
//     text_formatter: null,
// };

export const Fields = ({ fields }) => {
    const formik = useFormikContext();

    //TODO: field should be defined on the BE
    // const fieldsWithAccounting = useMemo(() => {
    //     if (fields[0].field_type === 'modal') {
    //         return [...fields, accountingField]
    //     }

    //     return fields;
    // }, [fields]);

    return fields.map(field => {
        const {
            field_name,
            field_type,
            label,
            helperText,
            required,
            text_formatter,
            placeholder,
            field_data,
            field_subtype,
        } = field;
        if (field_type === 'text' || field_type === 'textarea') {
            return (
                <Box key={field_name} mt={2}>
                    <TextField
                        fullWidth
                        required={required}
                        id={field_name}
                        name={field_name}
                        label={label}
                        value={formik.values[field_name] ?? ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched[field_name] && Boolean(formik.errors[field_name])}
                        helperText={
                            (formik.touched[field_name] && formik.errors[field_name]) || helperText
                        }
                        placeholder={placeholder}
                        InputProps={{
                            inputComponent: fieldFormatters[text_formatter],
                        }}
                        multiline={field_type === 'textarea'}
                        minRows={3}
                    />
                </Box>
            );
        } else if (field_type === 'select') {
            return (
                <Box key={field_name} mt={2}>
                    <TextField
                        fullWidth
                        required={required}
                        id={field_name}
                        name={field_name}
                        label={label}
                        value={formik.values[field_name] ?? ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched[field_name] && Boolean(formik.errors[field_name])}
                        helperText={
                            (formik.touched[field_name] && formik.errors[field_name]) || helperText
                        }
                        placeholder={placeholder}
                        select
                    >
                        {field_data.options.map(option => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                </Box>
            );
        } else if (field_type === 'date') {
            return (
                <Box key={field_name} mt={2}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            value={formik.values[field_name]}
                            onChange={value => {
                                formik.setFieldValue(field_name, value);
                            }}
                            inputFormat="MM/dd/yyyy"
                            maxDate={new Date()}
                            renderInput={props => (
                                <TextField
                                    {...props}
                                    id={field_name}
                                    name={field_name}
                                    error={
                                        formik.touched[field_name] &&
                                        Boolean(formik.errors[field_name])
                                    }
                                    required={required}
                                    label={label}
                                    placeholder={placeholder}
                                    fullWidth
                                    helperText={
                                        (formik.touched[field_name] && formik.errors[field_name]) ||
                                        helperText
                                    }
                                />
                            )}
                            PopperProps={{
                                disablePortal: true,
                                onClick: e => e.preventDefault(),
                            }}
                        />
                    </LocalizationProvider>
                </Box>
            );
        } else if (field_type === 'modal') {
            const Component = modalCardTypes[field_subtype];
            return (
                <Box key={field_name} mt={2}>
                    <Component field={field} />
                </Box>
            );
        } else if (field_type === 'dropzone') {
            return (
                <Box key={field_name} mt={2}>
                    <DropzoneField field={field} />
                </Box>
            );
        } else if (field_type === 'address') {
            return (
                <Box key={field_name} mt={2}>
                    <PlaceAutocomplete
                        fullWidth
                        variant="outlined"
                        required={required}
                        id={field_name}
                        name={field_name}
                        label={label}
                        value={formik.values[field_name] ?? null}
                        onChange={value => formik.setFieldValue(field_name, value)}
                        onBlur={formik.handleBlur}
                        error={formik.touched[field_name] && Boolean(formik.errors[field_name])}
                        helperText={
                            (formik.touched[field_name] && formik.errors[field_name]) || helperText
                        }
                        placeholder={placeholder}
                    />
                </Box>
            );
        }
    });
};
