import { Box, Backdrop, CircularProgress, Typography } from '@mui/material';
import { FC } from 'react';
import { FeatureLayout } from '@lendica/components';
import { useDataConnectionDataContext } from './DataConnectionData';
import { useDataConnectionElementContext } from './views/DataConnectionView';
import { Quickbooks } from './components/Accounting';
import { ECommerce } from './components/ECommerce';
import { PointOfSale } from './components/PointOfSale';
import { Plaid } from './components/Plaid';

export const DataConnectionContainer: FC<{ env: string; children: any }> = ({ children, env }) => {
    const { company, isLoading } = useDataConnectionDataContext();
    const { containerRef, dataConnectionRef } = useDataConnectionElementContext();

    const company_id = company ? company.id : '';

    return (
        <FeatureLayout loading={isLoading}>
            {/* <Box sx={{ px: 4 }}>

            </Box> */}
            <Box
                // ref={containerRef}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    height: '100%',
                    px: 4,
                    boxSizing: 'border-box',
                }}
            >
                <Box
                    sx={{
                        mt: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="subtitle1" my={3} fontWeight={500}>
                        Integrations
                    </Typography>
                </Box>
                {!isLoading && <Quickbooks env={env} company_id={company_id} />}
                {/* {!isLoading && <ECommerce company_id={company_id} env={env} />} */}
                {!isLoading && <PointOfSale company_id={company_id} env={env} />}
            </Box>
        </FeatureLayout>
    );
};
