import { Box, Button, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { SummaryCard } from '../components/SummaryCard';
import { FeatureLayout, RoundedButton } from '@lendica/components';
import { InvoiceCard } from '../components/InvoiceCard';
import { OfferCard } from '../components/OfferCard';
import { useEffect, useState } from 'react';
import * as api from '../api';
import { emitApi } from '@lendica/api';

export const DealSuccess = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});

    const { id } = useParams();

    useEffect(() => {
        const getInvoiceDetails = async () => {
            setLoading(true);
            const res = await api.getOffers(id);
            setData(res);
            setLoading(false);
        };
        getInvoiceDetails();
        emitApi.dealActivated();
    }, []);

    return (
        <FeatureLayout loading={loading}>
            <Box
                sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    boxSizing: 'border-box',
                    width: '100%',
                }}
            >
                <Box
                    sx={{
                        // height: '100%',
                        width: '100%',
                        padding: 2,
                        boxSizing: 'border-box',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-evenly',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Box flex={1}>
                            <Button onClick={() => navigate('/fundnow')}>Done</Button>
                        </Box>

                        <Box flex={1}>
                            <Typography variant="h6" fontSize={16} textAlign="center">
                                Submitted
                            </Typography>
                        </Box>

                        <Box flex={1}></Box>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-evenly',
                            height: '100%',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant="h5" color="text.secondary" mt={4} mb={2}>
                            FundNow Invoice Activated
                        </Typography>
                        <Typography variant="h1" color="text.secondary" mt={2} mb={2}>
                            👍
                        </Typography>

                        <InvoiceCard invoice={data.invoice} />
                        <OfferCard offer={data.offer} />
                    </Box>

                    <SummaryCard invoice={data.invoice} offer={data.offer} />
                </Box>
                <Box
                    sx={{
                        position: 'sticky',
                        bottom: 0,
                        mb: 2,
                        boxSizing: 'border-box',
                        px: 2,
                        width: '100%',
                        py: 1,
                        bgcolor: '#FFF',
                    }}
                >
                    <RoundedButton
                        variant="contained"
                        fullWidth
                        onClick={() => navigate('/fundnow')}
                    >
                        Ok
                    </RoundedButton>
                </Box>
            </Box>
        </FeatureLayout>
    );
};
