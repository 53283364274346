import { Box, Typography } from '@mui/material';

export const Toolbar = ({ title, value, action, className }) => {
    return (
        <Box
            py={1}
            height={64}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            className={className}
            position="sticky"
            bottom={0}
            sx={{ backgroundColor: 'background.paper' }}
        >
            {title && (
                <Box>
                    <Typography variant="overline" component={Box} height={20}>
                        {title}
                    </Typography>
                    <Typography variant="subtitle1">{value}</Typography>
                </Box>
            )}
            {action}
        </Box>
    );
};
