import { FC, useState } from 'react';
import { List } from '@mui/material';
import { HistoryListItem } from '../components/HistoryListItem';
import { FilterBar } from '../components/FilterBar';
import { NoSchedules } from '../components/NoSchedules';
import { useHistory } from '../hooks/useHistory';
import { useNavigate } from 'react-router-dom';
import { CompletedPayment } from '../sharedTypes';
import { parse, compareAsc, compareDesc } from 'date-fns';

interface HistoryProps {
    payments: CompletedPayment[];
}

const filterer = (filters: any, items: CompletedPayment[]) => {
    // let filteredItems = items.filter(x => ['002', '005', '111'].includes(x.description_code));
    let filteredItems: CompletedPayment[] = items.filter(
        item => item.resulting_status !== 'Scheduled' && item.resulting_status !== 'Cancelled'
    );
    let filterCategories = Object.keys(filters);

    filteredItems = filteredItems.sort((a: CompletedPayment, b: CompletedPayment) => {
        let date1 = parse(a.event_date, 'yyyy-MM-dd', new Date());
        let date2 = parse(b.event_date, 'yyyy-MM-dd', new Date());
        return compareDesc(date1, date2);
    });

    filterCategories.map(category => {
        if (Object.keys(filters).length === 0) {
            return filteredItems;
        }
        if (filters['earliestFirst']) {
            filteredItems = filteredItems.sort((a: CompletedPayment, b: CompletedPayment) => {
                let date1 = parse(a.event_date, 'yyyy-MM-dd', new Date());
                let date2 = parse(b.event_date, 'yyyy-MM-dd', new Date());
                return compareAsc(date1, date2);
            });
        }
        if (category === 'transactionCategory') {
            if (filters[category].includes('Payment')) {
                filteredItems = filteredItems.filter(
                    x => x.description_code === '005' || x.description_code === '111'
                );
            } else if (filters[category].includes('Funding')) {
                filteredItems = filteredItems.filter(x => x.description_code === '002');
            }
        }
        if (category == 'status') {
            if (filters[category].length === 0) {
                return filteredItems;
            } else {
                filteredItems = filteredItems.filter(x =>
                    filters[category].includes(x.resulting_status)
                );
            }
        }
        if (category === 'dealId') {
            if (filters[category].length === 0) {
                return filteredItems;
            } else {
                filteredItems = filteredItems.filter(x => filters[category].includes(x.deal));
            }
        }
    });

    return filteredItems;
};

export const History: FC<HistoryProps> = ({ payments }) => {
    const [filters, setFilters] = useState({});
    const { filtersList } = useHistory({ payments });
    const navigate = useNavigate();

    const onChange = (value: any) => {
        setFilters(value);
    };

    const handleNavigation = (id: string) => {
        navigate(`detail/${id}`);
    };

    const filteredResults = filterer(filters, payments);

    return (
        <>
            {payments.length !== 0 ? (
                filteredResults.length === 0 ? (
                    <>
                        <FilterBar
                            filters={filtersList}
                            initialValue={filters}
                            onChange={onChange}
                        />
                        <NoSchedules subtitle="No results matching filters." />
                    </>
                ) : (
                    <>
                        <FilterBar
                            filters={filtersList}
                            initialValue={filters}
                            onChange={onChange}
                        />
                        <List
                            sx={{
                                pr: 2,
                                overflow: 'auto',
                                height: '100%',
                                pb: 4,
                                boxSizing: 'border-box',
                            }}
                        >
                            {filteredResults.map(payment => (
                                <HistoryListItem
                                    key={payment.id}
                                    dealId={payment.deal.substring(0, 8)}
                                    date={payment.event_date}
                                    amount={payment.check_amount}
                                    transactionCategory={payment.description_code}
                                    status={payment.resulting_status}
                                    product={payment.product_name}
                                    hasNavigation={true}
                                    onNavigationClick={() => handleNavigation(payment.id)}
                                />
                            ))}
                        </List>
                    </>
                )
            ) : (
                <NoSchedules subtitle="You have no payment history." />
            )}
        </>
    );
};
