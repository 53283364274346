import { Box } from '@mui/material';
import { FC } from 'react';
import { Logo, ErrorMessage, FeatureLayout } from '@lendica/components';

export const NotFound: FC = () => {
    return (
        <FeatureLayout fullHeight>
            <Box sx={{ px: 4, height: '100%' }}>
                <Box
                    sx={{
                        mt: 2,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Logo height={20} />
                </Box>
                <ErrorMessage message="Page not found" />
            </Box>
        </FeatureLayout>
    );
};
