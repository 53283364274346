import { FC, useMemo, useState, useEffect } from 'react';
import { Box, Fade, IconButton, Typography, Tooltip } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { SummaryList, CircularIndeterminate } from '@lendica/components';
import { SidePanelLayout } from '@lendica/components';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { mapDealToSummary } from '../utils/helpers';

const convertToCSV = deal => {
    const replacer = function (key, value) {
        return value === null ? '' : value;
    };
    let csv = 'data:text/csv;charset=utf-8,';
    // csv += Object.keys(deal!).join(',') + '\r\n'; // add header column
    // csv += Object.values(deal!)
    //     .map(function (row) {
    //         return JSON.stringify(row, replacer);
    //     })
    //     .join(',');
    let dealSummary = mapDealToSummary(deal);

    let headers = dealSummary.map(a => a.label).join(',') + '\r\n';
    csv += headers;
    csv += dealSummary.map(x => JSON.stringify(x.value, replacer)).join(',') + '\r\n';

    const encodedUri = encodeURI(csv);
    let link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `Lendica_Deal_Summary_${deal.id}.csv`);
    document.body.appendChild(link);

    link.click();
};

export const DealSummaryView = ({ deals }) => {
    const { dealId } = useParams();
    const deal = useMemo(() => deals.find(deal => deal.id === dealId), [dealId]);
    const [visualCue, setVisualCue] = useState(false);

    useEffect(() => {
        setVisualCue(true);
        setTimeout(() => setVisualCue(false), 200);
    }, [dealId]);

    return (
        <SidePanelLayout redirectRoute="/drawdown" loading={visualCue}>
            <Fade in={!visualCue}>
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        overflow: 'auto',
                    }}
                >
                    <Box
                        sx={{
                            flexBasis: 600,
                            height: '100%',
                            // bgcolor: 'background.default',
                        }}
                    >
                        <SummaryList
                            title={
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography variant="subtitle1">Deal Summary</Typography>
                                    <Tooltip title="Download as CSV">
                                        <IconButton
                                            color="secondary"
                                            aria-label="download report"
                                            size="small"
                                            onClick={() => convertToCSV(deal)}
                                        >
                                            <DownloadIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            }
                            data={mapDealToSummary(deal)}
                        />
                        <Box pb={6} />
                    </Box>
                </Box>
            </Fade>
        </SidePanelLayout>
    );
};
