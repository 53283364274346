import {
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableCellProps,
    TablePagination,
} from '@mui/material';
import { FC, useMemo, useState, Fragment } from 'react';

export function StaticTable({
    columns,
    data,
    handleTableRowClick,
}) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const dataPage = useMemo(
        () => data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
        [data, page, rowsPerPage]
    );

    return (
        <>
            <TableContainer component={Paper} elevation={0} data-testid="deals-table">
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            {columns.map(column => (
                                <TableCell key={column.id} {...(column?.tableCellProps ?? {})}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataPage.map((row, index) => (
                            <Fragment key={index}>
                                <TableRow
                                    hover
                                    onClick={() => handleTableRowClick(row)}
                                    sx={{ cursor: 'pointer' }}
                                    data-testid={`deals-table-row-${index}`}
                                >
                                    {columns.map(column => {
                                        const value = (row)[
                                            column.id
                                        ];
                                        const CellContent =
                                            column.CellContent ??
                                            (({ value }) => {
                                                const formatter =
                                                    column.formatter ?? ((v) => v);
                                                return <>{formatter(value)}</>;
                                            });
                                        return (
                                            <TableCell
                                                key={column.id}
                                                {...(column?.tableCellProps ?? {})}
                                            >
                                                <CellContent value={value} />
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            </Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(_event, newPage) => {
                    setPage(newPage);
                }}
                onRowsPerPageChange={event => {
                    setRowsPerPage(parseInt(event.target.value, 10));
                    setPage(0);
                }}
            />
        </>
    );
}
