import { useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, Box, MenuItem, Skeleton, TextField, Typography } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Dropzone, FeatureLayout } from '@lendica/components';
import { PdfViewer } from '@lendica/fundnow/src/components/PdfViewer';
import * as api from './api/index';
import { FileItem } from './FileItem';

const NUMBER_OF_VISIBLE_FILES = 5;

const fileCategories = [
    {
        value: 'onboarding',
        label: 'Onboarding',
    },
    {
        value: 'bank',
        label: 'Bank statements',
    },
    {
        value: 'accounting',
        label: 'Accounting',
    },
    {
        value: 'other',
        label: 'Other files',
    },
];

const getObjectKey = (env, company, type) => fileName => {
    // const ext = fileName.split('.').pop();
    const [name, ext] = fileName.split('.').slice(-2);
    return `${env}/${company.id}/${type}/${type}_${new Date().valueOf()}_${name || ''}.${ext}`;
};

export const FileUpload = ({ env }) => {
    const [loading, setLoading] = useState(true);
    const [isDragActive, setIsDragActive] = useState(false);
    const [isUploadInProgress, setIsUploadInProgress] = useState(false);
    const [error, setError] = useState(null);
    const [fileRejected, setFileRejected] = useState(null);
    const [company, setCompany] = useState({});
    const [droppedFiles, setDroppedFiles] = useState({});
    const [type, setType] = useState('');
    const [fileForPreview, setFileForPreview] = useState(null);

    const displayedFilesHeight = useMemo(() => {
        const numberOfFiles = droppedFiles[type] ? droppedFiles[type].length : 0;

        return numberOfFiles > NUMBER_OF_VISIBLE_FILES ? '270px' : 'auto';
    }, [droppedFiles, type]);

    const updateDroppedFiles = useCallback(
        files =>
            files.reduce(
                (prevValue, currentFile) => ({
                    ...prevValue,
                    [type]: prevValue[type] ? [...prevValue[type], currentFile] : [currentFile],
                }),
                droppedFiles
            ),
        [droppedFiles, type]
    );

    const handleFileDrop = files => {
        setIsUploadInProgress(true);

        setTimeout(() => {
            const updatedDroppedFiles = updateDroppedFiles(files);
            setDroppedFiles(updatedDroppedFiles);
        }, 200);
    };

    const handleDragActive = value => {
        setIsDragActive(value);
    };

    const handleFileUpload = () => {
        setTimeout(() => {
            setIsUploadInProgress(false);
        }, 200);
    };

    const handleFileType = event => {
        setType(event.target.value);
    };

    const handleUploadFailed = () => {
        setError(true);
    };

    const handleUploadErrorClose = () => {
        setError(false);
    };

    const handleFileRejection = value => {
        setFileRejected(value);
    };

    const handleFileRejectionClose = () => {
        setFileRejected(false);
    };

    useEffect(() => {
        const getCompanyDetails = async () => {
            setLoading(true);
            const companyDetails = await api.getCompany();
            setCompany(companyDetails);
            setLoading(false);
        };
        getCompanyDetails();
    }, []);

    return (
        <>
            <FeatureLayout loading={loading}>
                <Box
                    sx={{
                        height: '100%',
                        overflow: 'hidden',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-evenly',
                        alignItems: 'flex-start',
                        pb: 4,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            py: 2,
                            px: 4,
                            boxSizing: 'border-box',
                        }}
                    >
                        <Typography fontWeight={500} fontSize={16}>
                            File Upload
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            px: 4,
                            py: 1,
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-evenly',
                            alignItems: 'flex-start',
                        }}
                    >
                        <Typography variant="caption" color="secondary" paragraph>
                            1. Select Category
                        </Typography>
                        <TextField
                            select
                            label="Select File Category"
                            value={type}
                            onChange={handleFileType}
                            sx={{ width: '100%', mb: 2 }}
                            size="small"
                            fullWidth
                            helperText={
                                !type
                                    ? 'Please select a file category to start uploading files.'
                                    : ''
                            }
                        >
                            {fileCategories.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>

                        {error && (
                            <Alert
                                severity="error"
                                onClose={handleUploadErrorClose}
                                sx={{ fontWeight: 500, mb: 2, width: '100%' }}
                            >
                                Failed to upload the file, please try again
                            </Alert>
                        )}
                        {fileRejected && (
                            <Alert
                                severity="error"
                                onClose={handleFileRejectionClose}
                                sx={{ fontWeight: 500, mb: 2, width: '100%' }}
                            >
                                {`File type must be pdf, jpeg, png, csv or json.`}
                            </Alert>
                        )}
                        {!!type && (
                            <Box>
                                <Typography variant="caption" color="secondary">
                                    2. Upload
                                </Typography>
                                <Typography variant="caption" paragraph>
                                    * Historical files uploaded may not show up here
                                </Typography>
                            </Box>
                        )}

                        <Box
                            sx={{
                                height: displayedFilesHeight,
                                width: '100%',
                                overflow: 'auto',
                            }}
                        >
                            {droppedFiles[type]?.map((file, index) => (
                                <FileItem
                                    key={index}
                                    file={file}
                                    onPreviewClick={setFileForPreview}
                                />
                            ))}
                        </Box>
                        {isUploadInProgress && (
                            <Skeleton
                                variant="rounded"
                                animation="wave"
                                sx={{ width: '100%', height: 54, mb: 1 }}
                            />
                        )}
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            width: '100%',
                            px: 4,
                            boxSizing: 'border-box',
                        }}
                    >
                        {type && (
                            <Dropzone
                                multiple
                                showDragActive
                                onChange={handleFileUpload}
                                onDragActive={handleDragActive}
                                onDrop={handleFileDrop}
                                onUploadFailed={handleUploadFailed}
                                onFileRejection={handleFileRejection}
                                accept={{
                                    'image/png': ['.png'],
                                    'image/jpg': ['.jpg'],
                                    'image/jpeg': ['.jpeg'],
                                    'application/pdf': ['.pdf'],
                                    'text/csv': ['.csv'],
                                    'application/json': ['.json'],
                                }}
                                acceptHelper="Accepting pdf, images, csv and json."
                                label="+ New"
                                api={{
                                    presignedUrl:
                                        'https://micro-awsmanager.herokuapp.com/s3/signed-url',
                                    presignedUrlParams: {
                                        bucket_name: 'lendica-pod',
                                    },
                                    getObjectKey: getObjectKey(env, company, type),
                                }}
                                styles={{
                                    height: '100%',
                                    backgroundColor: isDragActive ? '#F5F5F7' : 'unset',
                                    border: 'unset',
                                }}
                            />
                        )}
                    </Box>
                </Box>
            </FeatureLayout>

            <Box display="flex" alignItems="center" justifyContent="center">
                {fileForPreview ? (
                    <PdfViewer
                        styles={{ width: '100%', height: '100%' }}
                        invoices={[{ name: '', filename: fileForPreview.objectKey }]}
                    />
                ) : (
                    <FileCopyIcon sx={{ color: '#EFEFF2', width: 80, height: 90 }} />
                )}
            </Box>
        </>
    );
};
