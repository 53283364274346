import { FC } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { PhoneAuth, ProtectedRoute, AccountProvider, useAccountContext } from '@lendica/auth';
import { AppContainer } from './AppContainer';
import { baseURL } from './api/axiosInstance';

const PhoneAuthContainer = () => {
    const navigate = useNavigate();
    const { storePortedTokens } = useAccountContext();
    return (
        <PhoneAuth
            baseURL={baseURL}
            onSuccess={async tokens => {
                await storePortedTokens(tokens);
                setTimeout(() => {
                    navigate('/wallet');
                }, 100);
            }}
            heading="Lendica Portal"
            body="Use your phone number to sign in 🔐."
        />
    );
};

export const App: FC = () => {
    const navigate = useNavigate();

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <AccountProvider
                onAuthSuccess={() => {
                    navigate(`/wallet`);
                }}
                onAccountClear={() => {
                    navigate(`/`);
                }}
            >
                <Routes>
                    <Route element={<ProtectedRoute />}>
                        <Route path="*" element={<AppContainer />} />
                    </Route>
                    <Route path="/" element={<PhoneAuthContainer />} />
                </Routes>
            </AccountProvider>
        </LocalizationProvider>
    );
};
