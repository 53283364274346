import { currencyFormatter, dateFormatter } from "@lendica/utils"
import { Box, Typography } from "@mui/material"

export const InvoiceCard = ({ invoice }) => {

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                py: 1,
                px: 3,
                my: 2,
                background: '#2E31920A',
                boxSizing: 'border-box',
                borderRadius: 2,
                width: '100%'
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    pb: 1,
                    boxSizing: 'border-box',
                    borderBottom: '1px solid rgba(163, 163, 180, 0.2);'
                }}
            >
                <Typography variant="caption">{`Invoice #${invoice.invoice_number}`}</Typography>
                <Typography variant="caption">{dateFormatter(invoice.date)}</Typography>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    pt: 1,
                    boxSizing: 'border-box',
                }}
            >
                <Box>
                    <Typography variant="caption">Invoice total</Typography>
                    <Typography variant="h6">{currencyFormatter(invoice.total)}</Typography>
                </Box>

                <Box>
                    <Typography variant="caption" textAlign={'right'}>Customer</Typography>
                    <Typography variant="h6" textAlign={'right'}>{invoice.customer_name ?? 'N/A'}</Typography>
                </Box>
            </Box>
        </Box>
    )
}