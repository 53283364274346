import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { IconButton, Box, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { GetApp } from '@mui/icons-material';

import { Header, Body, SummaryList, BottomButton, Toolbar } from '@lendica/components';
import { DrawDownContainer } from '../../DrawDownContainer';
import { PaymentBody } from './PaymentBody';
// import { BottomButton } from '../../BottomButton';
// import { Toolbar } from '../../Toolbar';
import { currencyFormatter, dateFormatter, financial } from '@lendica/utils';

const useStyles = makeStyles(theme => ({
    button: {
        color: theme.palette.action.active,
    },
    toolbar: {
        backgroundColor: theme.palette.primary.light,
    },
    confirmButton: {
        width: 250,
        height: 42,
        borderRadius: 30,
    },
    bold: {
        fontWeight: 800,
        display: 'flex',
    },
    completed: {
        color: theme.palette.action.active,
        marginBottom: theme.spacing(1),
    },
    bottomButton: {
        width: '100%',
        borderRadius: 20,
        height: 36,
        padding: 0,
        boxShadow: 'none',
    },
    number: {
        fontSize: 40,
        fontWeight: 800,
        fontFamily: theme.typography.fontFamily,
    },
}));

const getSummary = paymentData => {
    return [
        {
            label: 'Transaction No.',
            value: paymentData?.id?.slice(0, 8) ?? '',
        },
        {
            label: 'Category',
            value: 'Early payment',
        },
        {
            label: 'Amount',
            value: currencyFormatter(paymentData?.payment_amount) ?? '',
        },
        {
            label: 'Date',
            value: dateFormatter(paymentData?.date ?? Date()),
        },
        {
            label: 'Factor rate',
            value: paymentData?.factor_rate ?? '',
        },
        // {
        //     label: 'Bank Account',
        //     value: 'Capital One - ****1234',
        // },
        {
            label: 'Transaction Type',
            value: 'Deposit ACH',
        },
        {
            label: 'Withdrawal ID',
            value: paymentData?.draw_id.slice(0, 8) ?? '',
        },
    ];
};

export const PaymentSuccess = () => {
    const navigate = useNavigate();
    const classes = useStyles();
    const {
        data: { early_payment },
        selectedWithdrawals,
        updateDrawDown,
    } = DrawDownContainer.useContainer();
    const printRef = useRef(null);

    const amount = financial(
        selectedWithdrawals.reduce(
            (prevVal, currItem) => prevVal + currItem.withdrawal_early_payoff_amount,
            0
        )
    );

    const handleGoBack = () => {
        navigate('..');
        updateDrawDown();
    };

    // const printSummary = () => {
    //     const summary = printRef.current.innerHTML;
    //     const a = window.open(
    //         '',
    //         '',
    //         'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0'
    //     );
    //     a.document.write('<html>');
    //     a.document.write('<style></style>');
    //     a.document.write(summary);
    //     a.document.write('</body></html>');
    //     a.document.close();
    //     a.print();
    // };

    return (
        <>
            <Header
                title="Payment Confirmation"
                showLogo={false}
                showClose={false}
                leftActions={
                    <Button onClick={handleGoBack} className={classes.button}>
                        Done
                    </Button>
                }
                rightActions={
                    <IconButton size="small" onClick={() => window.print()}>
                        <GetApp />
                    </IconButton>
                }
            />
            {/* {isLoading && <Loader />}
            {!isLoading && (
                <> */}
            <Body display="flex" flexDirection="column">
                <Box flexDirection="column" display="flex" p={4}>
                    <Box display="flex" alignItems="center" flexDirection="column" flex="0 0 auto">
                        <Typography variant="body2" className={classes.completed}>
                            The transaction will be completed within a few business days
                        </Typography>
                        <Typography
                            variant="h5"
                            component={Box}
                            textAlign="center"
                            color="text.primary"
                        >
                            Payment Successful!
                        </Typography>
                        <Box my={3}>
                            <svg
                                width="64"
                                height="65"
                                viewBox="0 0 64 65"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M58.2531 13.9884L28.1338 44.4417L16.9413 33.135L20.6633 29.375L28.1338 36.9217L54.5311 10.255L58.2531 13.9884ZM52.4193 27.4284C52.7625 28.9484 52.9736 30.5484 52.9736 32.175C52.9736 43.9617 43.5234 53.5084 31.8558 53.5084C20.1882 53.5084 10.738 43.9617 10.738 32.175C10.738 20.3884 20.1882 10.8417 31.8558 10.8417C36.0266 10.8417 39.8806 12.0684 43.1539 14.175L46.9551 10.335C42.6787 7.29503 37.4784 5.50836 31.8558 5.50836C17.2845 5.50836 5.4585 17.455 5.4585 32.175C5.4585 46.895 17.2845 58.8417 31.8558 58.8417C46.4271 58.8417 58.2531 46.895 58.2531 32.175C58.2531 29.0017 57.6724 25.9617 56.6693 23.135L52.4193 27.4284Z"
                                    fill="#4CAF50"
                                />
                            </svg>
                        </Box>
                        <Box textAlign="center">
                            <Typography variant="caption" color="text.primary">
                                Amount Deducted
                            </Typography>

                            <Typography variant="h4" component={Box} className={classes.bold}>
                                <Typography variant="h5" component="span" className={classes.bold}>
                                    $
                                </Typography>
                                <NumberFormat
                                    displayType="text"
                                    thousandSeparator={true}
                                    value={amount}
                                    className={classes.number}
                                />
                            </Typography>
                        </Box>
                    </Box>

                    <PaymentBody />

                    <Box ref={printRef} id="print" mt={3}>
                        <SummaryList data={getSummary(early_payment)} />
                    </Box>
                </Box>
            </Body>
            <Toolbar
                action={
                    <BottomButton
                        leftActions={
                            <Button
                                variant="outlined"
                                color="primary"
                                className={classes.bottomButton}
                                onClick={() => {
                                    navigate('../payment');
                                    updateDrawDown();
                                }}
                            >
                                See Future Payments
                            </Button>
                        }
                        rightActions={
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.bottomButton}
                                onClick={handleGoBack}
                            >
                                Ok, Take Me Back
                            </Button>
                        }
                    />
                }
            />
            {/* </>
            )} */}
        </>
    );
};
