import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IconButton, Box, Button, Typography, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
    Header,
    Body,
    SummaryList,
    Toolbar,
    BottomButton,
    FeatureLayout,
    PriceFormat,
} from '@lendica/components';
import { WithdrawalBody } from './WithdrawalBody';
// import { BottomButton } from '../../BottomButton';
// import { Toolbar } from '../../Toolbar';
import { currencyFormatter, dateFormatter } from '@lendica/utils';
import * as api from '../../api/index';

const useStyles = makeStyles(theme => ({
    button: {
        color: theme.palette.action.active,
    },
    toolbar: {
        backgroundColor: theme.palette.primary.light,
    },
    confirmButton: {
        width: 250,
        height: 42,
        borderRadius: 30,
    },
    bold: {
        fontWeight: 800,
        display: 'flex',
    },
    completed: {
        color: theme.palette.action.active,
        marginBottom: theme.spacing(1),
    },
    bottomButton: {
        width: '100%',
        borderRadius: 20,
        height: 36,
        padding: 0,
        boxShadow: 'none',
    },
}));

const getSummary = deal => {
    return [
        {
            label: 'Transaction No.',
            value: deal?.id ?? '',
        },
        {
            label: 'Category',
            value: 'Withdrawal',
        },
        {
            label: 'Amount',
            value: currencyFormatter(deal?.gross_funded_amount) ?? '',
        },
        {
            label: 'Origination Fee Amount',
            value: currencyFormatter(deal?.origination_fee_amount) ?? '',
        },
        {
            label: 'Date',
            value: dateFormatter(deal?.funded_date ?? Date()),
        },
        {
            label: 'No. of Payments',
            value: 26,
        },
        {
            label: 'Payment Frequency',
            value: 'Weeks',
        },
        {
            label: 'Weekly Payment',
            value: currencyFormatter(deal?.periodic_collection_from_company) ?? '',
        },
        // // {
        // //     label: 'Bank Account',
        // //     value: 'Capital One - ****1234',
        // // },
        // {
        //     label: 'Transaction Type',
        //     value: 'Deposit ACH',
        // },
        // {
        //     label: 'Withdrawal ID',
        //     value: withdrawalData?.id.slice(0, 8) ?? '',
        // },
    ];
};

export const WithdrawalSuccess = ({ withdrawalData }) => {
    const { dealId } = useParams();

    const [deal, setDeal] = useState(null);

    const navigate = useNavigate();
    const classes = useStyles();

    const handleGoBack = () => {
        navigate('/drawdown');
    };

    useEffect(() => {
        const getDeal = async () => {
            const dealInfo = await api.getDealById(dealId);
            setDeal(dealInfo);
        };
        getDeal();
    }, []);

    return (
        <FeatureLayout>
            <Box
                sx={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                }}
            >
                <Box sx={{ flexGrow: 1, pb: 2 }}>
                    <Header
                        title="Withdrawal Confirmation"
                        showLogo={false}
                        showClose={false}
                        leftActions={
                            <Button onClick={handleGoBack} className={classes.button}>
                                Done
                            </Button>
                        }
                    />
                </Box>
                {deal ? (
                    <>
                        <Body>
                            <Box
                                flexDirection="column"
                                display="flex"
                                p={4}
                                sx={{ overflowY: 'scroll' }}
                            >
                                <Box display="flex" alignItems="center" flexDirection="column">
                                    <Typography variant="body2" className={classes.completed}>
                                        The transaction will be completed within a few business days
                                    </Typography>
                                    <Typography
                                        variant="h5"
                                        component={Box}
                                        textAlign="center"
                                        color="text.primary"
                                    >
                                        Withdrawal Successful!
                                    </Typography>
                                    <Box my={3}>
                                        <svg
                                            width="64"
                                            height="65"
                                            viewBox="0 0 64 65"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M58.2531 13.9884L28.1338 44.4417L16.9413 33.135L20.6633 29.375L28.1338 36.9217L54.5311 10.255L58.2531 13.9884ZM52.4193 27.4284C52.7625 28.9484 52.9736 30.5484 52.9736 32.175C52.9736 43.9617 43.5234 53.5084 31.8558 53.5084C20.1882 53.5084 10.738 43.9617 10.738 32.175C10.738 20.3884 20.1882 10.8417 31.8558 10.8417C36.0266 10.8417 39.8806 12.0684 43.1539 14.175L46.9551 10.335C42.6787 7.29503 37.4784 5.50836 31.8558 5.50836C17.2845 5.50836 5.4585 17.455 5.4585 32.175C5.4585 46.895 17.2845 58.8417 31.8558 58.8417C46.4271 58.8417 58.2531 46.895 58.2531 32.175C58.2531 29.0017 57.6724 25.9617 56.6693 23.135L52.4193 27.4284Z"
                                                fill="#4CAF50"
                                            />
                                        </svg>
                                    </Box>
                                    <Box textAlign="center">
                                        <Typography variant="caption" color="text.primary">
                                            Credit Withdrawn
                                        </Typography>

                                        <Typography
                                            variant="h4"
                                            component={Box}
                                            className={classes.bold}
                                        >
                                            <Typography
                                                variant="h5"
                                                component="span"
                                                className={classes.bold}
                                            >
                                                $
                                            </Typography>
                                            <PriceFormat
                                                value={
                                                    currencyFormatter(
                                                        deal?.disbursement_to_company
                                                    ) ?? 0
                                                }
                                                noDollar
                                                decimalScale={2}
                                            />
                                        </Typography>
                                    </Box>
                                </Box>
                                <WithdrawalBody withdrawalData={withdrawalData} deal={deal} />

                                <Box mt={3}>
                                    <SummaryList data={getSummary(deal)} />
                                </Box>
                            </Box>
                        </Body>
                        <Toolbar
                            action={
                                <BottomButton
                                    leftActions={
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            className={classes.bottomButton}
                                            onClick={() => {
                                                navigate('/activity-center');
                                            }}
                                        >
                                            See Future Payments
                                        </Button>
                                    }
                                    rightActions={
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={classes.bottomButton}
                                            onClick={handleGoBack}
                                        >
                                            Ok, Take me back
                                        </Button>
                                    }
                                />
                            }
                        />
                    </>
                ) : (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '700px',
                            width: '100%',
                            boxSizing: 'border-box',
                        }}
                    >
                        <CircularProgress />
                    </Box>
                )}
            </Box>
        </FeatureLayout>
    );
};
