import { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { InitResponse, AccountAPI } from './accountAPI';

interface AccountContextInterface {
    isLoading: boolean;
    data: InitResponse; // TODO replace with type that accounts for API 2.0 response
    getSummary?: () => Promise<void>;
}

const defaultState = {
    isLoading: true,
    data: {},
};

const AccountDataContext = createContext<AccountContextInterface>(defaultState);

export const useAccountDataContext = () => {
    return useContext(AccountDataContext);
};

export const AccountData = ({ children }: { children: ReactNode }) => {
    const [state, setState] = useState(defaultState);

    useEffect(() => {
        (async () => {
            setState(currentState => ({ ...currentState, isLoading: true }));
            try {
                const data = await AccountAPI.init();
                setState(currentState => ({ ...currentState, data }));
            } catch {
                setState(currentState => ({ ...currentState, data: {} }));
            } finally {
                setState(currentState => ({ ...currentState, isLoading: false }));
            }
        })();
    }, []);

    if (process.env.NODE_ENV === 'development') {
        useEffect(() => {
            setTimeout(() => {
                localStorage.setItem('AccountState', JSON.stringify(state));
            }, 0);
        }, [state]);
    }

    return (
        <AccountDataContext.Provider
            value={{
                ...state,
            }}
        >
            {children}
        </AccountDataContext.Provider>
    );
};
