import { FC, ReactNode } from 'react';
import { Route, Routes } from 'react-router-dom';
import { WalletView } from './views/WalletView';

export interface WalletProps {
    placeholder?: ReactNode;
}

export const Wallet: FC<WalletProps> = ({ placeholder }) => {
    return (
        <Routes>
            <Route path="*" element={<WalletView placeholder={placeholder} />} />
        </Routes>
    );
};
