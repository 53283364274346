import { currencyFormatter, dateFormatter, formatDateShort } from '@lendica/utils';
import { Box, Typography } from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

export const OfferCard = ({ offer }) => {
    // let origination_fee_amount = offer.origination_fee_percentage * offer.advance_amount
    // let total_rate_fee_amount = offer.total_rate_percentage * offer.advance_amount
    // let total_fees = offer.origination_fee_amount + offer.total_rate_amount

    return (
        !!offer && (
            <Box
                sx={theme => ({
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column',
                    pt: 2,
                    px: 3,
                    pb: 1.5,
                    boxSizing: 'border-box',
                    background: '#4C4B45', // '#282761',
                    boxShadow: 3,
                    borderRadius: '8px',
                })}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        mb: 2,
                        borderBottom: '1px solid rgba(163, 163, 180, 0.2);',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Box mb={2}>
                            <Typography color="#F2F2F8" variant="caption" fontSize={12}>
                                Received today
                            </Typography>
                            <Typography color="#FCFCFD" variant="h4" fontWeight={800} fontSize={24}>
                                {currencyFormatter(offer.amount_recieved_today)}
                            </Typography>
                        </Box>
                    </Box>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        // justifyContent: 'space-between',
                        borderBottom: '1px solid rgba(163, 163, 180, 0.2);',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        {offer.number_of_payments === 1 ? (
                            <Box mb={2}>
                                <Typography color="#F2F2F8" variant="caption" fontSize={12}>
                                    1 Payment
                                </Typography>
                                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                    <Typography
                                        color="#FCFCFD"
                                        variant="h4"
                                        fontSize={24}
                                        fontWeight={800}
                                    >
                                        {currencyFormatter(offer.total_payment_amount)}
                                    </Typography>
                                    <Typography
                                        color="#FCFCFD"
                                        variant="body1"
                                        fontWeight={400}
                                        fontSize={16}
                                        ml={1}
                                    >{`due ${dateFormatter(offer.first_payment_date)}`}</Typography>
                                </Box>
                            </Box>
                        ) : (
                            <Box mb={2}>
                                <Typography
                                    color="#F2F2F8"
                                    variant="caption"
                                    fontSize={12}
                                >{`${offer.number_of_payments} Payments`}</Typography>
                                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                    <Typography color="#FCFCFD" variant="h4" fontSize={24}>
                                        {currencyFormatter(offer.total_payment_amount)}
                                    </Typography>
                                    <Typography
                                        color="#FCFCFD"
                                        variant="body1"
                                        fontWeight={400}
                                        fontSize={16}
                                        ml={1}
                                    >{`every week, for ${offer.number_of_payments} weeks`}</Typography>
                                </Box>
                            </Box>
                        )}
                    </Box>
                    {offer.number_of_payments !== 1 && (
                        <Box sx={{ alignSelf: 'flex-end' }}>
                            <PaymentTimeline offer={offer} />
                        </Box>
                    )}
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        mb: 2,
                        mt: 2,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Box>
                            <Typography color="#F2F2F8" variant="caption" fontSize={12}>
                                Total fees
                            </Typography>
                            <Typography color="#FCFCFD" variant="h4" fontSize={16}>
                                {currencyFormatter(
                                    offer.origination_fee_amount + offer.total_rate_amount
                                )}
                            </Typography>
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Box>
                            <Typography color="#F2F2F8" variant="caption" fontSize={12}>
                                Total payback
                            </Typography>
                            <Typography color="#FCFCFD" variant="h4" fontSize={16}>
                                {currencyFormatter(offer.total_payment_amount)}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        )
    );
};

const PaymentTimeline = ({ offer }) => {
    return (
        !!offer && (
            <Timeline sx={{ margin: 0, p: 0 }}>
                <TimelineItem key={'first-payment'}>
                    <TimelineSeparator>
                        <TimelineDot sx={{ fill: '#E2E2ED' }} />
                        <TimelineConnector sx={{ fill: '#E2E2ED', height: 2 }} />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                width: 250,
                            }}
                        >
                            <Typography
                                variant="body1"
                                color="#DEDEDE"
                                fontSize={16}
                                fontWeight={400}
                            >{`${formatDateShort(offer.first_payment_date)}`}</Typography>
                            <Typography color="#DEDEDE" variant="caption" fontSize={16} fontWeight>
                                {currencyFormatter(offer.first_payment_amount)}
                            </Typography>
                        </Box>
                        <Typography color="#DEDEDE" variant="body1" fontSize={12}>
                            First Payment
                        </Typography>
                    </TimelineContent>
                </TimelineItem>

                {offer.number_of_payments > 2 && (
                    <TimelineItem key={'placeholder'}>
                        <TimelineSeparator>
                            <TimelineDot sx={{ fill: '#E2E2ED' }} />
                            <TimelineConnector sx={{ fill: '#E2E2ED' }} />
                        </TimelineSeparator>
                        <TimelineContent>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    width: 250,
                                }}
                            >
                                <Typography color="#DEDEDE" variant="caption" fontSize={16}>
                                    ...
                                </Typography>
                            </Box>
                        </TimelineContent>
                    </TimelineItem>
                )}

                <TimelineItem key={'last-payment'}>
                    <TimelineSeparator>
                        <TimelineDot sx={{ fill: '#E2E2ED' }} />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                width: 250,
                            }}
                        >
                            <Typography
                                variant="body1"
                                color="#DEDEDE"
                                fontSize={16}
                                fontWeight={400}
                            >{`${formatDateShort(offer.last_payment_date)}`}</Typography>
                            <Typography color="#DEDEDE" variant="caption" fontSize={16}>
                                {currencyFormatter(offer.last_payment_amount)}
                            </Typography>
                        </Box>
                        <Typography color="#DEDEDE" variant="body1" fontSize={12}>
                            Last Payment
                        </Typography>
                    </TimelineContent>
                </TimelineItem>
            </Timeline>
        )
    );
};
