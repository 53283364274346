import {
    Box,
    Typography,
    List,
    ListItem,
    ListItemAvatar,
    IconButton,
    Avatar,
    Chip,
    Stack,
} from '@mui/material';
import { FC, ReactNode, MouseEvent } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PaidIcon from '@mui/icons-material/MonetizationOn';
import { currencyFormatter } from '@lendica/utils';
import { PaymentType, TransactionCategory } from '../sharedTypes';
import StoreIcon from '@mui/icons-material/Store';
import { formatDateSlashed } from '@lendica/utils';
import StatusBadge from './StatusBadge';

export interface HistoryListItemProps {
    dealId: string;
    amount: number;
    product: string;
    date?: string;
    transactionCategory?: string;
    rate?: number;
    action?: boolean;
    status: string;
    hasNavigation?: boolean;
    onNavigationClick?(e: MouseEvent<HTMLElement>): void;
}

const mapCodeToLabel = (code: string) => {
    if (code === '001') {
        return 'Vendor Disburse'
    } else if (code === '002') {
        return 'Company Disburse'
    } else if (code === '005') {
        return 'Company Collection'
    } else if (code === '111') {
        return 'Origination Fee to Servicer'
    }
}

export const HistoryListItem: FC<HistoryListItemProps> = ({
    dealId,
    amount,
    product,
    date,
    transactionCategory,
    status,
    action = false,
    hasNavigation = false,
    onNavigationClick,
}) => {

    let icon = (transactionCategory === "005" || transactionCategory === "111") ? (<CreditCardIcon />) :
        transactionCategory === "002" ? (<PaidIcon />) : <StoreIcon />

    let label = mapCodeToLabel(transactionCategory)

    return (

        <ListItem
            button
            component="a"
            // sx={{ py: 2, px: 0 }}
            secondaryAction={
                action ? (
                    <IconButton edge="end">
                        <ArrowForwardIosIcon />
                    </IconButton>
                ) : null
            }
            disableGutters
            divider
            onClick={onNavigationClick}
        >
            {
                <ListItemAvatar>
                    <Avatar>{icon}</Avatar>
                </ListItemAvatar>
            }

            <Box flex="1">
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Typography variant="body2" color="text.primary" sx={{ fontWeight: 400, fontSize: "14px", marginLeft: '0.5rem' }}>
                        {label}
                    </Typography>
                </Box>

                {date && (
                    <Stack direction="row" spacing={1}>
                        <Chip
                            size="small"
                            key={'id'}
                            sx={{
                                bgcolor: 'rgba(46, 49, 146, 0.08)',
                                color: 'primary.main'
                            }}
                            label={dealId}
                        />
                        <Chip
                            size="small"
                            key={'date'}
                            label={formatDateSlashed(date, true)}
                            sx={{
                                bgcolor: 'rgba(46, 49, 146, 0.08)',
                                color: 'primary.main'
                            }}
                        />
                    </Stack>
                )}
            </Box>

            <Box display="flex" flexDirection="column" alignItems="flex-end">
                <Typography variant="body2" color="text.primary">
                    {label === 'Payment' ? `+${currencyFormatter(amount)}` : `${currencyFormatter(amount)}`}
                </Typography>
                <StatusBadge status={status} />
            </Box>

            {hasNavigation && (
                <IconButton size="large" onClick={onNavigationClick} disableRipple>
                    <ArrowForwardIosIcon />
                </IconButton>
            )}
        </ListItem>
    );
};
