import { Route, Routes, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Box, Backdrop, CircularProgress } from '@mui/material';
import {
    Header,
    Loader,
    Body,
    StatusPage,
    ProductPending,
    ProductPaused,
    Waitlist,
    FeatureLayout,
} from '@lendica/components';
import {
    WithdrawalConfirmation,
    Withdrawal,
    Application,
    Activities,
    PayEarlyReview,
    PaymentSuccess,
    PaymentFailure,
    Payment,
    PayEarly,
    Main,
} from './views';
import { TransactionDetails } from './views/Activities/TransactionDetails';
import { WithdrawalFailure } from './views/Withdrawal/WithdrawalFailure';
import { WithdrawalSuccess } from './views/Withdrawal/WithdrawalSuccess';
import { DrawDownView } from './DrawDownView';
import { useDrawDown } from './hooks/useDrawDown';
import { useEffect, useState } from 'react';
import * as api from './api/index';
import { percentageFormatter } from '@lendica/utils';

// Potentially moving setError to its' own container would be a cleaner solution for use in different apps
export const DrawDown = ({ placeholder }) => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const [approval, setApproval] = useState(null);
    const [rate, setRate] = useState(0);
    const [rates, setRates] = useState([]);
    const [deals, setDeals] = useState([]);
    const [dealCreated, setDealCreated] = useState(false);
    const [withdrawalData, setWithdrawalData] = useState({
        withdrawal_amount: 0,
        net_funded_amount: 0,
        weekly_pmt: 0,
        total_pmt: 0,
        factor_rate: 0,
        origination_fee_percentage: 0,
        term_length: 0,
    });

    useEffect(() => {
        const getInfo = async () => {
            try {
                const [deals, approvals, rateCurves] = await Promise.all([
                    api.getDeals(),
                    api.getDrawDownApproval(),
                    api.getRateCurves(),
                ]);

                const drawdownApproval = approvals.find(x => x.active && x.product_name === 'drawdown');
                const approval = await api.getApprovalDetails(drawdownApproval.id);

                const rates = rateCurves
                    .filter(x => x.approval === drawdownApproval.id)
                    .sort((a, b) => b.day - a.day);

                const [maxApprovalRate = {}] = rates;

                setWithdrawalData({
                    ...withdrawalData,
                    factor_rate: maxApprovalRate.daily_rate * maxApprovalRate.day + 1,
                    origination_fee_percentage: approval.origination_fee_percentage,
                    term_length: approval.max_days_outstanding,
                });
                setRate(maxApprovalRate);
                setRates(rates);
                setApproval(drawdownApproval);
                setDeals(deals);

                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        };
        getInfo();
    }, []);

    useEffect(() => {
        const updateApproval = async () => {
            setIsLoading(true);
            const res = await api.getDrawDownApproval();
            const drawdownApproval = res.find(x => x.active && x.product_name === 'drawdown');
            const updatedDeals = await api.getDeals();

            setApproval(drawdownApproval);
            setDeals(updatedDeals);
            setIsLoading(false);
            setDealCreated(false);
        };
        if (dealCreated) {
            updateApproval();
        }
    }, [dealCreated]);

    const setWithdrawal = (amount) => {
        const netFundedAmount = amount * (1 - withdrawalData.origination_fee_percentage);
        const totalPayment = amount * (rate.daily_rate * withdrawalData.term_length + 1);
        const weeklyPayment = totalPayment / (withdrawalData?.term_length / 7);

        setWithdrawalData({
            ...withdrawalData,
            withdrawal_amount: amount,
            net_funded_amount: netFundedAmount,
            weekly_pmt: weeklyPayment,
            total_pmt: totalPayment,
        });
    };

    const activate = async () => {
        try {
            await api.activateDrawdown();
            navigate('/drawdown');
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <>
            {isLoading && <FeatureLayout loading />}
            {!isLoading && (
                <Routes>
                    <Route
                        path="/*"
                        element={
                            <DrawDownView
                                rates={rates}
                                deals={deals}
                                approval={approval}
                                withdrawalData={withdrawalData}
                                setWithdrawal={setWithdrawal}
                                placeholder={<Box></Box>}
                                setDealCreated={setDealCreated}
                                rate={rate}
                            />
                        }
                    />
                    <Route path="/activities/detail" element={<TransactionDetails />} />
                    <Route path="/activities" element={<Activities />} />
                    <Route path="/payment" element={<Payment />} />
                    <Route
                        path="/withdrawal"
                        element={
                            <Withdrawal
                                setWithdrawal={setWithdrawal}
                                approval={approval}
                                withdrawalData={withdrawalData}
                                setDealCreated={setDealCreated}
                            />
                        }
                    />
                    <Route
                        path="/withdrawal/review"
                        element={
                            <WithdrawalConfirmation
                                approval={approval}
                                withdrawalData={withdrawalData}
                                setDealCreated={setDealCreated}
                            />
                        }
                    />
                    <Route path="/withdrawal/failure" element={<WithdrawalFailure />} />
                    <Route
                        path="/withdrawal/success"
                        element={
                            <WithdrawalSuccess withdrawalData={withdrawalData} deals={deals} />
                        }
                    />
                    <Route
                        path="/withdrawal/success/:dealId"
                        element={<WithdrawalSuccess withdrawalData={withdrawalData} />}
                    />
                    <Route path="/product-pending" element={<ProductPending />} />
                    <Route path="/product-paused" element={<ProductPaused />} />
                    <Route
                        path="/waitlist"
                        element={<Waitlist product="DrawDown" callback={activate} />}
                    />

                    {/* <Route path="/application/:applicationId/*" element={<Application />} />
                    <Reoute
                        path="/submitted"
                        element={
                            <StatusPage
                                title="Your application is being processed"
                                subtitle="We will get back to you soon"
                                duration={7}
                                actionLabel="Go Back"
                                action={() => {
                                    navigate('..');
                                }}
                            />
                        }
                    />
                    <Route path="/withdrawal" element={<Withdrawal />} />
                    <Route path="/withdrawal/review" element={<WithdrawalConfirmation />} />
                    <Route path="/withdrawal/success" element={<WithdrawalSuccess />} />
                    <Route path="/payment" element={<Payment />} />
                    <Route path="/payment/review" element={<PayEarlyReview />} />
                    <Route path="/payment/success" element={<PaymentSuccess />} />
                    <Route path="/payment/failure" element={<PaymentFailure />} />
                    <Route path="/payment/pay-early" element={<PayEarly />} />
                    <Route path="/activities" element={<Activities />} />
                    <Route path="/activities/detail" element={<TransactionDetails />} /> */}
                </Routes>
            )}
        </>
    );
};
