import { useNavigate } from 'react-router-dom';
import { IconButton, Box, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { Header, Body, Toolbar, PriceFormat, FeatureLayout } from '@lendica/components';
import { WithdrawalBody } from './WithdrawalBody';
import { useWithdraw } from './useWithdraw';
import { currencyFormatter } from '@lendica/utils';
import * as api from '../../api/index';

const useStyles = makeStyles(theme => ({
    button: {
        color: theme.palette.action.active,
    },
    toolbar: {
        backgroundColor: theme.palette.primary.light,
    },
    confirmButton: {
        width: '70%',
        height: 42,
        borderRadius: 30,
        boxShadow: 'none',
    },
    bold: {
        fontWeight: 800,
        display: 'flex',
    },
}));

export const WithdrawalConfirmation = ({ approval, withdrawalData, setDealCreated }) => {
    const navigate = useNavigate();
    const classes = useStyles();

    const handleConfirm = async withdrawal_amount => {
        try {
            const res = await api.withdraw(withdrawal_amount);
            setDealCreated(true);
            navigate(`/drawdown/withdrawal/success/${res.deal.id}`);
        } catch (e) {
            navigate('/drawdown/withdrawal/failure');
        }
    };

    return (
        <FeatureLayout>
            <Box
                sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box sx={{ flexGrow: 1, pb: 2 }}>
                    <Header
                        title="Withdrawal"
                        showLogo={false}
                        showClose={false}
                        leftActions={
                            <IconButton size="small" onClick={() => navigate('/drawdown/withdrawal')}>
                                <ChevronLeftIcon />
                            </IconButton>
                        }
                        rightActions={
                            <Button onClick={() => navigate('/drawdown')} className={classes.button}>
                                Cancel
                            </Button>
                        }
                    />
                </Box>
                <Body display="flex" flexDirection="column">
                    <Box flexDirection="column" display="flex" p={4}>
                        <Box display="flex" alignItems="center" flexDirection="column">
                            <Typography
                                variant="h5"
                                component={Box}
                                textAlign="center"
                                maxWidth={235}
                                style={{ marginBottom: 27 }}
                            >
                                Review
                            </Typography>
                            <Typography variant="caption">
                                {currencyFormatter(
                                    approval.funds_available - withdrawalData.withdrawal_amount
                                )}{' '}
                                credit left after withdrawal
                            </Typography>
                            <Box textAlign="center" mt={2}>
                                <Typography variant="caption" color="text.primary">
                                    Credit Withdrawn
                                </Typography>
                                <Typography variant="h4" component={Box} className={classes.bold}>
                                    <Typography variant="h5" component="span" className={classes.bold}>
                                        $
                                    </Typography>
                                    <PriceFormat
                                        value={withdrawalData?.withdrawal_amount}
                                        noDollar
                                        decimalScale={2}
                                    />
                                </Typography>
                            </Box>
                        </Box>
                        <WithdrawalBody withdrawalData={withdrawalData} />
                    </Box>
                </Body>
            {/* <Toolbar
                title="Bank Account"
                value="Capital One ****1234"
                action={
                    <IconButton size="small" onClick={() => navigate('..')}>
                        <ChevronRightIcon />
                    </IconButton>
                }
                className={classes.toolbar}
            /> */}
                <Toolbar
                    title="Total"
                    value={<PriceFormat value={withdrawalData?.withdrawal_amount} />}
                    action={
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.confirmButton}
                            disabled={withdrawalData?.withdrawal_amount <= 0}
                            onClick={() => handleConfirm(withdrawalData?.withdrawal_amount)}
                        >
                            Confirm
                        </Button>
                    }
                />
            </Box>
        </FeatureLayout>
    );
};
