import { useNavigate, useParams } from 'react-router-dom';
import { FeatureLayout } from '@lendica/components';
import { useEffect, useState } from 'react';
import { payLaterAPI } from '../payLaterAPI';
import { Box } from '@mui/material';
import { SelectFinalPaymentTerms } from '@lendica/select-paylater-terms';

export const SelectTermsView = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);

    const { billId, offerId } = useParams();

    useEffect(() => {
        const getInvoiceDetails = async () => {
            setLoading(true);
            const res = await payLaterAPI.getBillOffers(billId);
            setData(res);
            if (offerId) {
                let offerSelected = res.offers.find(x => x.id === offerId);
                onSelect(offerSelected);
            } else {
                onSelect(res.offers[0]);
            }
            setLoading(false);
        };
        getInvoiceDetails();
    }, []);

    const confirmDeal = async () => {
        navigate(`/paylater/bill/${billId}/terms/agree-to-terms/${offerId}`);
    };

    const onSelect = offer => {
        navigate(`/paylater/bill/${billId}/terms/select/${offer?.id}`);
    };

    return (
        <FeatureLayout loading={loading}>
            <Box
                sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    boxSizing: 'border-box',
                    width: '100%',
                    px: 4,
                    py: 2,
                }}
            >
                {data && (
                    <SelectFinalPaymentTerms
                        bill={data.bill}
                        offers={data.offers}
                        onTermSelected={onSelect}
                        onSelected={confirmDeal}
                    />
                )}
            </Box>
        </FeatureLayout>
    );
};
