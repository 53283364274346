import { Formik } from 'formik';
import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useFormikContext } from 'formik';

import { useFormContext } from './FormContext';
import { useFormikParams } from './useFormikParams';
import { formAPI } from './formAPI';
import { applicationCache } from './applicationCache';

const PassSubmitFormHandler = () => {
    const context = useFormikContext();
    const { setSubmitForm } = useFormContext();

    useEffect(() => {
        setSubmitForm(() => context.submitForm());
    }, [screen]);
    return null;
};

function getFieldValue(field, value) {
    if (field.field_type === 'dropzone') {
        return value?.map(item => item.key);
    }
    return value;
}

export const FormikData = ({ children }) => {
    const {
        data: {
            application,
            application: {
                total_steps,
                screen: [screen],
            },
        },
        settings: { onComplete },
        toggleIsLoading,
        setApplication,
    } = useFormContext();
    const params = useParams();
    const step = +params.step;
    const navigate = useNavigate();

    const [initialValues, validate, validationSchema] = useFormikParams(screen);

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validate={validate}
            validationSchema={validationSchema}
            onSubmit={async values => {
                const applicationWithValues = application;
                const mapFields = fields => {
                    return fields.map(field => {
                        const fieldCopy = { ...field };
                        if (fieldCopy.children) {
                            fieldCopy.children = mapFields(fieldCopy.children);
                        }
                        return {
                            ...fieldCopy,
                            field_value: getFieldValue(fieldCopy, values[fieldCopy.field_name]),
                        };
                    });
                };
                applicationWithValues.screen[0].fields = mapFields(
                    applicationWithValues.screen[0].fields
                );

                applicationCache[step - 1] = applicationWithValues;
                toggleIsLoading();
                try {
                    const { application: newApplication } = await formAPI.saveApplication({
                        application: applicationWithValues,
                        error: [],
                    });
                    if (step < total_steps) {
                        setApplication(newApplication);
                        navigate(`../${step + 1}`);
                    } else {
                        onComplete(newApplication);
                    }
                } catch (e) {
                } finally {
                    toggleIsLoading();
                }
            }}
        >
            {() => (
                <>
                    <PassSubmitFormHandler />
                    {children}
                </>
            )}
        </Formik>
    );
};
