import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { payLaterAPI } from '../payLaterAPI';
import { RoundedButton, RoundedLoadingButton } from '@lendica/components';
import { LoadingButton } from '@mui/lab';

export interface ArchiveDialogProps {
    open: boolean;
    billId: string;
    onClose: (archived: boolean) => void;
}

export default function ArchiveDialog({ open, billId, onClose }: ArchiveDialogProps) {
    const [loading, setLoading] = useState(false)

    const handleClose = () => {
        onClose(false)
    };

    const handleAgree = async () => {
        setLoading(true)
        await payLaterAPI.archiveBill(billId);
        onClose(true)
        setTimeout(() => {
            setLoading(false)
        }, 3000)
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
                sx: {
                    paddingY: 1, paddingX: 1
                }
            }}
        >
            <DialogTitle id="alert-dialog-title">
                Are you sure you want to delete this bill?
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    You will have to re-upload the invoice if you want to fund this bill again.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <LoadingButton loading={loading} onClick={handleAgree} autoFocus>
                    Delete
                </LoadingButton>
                {/* <Button onClick={handleAgree} autoFocus variant="contained">

                </Button> */}
            </DialogActions>
        </Dialog >
    );
}