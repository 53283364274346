import {
    alpha,
    Box,
    Chip,
    IconButton,
    lighten,
    TableCell,
    TableRow,
    Typography,
    Tooltip,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { Dropzone, FeatureHeader, FundNowLogo } from '@lendica/components';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { NewReleases as NewReleasesIcon } from '@mui/icons-material';
import { currencyFormatter, getEnvFromHost, formatDateSlashed } from '@lendica/utils';
import * as api from '../api/index';
import { DynamicTable } from '../Table';
import { ToggleChip, Tag, FeatureLayout } from '@lendica/components';
import { SelectFilter } from '../SelectFilter';
import { AllFilters, emptyFilter } from '../AllFilters';
import { ContextMenu } from './ContextMenu';
import { InvoicePdfViewer } from './InvoicePdfViewer';
// import { VideoViewer } from './VideoViewer';
import { UploadInvoice } from './UploadInvoice';
import { InvoiceStatus, DealStatus, billStatusToColor, billStatusToLabel } from '@lendica/utils';
import { useActions } from '../useActions';
// import ArchiveDialog from '../components/ArchiveDialog';
// import { Delete } from '@mui/icons-material';

export const columns = [
    {
        id: 'funded_date',
        label: 'Date',
        CellContent: ({ value }) => {
            if (!value) {
                return (
                    <Chip
                        icon={<NewReleasesIcon />}
                        label="NEW"
                        color="warning"
                        size="small"
                        sx={{ color: 'common.white' }}
                    />
                );
            }
            return <>{formatDateSlashed(value)}</>;
        },
    },
    {
        id: 'customer_name',
        label: 'Customer',
        tableCellProps: {
            padding: 'none',
        },
    },
    {
        id: 'amount',
        label: 'Amount',
        formatter: value => (value ? currencyFormatter(value) : 'N/A'),
    },
    {
        id: 'status',
        label: 'Status',
        CellContent: ({ value }) => {
            return (
                <Tag
                    label={typeof value === 'string' ? value : billStatusToLabel[value]}
                    color={billStatusToColor[value]}
                />
            );
            // return <Tag label={InvoiceStatus[value]} color={billStatusToColor[value]} />;
        },
        tableCellProps: {
            width: 125,
        },
    },
];

export const FundNowView = () => {
    const [data, setData] = useState(null);
    const [open, setOpen] = useState(false);
    const [invoiceId, setInvoiceId] = useState('');

    const handleClickOpen = id => {
        setInvoiceId(id);
        setOpen(true);
    };

    // const handleClose = async archived => {
    //     if (archived) {
    //         let invoices = await api.getInvoicesList();
    //         setData(prevState => ({
    //             approval: prevState.approval,
    //             deals: prevState.deals,
    //             invoices: invoices,
    //             company: prevState.company,
    //         }));
    //     }
    //     setInvoiceId('');
    //     setOpen(false);
    // };

    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            const initData = await Promise.all([
                api.getFundnowApproval(),
                api.getInvoices(),
                api.getDealsList(),
                api.getCompanyDetails(),
            ]);
            setData({
                approval: initData[0],
                invoices: initData[1],
                deals: initData[2],
                company: initData[3],
            });
            let company = initData[3];
            if (company.status === 2) {
                if (company.fundnow_status === 0) {
                    navigate('/fundnow/waitlist');
                } else if (company.fundnow_status === 1) {
                    navigate('/fundnow/product-pending');
                } else if (company.fundnow_status === 3) {
                    navigate('/fundnow/product-paused');
                }
            }
        })();
    }, []);

    const rows = useMemo(
        () =>
            data
                ? [
                      ...data.invoices
                          .filter(item => item.status === InvoiceStatus.Created) // 0, previously called Available
                          .map(({ id, invoice_number, status, customer_name, total }) => ({
                              id,
                              invoice_number,
                              customer_name,
                              amount: total,
                              status,
                              type: 'invoice',
                          })),
                      ...data.deals.map(
                          ({
                              id,
                              invoice_number,
                              funded_date,
                              customer_name,
                              total_collected,
                              payback_amount,
                              deal_status,
                              gross_funded_amount,
                              customer,
                          }) => ({
                              id,
                              invoice_number,
                              funded_date,
                              customer_name: customer ? customer_name : 'N/A',
                              amount: gross_funded_amount,
                              paid: Math.floor((total_collected / payback_amount) * 100),
                              status: deal_status,
                              type: 'deal',
                          })
                      ),
                  ]
                : undefined,
        [data]
    );

    const [filter, setFilter] = useState(emptyFilter);
    const statuses = useMemo(
        () =>
            rows
                ? [...new Set(rows.map(item => item.status))].map(item => ({
                      label: typeof item === 'string' ? item : billStatusToLabel[item],
                      value: item,
                  }))
                : [],
        [rows]
    );
    const customers = useMemo(
        () =>
            rows
                ? [...new Set(rows.map(item => item.customer_name))].map(item => ({
                      label: item,
                      value: item,
                  }))
                : [],
        [rows]
    );

    const filteredSortedData = useMemo(() => {
        let result = [...(rows ?? [])];

        if (filter.earliestFirst) {
            result.sort((a, b) => {
                if (!a.funded_date) {
                    return -1;
                }
                if (!b.funded_date) {
                    return 1;
                }
                return new Date(a.funded_date).valueOf() - new Date(b.funded_date).valueOf();
            });
        } else {
            result.sort((a, b) => {
                if (!a.funded_date) {
                    return -1;
                }
                if (!b.funded_date) {
                    return 1;
                }
                return new Date(b.funded_date).valueOf() - new Date(a.funded_date).valueOf();
            });
        }

        if (filter.status.length > 0) {
            const statusSet = new Set(filter.status);
            result = result.filter(item => statusSet.has(item.status));
        }
        if (filter.customer.length > 0) {
            const customerSet = new Set(filter.customer);
            result = result.filter(item => customerSet.has(item.customer_name));
        }
        if (filter.liveDeals) {
            result = result.filter(
                item =>
                    item.status === DealStatus.Current ||
                    item.status === DealStatus.Late ||
                    item.status === DealStatus['Grace Period']
            );
        }
        if (filter.pastDeals) {
            result = result.filter(
                item =>
                    item.status === DealStatus.Completed ||
                    item.status === DealStatus['Early Completed'] ||
                    item.status === DealStatus.Default
            );
        }
        if (filter.notActivated) {
            result = result.filter(item => item.status === DealStatus.Submitted);
        }
        return result;
    }, [rows, filter]);

    const [uploadedFile, setUploadedFiles] = useState([]);

    return (
        <>
            {uploadedFile.length === 0 ? (
                <FeatureLayout loading={rows === undefined}>
                    <Box
                        sx={{
                            px: 4,
                            pb: 4,
                            pt: 1,
                            boxSizing: 'border-box',
                            width: '100%',
                        }}
                    >
                        {data && data.approval && (
                            <Box sx={{ mt: 2 }}>
                                <FeatureHeader
                                    name={<FundNowLogo />}
                                    total={data.approval.qualified_amount}
                                    available={data.approval.funds_available}
                                    unpaid={data.approval.total_payoff_amount}
                                    entity="invoices"
                                    entityNumber={data.approval.number_of_active_deals}
                                />
                            </Box>
                        )}

                        <Box
                            display="flex"
                            flexDirection="row"
                            flexWrap="wrap"
                            alignItems="center"
                            my={1}
                        >
                            <Typography
                                color="primary.dark"
                                variant="body2"
                                fontWeight={700}
                                // mr={0.5}
                            >
                                Upload an invoice here to start FundNow
                            </Typography>
                            {/* <Tooltip title="How does it work?" placement="right">
                                <IconButton
                                    color="info"
                                    size="small"
                                    onClick={() => navigate('walkthrough/view')}
                                >
                                    <HelpOutlineIcon />
                                </IconButton>
                            </Tooltip> */}
                        </Box>

                        <Box>
                            <Dropzone
                                value={uploadedFile}
                                onChange={value => {
                                    setUploadedFiles(value);
                                }}
                                // onDrop={() => {
                                //     navigate('..');
                                // }}
                                accept={{
                                    'image/png': ['.png'],
                                    'image/jpg': ['.jpg'],
                                    'image/jpeg': ['.jpeg'],
                                    'application/pdf': ['.pdf'],
                                }}
                                acceptHelper="Accepting pdf and images."
                                label="+ New"
                                api={{
                                    presignedUrl:
                                        'https://micro-awsmanager.herokuapp.com/s3/signed-url',
                                    getObjectKey(fileName) {
                                        const ext = fileName.split('.').pop();
                                        return `${getEnvFromHost(window.location.hostname)}/${
                                            data.approval.company
                                        }/invoice/invoice_${new Date().valueOf()}.${ext}`;
                                    },
                                    presignedUrlParams: {
                                        bucket_name: api.bucket_name,
                                    },
                                }}
                            />
                        </Box>

                        <Box sx={{ mt: 5 }}>
                            <Typography color="text.secondary" variant="h5">
                                My Invoices
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: 0.5,
                                    pb: 1,
                                    mt: 3,
                                    boxSizing: 'border-box',
                                    overflowX: 'auto',
                                    // flexWrap: 'wrap',
                                }}
                            >
                                <AllFilters
                                    initialValue={filter}
                                    onChange={value => {
                                        setFilter(value);
                                    }}
                                    statuses={statuses}
                                    customers={customers}
                                />
                                <ToggleChip
                                    label="Live Deals"
                                    value={filter.liveDeals}
                                    onChange={value => {
                                        setFilter({ ...filter, liveDeals: value });
                                    }}
                                />
                                <SelectFilter
                                    label="Status"
                                    options={statuses}
                                    value={filter.status}
                                    onChange={value => {
                                        setFilter({ ...filter, status: value });
                                    }}
                                />
                                <SelectFilter
                                    label="Customer"
                                    options={customers}
                                    value={filter.customer}
                                    onChange={value => {
                                        setFilter({ ...filter, customer: value });
                                    }}
                                />
                                <ToggleChip
                                    label="Earliest first"
                                    value={filter.earliestFirst}
                                    onChange={value => {
                                        setFilter({ ...filter, earliestFirst: value });
                                    }}
                                />
                            </Box>
                            <DynamicTable
                                columns={columns}
                                data={filteredSortedData}
                                ContextMenu={ContextMenu}
                                DetailContent={({ row }) => {
                                    const actions = useActions(row);

                                    const headerSx = {
                                        fontWeight: 'medium',
                                        borderTop: theme =>
                                            `1px solid ${lighten(
                                                alpha(theme.palette.divider, 1),
                                                0.88
                                            )}`,
                                    };

                                    return (
                                        <>
                                            <TableRow>
                                                <TableCell
                                                    padding="none"
                                                    sx={{
                                                        borderBottom: 'unset',
                                                    }}
                                                ></TableCell>
                                                <TableCell
                                                    sx={{
                                                        borderBottom: 'unset',
                                                    }}
                                                ></TableCell>
                                                <TableCell padding="none" sx={headerSx}>
                                                    ID
                                                </TableCell>
                                                <TableCell sx={headerSx}>Paid</TableCell>
                                                <TableCell sx={headerSx}>Action</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell padding="none"></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell padding="none">
                                                    {row.invoice_number}
                                                </TableCell>
                                                <TableCell>
                                                    {row.paid !== undefined
                                                        ? row.paid + '%'
                                                        : 'N/A'}
                                                </TableCell>
                                                <TableCell>
                                                    {actions.map(({ Icon, onClick, title }) => (
                                                        <Tooltip
                                                            key={title}
                                                            title={title}
                                                            placement="top"
                                                        >
                                                            <IconButton
                                                                size="small"
                                                                onClick={onClick}
                                                            >
                                                                <Icon fontSize="inherit" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    ))}
                                                    {/* {row.type === 'invoice' && row.status === 0 && (
                                                        <Tooltip
                                                            key={'Delete'}
                                                            title={'Delete'}
                                                            placement="top"
                                                        >
                                                            <IconButton
                                                                size="small"
                                                                onClick={() =>
                                                                    handleClickOpen(row.id)
                                                                }
                                                            >
                                                                <Delete fontSize="inherit" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )} */}
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    );
                                }}
                            />
                            {/* <ArchiveDialog open={open} invoiceId={invoiceId} onClose={handleClose} /> */}
                        </Box>
                    </Box>
                </FeatureLayout>
            ) : (
                <>
                    <Box sx={{ display: 'none' }}>
                        <Dropzone
                            value={uploadedFile}
                            onChange={value => {
                                setUploadedFiles(value);
                            }}
                            api={{
                                presignedUrl:
                                    'https://micro-awsmanager.herokuapp.com/s3/signed-url',
                            }}
                        />
                    </Box>
                    <UploadInvoice
                        files={uploadedFile}
                        onExit={() => {
                            setUploadedFiles([]);
                        }}
                    />
                </>
            )}
            <Routes>
                <Route path=":type/:id/view" element={<InvoicePdfViewer />} />
                {/* <Route
                    path="walkthrough/view"
                    element={<VideoViewer videoUrl="https://www.youtube.com/embed/9Kd4Qgj0IK8" />}
                /> */}
                <Route path="*" element={<Box></Box>} />
            </Routes>
        </>
    );
};
