import { InvoiceStatus, DealStatus } from '@lendica/utils';

export const enum RowType {
    Bill = 'bill',
    Deal = 'deal',
}

export interface BillOrDealRow {
    id: string;
    invoice_number: string;
    funded_date?: string;
    vendor_name: string;
    amount?: number;
    paid?: number;
    type: RowType;
    status: InvoiceStatus | DealStatus;
}
