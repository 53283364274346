import { useMemo } from 'react';
import {
    RemoveRedEye,
    // AppRegistration,
    ImportExport,
    Payments,
    SvgIconComponent,
    LibraryAddCheck,
    Delete,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { DealStatus, InvoiceStatus } from '@lendica/utils';
import { archiveInvoice } from './api';

export interface Action {
    title: string;
    onClick(): void;
    Icon: SvgIconComponent;
}

export const useActions = (row: any): Action[] => {
    const navigate = useNavigate();

    return useMemo(() => {
        const status = row?.status;

        const viewInvoice = {
            title: 'View invoice',
            onClick() {
                navigate(`${row?.type}/${row?.id}/view`);
            },
            Icon: RemoveRedEye,
        };

        const activate = {
            title: 'Activate',
            onClick() {
                navigate(`${row?.type}/${row?.id}/confirm`);
            },
            Icon: LibraryAddCheck,
        };

        const archive = {
            title: 'Delete',
            onClick() {
                navigate(`${row?.type}/${row?.id}/delete`);
                archiveInvoice(`${row?.id}`);
            },
            Icon: Delete,
        };

        const seePaymentSchedules = {
            title: 'See payment schedules',
            onClick() {},
            Icon: Payments,
        };

        const exportReport = {
            title: 'Export report',
            onClick() {},
            Icon: ImportExport,
        };

        const res: Action[] = [viewInvoice];

        if (status === InvoiceStatus.Created) {
            res.push(activate);
            // res.push(archive);
        }

        // if (status === DealStatus.Available) {
        //     res.push(activate);
        // } else if (status === DealStatus.InProgress) {
        //     res.push(seePayemntSchedules);
        //     res.push(exportReport);
        // } else if (status === DealStatus.Overdue) {
        //     res.push(seePayemntSchedules);
        //     res.push(exportReport);
        // } else if (status === DealStatus.Complete) {
        //     res.push(exportReport);
        // }

        return res;
    }, [row]);
};
