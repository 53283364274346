import {
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableCellProps,
    TablePagination,
    IconButton,
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@mui/icons-material';
import { FC, useMemo, useState, Fragment, MouseEvent } from 'react';

export interface CellContentProps<T = any> {
    value: T;
}

export interface Column {
    id: string;
    label: string;
    formatter?(val: unknown): string;
    CellContent?: FC<CellContentProps>;
    tableCellProps?: TableCellProps;
}

export interface ContextMenuProps<T = unknown> {
    contextMenu?: { mouseX: number; mouseY: number; row: T };
    handleClose: () => void;
}

export interface TableProps<T extends {}> {
    columns: Column[];
    data: T[];
    DetailContent: FC<{ row: T }>;
    ContextMenu: FC<ContextMenuProps<T>>;
}

export function DynamicTable<T extends {}>({
    columns,
    data,
    DetailContent,
    ContextMenu,
}: TableProps<T>): ReturnType<FC> {
    const [expandedRow, setExpandedRow] = useState<T>();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const dataPage = useMemo(
        () => data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
        [data, page, rowsPerPage]
    );

    const [contextMenu, setContextMenu] = useState<{
        mouseX: number;
        mouseY: number;
        row: T;
    }>();

    const handleContextMenu = (event: MouseEvent, row: T) => {
        event.preventDefault();
        setContextMenu(
            !contextMenu
                ? {
                    mouseX: event.clientX - 2,
                    mouseY: event.clientY - 4,
                    row,
                }
                : undefined
        );
    };

    const handleClose = () => {
        setContextMenu(undefined);
    };

    return (
        <>
            <TableContainer component={Paper} elevation={0}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell padding="none"></TableCell>
                            {columns.map(column => (
                                <TableCell key={column.id} {...(column?.tableCellProps ?? {})}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataPage.map((row, index) => (
                            <Fragment key={index}>
                                <TableRow
                                    hover
                                    onContextMenu={e => {
                                        handleContextMenu(e, row);
                                    }}
                                    onClick={() => {
                                        setExpandedRow(expandedRow === row ? undefined : row);
                                    }}
                                    sx={{
                                        '&.MuiTableRow-hover:hover': {
                                            cursor: 'pointer',
                                        },
                                    }}
                                >
                                    <TableCell
                                        padding="none"
                                        style={expandedRow === row ? { borderBottom: 'unset' } : {}}
                                    >
                                        <IconButton
                                            onClick={e => {
                                                e.preventDefault();
                                            }}
                                            size="small"
                                            disableRipple
                                            disableFocusRipple
                                        >
                                            {expandedRow === row ? (
                                                <ExpandLessIcon />
                                            ) : (
                                                <ExpandMoreIcon />
                                            )}
                                        </IconButton>
                                    </TableCell>
                                    {columns.map(column => {
                                        const value = (row as { [key: string]: string | number })[
                                            column.id
                                        ];
                                        const CellContent: FC<CellContentProps> =
                                            column.CellContent ??
                                            (({ value }) => {
                                                const formatter =
                                                    column.formatter ?? ((v: string) => v);
                                                return <>{formatter(value)}</>;
                                            });
                                        return (
                                            <TableCell
                                                style={
                                                    expandedRow === row
                                                        ? { borderBottom: 'unset' }
                                                        : {}
                                                }
                                                key={column.id}
                                                {...(column?.tableCellProps ?? {})}
                                            >
                                                <CellContent value={value} />
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                                {expandedRow === row && <DetailContent row={row} />}
                            </Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(_event, newPage) => {
                    setPage(newPage);
                }}
                onRowsPerPageChange={event => {
                    setRowsPerPage(parseInt(event.target.value, 10));
                    setPage(0);
                }}
            />
            <ContextMenu contextMenu={contextMenu} handleClose={handleClose} />
        </>
    );
}
