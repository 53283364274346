import { Routes, Route, Outlet, useNavigate } from 'react-router-dom';
import { FundNowView } from './pages/FundNowView';
import { CompanyStatusHandler } from './pages/CompanyStatusHandler';
import { ActivateFundNow } from './pages/ActivateFundNow';
import { DealConfirmation } from './pages/DealConfirmation';
import { ConfirmDetails } from './pages/ConfirmDetails';
import { ExceededLimit } from './pages/ExceededLimit';
import { DealSuccess } from './pages/DealSuccess';
import { DealSummary } from './pages/DealSummary';
import { InvoicePdfViewer } from './pages/InvoicePdfViewer';
import { Loading } from './pages/Loading';
import {
    ApplicationPending,
    ProductPaused,
    DealPending,
    ProductPending,
    ApplicationRejected,
    Waitlist,
} from '@lendica/components';
import * as api from './api/index';

export const FundNow = () => {
    const navigate = useNavigate();

    const activate = async () => {
        try {
            await api.activateFundnow();
            navigate('/fundnow');
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <Routes>
            <Route path="*" element={<FundNowView />} />
            <Route path="deal/:id" element={<DealSummary />} />
            <Route path="handle-company-status" element={<CompanyStatusHandler />} />
            <Route path="activate" element={<ActivateFundNow />} />
            <Route path="deal-pending" element={<DealPending />} />
            <Route path="application-rejected" element={<ApplicationRejected />} />
            <Route path="product-pending" element={<ProductPending />} />
            <Route path="pending" element={<ApplicationPending />} />
            <Route path="loading" element={<Loading />} />
            <Route path="exceeded-limit" element={<ExceededLimit />} />
            <Route path="product-paused" element={<ProductPaused />} />
            <Route path="waitlist" element={<Waitlist product="FundNow" callback={activate} />} />
            <Route path=":type/:id/confirm" element={<ConfirmDetails />} />
            {/* <Route path="marketing">
                <Route path="apply/:id" element={<MarketingOffer apply={true} />} />
                <Route path="activate/:id" element={<MarketingOffer activate={true} />} />
            </Route> */}
            <Route
                path=":type/:id/terms"
                element={
                    <>
                        <Outlet />
                        <InvoicePdfViewer collapsible />
                    </>
                }
            >
                <Route path="select" element={<DealConfirmation />} />
                <Route path="confirmation/:dealId" element={<DealSuccess />} />
            </Route>
        </Routes>
    );
};
