import { useEffect } from 'react';
import { usePlaidLink } from 'react-plaid-link';

export const OpenPlaidLink = ({ token, promiseHandlersRef }) => {
    const config = {
        token,
        onSuccess(public_token, metadata) {
            promiseHandlersRef.current.res({ public_token, metadata });
        },
        onExit() {
            promiseHandlersRef.current.rej();
        },
    };

    const { open, ready } = usePlaidLink(config);

    useEffect(() => {
        if (!ready) {
            return;
        }
        open();
    }, [ready, open]);

    return null;
};
