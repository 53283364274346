import { FundNowLogo } from '@lendica/components';
import {
    currencyFormatter,
    dateFormatter,
    formatDateSlashed,
    percentageFormatter,
} from '@lendica/utils';
import { Box, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useState } from 'react';

export const SummaryCard = ({ invoice, offer }) => {
    const [expanded, setExpanded] = useState(false);

    const getFrequency = paymentFrequency => {
        if (paymentFrequency === 'D') {
            return 'One-time';
        } else if (paymentFrequency === 'W') {
            return 'Weekly';
        }
    };

    const expand = () => {
        setExpanded(!expanded);
    };

    return (
        !!invoice &&
        !!offer && (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    p: 2,
                    background: '#F4F4F6',
                    height: 'fit-content',
                    width: '100%',
                    boxSizing: 'border-box',
                    borderRadius: '0px 0px 8px 8px',
                }}
            >
                {expanded && (
                    <>
                        <Box
                            sx={{
                                py: 2,
                            }}
                        >
                            <FundNowLogo height={16} />
                        </Box>

                        <Box
                            sx={{
                                pt: 2,
                                pb: 0.5,
                                borderBottom: '1px solid rgba(163, 163, 180, 0.2)',
                            }}
                        >
                            <Typography variant="h6" fontSize={12}>
                                Funding Details
                            </Typography>
                        </Box>

                        <Box
                            sx={{
                                py: 0.5,
                                borderBottom: '1px solid rgba(163, 163, 180, 0.2)',
                            }}
                        >
                            <SummaryLine
                                label={'Invoice Amount'}
                                leftValue={currencyFormatter(invoice.total)}
                            />
                            <SummaryLine
                                label={'Invoice Number'}
                                leftValue={invoice.invoice_number}
                            />
                        </Box>

                        <Box
                            sx={{
                                py: 0.5,
                                borderBottom: '1px solid rgba(163, 163, 180, 0.2)',
                            }}
                        >
                            <SummaryLine
                                label={'Advance Percentage'}
                                leftValue={percentageFormatter(offer.advance_rate)}
                            />
                            <SummaryLine
                                label={'Advance Amount'}
                                rightValue={currencyFormatter(offer.advance_amount)}
                            />

                            <SummaryLine
                                label={'Processing Fee %'}
                                leftValue={percentageFormatter(offer.origination_fee_percentage)}
                            />
                            <SummaryLine
                                label={'Processing Fee'}
                                rightValue={`(${currencyFormatter(offer.origination_fee_amount)})`}
                            />
                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                py: 0.5,
                            }}
                        >
                            <Typography variant="h6" fontSize={14} fontWeight={700}>
                                {'Amount received today'}
                            </Typography>
                            <Box sx={{ display: 'flex' }}>
                                <Typography variant="h6" fontSize={14} fontWeight={700} ml={1}>
                                    {currencyFormatter(offer.amount_recieved_today)}
                                </Typography>
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                pt: 2,
                                pb: 0.5,
                                borderBottom: '1px solid rgba(163, 163, 180, 0.2)',
                            }}
                        >
                            <Typography variant="h6" fontSize={12}>
                                Payment Schedule
                            </Typography>
                        </Box>

                        <Box
                            sx={{
                                py: 0.5,
                                borderBottom: '1px solid rgba(163, 163, 180, 0.2)',
                            }}
                        >
                            <SummaryLine
                                label={'Principal repay'}
                                rightValue={currencyFormatter(offer.advance_amount)}
                            />
                            <SummaryLine
                                label={'Total rate fee percentage'}
                                leftValue={percentageFormatter(offer.total_rate_percentage)}
                            />
                            <SummaryLine
                                label={'Total rate fee amount'}
                                rightValue={currencyFormatter(offer.total_rate_amount)}
                            />
                            <SummaryLine
                                label={'Days until invoice due'}
                                leftValue={offer.days_till_invoice_due}
                            />
                            <SummaryLine
                                label={'Number of payments'}
                                leftValue={offer.number_of_payments}
                            />
                            <SummaryLine
                                label={'Payment frequency'}
                                leftValue={getFrequency(offer.payment_frequency)}
                            />
                            {offer.number_of_payments === 1 ? (
                                <SummaryLine
                                    label={'First payment'}
                                    leftValue={formatDateSlashed(offer.first_payment_date)}
                                    rightValue={currencyFormatter(offer.first_payment_amount)}
                                />
                            ) : (
                                <>
                                    <SummaryLine
                                        label={'First payment'}
                                        leftValue={formatDateSlashed(offer.first_payment_date)}
                                        rightValue={currencyFormatter(offer.first_payment_amount)}
                                    />
                                    <SummaryLine
                                        label={'Last payment'}
                                        leftValue={formatDateSlashed(offer.last_payment_date)}
                                        rightValue={currencyFormatter(offer.last_payment_amount)}
                                    />
                                </>
                            )}
                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                py: 0.5,
                            }}
                        >
                            <Typography variant="h6" fontSize={14} fontWeight={700}>
                                {'Total payback amount'}
                            </Typography>
                            <Box sx={{ display: 'flex' }}>
                                <Typography variant="h6" fontSize={14} fontWeight={700} ml={1}>
                                    {currencyFormatter(offer.total_payment_amount)}
                                </Typography>
                            </Box>
                        </Box>
                    </>
                )}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        py: 2,
                        border: 'none',
                        background: 'inherit',
                        cursor: 'pointer',
                    }}
                    component={'button'}
                    onClick={expand}
                >
                    <Typography fontSize={13} fontWeight={500} color="info.main">
                        {expanded ? 'HIDE DEAL DETAILS' : 'SHOW DEAL DETAILS'}
                    </Typography>
                    {expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </Box>
            </Box>
        )
    );
};

const SummaryLine = ({ label, leftValue, rightValue }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                py: 0.5,
            }}
        >
            <Box flex={2} textAlign="left">
                <Typography variant="h6" fontSize={14} fontWeight={500} color="text.secondary">
                    {label}
                </Typography>
            </Box>
            <Box flex={1} textAlign="right">
                {leftValue && (
                    <Typography variant="h6" fontSize={14} fontWeight={500} color="text.secondary">
                        {leftValue}
                    </Typography>
                )}
            </Box>
            <Box flex={1} textAlign="right">
                {rightValue && (
                    <Typography variant="h6" fontSize={14} fontWeight={500} color="text.secondary">
                        {rightValue}
                    </Typography>
                )}
            </Box>
        </Box>
    );
};
