import { FC, Key, useEffect, useState, Fragment } from 'react';
import { Box, Typography, Tooltip, IconButton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { SummaryList, SidePanelLayout } from '@lendica/components';
import { useWalletDataContext } from '../WalletData';
import { walletAPI } from '../walletAPI';
import _ from 'lodash';
import {
    Deal,
    dateFormatter,
    currencyFormatter,
    percentageFormatter,
    formatProductName,
    Approval,
} from '@lendica/utils';
import { mapDealToSummary } from '../utils/helpers';

const mapApprovalToSummary = (approval: Approval) => {
    return [
        {
            label: 'Approval ID',
            value: approval.id ? approval.id.substring(0, 8) : '',
        },
        {
            label: 'Product',
            value: formatProductName(approval.product_name),
        },
        {
            label: 'Contract Start Date',
            value: dateFormatter(approval.contract_start_date),
        },
        {
            label: 'Term Length in Days',
            value: approval.term_length_in_days,
        },
        {
            label: 'Qualified Amount',
            value: currencyFormatter(approval.qualified_amount),
        },
        {
            label: 'Funds Available',
            value: currencyFormatter(approval.funds_available),
        },
        {
            label: 'Balance',
            value: currencyFormatter(approval?.total_payoff_amount ?? ''),
        },
        {
            label: 'Number of Active Deals',
            value: approval.number_of_active_deals,
        },
        {
            label: 'Total in Process Amount',
            value: currencyFormatter(approval.total_in_process_amount),
        },
        {
            label: 'Origination Fee Percentage',
            value: percentageFormatter(approval.origination_fee_percentage, 0),
        },
        {
            label: 'Max Days Outstanding',
            value: approval.max_days_outstanding,
        },
        {
            label: 'Advance Rate',
            value: percentageFormatter(approval.advance_rate, 0),
        },
    ];
};

const convertToCSV = (data: any) => {
    const replacer = function (key: string, value: string | number) {
        return value === null ? '' : value;
    };

    let csv = 'data:text/csv;charset=utf-8,';
    csv +=
        '\r\n' +
        'Client Name,Report Date' +
        '\r\n' +
        data!.company!.company_name +
        ',' +
        new Date().toLocaleDateString() +
        '\r\n';
    // csv += '\r\n' + 'Report Date' + '\r\n' + new Date().toLocaleDateString() + '\r\n';

    csv += '\r\n' + 'Contracts' + '\r\n';

    data!.approvals!.map((approval: Approval, i: number) => {
        let approvalSummary = mapApprovalToSummary(approval);
        if (i == 0) {
            let headers = approvalSummary.map(a => a.label).join(',') + '\r\n';
            csv += headers;
        }
        csv +=
            approvalSummary.map((x: any) => JSON.stringify(x.value, replacer)).join(',') + '\r\n';
    });

    csv += '\r\n' + 'Deals' + '\r\n';

    data!.deals!.map((deal: Deal, i: number) => {
        let dealSummary = mapDealToSummary(deal);
        if (i == 0) {
            let headers = dealSummary.map(a => a.label).join(',') + '\r\n';
            csv += headers;
        }
        csv += dealSummary.map((x: any) => JSON.stringify(x.value, replacer)).join(',') + '\r\n';
    });

    const encodedUri = encodeURI(csv);
    let link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `Lendica_Account_Summary_${data!.company!.id}_${Date.now()}.csv`);
    document.body.appendChild(link);

    link.click();
};

export const AccountSummaryView: FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState({ approvals: [], deals: [], company: {} });

    useEffect(() => {
        const getData = async () => {
            let summary = await walletAPI.getCompanySummary();
            setData(summary);
            setIsLoading(false);
        };
        getData();
    }, []);

    return (
        <SidePanelLayout loading={isLoading} redirectRoute="/wallet">
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'auto',
                }}
            >
                <Box
                    sx={{
                        maxWidth: 600,
                        width: '100%',
                        height: '100%',
                    }}
                >
                    {data?.approvals &&
                        data?.approvals.map((item: Approval, index: number) => (
                            <Fragment key={`account-summary-${index + 1}`}>
                                <SummaryList
                                    title={
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Typography variant="subtitle1">
                                                Contract No. {index + 1}
                                            </Typography>
                                            <Tooltip title="Download all as CSV">
                                                <IconButton
                                                    color="secondary"
                                                    aria-label="download report"
                                                    size="small"
                                                    onClick={() => convertToCSV(data)}
                                                >
                                                    <DownloadIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    }
                                    data={mapApprovalToSummary(item)}
                                />
                                <Box p={2} />
                            </Fragment>
                        ))}

                    {data?.deals &&
                        data?.deals.map((item: Deal | undefined, index: number) => (
                            <Fragment key={`account-summary-deal-${index + 1}`}>
                                <SummaryList
                                    title={
                                        <Typography variant="subtitle1">
                                            Deal No. {index + 1}
                                        </Typography>
                                    }
                                    data={mapDealToSummary(item)}
                                />
                                <Box p={2} />
                            </Fragment>
                        ))}

                    <Box pb={6} />
                </Box>
            </Box>
        </SidePanelLayout>
    );
};
