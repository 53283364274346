import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Chip } from '@mui/material';

import { useWalletElementContext } from '../views/WalletView';
import { useWalletDataContext } from '../WalletData';
import { DealStatus } from '@lendica/utils';

import { CellContentProps, Column, StaticTable } from './Table';
import { TagColor, Tag } from '@lendica/components';
import {
    currencyFormatter,
    percentageFormatter,
    dateFormatter,
    formatProductName,
} from '@lendica/utils';

const dealStatusToColorMapping = {
    'Current': 'success',
    'Completed': 'info',
    'Late': 'error',
    'Grace Period': 'warning',
    'Early Completed': 'info',
    'Default': 'error',
};

export const columns: Column[] = [
    {
        id: 'product',
        label: 'Product',
        CellContent: ({ value }: CellContentProps<string>) => {
            return (
                <Chip
                    label={formatProductName(value)}
                    size="small"
                    sx={{ color: '#2E3192', fontWeight: 500 }}
                />
            );
        },
        tableCellProps: {
            padding: 'none',
        },
    },
    {
        id: 'funded_date',
        label: 'Date',
        CellContent: ({ value }: CellContentProps<string>) => {
            return <>{dateFormatter(value, false)}</>;
        },
    },
    {
        id: 'amount',
        label: 'Amount',
        formatter: currencyFormatter,
        tableCellProps: {
            align: 'right',
            padding: 'none',
        },
    },
    {
        id: 'paid',
        label: 'Paid',
        formatter: (val: number) => percentageFormatter(val, 0),
        tableCellProps: {
            align: 'right',
        },
    },
    {
        id: 'deal_status',
        label: 'Status',
        CellContent: ({ value }: CellContentProps<string>) => {
            return (
                <Tag
                    label={value.includes('Early Completed') ? 'Completed' : value}
                    color={dealStatusToColorMapping[value]}
                />
            );
        },
        tableCellProps: {
            width: 95,
            align: 'center',
            padding: 'none',
        },
    },
];

export const MyDeals: FC = () => {
    const { myDealsRef } = useWalletElementContext();
    const { data } = useWalletDataContext();
    const navigate = useNavigate();

    const handleTableRowClick = (row: any) => {
        navigate(`deal/${row.id}`);
    };

    if (data.deals && data.deals.length !== 0) {
        data.deals.sort((a, b) => {
            return new Date(b.funded_date).valueOf() - new Date(a.funded_date).valueOf();
        });
    }

    return (
        <Box mt={3.5} pt={2} sx={{ minHeight: '100%' }} ref={myDealsRef}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center">
                    <Typography mb={0.5} variant="h5">
                        My Deals
                    </Typography>
                    {/* <IconButton color="secondary" onClick={() => navigate('/paylater')}>
                        <AddCircleOutlineIcon />
                    </IconButton> */}
                </Box>
                {/* <Button variant="text" color="info" endIcon={<CallMadeIcon />}>
                    Deal Summary
                </Button> */}
            </Box>
            <Box mt={2}>
                {data && data!.deals ? (
                    <StaticTable
                        columns={columns}
                        data={data!.deals.map(
                            ({
                                id,
                                invoice_number,
                                funded_date,
                                product_name,
                                total_collected,
                                payback_amount,
                                deal_status,
                                gross_funded_amount,
                            }) => ({
                                id,
                                invoice_number,
                                funded_date,
                                product: product_name,
                                amount: gross_funded_amount,
                                paid: total_collected / payback_amount,
                                deal_status,
                            })
                        )}
                        handleTableRowClick={handleTableRowClick}
                    />
                ) : (
                    <Typography variant="body1">You don't have any deals yet</Typography>
                )}
            </Box>
        </Box>
    );
};
