import { FC, useState } from 'react';
import { Box, List } from '@mui/material';
import { ActivityListItem } from '../components/ActivityListItem';
import { TransactionCategory, ScheduledPayment } from '../sharedTypes';
import { Approval } from '@lendica/utils';
import { FilterBar } from '../components/FilterBar';
import { NoSchedules } from '../components/NoSchedules';
import { useUpcomingPayments } from '../hooks/useUpcomingPayments';
import { parse, compareDesc, getDayOfYear, getYear, compareAsc, isToday } from 'date-fns';
import _ from 'lodash';

interface UpcomingPaymentsProps {
    payment_schedules: ScheduledPayment[];
    approvals: Approval[];
}

const filterer = (items: ScheduledPayment[], filters: any) => {
    let filteredItems = items.filter(x => ['005', '111'].includes(x.description_code));
    let filterCategories = Object.keys(filters);

    // sort descending
    filteredItems = filteredItems.sort((a: ScheduledPayment, b: ScheduledPayment) => {
        let date1 = parse(a.date, 'yyyy-MM-dd', new Date())
        let date2 = parse(b.date, 'yyyy-MM-dd', new Date())
        return compareAsc(date1, date2)
    })

    filterCategories.map(category => {
        if (_.isEmpty(filters)) {
            return filteredItems;
        }
        if (category === 'product') {
            if (filters[category].length === 0) {
                return filteredItems;
            } else {
                filteredItems = filteredItems.filter(item =>
                    filters[category].includes(item.product_name)
                );
            }
        }
        if (category === 'dealId') {
            if (filters[category].length === 0) {
                return filteredItems;
            } else {
                filteredItems = filteredItems.filter(x => filters[category].includes(x.deal));
            }
        }
        if (category === 'type') {
            if (filters[category].length === 0) {
                return filteredItems;
            } else {
                filteredItems = filteredItems.filter(x => filters[category].includes(x.description_code));
            }
        }
    });
    return filteredItems;
};

export const UpcomingPayments: FC<UpcomingPaymentsProps> = ({ payment_schedules, approvals }) => {
    const [filters, setFilters] = useState({});
    let currentDate = new Date()

    // filter out payments prior to today
    let filteredByDate = payment_schedules.filter((payment_schedule: ScheduledPayment) => {
        let date = parse(payment_schedule.date, 'yyyy-MM-dd', new Date())
        let compared = compareDesc(currentDate, date)
        let dateIsToday = isToday(date)
        return (compared >= 1 || dateIsToday)
    })

    const { filtersList } = useUpcomingPayments({
        payLater: !_.isEmpty(approvals.filter(approval => approval.product_name == "paylater")),
        drawDown: !_.isEmpty(approvals.filter(approval => approval.product_name == "drawdown")),
        fundNow: !_.isEmpty(approvals.filter(approval => approval.product_name == "fundnow")),
        payment_schedules: filteredByDate
    })

    const onChange = (value: any) => {
        setFilters(value)
    }

    let filtered_payment_schedules = filterer(filteredByDate, filters);

    return (
        <Box>
            {filteredByDate.length !== 0 ? (
                filtered_payment_schedules.length === 0 ? (
                    <>
                        <FilterBar
                            filters={filtersList}
                            initialValue={filters}
                            onChange={onChange}
                        />
                        <NoSchedules subtitle="No results matching filters." />
                    </>
                ) : (
                    <>
                        <FilterBar
                            filters={filtersList}
                            initialValue={filters}
                            onChange={onChange}
                        />
                        <List
                            sx={{
                                pr: 2,
                                overflow: 'auto',
                                height: '100%',
                                pb: 4,
                                boxSizing: 'border-box'
                            }}
                        >
                            {filtered_payment_schedules.map(payment_schedule => (
                                <ActivityListItem
                                    key={payment_schedule.id}
                                    dealId={payment_schedule.deal.substring(0, 8)}
                                    date={payment_schedule.date}
                                    amount={payment_schedule.amount}
                                    descriptionCode={payment_schedule.description_code}
                                    transactionCategory={TransactionCategory.Payment}
                                    product={payment_schedule.product_name}
                                    action={true}
                                    hasNavigation={false}
                                />
                            ))}
                        </List>
                    </>
                )
            ) : (
                <NoSchedules subtitle="You have no upcoming payments." />
            )}
        </Box>
    );
};
