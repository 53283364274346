import { Box, Typography, IconButton } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';

const useStyles = makeStyles(theme => ({
    boldText: { fontWeight: theme.typography.fontWeightBold },
}));

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.secondary,
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        // border: '1px solid #dadde9',
        boxShadow: theme.shadows[4],
    },
}));

export const InvoiceSubtitle = ({ subtitle, value, moreInfo = null }) => {
    const classes = useStyles();

    return (
        <Box display="flex" flexDirection="column" alignItems="center" px={2}>
            <Typography variant="overline">{subtitle}</Typography>
            <Box display="flex" flexDirection="row" alignItems="flex-start">
                <Typography
                    variant="subtitle1"
                    align="center"
                    className={classes.boldText}
                    color="text.secondary"
                >
                    {value}
                </Typography>
                {!!moreInfo && (
                    <HtmlTooltip title={moreInfo} enterTouchDelay={0} leaveTouchDelay={5000}>
                        <IconButton size="small">
                            <InfoIcon fontSize="small" />
                        </IconButton>
                    </HtmlTooltip>
                )}
            </Box>
        </Box>
    );
};
