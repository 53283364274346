import { FC, useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ChevronLeft, InfoOutlined } from '@mui/icons-material';
import {
    Alert,
    Box,
    Button,
    IconButton,
    InputAdornment,
    Stack,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    Typography,
    Tooltip,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { FormikProvider, useFormik } from 'formik';

import * as yup from 'yup';

import { InvoiceDetails, DeepPartial } from '../sharedTypes';
import { StepProgressContent } from './StepProgressContent';
import { InvoicePdfViewer } from './InvoicePdfViewer';

import CustomerSelect from '../components/CustomerSelect';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CustomerFormPopup from '../components/CustomerFormPopup';
import {
    Company,
    Customer,
    Approval,
    hasNumberOfDigits,
    isNumber,
    isNumberOfDigitsInRange,
    // isValidRoutingNumber,
    maskAccountNumber,
} from '@lendica/utils';
import * as api from '../api';

import {
    HeaderToolbar,
    FeatureLayout,
    FormikTextField,
    FormikDatePicker,
    BankAccount,
    CompanyBankStatus,
    BankService,
    CompanyBankSelect,
    BankSupportButton,
    ApprovalAccordion,
    SaveStatus,
    useSaveStatus,
    SaveFormButton,
} from '@lendica/components';

import { getApprovalSummary } from '@lendica/api';
import debounce from 'lodash/debounce';

const validationSchema = yup.object({
    invoice: yup.object({
        total: yup.number().nullable().required('Required field'),
        invoice_number: yup.string().nullable().required('Required field'),
        date: yup.string().nullable().required('Required field'),
        due_date: yup.string().nullable().required('Required field'),
        delivery_date: yup.string().nullable().required('Required field'),
    }),
    customer: yup.object({
        company_name: yup.string().nullable().required('Required field'),
        email: yup
            .string()
            .email('Please enter a valid email')
            .nullable()
            .required('Required field'),
    }),
    company: yup.object({
        bank_name: yup.string().nullable().required('Required field'),
        bank_account: yup
            .string()
            .trim()
            .required('Required field')
            .test('match', 'Must be a number', isNumber)
            .test(
                'len',
                'Must have at least 5 and less than 18 digits',
                isNumberOfDigitsInRange(5, 18)
            ),
        bank_routing: yup
            .string()
            .trim()
            .required('Required field')
            .test('match', 'Must be a number', isNumber)
            .test('len', 'Routing number must be 9 digits', hasNumberOfDigits(9)),
        // .test('is-valid-routing-number', 'Invalid routing number', isValidRoutingNumber)
    }),
});

export const ConfirmDetails: FC = () => {
    const [data, setData] = useState<InvoiceDetails | undefined>();
    const [activeStep, setActiveStep] = useState(1);
    const [open, setOpen] = useState(false);
    const [addNew, setAddNew] = useState(false);

    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();

    /* Bank */
    const [bankStatus, setBankStatus] = useState<CompanyBankStatus>(CompanyBankStatus.HAS_NONE);
    const [bankAccounts, setBankAccounts] = useState<BankAccount[]>([]);

    const handleBank = async (company: Company) => {
        // Step 1: check if company has default bank account
        if (BankService.hasDefaultBank(company)) {
            setBankStatus(CompanyBankStatus.HAS_DEFAULT);
            setBankAccounts([
                {
                    bank_account: maskAccountNumber(company!.bank_account || ''),
                    bank_name: company!.bank_name || '',
                    bank_routing: company!.bank_routing || '',
                },
            ]);
            return true;
        }

        // Step 2: check if company has connected plaid bank accounts
        const connectionList = await api.getDataConnections();
        const bankList = BankService.getBankAccounts(connectionList);
        const status = BankService.getConnectionStatus(bankList);
        setBankStatus(status);
        setBankAccounts(bankList);
    };
    /* End of Bank */

    useEffect(() => {
        (async () => {
            const res = await api.getInvoiceDetails(id!);
            await handleBank(res.company);
            setData(res);
            setActiveStep(2);
        })();
    }, [id]);

    /* Getting approval */
    interface ApprovalWithRate extends Approval {
        rateCurve: any;
    }
    const [approval, setApproval] = useState<ApprovalWithRate>();
    useEffect(() => {
        if (activeStep === 2) {
            (async () => {
                try {
                    // const fundnowApproval = await api.getFundnowFullApproval();
                    const fundnowApproval = await getApprovalSummary('fundnow');
                    setApproval(fundnowApproval![0]);
                } catch (e) {
                    console.log(e);
                }
            })();
        }
    }, [activeStep]);
    /* End of approval */

    const formik = useFormik<DeepPartial<InvoiceDetails>>({
        initialValues: data ?? { invoice: {}, customer: null, company: {} },
        async onSubmit(values) {
            try {
                await api.confirmInvoiceDetails(values);
                navigate(`../invoice/${id}/terms/select`);
            } catch (e) {
                console.log(e);
            }
        },
        validationSchema,
        enableReinitialize: true,
    });

    /* Auto save */
    let resetTimeout = 3000;
    const { saveStatus, setSaveStatus } = useSaveStatus(resetTimeout);
    const handleSave = async () => {
        try {
            setSaveStatus(SaveStatus.SUBMITTING);
            await api.confirmInvoiceDetails(formik.values);
            setSaveStatus(SaveStatus.SUCCESS);
            // setTimeout(() => {
            //     formik.resetForm({ values: formik.values });
            // }, resetTimeout);
        } catch (e) {
            console.log(e);
            setSaveStatus(SaveStatus.ERROR);
        }
    };

    const handleBlur = useCallback(
        debounce(async () => {
            if (formik.dirty) {
                await handleSave();
            }
        }, 300),
        [formik.dirty, handleSave]
    );

    const handleDateSave = useCallback(
        debounce(async () => {
            if (formik.dirty) {
                await handleSave();
            }
        }, 1000),
        [handleSave]
    );

    useEffect(() => {
        if (
            formik.values.invoice?.date &&
            formik.values.invoice?.due_date &&
            formik.values.invoice?.delivery_date
        ) {
            handleDateSave();
        }
    }, [
        formik.values.invoice?.date,
        formik.values.invoice?.due_date,
        formik.values.invoice?.delivery_date,
    ]);
    /* End of auto save */

    /* Customer */
    const handleClose = () => {
        setOpen(false);
        setAddNew(false);
    };

    const handleSubmit = (customer: Customer) => {
        handleCustomerSelect(customer);
        handleClose();
    };

    const addNewCustomer = () => {
        setOpen(true);
        setAddNew(true);
    };

    const editCustomer = () => {
        setOpen(true);
    };

    const handleCustomerSelect = async (customer: Customer) => {
        if (customer) {
            await api.postCustomerToInvoice(customer, id!);
            let invoice = await api.getInvoiceDetails(id!);
            formik
                .setValues(values => ({
                    ...values,
                    customer: invoice.customer,
                }))
                .then(() => {
                    handleSave();
                });
        } else {
            formik.setValues(values => ({
                ...values,
                customer: null,
            }));
        }
    };

    // returns true is customer is empty, else returns false
    const isCustomerEmpty = (customer: Customer | null | undefined) => {
        if (customer) {
            if (Object.values(customer).length === 0) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    };

    // returns true if customer is complete, else returns false
    const isCustomerComplete = (customer: Customer | null | undefined) => {
        if (customer) {
            if (Object.values(customer).length === 0) {
                return false;
            }
            return (
                customer?.company_name &&
                customer?.company_name !== '' &&
                customer?.email &&
                customer?.email !== '' &&
                customer?.company_address &&
                customer?.company_address !== '' &&
                customer?.first_name &&
                customer?.first_name !== '' &&
                customer?.last_name &&
                customer?.last_name !== '' &&
                customer?.phone_number &&
                customer?.phone_number !== ''
            );
        } else {
            return false;
        }
    };
    /* End of Customer */

    return (
        <FormikProvider value={formik}>
            <FeatureLayout>
                <Box
                    sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        boxSizing: 'border-box',
                    }}
                >
                    <Box sx={{ px: 2.5 }}>
                        <HeaderToolbar
                            label="Upload Invoice"
                            leftAction={
                                <IconButton
                                    size="large"
                                    onClick={() => {
                                        navigate('..');
                                    }}
                                >
                                    <ChevronLeft fontSize="inherit" />
                                </IconButton>
                            }
                        />
                    </Box>
                    <Box sx={{ px: 4, pt: 2, flexGrow: 1, overflowY: 'auto', pb: 4 }}>
                        <Stepper
                            activeStep={activeStep}
                            orientation="vertical"
                            sx={{
                                '& > .MuiStepConnector-root span.MuiStepConnector-line': {
                                    minHeight: '8px !important',
                                },
                            }}
                        >
                            <Step>
                                <StepLabel>File upload</StepLabel>
                                <StepProgressContent
                                    label="Files are uploading..."
                                    description="Please wait for invoices to finish uploading."
                                />
                            </Step>
                            <Step>
                                <StepLabel>Processing invoice</StepLabel>
                                <StepProgressContent
                                    label="Uploaded and processing..."
                                    description="It takes a few moments to process the invoice. You can continue to add invoices and come back in a few minutes to confirm the details."
                                />
                            </Step>
                            <Step>
                                <StepLabel>Confirm details</StepLabel>
                                <StepContent>
                                    {!!approval && approval.rateCurve && (
                                        <Box mt={2}>
                                            <ApprovalAccordion approval={approval} />
                                        </Box>
                                    )}
                                    <Typography fontWeight="bold" sx={{ mb: 1, mt: 3.5 }}>
                                        Invoice summary
                                    </Typography>
                                    <Box
                                        sx={{
                                            '& > .MuiTextField-root': {
                                                mt: 2,
                                            },
                                        }}
                                    >
                                        <FormikTextField
                                            name="invoice.total"
                                            label="Total Amount"
                                            fullWidth
                                            required
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        $
                                                    </InputAdornment>
                                                ),
                                            }}
                                            type="number"
                                            onBlur={handleBlur}
                                        />
                                        <FormikTextField
                                            name="invoice.invoice_number"
                                            label="Invoice Number"
                                            required
                                            fullWidth
                                            onBlur={handleBlur}
                                        />
                                        <Stack
                                            mt={2}
                                            spacing={2}
                                            direction="row"
                                            alignItems="center"
                                        >
                                            <FormikDatePicker
                                                name="invoice.date"
                                                TextFieldProps={{
                                                    label: 'Invoice Issue Date',
                                                    required: true,
                                                    fullWidth: true,
                                                    onBlur: handleBlur,
                                                }}
                                            />
                                            <FormikDatePicker
                                                name="invoice.delivery_date"
                                                TextFieldProps={{
                                                    label: 'Delivery Date',
                                                    required: true,
                                                    fullWidth: true,
                                                    onBlur: handleBlur,
                                                }}
                                            />
                                            <div>
                                                <Tooltip
                                                    title="The delivery date is the date when the customer received the goods. We do not accept invoices that are more than 90 days past the invoice date or invoices for goods that have not yet been delivered to the client."
                                                    placement="top"
                                                >
                                                    <IconButton
                                                        size="small"
                                                        sx={{
                                                            ml: -1,
                                                        }}
                                                    >
                                                        <InfoOutlined />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </Stack>
                                        <Stack
                                            direction="row"
                                            spacing={1}
                                            mt={2}
                                            alignItems="flex-start"
                                        >
                                            <FormikDatePicker
                                                name="invoice.due_date"
                                                disablePast
                                                TextFieldProps={{
                                                    label: 'Payment Due Date',
                                                    required: true,
                                                    fullWidth: true,
                                                    helperText:
                                                        'When you expect to be paid by the customer.',
                                                    onBlur: handleBlur,
                                                }}
                                            />
                                            <Box
                                                height="40px"
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                                <Tooltip
                                                    title={
                                                        approval &&
                                                        approval!.payment_schema === 'WEEKLY'
                                                            ? `The payment due date is when your customer is expected to pay you. Lendica will collect payments weekly starting a week from funding, up until the due date or when your approved maximum days outstanding are reached, whichever comes first.`
                                                            : `The payment due date is when your customer is expected to pay you. Lendica will collect one payment from you either on the due date or when your approved maximum days outstanding are reached, whichever comes first.`
                                                    }
                                                    placement="top"
                                                >
                                                    <IconButton size="small">
                                                        <InfoOutlined />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Stack>
                                    </Box>

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            height: 'fit-content',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            pt: 3.5,
                                            pb: 1,
                                        }}
                                    >
                                        <Typography fontWeight="bold">Customer</Typography>
                                        <Button
                                            variant="text"
                                            endIcon={<PersonAddIcon />}
                                            onClick={addNewCustomer}
                                        >
                                            Add New Customer
                                        </Button>
                                    </Box>

                                    <Box
                                        sx={{
                                            '& > .MuiTextField-root': {
                                                mt: 2,
                                            },
                                        }}
                                    >
                                        {isCustomerEmpty(formik.values.customer) && (
                                            <Alert severity="warning">
                                                Please add a customer to continue.
                                            </Alert>
                                        )}
                                        <Box display="flex" alignItems="center" gap={1} mt={2}>
                                            <CustomerSelect
                                                handleSelect={handleCustomerSelect}
                                                selectedCustomer={formik.values.customer}
                                            />
                                            <Button
                                                disabled={isCustomerEmpty(formik.values.customer)}
                                                onClick={editCustomer}
                                            >
                                                Edit
                                            </Button>
                                        </Box>

                                        {!isCustomerComplete(formik.values.customer) &&
                                            !isCustomerEmpty(formik.values.customer) && (
                                                <Alert sx={{ mt: 1 }} severity="warning">
                                                    This customer's information is incomplete.
                                                    Please update the customer info to continue.
                                                </Alert>
                                            )}
                                        {!isCustomerEmpty(formik.values.customer) && (
                                            // <TextField
                                            //     name="customer.email"
                                            //     size="small"
                                            //     label="Contact Email"
                                            //     type="string"
                                            //     fullWidth
                                            //     required
                                            //     value={formik.values.customer?.email || ''}
                                            //     onChange={formik.handleChange}
                                            //     helperText={
                                            //         'Customer information may be used to verify the invoice.'
                                            //     }
                                            // />
                                            <FormikTextField
                                                name="customer.email"
                                                label="Contact Email"
                                                fullWidth
                                                required
                                                type="string"
                                                helperText={
                                                    'Customer information may be used to verify the invoice.'
                                                }
                                                onBlur={handleBlur}
                                            />
                                        )}
                                    </Box>

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            height: 'fit-content',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            pt: 3.5,
                                            pb: 1,
                                        }}
                                    >
                                        <Typography fontWeight="bold">Your account</Typography>
                                        {data && (
                                            <BankSupportButton
                                                company={data!.company}
                                                invoiceOrBill="Invoice"
                                                invoiceId={data!.invoice!.id}
                                            />
                                        )}
                                    </Box>
                                    <Box
                                        sx={{
                                            '& > .MuiTextField-root': {
                                                mt: 2,
                                            },
                                        }}
                                    >
                                        <CompanyBankSelect
                                            bankStatus={bankStatus}
                                            bankAccounts={bankAccounts}
                                            onBlur={handleBlur}
                                        />
                                    </Box>
                                </StepContent>
                            </Step>
                        </Stepper>
                    </Box>
                    {open && (
                        <CustomerFormPopup
                            customer={addNew ? null : formik.values.customer}
                            open={open}
                            handleClose={handleClose}
                            handleSubmit={handleSubmit}
                        />
                    )}
                    {activeStep === 1 && (
                        <Box sx={{ px: 4, py: 1.5 }}>
                            <Button variant="contained" fullWidth onClick={() => navigate('../..')}>
                                Ok
                            </Button>
                        </Box>
                    )}
                    {activeStep === 2 && (
                        <Box
                            sx={{
                                px: 4,
                                py: 1.5,
                                borderTop: '1px solid #E5E5E5',
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 2,
                            }}
                        >
                            <SaveFormButton status={saveStatus} onSave={handleSave} />
                            <LoadingButton
                                variant="contained"
                                fullWidth
                                loading={formik.isSubmitting}
                                onClick={() => {
                                    formik.handleSubmit();
                                }}
                                disabled={
                                    !isCustomerComplete(formik.values.customer) ||
                                    isCustomerEmpty(formik.values.customer)
                                }
                            >
                                See Offer
                            </LoadingButton>
                        </Box>
                    )}
                </Box>
            </FeatureLayout>
            <InvoicePdfViewer collapsible />
        </FormikProvider>
    );
};
