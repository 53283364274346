import { IconButton, Box, Typography, ListItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PaidIcon from '@mui/icons-material/MonetizationOn';
import { currencyFormatter } from '@lendica/utils';

const useStyles = makeStyles(theme => ({
    cardCircle: {
        width: 40,
        height: 40,
        borderRadius: 20,
        backgroundColor: theme.palette.action.disabled,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: theme.spacing(2),
    },
    activityItem: {
        alignItems: 'center',
    },
    grey: {
        color: theme.palette.background.default,
    },
    capital: {
        textTransform: 'capitalize',
    },
    pending: {
        backgroundColor: theme.palette.background.cardBorder,
        padding: 2,
        borderRadius: 4,
    },
}));

export const ActivityListItem = props => {
    const classes = useStyles();
    const { id, activity_type, title, caption, amount, status, handleClick } = props;

    return (
        <ListItem button component="a" key={id} onClick={handleClick}>
            <Box ml={1} className={classes.cardCircle}>
                {activity_type === 1 ? (
                    <CreditCardIcon className={classes.grey} />
                ) : (
                    <PaidIcon className={classes.grey} />
                )}
            </Box>
            <Box flex="1">
                <Typography variant="body2" color="text.primary" className={classes.capital}>
                    {title}
                </Typography>
                <Typography variant="caption">{caption}</Typography>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="flex-end">
                <Typography variant="body2" color="text.primary">
                    {activity_type === 1 ? '+' : ''}
                    {currencyFormatter(amount)}
                </Typography>
                <Typography variant="caption" component="div" className={classes.pending}>
                    {status}
                </Typography>
            </Box>
            <IconButton size="large">
                <ArrowForwardIosIcon />
            </IconButton>
        </ListItem>
    );
};
