import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    appbar: {
        position: 'sticky',
        bottom: 0,
        background: 'inherit',
        zIndex: 1,
    },
}));

export const BottomBar = ({ title, value, action, ...rest }) => {
    const classes = useStyles();
    return (
        <Box
            px={2}
            py={1}
            height={48}
            display="flex"
            justifyContent="center"
            alignItems="center"
            className={classes.appbar}
            {...rest}
        >
            {title && (
                <Box>
                    <Typography variant="overline" component={Box} height={20}>
                        {title}
                    </Typography>
                    <Typography variant="subtitle1">{value}</Typography>
                </Box>
            )}
            {action}
        </Box>
    );
};
