import axios from 'axios';
import { Deal } from '@lendica/utils';
export interface InitResponse {
    company?: {
        id?: string;
        name?: string;
        first_name?: string;
        last_name?: string;
        email?: string;
        activated_paylater?: boolean;
        activated_drawdown?: boolean;
        activated_fundnow?: boolean;
    };
    summary?: {
        [key: string]: any;
    };
    deals?: Deal[];
}

export interface AccountSummary {
    approvals?: {
        [key: string]: any;
    };
    deals?: Deal[][];
}

export interface AccountSummaryResponse {
    company?: {
        id?: string;
        name?: string;
        first_name?: string;
        last_name?: string;
        email?: string;
        activated_paylater?: boolean;
        activated_drawdown?: boolean;
        activated_fundnow?: boolean;
    };
    account_summary?: AccountSummary;
}

export const dataConnectionAPI = {
    async init() {
        return (await axios.get('/company/details')).data;
    }
}