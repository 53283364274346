import { useState, useEffect, Fragment } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { payLaterAPI } from '../payLaterAPI';
import { Vendor, maskAccountNumber } from '@lendica/utils';

export default function VendorSelect({
    handleSelect,
    selectedVendor,
}: {
    handleSelect: any;
    selectedVendor: any;
}) {
    const [value, setValue] = useState<Vendor | null>(selectedVendor);
    const [vendors, setVendors] = useState<Vendor[]>([]);

    const setVendor = (vendor: Vendor) => {
        handleSelect(vendor);
    };

    useEffect(() => {
        (async () => {
            let vendorList = await payLaterAPI.getVendorsList();
            setVendors(vendorList);
        })();
    }, []);

    useEffect(() => {
        if (selectedVendor) {
            setValue(selectedVendor);
        }
    }, [selectedVendor]);

    return (
        <Fragment>
            <Autocomplete
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                    setVendor(newValue!);
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                options={vendors}
                getOptionLabel={option => {
                    // e.g value selected with enter, right from the input
                    if (typeof option === 'string') {
                        return option;
                    }
                    return option.company_name || '';
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                renderOption={(props, option) => <li {...props}>{option.company_name}</li>}
                fullWidth
                disablePortal
                size="small"
                renderInput={params => (
                    <TextField
                        {...params}
                        label="Vendor"
                        helperText={
                            !!value &&
                            !!value?.bank_name &&
                            !!value?.bank_account &&
                            `${value?.bank_name} - ${maskAccountNumber(value?.bank_account || '')}`
                        }
                    />
                )}
            />
        </Fragment>
    );
}
