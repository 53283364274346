import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Box, CssBaseline } from '@mui/material';
import { Sidebar } from './Sidebar';

export const SidebarContainer: FC = () => {
    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Sidebar />
            <Box
                component="main"
                sx={{
                    display: 'grid',
                    width: '100%',
                    height: '100vh',
                    gridTemplateColumns: '480px 1fr',
                    gridTemplateAreas: '"main ."',
                }}
            >
                <Outlet />
            </Box>
        </Box>
    );
};
