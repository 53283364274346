import { currencyFormatter, dateFormatter } from "@lendica/utils"
import { Box, Typography } from "@mui/material"

export const BillCard = ({ bill }) => {

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    py: 1,
                    px: 3,
                    mt: 2,
                    mb: 0.5,
                    background: '#2E31920A',
                    boxSizing: 'border-box',
                    borderRadius: 2,
                    width: '100%'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        pb: 1,
                        boxSizing: 'border-box',
                        borderBottom: '1px solid rgba(163, 163, 180, 0.2);'
                    }}
                >
                    <Typography variant="caption">{`Bill #${bill.invoice_number}`}</Typography>
                    <Typography variant="caption">{bill.data ? dateFormatter(bill.date) : ''}</Typography>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        pt: 1,
                        boxSizing: 'border-box',
                    }}
                >
                    <Box display="flex" flexDirection="column" alignItems="flex-start" flex={1}>
                        <Typography variant="caption">Bill total</Typography>
                        <Typography variant="h6" fontSize={16}>{currencyFormatter(bill.total)}</Typography>
                    </Box>

                    <Box display="flex" flexDirection="column" alignItems="flex-end" flex={1}>
                        <Typography variant="caption" textAlign={'right'}>Vendor</Typography>
                        <Typography variant="h6" fontSize={16} textAlign={'right'}>{bill.vendor_name ?? 'N/A'}</Typography>
                    </Box>
                </Box>
            </Box>
            <Typography variant="caption" fontSize={11} fontWeight={400} color="text.secondary" mb={1} textAlign="center">Your vendor will receive the full payment from Lendica on invoice due date.</Typography>
        </>
    )
}