import { Box, Typography, Card } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { PdfViewer } from '../components/PdfViewer';
import { RowType } from '../sharedTypes';
import { SummaryList, SidePanelLayout } from '@lendica/components';
import * as api from '../api/index';
import { currencyFormatter, Invoice } from '@lendica/utils';

interface NoContentProps {
    subtitle?: string;
}

interface InvoiceProps {
    invoice: Invoice | null;
}

export const NoContent: FC<NoContentProps> = ({ subtitle = '' }) => (
    <Box
        sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }}
    >
        <Box
            sx={{
                // width: '80%',
                width: 500,
                height: '40%',
                background: `center / 50% no-repeat url("https://lendicapublic.s3.amazonaws.com/assets/illustrations/love-glasses.svg")`,
            }}
        />
        <Typography variant="subtitle1" color="#4A4A68" paragraph>
            {subtitle}
        </Typography>
    </Box>
);

export const InvoiceSummary: FC<InvoiceProps> = ({ invoice }) => {
    return !!invoice ? (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                boxSizing: 'border-box',
            }}
        >
            <Box
                sx={{
                    width: '40%',
                    height: '10%',
                    background: `center / 50% no-repeat url("https://lendicapublic.s3.amazonaws.com/assets/illustrations/love-glasses.svg")`,
                    mb: 2,
                }}
            />
            <Typography variant="subtitle1" color="#4A4A68" paragraph pb={2}>
                Here's a summary of your invoice
            </Typography>
            <Card
                sx={{
                    width: '100%',
                    maxWidth: 400,
                    py: 2,
                    px: 4,
                    boxSizing: 'border-box',
                }}
            >
                <SummaryList
                    title="Invoice Summary"
                    data={[
                        {
                            label: 'Invoice No.',
                            value: invoice?.invoice_number ?? 'N/A',
                        },
                        {
                            label: 'Customer Name',
                            value: invoice?.customer_name ?? 'N/A',
                        },
                        {
                            label: 'Date',
                            // value: formatDateShort(formatISO(invoice?.date)) ?? 'N/A',
                            value: invoice?.date ?? 'N/A',
                        },
                        {
                            label: 'Due Date',
                            value: invoice?.due_date ?? 'N/A',
                        },
                        {
                            label: 'Subtotal',
                            value: currencyFormatter(invoice?.subtotal) ?? 'N/A',
                        },
                        {
                            label: 'Tax',
                            value: currencyFormatter(invoice?.tax) ?? 'N/A',
                        },
                        {
                            label: 'Total',
                            value: currencyFormatter(invoice?.total) ?? 'N/A',
                        },
                    ]}
                />
            </Card>
        </Box>
    ) : (
        <NoContent subtitle="Invoice file not available.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" />
    );
};

export const InvoicePdfViewer: FC<{
    collapsible?: boolean;
}> = ({ collapsible }) => {
    const { id, type } = useParams<{ id: string; type: RowType }>();
    const [filename, setFilename] = useState<string | null>(null);
    const [invoice, setInvoice] = useState<Invoice | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        try {
            setLoading(true);
            setFilename(null);
            (async () => {
                if (type === 'invoice') {
                    const data = await api.getInvoice(id!);
                    setInvoice(data);
                    setFilename(data.filename);
                    return;
                }
                setFilename((await api.getDealDetails(id!)).filename);
            })();
        } catch (e) {
            console.log(e);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 100);
        }
    }, [id]);

    return (
        <SidePanelLayout redirectRoute="/fundnow" collapsible={collapsible} loading={loading}>
            {filename !== null && filename!.length > 0 ? (
                <PdfViewer
                    invoices={[{ name: '', filename }]}
                    // styles={{ width: '100%', height: '100%' }}
                />
            ) : (
                <InvoiceSummary invoice={invoice} />
            )}
        </SidePanelLayout>
    );
};
