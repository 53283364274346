import axios from 'axios';

export const formAPI = {
    async getApplication(application_id) {
        return (
            await axios.post('/application/init', {
                application_id,
            })
        ).data;
    },
    async saveApplication(data) {
        return (await axios.post('/application/apply', data)).data;
    },
    async getPlaidToken() {
        return (await axios.get('/application/plaid/create_link_token')).data.link_token;
    },
};
