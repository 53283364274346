import { useEffect, useState, useCallback } from 'react';

import {
    usePlaidLink,
    PlaidLinkOnSuccess,
    PlaidLinkOnExit,
    PlaidLinkOnSuccessMetadata,
} from 'react-plaid-link';
import * as api from '../api';

import Debounce from 'lodash/debounce';

export const usePlaid = ({ company_id, env }: { company_id: string; env: string }) => {
    const [token, setToken] = useState('');
    const [connectedAccounts, setConnectedAccounts] = useState<PlaidLinkOnSuccessMetadata[]>([]);
    const [exited, setExited] = useState(false);

    let baseURL =
        env === 'prod'
            ? 'https://oauth.app.golendica.com'
            : 'https://micro-oauth-dev.herokuapp.com';

    // let baseURL = 'https://micro-oauth-dev.herokuapp.com';

    // get a link_token from your API when component mounts
    useEffect(() => {
        const createLinkToken = async () => {
            const token = await api.getPlaidToken(baseURL);
            setToken(token);
        };
        createLinkToken();
    }, []);

    const onSuccess = useCallback<PlaidLinkOnSuccess>(
        async (publicToken: string, metadata: PlaidLinkOnSuccessMetadata) => {
            // send public_token to your server
            // https://plaid.com/docs/api/tokens/#token-exchange-flow

            try {
                const response = await api.postPlaidToken(publicToken, company_id, baseURL);
                setConnectedAccounts(prev => [...prev, metadata]);
            } catch (error) {
                // TODO: handle plaid post link token error
                console.log(error);
            }
        },
        []
    );

    const onExit = useCallback<PlaidLinkOnExit>((error, metadata) => {
        // log onExit callbacks from Link, handle errors
        // https://plaid.com/docs/link/web/#onexit
        // console.log(error, metadata);
        if (connectedAccounts.length === 0) {
            setExited(true);
        }
    }, []);

    const config = {
        token,
        onSuccess,
        onExit,
    };

    const {
        open,
        ready,
        // error,
        // exit
    } = usePlaidLink(config);

    return {
        openPlaid: open,
        ready,
        exited,
    };
};
