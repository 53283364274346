import { DataConnection } from '@lendica/data-connection';
import axios from 'axios';
import { Deal, Company } from '@lendica/utils';

export interface InitResponse {
    company?: Company;
    dataConnections?: DataConnection[];
}

export interface AccountSummary {
    approvals?: {
        [key: string]: any;
    };
    deals?: Deal[][];
}

export interface AccountSummaryResponse {
    company?: {
        id?: string;
        name?: string;
        first_name?: string;
        last_name?: string;
        email?: string;
        activated_paylater?: boolean;
        activated_drawdown?: boolean;
        activated_fundnow?: boolean;
    };
    account_summary?: AccountSummary;
}

export interface DataConnection {
    id: string;
    active: boolean;
    company: string;
    company_name: string;
    display_data: any;
    saas_category: string;
    saas_name: string;
}

export const AccountAPI = {
    async init() {
        const company = (await axios.get('/company/details')).data;
        const dataConnections = (await axios.get('/company/connections')).data;
        return { company, dataConnections };
    },
    async getDataConnections() {
        return (await axios.get('/company/connections')).data;
    },
};
