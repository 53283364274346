import { FC, ReactNode, useRef, createContext, useContext, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { DataConnectionContainer } from '../DataConnectionContainer';
import { DataConnectionData } from '../DataConnectionData';

const DataConnectionElementContext = createContext<any>(null);
export const useDataConnectionElementContext = () => {
    return useContext(DataConnectionElementContext);
};

export interface DataConnectionViewProps {
    placeholder?: ReactNode;
    callback?: any;
    env: string;
}

export const DataConnectionView: FC<DataConnectionViewProps> = ({ placeholder, callback, env }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const dataConnectionRef = useRef<HTMLDivElement>(null);

    return (
        <DataConnectionData>
            <DataConnectionElementContext.Provider value={{ containerRef, dataConnectionRef }}>
                <DataConnectionContainer env={env} />
                <Routes>
                    <Route path="*" element={placeholder} />
                </Routes>
            </DataConnectionElementContext.Provider>
        </DataConnectionData>
    );
};
