import { useState, useEffect, Fragment } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Customer } from '@lendica/utils';
import * as api from '../api/index';

export default function CustomerSelect({
    handleSelect,
    selectedCustomer,
}: {
    handleSelect: any;
    selectedCustomer: any;
}) {
    const [value, setValue] = useState<Customer | null>(selectedCustomer);
    const [customers, setCustomers] = useState<Customer[]>([]);

    const setCustomer = (customer: Customer) => {
        handleSelect(customer);
    };

    useEffect(() => {
        (async () => {
            let customerList = await api.getCustomersList();
            let filteredList = customerList.filter(
                (customer: Customer) =>
                    customer.company_name &&
                    customer.company_name !== '' &&
                    customer.email &&
                    customer.email !== ''
            );
            setCustomers(filteredList);
        })();
    }, []);

    useEffect(() => {
        if (selectedCustomer) {
            setValue(selectedCustomer);
        }
    }, [selectedCustomer]);

    return (
        <Fragment>
            <Autocomplete
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                    setCustomer(newValue!);
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                options={customers}
                getOptionLabel={option => {
                    // e.g value selected with enter, right from the input
                    if (typeof option === 'string') {
                        return option;
                    }
                    return option.company_name || '';
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                renderOption={(props, option) => <li {...props}>{option.company_name}</li>}
                fullWidth
                disablePortal
                size="small"
                renderInput={params => <TextField {...params} label="Customer" />}
            />
        </Fragment>
    );
}
