import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { Outlet, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import {
    SelectFinalPaymentTerms,
    AgreeOnTerms,
    SuccessConfirmation,
} from '@lendica/select-payment-terms';
import { Box, IconButton, ThemeProvider, createTheme, Button } from '@mui/material';
import {
    ApplicationRejected,
    DealPending,
    Logo,
    ProductPaused,
    ProductPending,
    Waitlist,
    FeatureLayout,
    HeaderToolbar,
} from '@lendica/components';
import { MoreVert } from '@mui/icons-material';
import { theme as coreTheme } from '@lendica/theme';
import { currencyFormatter, dateFormatter, percentageFormatter, Deal } from '@lendica/utils';
import { SelectTermsView } from './views/SelectTermsView';
import { ConfirmDetails } from './views/ConfirmDetails';
import { PayLaterView } from './views/PayLaterView';
import { InvoicePdfViewer } from './views/InvoicePdfViewer';
import { Loader } from './components/Loader';
import { StatusPage } from './views/StatusPage';
import { DealSummary } from './views/DealSummary';
import { AgreeToTermsView } from './views/AgreeToTermsView';
import { PayLaterMarketingOffer } from './views/PayLaterMarketingOffer';

import { Bill, Offer, payLaterAPI } from './payLaterAPI';
import { emitApi } from '@lendica/api';

const theme = createTheme(coreTheme);

export interface PayLaterProps {
    placeholder?: ReactNode;
}

function useBillData() {
    const { id } = useParams();
    const [data, setData] = useState<{ bill: Bill; offers: Offer[] } | undefined>();

    useEffect(() => {
        (async () => {
            const billData = await Promise.all([
                payLaterAPI.getBill(id!),
                payLaterAPI.getOffers(id!),
            ]);
            setData({ bill: billData[0], offers: billData[1] });
        })();
    }, []);

    return data;
}

export const SelectTerms: FC = () => {
    const { id } = useParams();
    const billData = useBillData();
    const navigate = useNavigate();
    const [offerId, setOfferId] = useState<string>();
    const [isLoading, setIsLoading] = useState(false);

    const data = useMemo(() => {
        if (!billData) {
            return undefined;
        }

        return {
            offers: billData.offers,
            bill: billData.bill,
        };
    }, [billData]);

    const offer = useMemo(() => {
        if (offerId && data) {
            return data.offers.find(item => item.id == offerId);
        }
    }, [offerId, data]);

    return (
        <FeatureLayout loading={!data}>
            <Box
                sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box
                    sx={{
                        px: 4,
                        mt: 2,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Logo height={20} />
                    <IconButton size="large">
                        <MoreVert />
                    </IconButton>
                </Box>
                <Box sx={{ flexGrow: 1, overflowY: 'auto', px: offerId ? 2 : 4 }}>
                    {data && !offerId && (
                        <SelectFinalPaymentTerms
                            onSelected={(offerId: string) => setOfferId(offerId)}
                            onTermSelected={(offerId: string) => {
                                navigate(`../select/${offerId}`);
                            }}
                            bill={data.bill}
                            offers={data.offers}
                        />
                    )}
                    {data && offerId && (
                        <AgreeOnTerms
                            offer={offer}
                            onGoBack={() => {
                                setOfferId(undefined);
                            }}
                            onAgree={async () => {
                                try {
                                    setIsLoading(true);
                                    const dealId = await payLaterAPI.initializeDeal(id!, offerId!);
                                    setIsLoading(false);
                                    navigate(`../confirmation/${dealId}`);
                                } catch (e) {
                                    navigate('/paylater/error');
                                    setIsLoading(false);
                                }
                            }}
                        />
                    )}
                </Box>
            </Box>
            {isLoading && <Loader />}
        </FeatureLayout>
    );
};

export const SuccessConfirmationView: FC = () => {
    const navigate = useNavigate();
    const { dealId } = useParams();
    const [deal, setDeal] = useState<Deal>();

    const getPaymentUpfront = (total: number, advance: number, fee: number) => {
        return total - advance + fee;
    };
    const paymentUpfront = useMemo(
        () =>
            getPaymentUpfront(
                deal?.gross_funded_amount ?? 0,
                deal?.advance_amount ?? 0,
                deal?.origination_fee_amount ?? 0
            ),
        [deal]
    );

    useEffect(() => {
        emitApi.dealActivated();
    }, []);

    useEffect(() => {
        (async () => {
            setDeal(await payLaterAPI.getDeal(dealId!));
        })();
    }, [dealId]);

    return (
        <FeatureLayout loading={!deal}>
            <Box
                sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box sx={{ px: 4 }}>
                    <HeaderToolbar
                        label="Deal Confirmation"
                        leftAction={
                            <Button
                                size="small"
                                onClick={() => {
                                    navigate('/paylater');
                                }}
                                sx={{ color: 'action.disabled' }}
                            >
                                Done
                            </Button>
                        }
                        // rightAction={
                        //     <IconButton
                        //         size="medium"
                        //         onClick={() => {
                        //             navigate('/paylater');
                        //         }}
                        //     >
                        //         <SaveAlt fontSize="inherit" />
                        //     </IconButton>
                        // }
                    />
                </Box>
                <Box sx={{ flexGrow: 1, overflowY: 'auto', px: 4 }}>
                    <SuccessConfirmation
                        amount={deal?.gross_funded_amount}
                        transaction={{
                            expected_rate: deal?.expected_rate,
                            origination_fee_amount: deal?.origination_fee_amount,
                            payment_upfront: paymentUpfront,
                            term_length: deal?.number_of_payments,
                            weekly_payment: deal?.periodic_collection_from_company,
                            total_pmt: paymentUpfront + (deal?.payback_amount ?? 0),
                            payment_frequency: deal?.payment_frequency,
                        }}
                        summaryData={[
                            {
                                label: 'Deal ID',
                                value: deal?.id ?? '',
                            },
                            {
                                label: 'Invoice No.',
                                value: deal?.invoice_number ?? '',
                            },
                            {
                                label: 'Product',
                                value: deal?.product_name ?? '',
                            },
                            {
                                label: 'Funded Date',
                                value: dateFormatter(deal?.funded_date ?? ''),
                            },
                            {
                                label: 'Amount',
                                value: currencyFormatter(deal?.gross_funded_amount ?? 0),
                            },
                            {
                                label: 'Vendor Name',
                                value: deal?.vendor_name ?? '',
                            },
                            {
                                label: 'Origination Fee Amount',
                                value: currencyFormatter(deal?.origination_fee_amount ?? 0),
                            },
                            {
                                label: 'Advance Amount',
                                value: currencyFormatter(deal?.advance_amount ?? 0),
                            },
                            {
                                label: 'Payment Frequency',
                                value:
                                    deal?.number_of_payments === 1
                                        ? 'One-time'
                                        : deal?.payment_frequency ?? '',
                            },
                            {
                                label: 'Number of Payments',
                                value: deal?.number_of_payments ?? '',
                            },
                            {
                                label: 'Expected Rate',
                                value: percentageFormatter(deal?.expected_rate ?? 0),
                            },
                            {
                                label: 'Periodic Payment Amount',
                                value: currencyFormatter(
                                    deal?.periodic_collection_from_company ?? 0
                                ),
                            },
                            {
                                label: 'First Payment Date',
                                value: dateFormatter(deal?.first_payment_date ?? ''),
                            },
                            {
                                label: 'Total Payback Amount',
                                value: currencyFormatter(deal?.payback_amount ?? 0),
                            },
                        ]}
                        onGoBack={() => {
                            navigate('/paylater');
                        }}
                    />
                </Box>
            </Box>
        </FeatureLayout>
    );
};

export const OverdrawView: FC = () => {
    const navigate = useNavigate();

    useEffect(() => {
        emitApi.dealExceedsLimit();
    }, []);

    return (
        <FeatureLayout>
            <StatusPage
                title="Deal amount exceeds available credit."
                subtitle="Please contact the Lendica team if you believe this is an error."
                duration={7}
                actionLabel="Go Back"
                action={() => {
                    navigate('/paylater');
                }}
            />
        </FeatureLayout>
    );
};

export const PayLater: FC<PayLaterProps> = ({ placeholder }) => {
    const navigate = useNavigate();

    const activate = async () => {
        try {
            await payLaterAPI.activatePaylater();
            navigate('/paylater');
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <Routes>
            <Route path="*" element={<PayLaterView placeholder={<Box></Box>} />} />
            <Route path=":type/:billId/confirm" element={<ConfirmDetails />} />
            <Route path="product-pending" element={<ProductPending />} />
            <Route path="product-paused" element={<ProductPaused />} />
            <Route path="deal-pending" element={<DealPending />} />
            <Route path="deal/:id" element={<DealSummary />} />
            <Route path="application-rejected" element={<ApplicationRejected />} />
            <Route path="marketing" element={<PayLaterMarketingOffer />} />

            <Route path="waitlist" element={<Waitlist product="PayLater" callback={activate} />} />
            <Route
                path=":type/:billId/terms"
                element={
                    <>
                        <ThemeProvider theme={theme}>
                            <Outlet />
                        </ThemeProvider>
                        <InvoicePdfViewer collapsible />
                    </>
                }
            >
                {/* <Route path="select" element={<SelectTerms />} />
                <Route path="select/:offerId" element={<SelectTerms />} /> */}
                <Route path="select" element={<SelectTermsView />} />
                <Route path="select/:offerId" element={<SelectTermsView />} />
                <Route path="agree-to-terms/:offerId" element={<AgreeToTermsView />} />
                <Route path="confirmation/:dealId" element={<SuccessConfirmationView />} />
            </Route>
            <Route path="error" element={<OverdrawView />} />
        </Routes>
    );
};
