import axios from 'axios';
// import { refreshToken } from '../utils/auth';

// export const logout = async (): Promise<any> => {
//     await axios.post("/auth/logout", { refresh_token: refreshToken.get() });
// }

export const getCompany = async (): Promise<any> => {
    return (await axios.get("/company/details")).data
}

export const getApprovals = async (): Promise<any> => {
    return (await axios.get("/company/approvals")).data
}

// export const forgotPassword = async (username: string): Promise<any> => {
//     await axiosInstance.post("/auth/forgot_password", { username });
// }

// export const resetPassword = async (
//     username: string,
//     old_password: string,
//     password: string,
//     password2: string,
// ): Promise<any> => {
//     const response = await axiosInstance.post("/auth/change_password", {
//         username,
//         old_password,
//         password,
//         password2
//     });
//     return response.data;
// }