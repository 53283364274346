import { useState } from 'react';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ModeStandbyIcon from '@mui/icons-material/ModeStandby';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

export const BankAccountCard = ({
    primary,
    name,
    institution,
    account_number,
    active,
    handlePlaidClick,
}: {
    name: string;
    institution: string;
    account_number: string;
    primary: boolean;
    active: boolean;
    handlePlaidClick: () => void;
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const isOpen = Boolean(anchorEl);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleReconnect = (e: any) => {
        e.preventDefault();
        handlePlaidClick();
        setAnchorEl(null);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '1rem 1rem',
                background: '#f8f8fb',
                borderRadius: 2,
                marginY: 0.5,
                // marginX: 2,
                // cursor: 'pointer',
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                {primary ? (
                    <AccountBalanceIcon color="info" />
                ) : active ? (
                    <ModeStandbyIcon color="success" />
                ) : (
                    <SyncProblemIcon color="error" />
                )}

                <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2 }}>
                    <Typography variant="subtitle2" fontWeight={400} fontSize={14}>
                        {primary
                            ? `${institution}`
                            : `${institution} - ${name} - ${account_number}`}
                    </Typography>
                    {
                        <Typography variant="caption" fontWeight={400} fontSize={11}>
                            {primary ? 'ACH' : 'Connected via Plaid'}
                        </Typography>
                    }
                </Box>
            </Box>

            <>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}
                >
                    {primary && (
                        <Chip
                            label="Default"
                            variant="filled"
                            color="primary"
                            sx={{ fontWeight: 500, height: 20, paddingX: 0, fontSize: 14 }}
                        />
                    )}

                    {!active && (
                        <>
                            <IconButton onClick={handleClick}>
                                <MoreVertIcon color="action" />
                            </IconButton>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={isOpen}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={handleReconnect}>Reconnect</MenuItem>
                            </Menu>
                        </>
                    )}
                </Box>
            </>
        </Box>
    );
};
