import React, { useState } from 'react';
import { Modal, Box, Grid } from '@mui/material';
import { Article as ArticleIcon } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { ModalCard } from './ModalCard';

const vendors = [
    {
        name: 'Quickbooks',
        logoSrc: 'https://upload.wikimedia.org/wikipedia/commons/c/ce/Intuit_QuickBooks_logo.png',
    },
];

const useStyles = makeStyles(theme => ({
    vendorImage: {
        'padding': theme.spacing(1),
        'margin': '1px',
        'width': 98,
        'height': 80,
        'maxWidth': 100,
        'objectFit': 'contain',
        'border': `1px solid ${theme.palette.common.white}`,
        '&:hover': {
            borderRadius: 16,
            border: `1px solid ${theme.palette.primary.main}`,
        },
    },
}));

export const AccountingModalCard = props => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const getFilteredVendorList = () => {
        return vendors.map(vendor => (
            <Grid key={vendor.name} item container justify="center" xs={6}>
                <img
                    className={classes.vendorImage}
                    src={vendor.logoSrc}
                    alt={vendor.name}
                    onClick={() => handleSignIn(vendor.name)}
                />
            </Grid>
        ));
    };

    const handleSignIn = (vendorName) => {
        let parameters = 'location=1,width=800,height=650';
        //TODO: company id is taken from a global variable
        let oauthPath = `http://localhost:5200/oauth/${vendorName.toLowerCase()}?id=${lendicaCredentials.company_id}`;

        window.open(oauthPath, 'connectPopup', parameters);
        window.addEventListener('message', event => {
            console.log('message', event);
        }, false);
    };

    return (
        <>
            <ModalCard
                {...props}
                Logo={ArticleIcon}
                actionId="card-appl-bank-02"
                connectHandler={() => setOpen(!open)}
            />
            <Modal open={open} onClose={() => setOpen(!open)}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Grid container>{getFilteredVendorList()}</Grid>
                </Box>
            </Modal>
        </>
    );
};
