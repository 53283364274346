import {
    currencyFormatter,
    dateFormatter,
    formatDateSlashed,
    formatISO,
    percentageFormatter,
} from '@lendica/utils';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { format, addWeeks, parseISO, addDays, differenceInDays } from 'date-fns';

export const SummaryCard = ({ invoice, offer, approval }) => {
    const dueDate = invoice.due_date ? parseISO(invoice.due_date) : new Date();
    const invoiceDate = invoice.date ? parseISO(invoice.date) : new Date();
    const numPayments = offer.number_of_payments;

    let deposit = offer.total_price - offer.advance_amount;
    let paymentOnInvoiceDueDate = deposit + offer.origination_fee;
    let totalPaymentAmount = offer.total_payments + deposit + offer.origination_fee;
    let firstPaymentDate = format(addDays(dueDate, offer.first_payment_delay), 'yyyy-MM-dd');
    let lastPaymentDate = format(
        addDays(dueDate, offer.first_payment_delay * numPayments),
        'yyyy-MM-dd'
    );

    const calculateDaysUntilFirstPayment = () => {
        let today = new Date();
        let billDueDate = invoice.due_date ? parseISO(invoice.due_date) : new Date();
        let numberOfDaysTillDueDate =
            differenceInDays(billDueDate, today) + offer.first_payment_delay;
        return numberOfDaysTillDueDate;
    };

    let paymentFrequency;

    if (offer.payment_frequency === 'W') {
        paymentFrequency = 'Weekly';
    } else if (offer.payment_frequency === 'M') {
        paymentFrequency = 'Monthly';
    } else {
        paymentFrequency = 'Daily';
    }

    return (
        !!offer &&
        !!invoice && (
            <Accordion
                sx={{
                    'boxShadow': 0,
                    'borderRadius': 2,
                    'border': 'none',
                    'boxSizing': 'border-box',
                    'background': '#F4F4F6',
                    'mb': 2,
                    'mt': 2,
                    'p': 1,
                    'width': '100%',
                    '&:before': {
                        display: 'none',
                    },
                }}
                disableGutters={true}
            >
                <AccordionSummary expandIcon={<ArrowDropDown color="info.main" />}>
                    <Typography
                        fontSize={13}
                        fontWeight={500}
                        color={'text.secondary'}
                        variant="subtitle1"
                    >
                        Deal details
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box
                        sx={{
                            pb: 0.5,
                            borderBottom: '1px solid rgba(163, 163, 180, 0.2)',
                        }}
                    >
                        <Typography variant="h6" fontSize={12}>
                            Invoice Details
                        </Typography>
                    </Box>

                    <Box sx={{ py: 0.5 }}>
                        <SummaryLine
                            label={'Invoice amount'}
                            leftValue={currencyFormatter(invoice.total)}
                        />
                        <SummaryLine label={'Invoice number'} leftValue={invoice.invoice_number} />
                        <SummaryLine label={'Vendor name'} leftValue={invoice.vendor_name} />
                        <SummaryLine
                            label={'Invoice date'}
                            leftValue={format(invoiceDate, 'MM/dd/yy')}
                        />
                        <SummaryLine label={'Due date'} leftValue={format(dueDate, 'MM/dd/yy')} />
                    </Box>

                    <Box
                        sx={{
                            pt: 2,
                            pb: 0.5,
                            borderBottom: '1px solid rgba(163, 163, 180, 0.2)',
                        }}
                    >
                        <Typography variant="h6" fontSize={12}>
                            On Due Date
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            borderBottom: '1px solid rgba(163, 163, 180, 0.2)',
                            py: 0.5,
                        }}
                    >
                        <Box flex={2} textAlign="left">
                            <Typography variant="h6" fontSize={14} fontWeight={700}>
                                {'Your vendor receives'}
                            </Typography>
                        </Box>
                        <Box flex={1} textAlign="right">
                            <Typography
                                variant="h6"
                                fontSize={14}
                                fontWeight={500}
                                color="text.secondary"
                            >
                                {currencyFormatter(invoice.total)}
                            </Typography>
                        </Box>
                        <Box flex={1} textAlign="right"></Box>
                    </Box>

                    <Box
                        sx={{
                            py: 0.5,
                            borderBottom: '1px solid rgba(163, 163, 180, 0.2)',
                        }}
                    >
                        <SummaryLine
                            label={'Unfundable amount'}
                            rightValue={currencyFormatter(0)}
                        />
                        <SummaryLine
                            label={'Fundable amount'}
                            leftValue={currencyFormatter(invoice.total)}
                        />
                        <SummaryLine
                            label={'Advance percentage'}
                            leftValue={percentageFormatter(offer.advance_amount / invoice.total)}
                        />
                        <SummaryLine
                            label={'Advance amount'}
                            leftValue={currencyFormatter(offer.advance_amount)}
                        />
                        <SummaryLine
                            label={'Amount not covered'}
                            rightValue={currencyFormatter(invoice.total - offer.advance_amount)}
                        />
                        <SummaryLine
                            label={'Processing fee %'}
                            leftValue={percentageFormatter(offer.origination_fee / invoice.total)}
                        />
                        <SummaryLine
                            label={'Processing fee'}
                            rightValue={currencyFormatter(offer.origination_fee)}
                        />
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            py: 0.5,
                        }}
                    >
                        <Typography variant="h6" fontSize={14} fontWeight={700}>
                            {'You pay on due date'}
                        </Typography>
                        <Box sx={{ display: 'flex' }}>
                            <Typography variant="h6" fontSize={14} fontWeight={700} ml={1}>
                                {currencyFormatter(paymentOnInvoiceDueDate)}
                            </Typography>
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            pt: 2,
                            pb: 0.5,
                            borderBottom: '1px solid rgba(163, 163, 180, 0.2)',
                        }}
                    >
                        <Typography variant="h6" fontSize={12} color="text.primary">
                            Payment Schedule
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            py: 0.5,
                            borderBottom: '1px solid rgba(163, 163, 180, 0.2)',
                        }}
                    >
                        <SummaryLine
                            label={'Principal repay'}
                            rightValue={currencyFormatter(offer.advance_amount)}
                        />
                        <SummaryLine
                            label={'Total rate fee percentage'}
                            leftValue={percentageFormatter(offer.rate)}
                        />
                        <SummaryLine
                            label={'Total rate fee amount'}
                            rightValue={currencyFormatter(offer.interest)}
                        />
                        <SummaryLine
                            label={'Number of payments'}
                            leftValue={offer.number_of_payments}
                        />
                        <SummaryLine
                            label={'Days until first payment'}
                            leftValue={calculateDaysUntilFirstPayment()}
                        />

                        {offer.number_of_payments === 1 ? (
                            <>
                                <SummaryLine label={'Payment frequency'} leftValue={'One-time'} />
                                <SummaryLine
                                    label={'One-time payment'}
                                    leftValue={formatDateSlashed(firstPaymentDate)}
                                    rightValue={currencyFormatter(offer.periodic_payment)}
                                />
                            </>
                        ) : (
                            <>
                                <SummaryLine
                                    label={'Payment frequency'}
                                    leftValue={paymentFrequency}
                                />
                                <SummaryLine
                                    label={'First payment'}
                                    leftValue={formatDateSlashed(firstPaymentDate)}
                                    rightValue={currencyFormatter(offer.periodic_payment)}
                                />
                                <SummaryLine
                                    label={'Last payment'}
                                    leftValue={formatDateSlashed(lastPaymentDate)}
                                    rightValue={currencyFormatter(offer.periodic_payment)}
                                />
                            </>
                        )}
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            py: 0.5,
                        }}
                    >
                        <Typography variant="h6" fontSize={14} fontWeight={700}>
                            {'Total payback amount'}
                        </Typography>
                        <Box sx={{ display: 'flex' }}>
                            <Typography variant="h6" fontSize={14} fontWeight={700} ml={1}>
                                {currencyFormatter(offer.advance_amount + offer.interest)}
                            </Typography>
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            py: 1,
                        }}
                    >
                        <Typography variant="h6" fontSize={14} fontWeight={700}>
                            {'Total payments'}
                        </Typography>
                        <Box sx={{ display: 'flex' }}>
                            <Typography variant="h6" fontSize={14} fontWeight={700} ml={1}>
                                {currencyFormatter(totalPaymentAmount)}
                            </Typography>
                        </Box>
                    </Box>
                </AccordionDetails>
            </Accordion>
        )
    );
};

const SummaryLine = ({ label, leftValue, rightValue }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                // justifyContent: 'space-between',
                // py: 0.5
            }}
        >
            <Box flex={2} textAlign="left">
                <Typography variant="h6" fontSize={14} fontWeight={500} color="text.secondary">
                    {label}
                </Typography>
            </Box>
            <Box flex={1} textAlign="right">
                {leftValue && (
                    <Typography variant="h6" fontSize={14} fontWeight={500} color="text.secondary">
                        {leftValue}
                    </Typography>
                )}
            </Box>
            <Box flex={1} textAlign="right">
                {rightValue && (
                    <Typography variant="h6" fontSize={14} fontWeight={500} color="text.secondary">
                        {rightValue}
                    </Typography>
                )}
            </Box>
        </Box>
    );
};
