import {
    alpha,
    Box,
    Chip,
    IconButton,
    lighten,
    TableCell,
    TableRow,
    Theme,
    Typography,
    Tooltip,
} from '@mui/material';
import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { Dropzone, PayLaterLogo, DropzoneFile, FeatureHeader } from '@lendica/components';
import { Route, Routes, useNavigate } from 'react-router-dom';
import {
    NewReleases as NewReleasesIcon,
    HelpOutline as HelpOutlineIcon,
} from '@mui/icons-material';
import { currencyFormatter, getEnvFromHost, formatDateSlashed } from '@lendica/utils';

import { CellContentProps, Column, DynamicTable } from '../Table';
import { ToggleChip, Tag, FeatureLayout } from '@lendica/components';
import { SelectFilter } from '../SelectFilter';
import { AllFilters, emptyFilter } from '../AllFilters';
import { ContextMenu } from '../ContextMenu';
import { InvoicePdfViewer } from './InvoicePdfViewer';
import { VideoViewer } from './VideoViewer';
import { UploadInvoice } from './UploadInvoice';
import { payLaterAPI, Bill, bucket_name } from '../payLaterAPI';
import {
    Deal,
    DealStatus,
    InvoiceStatus,
    Approval,
    billStatusToColor,
    billStatusToLabel,
} from '@lendica/utils';
import { useActions } from '../useActions';
import { BillOrDealRow, RowType } from '../sharedTypes';
import ArchiveDialog from '../components/ArchiveDialog';
import { Delete } from '@mui/icons-material';

export const columns: Column[] = [
    {
        id: 'funded_date',
        label: 'Date',
        CellContent: ({ value }: CellContentProps<string | undefined>) => {
            if (!value) {
                return (
                    <Chip
                        icon={<NewReleasesIcon />}
                        label="NEW"
                        color="warning"
                        size="small"
                        sx={{ color: 'common.white' }}
                    />
                );
            }
            return <>{formatDateSlashed(value)}</>;
        },
    },
    {
        id: 'vendor_name',
        label: 'Vendor',
        tableCellProps: {
            padding: 'none',
        },
    },
    {
        id: 'amount',
        label: 'Amount',
        formatter: (value: number | undefined) => (value ? currencyFormatter(value) : 'N/A'),
    },
    {
        id: 'status',
        label: 'Status',
        CellContent: ({ value }: CellContentProps<InvoiceStatus | DealStatus>) => {
            return (
                <Tag
                    label={typeof value === 'string' ? value : billStatusToLabel[value]}
                    color={billStatusToColor[value]}
                />
            );
        },
        tableCellProps: {
            width: 125,
        },
    },
];

export interface PayLaterViewProps {
    placeholder?: ReactNode;
}

export interface PayLaterDataProps {
    approval: Approval | undefined;
    bills: Bill[] | undefined;
    deals: Deal[] | undefined;
}

export const PayLaterView: FC<PayLaterViewProps> = ({ placeholder }) => {
    const [data, setData] = useState<PayLaterDataProps | null>(null);
    const [open, setOpen] = useState(false);
    const [billId, setBillId] = useState('');

    const handleClickOpen = (id: string) => {
        setBillId(id);
        setOpen(true);
    };

    const handleClose = async (archived: boolean) => {
        if (archived) {
            let bills = await payLaterAPI.getBillsList();
            setData(prevState => ({
                approval: prevState?.approval,
                deals: prevState?.deals,
                bills: bills,
            }));
        }
        setBillId('');
        setOpen(false);
    };

    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            const initData = await Promise.all([
                payLaterAPI.getPaylaterApproval(),
                payLaterAPI.getBillsList(),
                payLaterAPI.getPaylaterDealsList(),
                payLaterAPI.getCompanyDetails(),
            ]);
            setData({ approval: initData[0], bills: initData[1], deals: initData[2] });
            let company = initData[3];
            if (company.status === 2) {
                if (company.paylater_status === 0) {
                    navigate('/paylater/waitlist');
                } else if (company.paylater_status === 1) {
                    navigate('/paylater/product-pending');
                } else if (company.paylater_status === 3) {
                    navigate('/paylater/product-paused');
                }
            }
        })();
    }, []);

    const rows: BillOrDealRow[] | undefined = useMemo(
        () =>
            data
                ? [
                      ...data!
                          .bills!.filter(item => item.status === InvoiceStatus.Created)
                          .map(({ id, invoice_number, status, vendor_name, total }) => ({
                              id,
                              invoice_number,
                              vendor_name,
                              amount: total,
                              status,
                              type: RowType.Bill,
                          })),
                      ...data!.deals!.map(
                          ({
                              id,
                              invoice_number,
                              funded_date,
                              vendor_name,
                              total_collected,
                              payback_amount,
                              deal_status,
                              gross_funded_amount,
                              vendor,
                          }) => ({
                              id,
                              invoice_number,
                              funded_date,
                              vendor_name: vendor ? vendor_name : 'N/A',
                              amount: gross_funded_amount,
                              paid: Math.floor((total_collected / payback_amount) * 100),
                              status: deal_status,
                              type: RowType.Deal,
                          })
                      ),
                  ]
                : undefined,
        [data]
    );

    const [filter, setFilter] = useState(emptyFilter);
    const statuses = useMemo(
        () =>
            rows
                ? [...new Set(rows.map(item => item.status))].map(item => ({
                      label: typeof item === 'string' ? item : billStatusToLabel[item],
                      value: item,
                  }))
                : [],
        [rows]
    );
    const vendors = useMemo(
        () =>
            rows
                ? [...new Set(rows.map(item => item.vendor_name))].map(item => ({
                      label: item,
                      value: item,
                  }))
                : [],
        [rows]
    );

    const filteredSortedData = useMemo(() => {
        let result = [...(rows ?? [])];

        if (filter.earliestFirst) {
            result.sort((a, b) => {
                if (!a.funded_date) {
                    return -1;
                }
                if (!b.funded_date) {
                    return 1;
                }
                return new Date(a.funded_date).valueOf() - new Date(b.funded_date).valueOf();
            });
        } else {
            result.sort((a, b) => {
                if (!a.funded_date) {
                    return -1;
                }
                if (!b.funded_date) {
                    return 1;
                }
                return new Date(b.funded_date).valueOf() - new Date(a.funded_date).valueOf();
            });
        }

        if (filter.status.length > 0) {
            const statusSet = new Set(filter.status);
            result = result.filter(item => statusSet.has(item.status));
        }
        if (filter.vendor.length > 0) {
            const vendorSet = new Set(filter.vendor);
            result = result.filter(item => vendorSet.has(item.vendor_name));
        }
        if (filter.liveDeals) {
            result = result.filter(
                item =>
                    item.status === DealStatus.Current ||
                    item.status === DealStatus.Late ||
                    item.status === DealStatus['Grace Period']
            );
        }
        if (filter.pastDeals) {
            result = result.filter(
                item =>
                    item.status === DealStatus.Completed ||
                    item.status === DealStatus['Early Completed'] ||
                    item.status === DealStatus['Default']
            );
        }
        if (filter.notActivated) {
            result = result.filter(item => item.status === DealStatus.Submitted);
        }
        return result;
    }, [rows, filter]);

    const [uploadedFile, setUploadedFiles] = useState<DropzoneFile[]>([]);

    return (
        <>
            {uploadedFile.length === 0 ? (
                <FeatureLayout loading={rows === undefined}>
                    {/* <Box
                        sx={{
                            px: 4,
                            pt: 2,
                            pb: 1,
                            position: 'sticky',
                            bgcolor: 'background.paper',
                            top: 0,
                            zIndex: theme => theme.zIndex.drawer + 1,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <Box component="a" href="/">
                                <Logo height={18} />
                            </Box>
                             <IconButton size="large">
                                <MoreVertIcon />
                            </IconButton> 
                        </Box>
                    </Box> */}
                    <Box
                        sx={{
                            px: 4,
                            pb: 4,
                            pt: 1,
                        }}
                    >
                        {data! && data!.approval! && (
                            <Box sx={{ mt: 2 }}>
                                <FeatureHeader
                                    name={<PayLaterLogo />}
                                    total={data.approval.qualified_amount}
                                    available={data.approval.funds_available}
                                    unpaid={data.approval.total_payoff_amount}
                                    entity="invoices"
                                    entityNumber={data.approval.number_of_active_deals}
                                />
                            </Box>
                        )}
                        <Box
                            display="flex"
                            flexDirection="row"
                            flexWrap="wrap"
                            alignItems="center"
                            mt={1}
                        >
                            <Typography color="primary.dark" variant="body2" fontWeight={700}>
                                Upload a bill here to start PayLater
                            </Typography>
                            <Tooltip title="How does it work?" placement="right">
                                <IconButton
                                    color="info"
                                    size="small"
                                    onClick={() => navigate('walkthrough/view')}
                                >
                                    <HelpOutlineIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Box>
                            <Dropzone
                                value={uploadedFile}
                                onChange={value => {
                                    setUploadedFiles(value);
                                }}
                                // onDrop={() => {
                                //     navigate('..');
                                // }}
                                accept={{
                                    'image/png': ['.png'],
                                    'image/jpg': ['.jpg'],
                                    'image/jpeg': ['.jpeg'],
                                    'application/pdf': ['.pdf'],
                                }}
                                acceptHelper="Accepting pdf and images."
                                label="+ New"
                                api={{
                                    presignedUrl:
                                        'https://micro-awsmanager.herokuapp.com/s3/signed-url',
                                    getObjectKey(fileName) {
                                        const ext = fileName.split('.').pop();
                                        return `${getEnvFromHost(window.location.hostname)}/${
                                            data!.approval!.company
                                        }/invoice/invoice_${new Date().valueOf()}.${ext}`;
                                    },
                                    presignedUrlParams: {
                                        bucket_name,
                                    },
                                }}
                            />
                        </Box>
                        <Box sx={{ mt: 5 }}>
                            <Typography color="text.secondary" variant="h5">
                                My Bills
                            </Typography>
                            <Box sx={{ display: 'flex', gap: 0.5, pb: 1, mt: 3, flexWrap: 'wrap' }}>
                                <AllFilters
                                    initialValue={filter}
                                    onChange={value => {
                                        setFilter(value);
                                    }}
                                    statuses={statuses}
                                    vendors={vendors}
                                />
                                <ToggleChip
                                    label="Live Deals"
                                    value={filter.liveDeals}
                                    onChange={value => {
                                        setFilter({ ...filter, liveDeals: value });
                                    }}
                                />
                                <SelectFilter
                                    label="Status"
                                    options={statuses}
                                    value={filter.status}
                                    onChange={value => {
                                        setFilter({ ...filter, status: value });
                                    }}
                                />
                                <SelectFilter
                                    label="Vendor"
                                    options={vendors}
                                    value={filter.vendor}
                                    onChange={value => {
                                        setFilter({ ...filter, vendor: value });
                                    }}
                                />
                                <ToggleChip
                                    label="Earliest first"
                                    value={filter.earliestFirst}
                                    onChange={value => {
                                        setFilter({ ...filter, earliestFirst: value });
                                    }}
                                />
                            </Box>
                            <DynamicTable
                                columns={columns}
                                data={filteredSortedData}
                                ContextMenu={ContextMenu}
                                DetailContent={({ row }) => {
                                    const actions = useActions(row);

                                    const headerSx = {
                                        fontWeight: 'medium',
                                        borderTop: (theme: Theme) =>
                                            `1px solid ${lighten(
                                                alpha(theme.palette.divider, 1),
                                                0.88
                                            )}`,
                                    };

                                    return (
                                        <>
                                            <TableRow>
                                                <TableCell
                                                    padding="none"
                                                    sx={{
                                                        borderBottom: 'unset',
                                                    }}
                                                ></TableCell>
                                                <TableCell
                                                    sx={{
                                                        borderBottom: 'unset',
                                                    }}
                                                ></TableCell>
                                                <TableCell padding="none" sx={headerSx}>
                                                    ID
                                                </TableCell>
                                                <TableCell sx={headerSx}>Paid</TableCell>
                                                <TableCell sx={headerSx}>Action</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell padding="none"></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell padding="none">
                                                    {row.invoice_number}
                                                </TableCell>
                                                <TableCell>
                                                    {row.paid !== undefined
                                                        ? row.paid + '%'
                                                        : 'N/A'}
                                                </TableCell>
                                                <TableCell>
                                                    {actions.map(({ Icon, onClick, title }) => (
                                                        <Tooltip
                                                            key={title}
                                                            title={title}
                                                            placement="top"
                                                        >
                                                            <IconButton
                                                                size="small"
                                                                onClick={onClick}
                                                            >
                                                                <Icon fontSize="inherit" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    ))}
                                                    {row.type === 'bill' && row.status === 0 && (
                                                        <Tooltip
                                                            key={'Delete'}
                                                            title={'Delete'}
                                                            placement="top"
                                                        >
                                                            <IconButton
                                                                size="small"
                                                                onClick={() =>
                                                                    handleClickOpen(row.id)
                                                                }
                                                            >
                                                                <Delete fontSize="inherit" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    );
                                }}
                            />
                            <ArchiveDialog open={open} billId={billId} onClose={handleClose} />
                        </Box>
                    </Box>
                </FeatureLayout>
            ) : (
                <>
                    <Box sx={{ display: 'none' }}>
                        <Dropzone
                            value={uploadedFile}
                            onChange={value => {
                                setUploadedFiles(value);
                            }}
                            api={{
                                presignedUrl:
                                    'https://micro-awsmanager.herokuapp.com/s3/signed-url',
                            }}
                        />
                    </Box>
                    <UploadInvoice
                        files={uploadedFile}
                        onExit={() => {
                            setUploadedFiles([]);
                        }}
                    />
                </>
            )}
            <Routes>
                <Route path=":type/:billId/view" element={<InvoicePdfViewer />} />
                <Route
                    path="walkthrough/view"
                    element={<VideoViewer videoUrl="https://www.youtube.com/embed/9Kd4Qgj0IK8" />}
                />
                <Route path="*" element={placeholder} />
            </Routes>
        </>
    );
};
