import { Bill } from '@lendica/select-payment-terms'
import { Vendor } from '@lendica/paylater'
import { Company, Deal, Approval } from '@lendica/utils';

export enum TransactionStatus {
    Pending,
    Complete,
}

export enum PaymentType {
    periodic,
    early,
    late,
}

export enum TransactionCategory {
    Payment,
    Funding,
    Collection,
}

export interface ScheduledPayment {
    id: string,
    guts_external_uuid: string | null,
    guts_achq_reference_id: string | null,
    created_date: string,
    update_date: string,
    date: string,
    amount: number,
    description_code: string,
    deal: string,
    company: string,
    customer: string | null,
    product_name: string,
    vendor: string
}

export interface Payment {
    id: string,
    guts_external_uuid: string | null,
    created_date: string,
    update_date: string,
    payment_method: string;
    reference_id: string | null,
    confirmation_number: string | null,
    guts_reference_id: string | null,
    description: string | null,
    counterparty_type: string,
    date_created: string | null,
    date_scheduled: string | null,
    check_amount: number,
    payment_direction: string;
    event_name: string | null,
    event_date: string,
    event_time: string | null,
    resulting_status: string,
    return_codes: string,
    description_code: string,
    notes: string,
    deal: string,
    company: string,
    customer: string | null,
    vendor: string | null,
    product_name: string
}

export interface CompletedPayment {
    id: string,
    guts_external_uuid: string | null,
    created_date: string,
    update_date: string,
    payment_method: string;
    reference_id: string | null,
    confirmation_number: string | null,
    guts_reference_id: string | null,
    description: string | null,
    counterparty_type: string,
    date_created: string | null,
    date_scheduled: string | null,
    check_amount: number,
    payment_direction: string;
    event_name: string | null,
    event_date: string,
    event_time: string | null,
    resulting_status: string,
    return_codes: string,
    description_code: string,
    notes: string | null,
    deal: string,
    company: string,
    customer: string | null,
    vendor: string | null,
    product_name: string
}
export interface ProductSummary {
    advance_rate?: number;
    contract_end_date?: string;
    contract_start_date?: string;
    funds_available?: number;
    funds_in_use?: number;
    id?: string;
    max_days_outstanding?: number;
    number_of_active_deals?: number;
    origination_fee_percentage?: number;
    product?: string;
    qualified_amount?: number;
    term_length_in_days?: number;
    total_in_process_amount?: number;
    total_payoff_amount?: number;
}

export interface CompanySummaryResponse {
    company: Company,
    partner: Partner,
    approvals: Approval[],
    bills: Bill[],
    // invoices: Invoice[],
    deals: Deal[],
    vendors: Vendor[],
    // customers: Customer[],
    payments: CompletedPayment[],
    payment_schedules: ScheduledPayment[],
}

export interface Partner {
    name: string | null,
    base_url: string | null,
    has_fundnow: boolean,
    has_drawdown: boolean,
    has_paylater: boolean,
    has_ipaylater: boolean
}


