import { ModalCard } from './ModalCard';
import { SquareLogo } from './SquareLogo';

export const SquareModalCard = props => (
    <ModalCard
        {...props}
        Logo={SquareLogo}
        showLabel={false}
        connectHandler={() =>
            confirm('Connect?') ? Promise.resolve('my_square_data') : Promise.reject(null)
        }
    />
);
