import { Receipt } from '@mui/icons-material';
import { Box, Chip, Pagination } from '@mui/material';
import { FC, useState } from 'react';
import { awsURL, bucket_name } from '../api';

export interface Invoice {
    name: string;
    filename: string;
}

export const PdfViewer: FC<{ invoices: Invoice[]; styles?: object }> = ({
    invoices,
    styles = {},
}) => {
    // TODO: set selectedInvoice to 0 when route changes
    const [selectedInvoice, setSelectedInvoice] = useState(0);

    return (
        <>
            {invoices.length > 1 && (
                <Box sx={{ pb: 6, display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                        {invoices.map((item, index) => (
                            <Chip
                                key={item.name}
                                label={item.name}
                                icon={<Receipt />}
                                variant={selectedInvoice === index ? 'outlined' : 'filled'}
                                onClick={() => {
                                    setSelectedInvoice(index);
                                }}
                                sx={[
                                    selectedInvoice === index
                                        ? {}
                                        : { border: '1px solid transparent' },
                                    {
                                        '& .MuiChip-icon': {
                                            marginLeft: '5px',
                                        },
                                        'textTransform': 'uppercase',
                                    },
                                ]}
                            />
                        ))}
                    </Box>
                    <Box sx={{ flexShrink: 0 }}>
                        <Pagination
                            count={invoices.length}
                            page={selectedInvoice + 1}
                            onChange={(_e, value) => setSelectedInvoice(value - 1)}
                        />
                    </Box>
                </Box>
            )}
            <embed
                src={`${awsURL}/get-file?object_key=${invoices[selectedInvoice].filename}&bucket_name=${bucket_name}`}
                style={{ flexGrow: 1, ...styles }}
            />
        </>
    );
};
