import { FC } from 'react';
import { Box, IconButton, Typography, Tooltip, Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { SummaryList } from '@lendica/components';
import _ from 'lodash';
import { useParams, useNavigate } from 'react-router-dom';
import { currencyFormatter, dateFormatter, formatProductName } from '@lendica/utils';
import { ArrowBackIos } from '@mui/icons-material';
import { CompletedPayment } from '../sharedTypes';

interface TransactionSummaryProps {
    payments: CompletedPayment[];
}

const mapTransactionToSummary = (transaction: CompletedPayment | undefined) => {
    return [
        {
            label: 'Transaction ID',
            value: transaction ? transaction.id.substring(0, 8) : '',
        },
        {
            label: 'Deal ID',
            value: transaction ? transaction.deal.substring(0, 8) : '',
        },
        {
            label: 'Date',
            value: transaction ? dateFormatter(transaction.event_date) : ''
        },
        {
            label: 'Amount',
            value: transaction ? currencyFormatter(transaction.check_amount) : ''
        },
        {
            label: 'Product',
            value: transaction ? formatProductName(transaction.product_name) : ''
        },
        {
            label: 'Payment Direction',
            value: transaction ? transaction.payment_direction : ''
        },
        {
            label: 'Status',
            value: transaction ? transaction.resulting_status : ''
        }
    ];
};

const convertToCSV = (transaction: CompletedPayment | undefined) => {
    const replacer = function (key: string, value: string | number) {
        return value === null ? '' : value;
    };
    let csv = 'data:text/csv;charset=utf-8,';
    let transactionSummary = mapTransactionToSummary(transaction)

    let headers = transactionSummary.map(a => a.label).join(',') + '\r\n'
    csv += headers
    csv += transactionSummary.map((x: any) => JSON.stringify(x.value, replacer)).join(',') + '\r\n'

    const encodedUri = encodeURI(csv);
    let link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `Lendica_Transaction_Summary_${transaction!.id}.csv`);
    document.body.appendChild(link);

    link.click();
};

export const TransactionSummaryView: FC<TransactionSummaryProps> = ({ payments }) => {
    const { transactionId } = useParams();
    const navigate = useNavigate()

    const transaction = payments.find((x: CompletedPayment) => x.id === transactionId)

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                overflow: 'auto',
            }}
        >
            <Box
                sx={{
                    flexBasis: 600,
                    height: '100%',
                    bgcolor: 'inherit',
                    p: 2
                }}
            >
                <SummaryList
                    title={
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <Button onClick={() => navigate("/activity-center")} startIcon={<ArrowBackIos />}>Back</Button>
                            <Typography variant="subtitle1">Transaction</Typography>
                            <Tooltip title="Download as CSV">
                                <IconButton
                                    color="secondary"
                                    aria-label="download report"
                                    size="small"
                                    onClick={() => convertToCSV(transaction)}
                                >
                                    <DownloadIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    }
                    data={mapTransactionToSummary(transaction)}
                />
                <Box pb={6} />
            </Box>
        </Box>
    );
};
