import axios from 'axios';

export const getAccounts = async () => {
    const response = await axios.get('/company/connections')
    return response.data
}

export const getAccountSummary = async () => {
    const response = await axios.get('/reporting/download_approval_summary')
    return response.data
}

export const getDealSummary = async () => {
    const response = await axios.get('/reporting/download_deal_summary')
    return response.data
}

export const getTransactionSummary = async () => {
    const response = await axios.get('/reporting/download_transaction_summary')
    return response.data
}

export const getFuturePayments = async () => {
    const response = await axios.get('/reporting/download_30_day_payment')
    return response.data
}