import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';

import { useFormContext } from '../FormContext';

const useStyles = makeStyles(theme => ({
    root: {
        'backgroundColor': theme.palette.secondary.light,
        'height': theme.spacing(1),
        'flex': 1,
        '&:not(:last-child)': {
            marginRight: theme.spacing(0.5),
        },
        'transition': `backgroundColor ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut} 0ms`,
    },
    active: {
        backgroundColor: theme.palette.secondary.main,
    },
}));

const StepIndicator = ({ index }) => {
    const classes = useStyles();
    const { step } = useParams();

    return <Box className={clsx(classes.root, index < step && classes.active)}></Box>;
};

export const StepIndicatorPanel = () => {
    const {
        data: {
            application: { total_steps },
        },
    } = useFormContext();
    const boxes = [];
    for (let i = 0; i < total_steps; i++) {
        boxes.push(<StepIndicator key={i} index={i} />);
    }
    return (
        <Grid container alignContent="space-between">
            {boxes}
        </Grid>
    );
};
