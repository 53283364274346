import axios from 'axios';
import { matchBackend, getEnvFromHost } from '@lendica/utils';
import { setAxiosInterceptors } from '@lendica/auth';

export const env = getEnvFromHost(window.location.hostname);
// export const env = 'idev';

export const baseURL = matchBackend();
// export const baseURL = 'http://127.0.0.1:8000/api/v1';

axios.defaults.baseURL = baseURL;

export const axiosInstance = axios.create({ baseURL });

setAxiosInterceptors(baseURL);
