import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useState } from 'react';

import { FormData } from './FormData';
import { Header } from './Header';
import { Body } from './Body';
import { Footer } from './Footer';
import { FAQ } from './FAQ';

const useConfigureAxios = props => {
    const [isConfigured, setIsConfigured] = useState(false);
    useEffect(() => {
        const oldBaseURL = axios.defaults.baseURL;
        axios.defaults.baseURL = props.baseURL;
        axios.interceptors.response.use(response => response, props.onRequestError);
        setIsConfigured(true);
        return () => (axios.defaults.baseURL = oldBaseURL);
    }, []);
    return isConfigured;
};

export const Form = props => {
    const isConfigured = useConfigureAxios(props);
    return (
        isConfigured && (
            <Routes>
                <Route
                    path="/:step"
                    element={
                        <FormData {...props}>
                            <Outlet />
                        </FormData>
                    }
                >
                    <Route path="faq" element={<FAQ />} />
                    <Route
                        path=""
                        element={
                            <>
                                <Header />
                                <Body />
                                <Footer />
                            </>
                        }
                    />
                </Route>
                <Route path="/*" element={<Navigate to="1" />} />
            </Routes>
        )
    );
};
