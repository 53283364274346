import { ScheduledPayment } from './../sharedTypes';
import { useEffect, useState } from 'react';
import { FilterItem } from '../components/AllFilters';
import { FilterType } from '../components/FilterBar';

// TODO: in this hook should be done all data transformation such as, filtering, sorting etc
// *key and *type properties in FilterItem interface should/can be the properties which can help do the proper filtering, sorting etc.
export const useUpcomingPayments = ({
    payLater,
    drawDown,
    fundNow,
    payment_schedules,
}: {
    payLater: boolean;
    drawDown: boolean;
    fundNow: boolean;
    payment_schedules: ScheduledPayment[];
}) => {
    const [filtersList, setFiltersList] = useState<FilterItem[]>([]);
    const getProducts = () => {
        let numProductsActive = 0;
        let options = [];

        if (payLater) {
            options.push({ label: 'PayLater', value: 'paylater' });
            numProductsActive++;
        }
        if (drawDown) {
            options.push({ label: 'DrawDown', value: 'drawdown' });
            numProductsActive++;
        }
        if (fundNow) {
            options.push({ label: 'FundNow', value: 'fundnow' });
            numProductsActive++;
        }
        if (numProductsActive >= 1) {
            return options;
        } else {
            return [];
        }
    };

    const getDealIds = () => {
        let deals: { [key: string]: any } = {};
        payment_schedules.forEach(payment_schedule => {
            let dealId = payment_schedule.deal.substring(0, 8);
            if (deals[dealId] == undefined) {
                deals[dealId] = {
                    label: dealId,
                    value: payment_schedule.deal,
                };
            }
        });
        return Object.values(deals);
    };

    useEffect(() => {
        let productFilter: FilterItem = {
            key: 'product',
            type: FilterType.SELECT_FILTER,
            label: 'Product',
            defaultValue: [],
            sectionLabel: 'Filter by product',
            options: getProducts(),
        };

        let dealIdFilter: FilterItem = {
            key: 'dealId',
            type: FilterType.SELECT_FILTER,
            label: 'Deal ID',
            defaultValue: [],
            sectionLabel: 'Filter by deal ID',
            options: getDealIds(),
        };

        let typeFilter: FilterItem = {
            key: 'type',
            type: FilterType.SELECT_FILTER,
            label: 'Type',
            defaultValue: [],
            sectionLabel: 'Filter by type',
            options: [
                {
                    label: 'Periodic',
                    value: '005',
                },
                {
                    label: 'Origination Fee',
                    value: '111',
                },
            ],
        };

        setFiltersList(prev => [...prev, productFilter, dealIdFilter, typeFilter]);
    }, []);

    return { filtersList };
};
