import { ArrowDropDown, Info } from '@mui/icons-material';
import React, { useState } from 'react';
import {
    Box,
    Button,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Popover,
    Link,
} from '@mui/material';

import { makeStyles, withStyles } from '@mui/styles';

import { currencyFormatter, percentageFormatter } from '@lendica/utils';

const useStyles = makeStyles(theme => ({
    bold: {
        fontWeight: 700,
    },
    modal: {
        width: 230,
    },
    item: {
        'display': 'flex',
        'alignItems': 'center',
        'padding': '6px 16px',
        'borderBottom': '1px solid #ccc',

        '& > div:last-child': {
            width: 80,
        },
        '& > div:first-child': {
            flex: 1,
        },
        '&:hover': {
            backgroundColor: '#8C8CA11F',
        },
    },
}));

const CustomAccordion = withStyles({
    root: {
        'borderTop': '1px solid #D9D9E5',
        'borderBottom': '1px solid #D9D9E5',
        'boxShadow': 'none',
        'marginBottom': 24,
        '& > div:first-child': {
            paddingLeft: 0,
            paddingRight: 8,
        },
        '&:before': {
            display: 'none',
        },
        '&.Mui-expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(Accordion);

export const SuccessConfirmationBody = ({ data }) => {
    const classes = useStyles();

    let frequency = ''

    if (data.payment_frequency === 'D') {
        frequency = 'day'
    } else if (data.payment_frequency === 'W') {
        frequency = 'week'
    } else if (data.payment_frequency === 'M') {
        frequency = 'month'
    }

    if (data.term_length > 1) {
        frequency += 's'
    }

    return (
        <>
            <Box mt={6} display="flex" flex="0 0 auto">
                <Box flex="1">
                    <Typography variant="caption">Rate</Typography>
                    <Box display="flex">
                        <Typography
                            variant="h6"
                            component={Box}
                            className={classes.bold}
                            color="text.secondary"
                        >
                            {percentageFormatter(data?.expected_rate)}
                        </Typography>
                    </Box>
                </Box>
                <Box flex="1" textAlign="right">
                    <Typography variant="caption">Origination Fee</Typography>
                    <Typography
                        variant="h6"
                        component={Box}
                        className={classes.bold}
                        color="text.secondary"
                    >
                        {currencyFormatter(data?.origination_fee_amount)}
                    </Typography>
                </Box>
                <Box flex="1" textAlign="right">
                    <Typography variant="caption">Payment Upfront</Typography>
                    <Typography
                        variant="h6"
                        component={Box}
                        className={classes.bold}
                        color="text.secondary"
                    >
                        {currencyFormatter(data?.payment_upfront ?? 0)}
                    </Typography>
                </Box>
            </Box>
            <Box mt={3} mb={6} display="flex" flex="0 0 auto">
                <Box flex="1">
                    <Typography variant="caption">Term Length</Typography>
                    <Typography
                        variant="h6"
                        component={Box}
                        className={classes.bold}
                        color="text.secondary"
                    >
                        {`${data.term_length} ${frequency}`}
                    </Typography>
                </Box>
                <Box flex="1" textAlign="right">
                    <Typography variant="caption">Weekly Payment</Typography>
                    <Typography
                        variant="h6"
                        component={Box}
                        className={classes.bold}
                        color="text.secondary"
                    >
                        {currencyFormatter(data.weekly_payment)}
                    </Typography>
                </Box>
                <Box flex="1" textAlign="right">
                    <Typography variant="caption">Total Payment</Typography>
                    <Typography
                        variant="h6"
                        component={Box}
                        className={classes.bold}
                        color="text.secondary"
                    >
                        {currencyFormatter(data?.total_pmt ?? 0)}
                    </Typography>
                </Box>
            </Box>
            <CustomAccordion>
                <AccordionSummary
                    expandIcon={<ArrowDropDown />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant="body2">Terms and conditions</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body2">
                        The terms and conditions related to your Inventory Financing Agreement can
                        be found{' '}
                        <Link
                            variant="inherit"
                            color="text.secondary"
                            href="https://home.golendica.com/agreement-paylater"
                            target="_blank"
                            rel="noopener noreferrer"
                            underline="always"
                        >
                            here
                        </Link>
                    </Typography>
                </AccordionDetails>
            </CustomAccordion>
        </>
    );
};
