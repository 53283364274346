import { FeatureLayout, Logo, RoundedButton } from '@lendica/components';
import { Box, Typography, TextField, IconButton, CircularProgress } from '@mui/material';

export const Loading = () => {
    return (
        <FeatureLayout>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    boxSizing: 'border-box',
                    px: 4,
                }}
            >
                {/* <BounceLoader color="#2E3192" loading /> */}
                <CircularProgress color="primary" />
                <Typography variant="subtitle2" sx={{ mt: 4 }}>
                    Retrieving your invoice details...
                </Typography>
            </Box>
        </FeatureLayout>
    );
};
