import axios from 'axios';

export const payLaterAPI = {
    async getOffers(bill_id) {
        return (await axios.get(`/paylater/bill/offers?bill_id=${bill_id}`)).data;
    },
    async addBill(bill_id) {
        return (await axios.post(`/add_bill`, { bill_id })).data;
    },
    async getBill(bill_id) {
        return (await axios.get(`/company/bills?id=${bill_id}`)).data;
    },
    async createDeal(bill_id, offer_id) {
        return (await axios.post('/paylater/deal/init', { bill_id, offer_id })).data;
    },
    async getDeal(deal_id) {
        return (await axios.post(`/company/deals?id=${deal_id}`)).data;
    },
    async getApproval(approval_id) {
        return (await axios.post(`/company/approvals?id=${approval_id}`)).data;
    },
};
