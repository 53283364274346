import { FC, useState, useEffect, useContext, createContext } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { matchHostname } from '@lendica/utils';
import { useAsyncError } from '@lendica/components';
import { useAccountContext, logout } from '@lendica/auth';
import * as api from './api';
import { Company } from '@lendica/utils';

interface AppDataProps {
    loading: boolean;
    company: Partial<Company>;
}

const AppDataContext = createContext<AppDataProps | undefined>(undefined);

export const useAppDataContext = () => {
    let context = useContext(AppDataContext);
    if (context === undefined) {
        throw Error('AppDataContext undefined');
    }
    return context;
};

interface AppDataProviderProps {
    children: React.ReactNode;
}

export const AppData: FC<AppDataProviderProps> = ({ children }) => {
    const [loading, setLoading] = useState(true);
    const [company, setCompany] = useState({
        drawdown_status: 0,
        paylater_status: 0,
        fundnow_status: 0,
    });

    const navigate = useNavigate();
    const { clearAccounts } = useAccountContext();
    const throwError = useAsyncError();

    const handleUserError = async () => {
        await clearAccounts();
        navigate(`login`);
        throwError(new Error(`Portal user 401 error.`));
    };

    useEffect(() => {
        const getCompany = async () => {
            try {
                const companyDetails = await api.getCompany();
                if (!companyDetails?.phone_number) {
                    throw new Error('no phone number', { cause: { code: 'NO_PHONE' } });
                }
                if (companyDetails?.status === 0) {
                    throw new Error('not approved', { cause: { code: 'NO_APPROVAL' } });
                }
                setCompany(companyDetails);
            } catch (error: any) {
                if (
                    (error!.response && error!.response!.status === 401) ||
                    (!!error!.cause!.code && error!.cause!.code === 'NO_PHONE')
                ) {
                    await handleUserError();
                }
                if (!!error!.cause!.code && error!.cause!.code === 'NO_APPROVAL') {
                    console.log('company has no approval');
                    return navigate(`no-approval`);
                    // window.location.replace(`https://apply.${matchHostname()}/application`);
                    // throwError(new Error(`Portal company has no approval.`));
                }
                console.log(error);
                throwError(new Error(`${error}`));
            } finally {
                setLoading(false);
            }
        };
        getCompany();
    }, []);

    return (
        <AppDataContext.Provider value={{ loading, company }}>
            {loading ? <></> : children}
        </AppDataContext.Provider>
    );
};
