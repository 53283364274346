import { FC, ReactNode } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AccountView } from './views/AccountView';

export interface AccountProps {
    placeholder?: ReactNode;
    env: string;
}

export const Account: FC<AccountProps> = ({ placeholder, env }) => {
    return (
        <Routes>
            <Route path="*" element={<AccountView env={env} placeholder={placeholder} />} />
        </Routes>
    );
};