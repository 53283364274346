export enum BillStatus {
    Available,
    Submitted,
    InProgress,
    Overdue,
    Complete,
    Invalid
}
export interface OfferNew {
    id: string;
    total_price: number;
    advance_amount: number;
    origination_fee: number;
    weekly_payment: number;
    rate: number;
    interest: number;
    number_of_payments: number;
    total_payments: number;
    merchant_fee: number;
    company: string;
    bill: string | null;
    invoice: string | null;
}

export interface Bill {
    id: string;
    date: string;
    due_date: string;
    invoice_number: string;
    filename: string | null;
    status: BillStatus;
    order_date: string;
    payment_terms: string;
    ship_to_address: string;
    subtotal: number;
    tax: number;
    total: number;
    company: string;
    vendor: string;
    vendor_name: string;
}
