import { Box, Typography, Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { RoundedButton, FeatureLayout } from '@lendica/components';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import HistoryIcon from '@mui/icons-material/History';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
// import { useState } from 'react';
import * as api from './api';
import { detectUserAgent } from '@lendica/utils';

export const Reports = ({ children, env }) => {
    // const [company, setCompany] = useState({});
    // const [error, setError] = useState(null);

    const getFile = async fileType => {
        let res;
        let fileName;
        try {
            if (fileType === 'account') {
                res = await api.getAccountSummary();
                fileName = `Lendica_Account_Summary_${Date.now()}.csv`;
            } else if (fileType === 'deal') {
                res = await api.getDealSummary();
                fileName = `Lendica_Deal_Summary_${Date.now()}.csv`;
            } else if (fileType === 'transaction') {
                res = await api.getTransactionSummary();
                fileName = `Lendica_Transaction_Summary_${Date.now()}.csv`;
            } else if (fileType === 'future_payment_report') {
                res = await api.getFuturePayments();
                fileName = `Lendica_Future_Payments_Summary_${Date.now()}.csv`;
            }
        } catch (error) {
            console.error(error);
        }

        return { res, fileName };
    };

    const downloadFile = async fileType => {
        const userAgent = detectUserAgent();
        const { res, fileName } = await getFile(fileType);
        const url = window.URL.createObjectURL(new Blob([res]));

        switch (userAgent) {
            case 'iOSApp':
                if (window.webkit && window.webkit.messageHandlers.downloadHandler) {
                    window.webkit.messageHandlers.downloadHandler.postMessage({
                        fileName: fileName,
                        url: url,
                    });
                }
                break;

            case 'AndroidApp':
                if (window.AndroidDownloadHandler) {
                    window.AndroidDownloadHandler.downloadFile(fileName, url);
                }
                break;

            case 'iOSBrowser':
            case 'AndroidBrowser':
            case 'Web':
            default:
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                break;
        }
    };

    const downloadAll = async () => {
        ['account', 'deal', 'transaction', 'future_payment_report'].map(
            async fileType => await downloadFile(fileType)
        );
    };

    return (
        <FeatureLayout>
            <Box sx={{ height: '100%' }}>
                <Box
                    sx={{
                        px: 2,
                        pb: 1,
                        pt: 3,
                        boxSizing: 'border-box',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Typography fontWeight={500} fontSize={16}>
                        Reports
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                        py: 2,
                        px: 4,
                        boxSizing: 'border-box',
                    }}
                >
                    <Typography variant="h4" fontSize={18}>
                        Download Reports
                    </Typography>
                    <Button variant="text" endIcon={<DownloadIcon />} onClick={downloadAll}>
                        Download All
                    </Button>
                </Box>
                <Box
                    sx={{
                        overflowY: 'scroll',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        width: '100%',
                        px: 4,
                        pb: 4,
                        boxSizing: 'border-box',
                    }}
                >
                    <RoundedButton
                        variant="outlined"
                        color="secondary"
                        id="account"
                        endIcon={<AccountBoxIcon />}
                        fullWidth
                        onClick={e => downloadFile(e.target.id)}
                        sx={{ mt: 1, mb: 1, boxSizing: 'border-box' }}
                    >
                        Account Summary
                    </RoundedButton>
                    <RoundedButton
                        id="deal"
                        variant="outlined"
                        color="secondary"
                        endIcon={<FormatListBulletedIcon />}
                        onClick={e => downloadFile(e.target.id)}
                        fullWidth
                        sx={{ mt: 1, mb: 1, boxSizing: 'border-box' }}
                    >
                        Deal Summary
                    </RoundedButton>
                    <RoundedButton
                        id="transaction"
                        variant="outlined"
                        color="secondary"
                        endIcon={<HistoryIcon />}
                        onClick={e => downloadFile(e.target.id)}
                        fullWidth
                        sx={{ mt: 1, mb: 1, boxSizing: 'border-box' }}
                    >
                        Transaction History
                    </RoundedButton>
                    <RoundedButton
                        id="future_payment_report"
                        variant="outlined"
                        color="secondary"
                        endIcon={<CalendarMonthIcon />}
                        onClick={e => downloadFile(e.target.id)}
                        fullWidth
                        sx={{ mt: 1, mb: 6, boxSizing: 'border-box' }}
                    >
                        Next 30 Day Payment Report
                    </RoundedButton>
                    <Box
                        component={'img'}
                        sx={{
                            height: { xs: 250, sm: 250, md: 300, lg: 300, xl: 420 },
                            width: '100%',
                        }}
                        src="https://static.golendica.com/assets/svg/download_reports_graphics.svg"
                        alt=""
                    />
                </Box>
            </Box>
        </FeatureLayout>
    );
};
