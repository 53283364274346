import { useState } from 'react';
import { Box, Tooltip, Typography, IconButton, Avatar, Chip } from '@mui/material';
import { PictureAsPdf, Image, InsertDriveFile, Visibility, Done } from '@mui/icons-material';

const formatDate = dateString => {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });

    return `${day} ${month}`;
};

const bytesToMegabytes = bytes => {
    const megabytes = bytes / (1024 * 1024);
    return megabytes.toFixed(2);
};

export const FileItem = ({ file, onPreviewClick }) => {
    const [fileHovered, setFileHovered] = useState(false);

    return (
        !!file && (
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                    'width': '100%',
                    'py': 1,
                    'pr': 1,
                    'borderRadius': 1,
                    '&:hover': {
                        backgroundColor: '#EFEFF2',
                    },
                }}
                onMouseOver={() => setFileHovered(true)}
                onMouseOut={() => setFileHovered(false)}
            >
                <Box display="flex" alignItems="center">
                    <Avatar sx={{ mr: 1, background: 'none' }}>
                        {file.type?.includes('pdf') ? (
                            <PictureAsPdf sx={{ color: '#0E0E2C' }} />
                        ) : file.type?.includes('image') ? (
                            <Image sx={{ color: '#0E0E2C' }} />
                        ) : (
                            <InsertDriveFile sx={{ color: '#0E0E2C' }} />
                        )}
                    </Avatar>
                    <Box>
                        <Typography sx={{ fontSize: 14, fontWeight: 400 }}>{file.name}</Typography>
                        <Typography sx={{ fontSize: 11, fontWeight: 400 }}>
                            {formatDate(file.lastModified)} &#183; {bytesToMegabytes(file.size)}Mb
                        </Typography>
                    </Box>
                </Box>

                {fileHovered ? (
                    <Tooltip title="View file">
                        <IconButton size="small" onClick={() => onPreviewClick(file)}>
                            <Visibility />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <Chip
                        label="Uploaded"
                        deleteIcon={<Done />}
                        onDelete={() => null}
                        size="small"
                    />
                )}
            </Box>
        )
    );
};
