import React from 'react';
import { ArrowDropDown } from '@mui/icons-material';
import {
    Box,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Divider,
    Link,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { DrawDownContainer } from '../../DrawDownContainer';
import { usePaymentBody } from './hooks/usePaymentBody';
import { currencyFormatter } from '@lendica/utils';

const useStyles = makeStyles(theme => ({
    divider: {
        'marginBottom': 12,
        '&:last-child': {
            display: 'none',
            visibility: 'hidden',
        },
    },
    accordionDetails: {
        padding: 0,
    },
}));

const CustomAccordion = withStyles({
    root: {
        'width': '100%',
        'borderTop': '1px solid #D9D9E5',
        'borderBottom': '1px solid #D9D9E5',
        'boxShadow': 'none',
        'marginBottom': 24,
        '& > div:first-child': {
            paddingLeft: 0,
            paddingRight: 8,
        },
        '&:before': {
            display: 'none',
        },
    },
    expanded: {},
})(Accordion);

export const PaymentBody = () => {
    const {
        data: { summary, live_withdrawals },
        selectedWithdrawals,
    } = DrawDownContainer.useContainer();
    const classes = useStyles();
    const { totalSaved, unpaidBalance, updatedAvailableCredit } = usePaymentBody({
        selectedWithdrawals,
        summary,
        live_withdrawals,
    });

    return (
        <>
            <Box mt={4} mb={6} display="flex" flex="0 0 auto">
                <Box flex="1">
                    <Typography variant="caption">Total Saved</Typography>
                    <Typography variant="h6" component={Box}>
                        {currencyFormatter(totalSaved ?? 0)}
                    </Typography>
                </Box>
                <Box flex="1" textAlign="left">
                    <Typography variant="caption">Unpaid Balance</Typography>
                    <Typography variant="h6" component={Box}>
                        {currencyFormatter(unpaidBalance ?? 0)}
                    </Typography>
                </Box>
                <Box flex="1" textAlign="right">
                    <Typography variant="caption">Updated Available Credit</Typography>
                    <Typography variant="h6" component={Box}>
                        {currencyFormatter(updatedAvailableCredit ?? 0)}
                    </Typography>
                </Box>
            </Box>

            <CustomAccordion>
                <AccordionSummary
                    expandIcon={<ArrowDropDown />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant="body2">Details on Each Withdrawal</Typography>
                </AccordionSummary>
                <AccordionDetails classes={{ root: classes.accordionDetails }}>
                    <Box flex="1" pb={4}>
                        <Box display="flex" flex="1" pb={1.5}>
                            <Box display="flex" flex="1">
                                <Typography variant="caption" color="text.primary">
                                    Withdrawal
                                </Typography>
                            </Box>
                            <Box display="flex" justifyContent="center" flex="1">
                                <Typography variant="caption" color="text.primary">
                                    Today's Rate
                                </Typography>
                            </Box>
                            <Box display="flex" justifyContent="center" flex="1">
                                <Typography variant="caption" color="text.primary">
                                    Amount
                                </Typography>
                            </Box>
                            <Box display="flex" justifyContent="flex-end" flex="1">
                                <Typography variant="caption" color="text.primary">
                                    Amount Saved
                                </Typography>
                            </Box>
                        </Box>
                        {selectedWithdrawals.map(item => (
                            <Box key={`selected-withdrawal-${item.withdrawal_id}`}>
                                <Box mb={1} display="flex" flex="1">
                                    <Box display="flex" flex="1">
                                        <Typography variant="caption" color="text.primary">
                                            {`w${item.withdrawal_id.slice(0, 8)}`}
                                        </Typography>
                                    </Box>
                                    <Box display="flex" justifyContent="center" flex="1">
                                        <Typography variant="caption" color="text.primary">
                                            {item.withdrawal_early_payoff_rate}
                                        </Typography>
                                    </Box>
                                    <Box display="flex" justifyContent="center" flex="1">
                                        <Typography variant="caption" color="text.primary">
                                            {currencyFormatter(
                                                item?.withdrawal_early_payoff_amount ?? 0
                                            )}
                                        </Typography>
                                    </Box>
                                    <Box display="flex" justifyContent="flex-end" flex="1">
                                        <Typography variant="caption" color="text.primary">
                                            -
                                            {currencyFormatter(
                                                item?.withdrawal_early_payoff_benefit ?? 0
                                            )}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Divider component="div" className={classes.divider} />
                            </Box>
                        ))}
                    </Box>
                </AccordionDetails>
            </CustomAccordion>

            <CustomAccordion>
                <AccordionSummary
                    expandIcon={<ArrowDropDown />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant="body2">Terms and conditions</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body2">
                        The terms and conditions related to your Future Receivable Sales Agreement
                        can be found{' '}
                        <Link
                            variant="inherit"
                            color="text.primary"
                            href="https://home.golendica.com/agreement-drawdown"
                            target="_blank"
                            rel="noopener noreferrer"
                            underline="always"
                        >
                            here
                        </Link>
                        . Please review before signing.
                    </Typography>
                </AccordionDetails>
            </CustomAccordion>
        </>
    );
};
