import { useNavigate, useParams } from 'react-router-dom';
import { FeatureLayout } from '@lendica/components';
import { useEffect, useState } from 'react';
import { payLaterAPI } from '../payLaterAPI';
import { Box } from '@mui/material';
import { AgreeToTerms } from '@lendica/select-paylater-terms';

export const AgreeToTermsView = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [selectedOffer, setSelectedOffer] = useState(null);
    const [approval, setApproval] = useState({});

    const { billId, offerId } = useParams();

    useEffect(() => {
        const getInvoiceDetails = async () => {
            setLoading(true);
            const res = await payLaterAPI.getBillOffers(billId);
            const offer = res.offers.findIndex(offer => offer?.id === offerId);
            const approvalData = await payLaterAPI.getPaylaterApproval();
            setApproval(approvalData);
            setData(res);
            setSelectedOffer(res.offers[offer]);
            setLoading(false);
        };
        getInvoiceDetails();
    }, []);

    const confirmDeal = async () => {
        try {
            const dealId = await payLaterAPI.initializeDeal(billId, offerId);
            navigate(`../confirmation/${dealId}`);
        } catch (e) {
            navigate('/paylater/error');
            setLoading(false);
        }
    };

    return (
        <FeatureLayout loading={loading}>
            <Box
                sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    boxSizing: 'border-box',
                    width: '100%',
                    px: 4,
                    py: 2,
                }}
            >
                {data && (
                    <AgreeToTerms
                        onAgree={confirmDeal}
                        onGoBack={() => navigate(-1)}
                        offer={selectedOffer}
                    />
                )}
            </Box>
        </FeatureLayout>
    );
};
