import { Box, Typography, Tab, Tabs } from '@mui/material';
import React, { FC, useState } from 'react';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        'id': `nav-tab-${index}`,
        'aria-controls': `nav-tabpanel-${index}`,
    };
}

interface BasicTabsProps {
    labels: string[];
    contents: any[];
}

export const BasicTabs = (props: BasicTabsProps) => {
    const { labels, contents } = props;
    const [tabValue, setTabValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    return (
        <Box sx={{ width: '100%', px: 2, boxSizing: 'border-box', height: '100%' }}>
            <Box>
                <Tabs
                    value={tabValue}
                    onChange={handleChange}
                    variant="fullWidth"
                    aria-label="activity center tabs"
                    textColor="primary"
                    indicatorColor="primary"
                >
                    {labels!.map((label, index) => (
                        <Tab key={`tab-menu-${index}`} label={label} {...a11yProps(index)} />
                    ))}
                </Tabs>
            </Box>
            <Box
                sx={{
                    overflowY: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    height: '100%',
                }}
            >
                {contents!.map((content, index) => (
                    <TabPanel key={`tab-content-${index}`} value={tabValue} index={index}>
                        {content}
                    </TabPanel>
                ))}
            </Box>
        </Box>
    );
};
