import { FC, useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ChevronLeft, PersonAdd } from '@mui/icons-material';
import {
    Box,
    Button,
    IconButton,
    InputAdornment,
    Stack,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    Typography,
    Alert,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import { StepProgressContent } from './StepProgressContent';
import { InvoicePdfViewer } from './InvoicePdfViewer';
import VendorSelect from '../components/VendorSelect';
import VendorFormPopup from '../components/VendorFormPopup';

import {
    FeatureLayout,
    HeaderToolbar,
    FormikTextField,
    FormikDatePicker,
    BankAccount,
    CompanyBankStatus,
    BankService,
    CompanyBankSelect,
    BankSupportButton,
    ApprovalAccordion,
    SaveStatus,
    useSaveStatus,
    SaveFormButton,
} from '@lendica/components';
import {
    // billStatusToLabel,
    Vendor,
    hasNumberOfDigits,
    isNumber,
    isNumberOfDigitsInRange,
    maskAccountNumber,
    Company,
    Approval,
} from '@lendica/utils';
import { getApprovalSummary } from '@lendica/api';
import { payLaterAPI, BillDetails, DeepPartial } from '../payLaterAPI';
import debounce from 'lodash/debounce';

const validationSchema = yup.object({
    bill: yup.object({
        total: yup.number().nullable().required('Required field'),
        invoice_number: yup.string().nullable().required('Required field'),
        date: yup.string().nullable().required('Required field'),
        due_date: yup.string().nullable().required('Required field'),
    }),
    vendor: yup.object({
        company_name: yup.string().nullable().required('Required field'),
        bank_name: yup.string().nullable().required('Required field'),
        bank_account: yup
            .string()
            .trim()
            .required('Required field')
            .test('match', 'Must be a number', isNumber)
            .test(
                'len',
                'Must have at least 5 and less than 18 digits',
                isNumberOfDigitsInRange(5, 18)
            ),
        bank_routing: yup
            .string()
            .trim()
            .required('Required field')
            .test('match', 'Must be a number', isNumber)
            .test('len', 'Routing number must be 9 digits', hasNumberOfDigits(9)),
        email: yup
            .string()
            .email('Please enter a valid email')
            .nullable()
            .required('Required field'),
    }),
    company: yup.object({
        bank_name: yup.string().nullable().required('Required field'),
        bank_account: yup
            .string()
            .trim()
            .required('Required field')
            .test('match', 'Must be a number', isNumber)
            .test(
                'len',
                'Must have at least 5 and less than 18 digits',
                isNumberOfDigitsInRange(5, 18)
            ),
        bank_routing: yup
            .string()
            .trim()
            .required('Required field')
            .test('match', 'Must be a number', isNumber)
            .test('len', 'Routing number must be 9 digits', hasNumberOfDigits(9)),
    }),
});

export const ConfirmDetails: FC = () => {
    const [data, setData] = useState<BillDetails | undefined>();
    const [activeStep, setActiveStep] = useState(1);
    const [open, setOpen] = useState(false);
    const [addNew, setAddNew] = useState(false);

    const navigate = useNavigate();
    const { billId } = useParams<{ billId: string }>();

    useEffect(() => {
        (async () => {
            const res = await payLaterAPI.getBillDetails(billId!);
            await handleBank(res.company);
            setData(res);
            setActiveStep(2);
        })();
    }, [billId]);

    const formik = useFormik<DeepPartial<BillDetails>>({
        initialValues: data ?? { bill: {}, vendor: null, company: {} },
        async onSubmit(values) {
            try {
                await payLaterAPI.confirmBillDetails(values);
                navigate(`../bill/${billId}/terms/select`);
            } catch (e) {
                console.log(e);
            }
        },
        validationSchema,
        enableReinitialize: true,
    });

    /* Bank */
    const [bankStatus, setBankStatus] = useState<CompanyBankStatus>(CompanyBankStatus.HAS_NONE);
    const [bankAccounts, setBankAccounts] = useState<BankAccount[]>([]);

    const handleBank = async (company: Company) => {
        // Step 1: check if company has default bank account
        if (BankService.hasDefaultBank(company)) {
            setBankStatus(CompanyBankStatus.HAS_DEFAULT);
            setBankAccounts([
                {
                    bank_account: maskAccountNumber(company!.bank_account || ''),
                    bank_name: company!.bank_name || '',
                    bank_routing: company!.bank_routing || '',
                },
            ]);
            return true;
        }

        // Step 2: check if company has connected plaid bank accounts
        const connectionList = await payLaterAPI.getDataConnections();
        const bankList = BankService.getBankAccounts(connectionList);
        const status = BankService.getConnectionStatus(bankList);
        setBankStatus(status);
        setBankAccounts(bankList);
    };

    const isBankFilled = () => {
        return (
            formik.values.company?.bank_name &&
            formik.values.company?.bank_account &&
            formik.values.company?.bank_routing
        );
    };
    /* End of Bank */

    /* Auto Save */
    let resetTimeout = 3000;
    const { saveStatus, setSaveStatus } = useSaveStatus(resetTimeout);
    const handleSave = async () => {
        try {
            setSaveStatus(SaveStatus.SUBMITTING);
            await payLaterAPI.confirmBillDetails(formik.values);
            setSaveStatus(SaveStatus.SUCCESS);
            // setTimeout(() => {
            //     formik.resetForm({ values: formik.values });
            // }, resetTimeout);
        } catch (e) {
            console.log(e);
            setSaveStatus(SaveStatus.ERROR);
        }
    };

    const handleBlur = useCallback(
        debounce(async () => {
            if (formik.dirty) {
                await handleSave();
            }
        }, 300),
        [formik.dirty, handleSave]
    );

    const handleDateSave = useCallback(
        debounce(async () => {
            if (formik.dirty) {
                await handleSave();
            }
        }, 1000),
        [handleSave]
    );

    useEffect(() => {
        if (formik.values.bill?.date && formik.values.bill?.due_date) {
            handleDateSave();
        }
    }, [formik.values.bill?.date, formik.values.bill?.due_date]);
    /* End of Auto Save */

    /* Vendor */
    const handleClose = () => {
        setOpen(false);
        setAddNew(false);
    };

    const addNewVendor = () => {
        setOpen(true);
        setAddNew(true);
    };

    const editVendor = () => {
        setOpen(true);
    };

    const handleSubmit = (vendor: Vendor) => {
        handleVendorSelect(vendor);
        handleClose();
    };

    const handleVendorSelect = async (vendor: Vendor) => {
        if (vendor) {
            await payLaterAPI.postVendorToBill(vendor, billId!);
            const bill = await payLaterAPI.getBillDetails(billId!);
            formik
                .setValues(values => ({
                    ...values,
                    vendor: bill.vendor,
                }))
                .then(() => {
                    handleSave();
                });
        } else {
            formik.setValues(values => ({
                ...values,
                vendor: null,
            }));
        }
    };

    // returns true if Vendor is empty, else returns false
    const isVendorEmpty = (vendor: Vendor | null | undefined) => {
        if (!!vendor) {
            if (Object.values(vendor).length === 0) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    };

    // returns true if Vendor is complete, else returns false
    const isVendorComplete = (vendor: Vendor | null | undefined) => {
        if (vendor) {
            if (Object.values(vendor).length === 0) {
                return false;
            }
            return (
                !!vendor?.company_name &&
                !!vendor?.email &&
                !!vendor?.company_address &&
                !!vendor?.first_name &&
                !!vendor?.last_name &&
                !!vendor?.phone_number &&
                !!vendor?.bank_name &&
                !!vendor?.bank_account &&
                !!vendor?.bank_routing
            );
        } else {
            return false;
        }
    };
    /* End of Vendor */

    /* Getting approval */
    interface ApprovalWithRate extends Approval {
        rateCurve: any;
    }
    const [approval, setApproval] = useState<ApprovalWithRate>();
    useEffect(() => {
        if (activeStep === 2) {
            (async () => {
                try {
                    const paylaterApproval = await getApprovalSummary('paylater');
                    setApproval(paylaterApproval![0]);
                } catch (e) {
                    console.log(e);
                }
            })();
        }
    }, [activeStep]);
    /* End of approval */

    return (
        <FormikProvider value={formik}>
            <FeatureLayout>
                <Box
                    sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        boxSizing: 'border-box',
                    }}
                >
                    <Box sx={{ px: 2.5 }}>
                        <HeaderToolbar
                            label="Upload Invoice"
                            leftAction={
                                <IconButton
                                    size="large"
                                    onClick={() => {
                                        navigate('..');
                                    }}
                                >
                                    <ChevronLeft fontSize="inherit" />
                                </IconButton>
                            }
                        />
                    </Box>
                    <Box sx={{ px: 4, pt: 1, pb: 4, flexGrow: 1, overflowY: 'auto' }}>
                        <Stepper
                            activeStep={activeStep}
                            orientation="vertical"
                            sx={{
                                '& > .MuiStepConnector-root span.MuiStepConnector-line': {
                                    minHeight: '8px !important',
                                },
                            }}
                        >
                            <Step>
                                <StepLabel>File upload</StepLabel>
                                <StepProgressContent
                                    label="Files are uploading..."
                                    description="Please wait for invoices to finish uploading."
                                />
                            </Step>
                            <Step>
                                <StepLabel>Processing invoice</StepLabel>
                                <StepProgressContent
                                    label="Uploaded and processing..."
                                    description="It takes a few moments to process the invoice. You can continue to add invoices and come back in a few minutes to confirm the details."
                                />
                            </Step>
                            <Step>
                                <StepLabel>Confirm details</StepLabel>
                                <StepContent>
                                    {!!approval && approval.rateCurve && (
                                        <Box mt={2}>
                                            <ApprovalAccordion approval={approval} />
                                        </Box>
                                    )}
                                    <Typography fontWeight="bold" sx={{ mb: 1, mt: 3.5 }}>
                                        Invoice summary
                                    </Typography>
                                    <Box
                                        sx={{
                                            '& > .MuiTextField-root': {
                                                mt: 2,
                                            },
                                        }}
                                    >
                                        <FormikTextField
                                            name="bill.total"
                                            label="Total Amount"
                                            fullWidth
                                            required
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        $
                                                    </InputAdornment>
                                                ),
                                            }}
                                            type="number"
                                            onBlur={handleBlur}
                                        />
                                        <FormikTextField
                                            name="bill.invoice_number"
                                            label="Invoice #"
                                            fullWidth
                                            required
                                            onBlur={handleBlur}
                                        />
                                        <Stack mt={2} spacing={2} direction="row">
                                            <FormikDatePicker
                                                name="bill.date"
                                                TextFieldProps={{
                                                    label: 'Invoice Issue Date',
                                                    fullWidth: true,
                                                    required: true,
                                                    onBlur: handleBlur,
                                                }}
                                                // onAccept={handleDateSave}
                                            />
                                            <FormikDatePicker
                                                name="bill.due_date"
                                                TextFieldProps={{
                                                    label: 'Payment Due Date',
                                                    fullWidth: true,
                                                    required: true,
                                                    onBlur: handleBlur,
                                                }}
                                                // onAccept={handleDateSave}
                                            />
                                        </Stack>
                                    </Box>

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            height: 'fit-content',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            pt: 3.5,
                                            pb: 1,
                                        }}
                                    >
                                        <Typography fontWeight="bold">Vendor</Typography>
                                        <Button
                                            variant="text"
                                            endIcon={<PersonAdd />}
                                            onClick={addNewVendor}
                                        >
                                            Add New Vendor
                                        </Button>
                                    </Box>

                                    <Box
                                        sx={{
                                            '& > .MuiTextField-root': {
                                                mt: 2,
                                            },
                                        }}
                                    >
                                        {isVendorEmpty(formik.values.vendor) && (
                                            <Alert severity="warning">
                                                Please add a vendor to continue.
                                            </Alert>
                                        )}
                                        <Box display="flex" gap={1} mt={2}>
                                            <VendorSelect
                                                handleSelect={handleVendorSelect}
                                                selectedVendor={formik.values.vendor}
                                            />
                                            <Button
                                                sx={{ height: 'fit-content' }}
                                                disabled={isVendorEmpty(formik.values.vendor)}
                                                onClick={editVendor}
                                            >
                                                Edit
                                            </Button>
                                        </Box>

                                        {!isVendorComplete(formik.values.vendor) &&
                                            !isVendorEmpty(formik.values.vendor) && (
                                                <Alert sx={{ mt: 1 }} severity="warning">
                                                    This vendor's information is incomplete. Please
                                                    finish editing the vendor and submit to
                                                    continue.
                                                </Alert>
                                            )}

                                        {!isVendorEmpty(formik.values.vendor) && (
                                            // <TextField
                                            //     name="vendor.email"
                                            //     size="small"
                                            //     label="Contact Email"
                                            //     type="string"
                                            //     fullWidth
                                            //     required
                                            //     value={formik.values.vendor?.email || ''}
                                            //     onChange={formik.handleChange}
                                            // />
                                            <FormikTextField
                                                name="vendor.email"
                                                label="Contact Email"
                                                fullWidth
                                                required
                                                type="string"
                                                onBlur={handleBlur}
                                            />
                                        )}
                                    </Box>

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            height: 'fit-content',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            pt: 3.5,
                                            pb: 1,
                                        }}
                                    >
                                        <Typography fontWeight="bold">Your account</Typography>
                                        {data && (
                                            <BankSupportButton
                                                company={data!.company}
                                                invoiceOrBill="Bill"
                                                invoiceId={data!.bill!.id}
                                            />
                                        )}
                                    </Box>
                                    <Box
                                        sx={{
                                            '& > .MuiTextField-root': {
                                                mt: 2,
                                            },
                                        }}
                                    >
                                        <CompanyBankSelect
                                            bankStatus={bankStatus}
                                            bankAccounts={bankAccounts}
                                            onBlur={handleBlur}
                                        />
                                    </Box>
                                </StepContent>
                            </Step>
                        </Stepper>
                    </Box>
                    {open && (
                        <VendorFormPopup
                            vendor={addNew ? null : formik.values.vendor}
                            open={open}
                            handleClose={handleClose}
                            handleSubmit={handleSubmit}
                        />
                    )}
                    {activeStep === 1 && (
                        <Box sx={{ px: 4, py: 1.5, borderTop: '1px solid #E5E5E5' }}>
                            <Button variant="contained" fullWidth onClick={() => navigate('../..')}>
                                Ok
                            </Button>
                        </Box>
                    )}
                    {activeStep === 2 && (
                        <Box
                            sx={{
                                px: 4,
                                py: 1.5,
                                borderTop: '1px solid #E5E5E5',
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 2,
                            }}
                        >
                            <SaveFormButton status={saveStatus} onSave={handleSave} />
                            <LoadingButton
                                variant="contained"
                                type="submit"
                                fullWidth
                                loading={formik.isSubmitting}
                                onClick={() => {
                                    formik.handleSubmit();
                                }}
                                // disabled={!formik.isValid}
                                disabled={
                                    !isVendorComplete(formik.values.vendor) ||
                                    isVendorEmpty(formik.values.vendor) ||
                                    !isBankFilled()
                                }
                            >
                                See Offers
                            </LoadingButton>
                        </Box>
                    )}
                </Box>
            </FeatureLayout>
            <InvoicePdfViewer collapsible />
        </FormikProvider>
    );
};
