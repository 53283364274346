import { Box, Typography, Button } from "@mui/material";

export const StatusPage = ({ title, subtitle, duration, action, actionLabel }) => {
    return (
        <Box
            flex={1}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            sx={{ height: '100%' }}
        >
            <Box>
                <Typography variant="h5" color="text.secondary" align="center" paragraph>
                    {title}
                </Typography>
            </Box>
            <Box mt={2} sx={{ width: '70%' }}>
                <Typography variant="subtitle1" color="secondary" align="center" paragraph>
                    {subtitle}
                </Typography>
            </Box>
            {/* <Box mt={2} display="flex" justifyContent="center">
                <CircularProgressTimer total={duration} onComplete={action} />
            </Box> */}
            <Box mt={4} display="flex" justifyContent="center">
                <Button variant="contained" color="primary" onClick={action}>
                    {actionLabel}
                </Button>
            </Box>
        </Box>
    );
};
