import { FC, ReactNode, useRef, createContext, useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import { WalletContainer } from '../WalletContainer';
import { MyCard } from '../components/MyCard';
import { MyDeals } from '../components/MyDeals';
import { DealSummaryView } from './DealSummaryView';
import { AccountSummaryView } from './AccountSummaryView';
import { WalletData } from '../WalletData';

const WalletElementContext = createContext<any>(null);
export const useWalletElementContext = () => {
    return useContext(WalletElementContext);
};

export interface WalletViewProps {
    placeholder?: ReactNode;
    callback?: any;
}

export const WalletView: FC<WalletViewProps> = ({ placeholder, callback }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const walletRef = useRef<HTMLDivElement>(null);
    const myDealsRef = useRef<HTMLDivElement>(null);
    // const location = useLocation();

    return (
        <WalletData>
            <WalletElementContext.Provider value={{ containerRef, walletRef, myDealsRef }}>
                <WalletContainer>
                    <MyCard />
                    <MyDeals />
                </WalletContainer>
                <Routes>
                    <Route path="account" element={<AccountSummaryView />} />
                    <Route path="deal/:dealId" element={<DealSummaryView />} />
                    <Route path="*" element={placeholder} />
                </Routes>
            </WalletElementContext.Provider>
        </WalletData>
    );
};
