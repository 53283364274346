import { Box } from '@mui/material';
import { FC } from 'react';
import { FeatureLayout } from '@lendica/components';
import { BasicTabs } from './components/BasicTabs';
import { Route, Routes } from 'react-router-dom';
import { UpcomingPayments } from './views/UpcomingPayments';
import { History } from './views/History';
import { useActivityCenter } from './hooks/useActivityCenter';
import { TransactionSummaryView } from './views/TransactionSummaryView';

export const ActivityCenter: FC = () => {
    const { isLoading, payments, payment_schedules, approvals } = useActivityCenter();

    return (
        <FeatureLayout loading={isLoading}>
            <Box
                sx={{
                    pb: 2,
                    px: 2,
                    pt: 1,
                    boxSizing: 'border-box',
                    maxWidth: 480,
                    height: '100%',
                    overflow: 'hidden',
                }}
            >
                <Routes>
                    <Route
                        path="/"
                        element={
                            <>
                                {/* <Box
                                    sx={{
                                        position: 'sticky',
                                        top: 0,
                                        mt: 2,
                                        boxSizing: 'border-box',
                                        width: '100%',
                                        zIndex: theme => theme.zIndex.drawer + 1,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            mt: 2,
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Box component="a" href="/">
                                            <Logo height={18} />
                                        </Box>
                                        <IconButton size="medium">
                                            <MoreVertIcon />
                                        </IconButton>
                                    </Box> 
                                </Box> 
                                */}
                                <BasicTabs
                                    labels={['Upcoming payments', 'History']}
                                    contents={[
                                        <UpcomingPayments
                                            payment_schedules={payment_schedules}
                                            approvals={approvals}
                                        />,
                                        <History payments={payments} />,
                                    ]}
                                />
                            </>
                        }
                    />
                    <Route
                        path="/detail/:transactionId"
                        element={<TransactionSummaryView payments={payments} />}
                    />
                </Routes>
            </Box>
        </FeatureLayout>
    );
};
