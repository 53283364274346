import { useNavigate, useParams } from 'react-router-dom';
import { SummaryCard } from '../components/SummaryCard';
import { FeatureLayout, RoundedButton } from '@lendica/components';
import { InvoiceCard } from '../components/InvoiceCard';
import { OfferCard } from '../components/OfferCard';
import { useEffect, useState } from 'react';
import * as api from '../api/index';
import { ChevronLeft } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { ArrowDropDown } from '@mui/icons-material';
import {
    Box,
    IconButton,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Link,
} from '@mui/material';

const CustomAccordion = withStyles({
    root: {
        'borderTop': '1px solid #D9D9E5',
        'borderBottom': '1px solid #D9D9E5',
        'boxShadow': 'none',
        'marginBottom': 24,
        '& > div:first-child': {
            paddingLeft: 0,
            paddingRight: 8,
        },
        '&:before': {
            display: 'none',
        },
        '&.Mui-expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(Accordion);

export const DealConfirmation = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});

    const { id } = useParams();

    useEffect(() => {
        const getInvoiceDetails = async () => {
            setLoading(true);
            const res = await api.getOffers(id);
            setData(res);
            setLoading(false);
        };
        getInvoiceDetails();
    }, []);

    const confirmDeal = async () => {
        try {
            const res = await api.createFundnowDeal(id);
            navigate(`/fundnow/invoice/${id}/terms/confirmation/${res.deal.id}`);
        } catch (e) {
            console.log(e);
            if (
                e.response.data.error[0] ===
                'Deal amount exceeds available credit. Please contact the Lendica team if you believe this is an error.'
            ) {
                navigate('/fundnow/exceeded-limit');
            }
        }
    };

    return (
        <FeatureLayout loading={loading}>
            <Box
                sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    boxSizing: 'border-box',
                    width: '100%',
                }}
            >
                <Box
                    sx={{
                        // height: '100%',
                        width: '100%',
                        padding: 2,
                        boxSizing: 'border-box',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-evenly',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Box flex={1}>
                            <IconButton
                                onClick={() => navigate(-1)}
                                sx={{ width: 'fit-content', alignSelf: 'flex-end' }}
                                color="secondary"
                                size="small"
                            >
                                <ChevronLeft />
                            </IconButton>
                        </Box>

                        <Box flex={1} textAlign="center">
                            <Typography variant="h6" fontSize={16}>
                                Agree to terms
                            </Typography>
                        </Box>

                        <Box flex={1}></Box>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-evenly',
                            height: '100%',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant="h6" color="text.secondary" mt={2} mb={2}>
                            Congrats! You’re getting paid today
                        </Typography>
                        <Typography variant="h2" color="text.secondary" mt={2} mb={2}>
                            🎉
                        </Typography>

                        <InvoiceCard invoice={data.invoice} />
                        <OfferCard offer={data.offer} />
                    </Box>

                    <SummaryCard invoice={data.invoice} offer={data.offer} />
                </Box>

                <Box
                    sx={{
                        px: 2.5,
                        my: 2,
                        mb: 3,
                        boxSizing: 'border-box',
                    }}
                >
                    <CustomAccordion>
                        <AccordionSummary
                            expandIcon={<ArrowDropDown />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography variant="body2">Terms and conditions</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2">
                                The terms and conditions related to your Future Receivable Sales
                                Agreement can be found{' '}
                                <Link
                                    variant="inherit"
                                    color="text.primary"
                                    href="https://home.golendica.com/agreement-fundnow"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    underline="always"
                                >
                                    here
                                </Link>
                                . Please review before signing.
                            </Typography>
                        </AccordionDetails>
                    </CustomAccordion>
                    <Typography variant="caption">
                        *By clicking 'ACCEPT OFFER' you agree to all{' '}
                        <Link
                            variant="inherit"
                            color="text.primary"
                            href="https://home.golendica.com/agreement-fundnow"
                            target="_blank"
                            rel="noopener noreferrer"
                            underline="always"
                        >
                            terms and conditions
                        </Link>{' '}
                        of FundNow product.
                    </Typography>
                </Box>

                <Box
                    sx={{
                        position: 'sticky',
                        bottom: 0,
                        mb: 2,
                        boxSizing: 'border-box',
                        px: 2,
                        width: '100%',
                        py: 1,
                        background: '#FFF',
                    }}
                >
                    <RoundedButton variant="contained" fullWidth onClick={confirmDeal}>
                        Accept Offer
                    </RoundedButton>
                </Box>
            </Box>
        </FeatureLayout>
    );
};
