import { Menu, MenuItem, ListItemIcon } from '@mui/material';
import { useActions } from '../useActions';

export const ContextMenu = ({ contextMenu, handleClose }) => {
    const actions = useActions(contextMenu?.row);

    return (
        <Menu
            open={!!contextMenu}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={
                contextMenu ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined
            }
            componentsProps={{
                root: {
                    onContextMenu: e => {
                        e.preventDefault();
                        handleClose();
                    },
                },
            }}
        >
            {actions.map(({ Icon, title, onClick }) => {
                return (
                    <MenuItem
                        key={title}
                        onClick={() => {
                            onClick();
                            handleClose();
                        }}
                    >
                        <ListItemIcon>
                            <Icon fontSize="small" />
                        </ListItemIcon>
                        {title}
                    </MenuItem>
                );
            })}
        </Menu>
    );
};
