import {
    Box,
    Typography,
    List,
    ListItem,
    ListItemAvatar,
    IconButton,
    Avatar,
    Chip,
    Stack,
} from '@mui/material';
import { FC, ReactNode, MouseEvent } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PaidIcon from '@mui/icons-material/MonetizationOn';
import { currencyFormatter } from '@lendica/utils';
import { PaymentType, TransactionCategory } from '../sharedTypes';
import { formatDateLong } from '@lendica/utils'
import ProductBadge from './ProductBadge';
import PaymentBadge from './PaymentBadge';

export interface ActivityListItemProps {
    dealId: string;
    amount: number;
    product: string;
    date: string;
    paymentType?: PaymentType;
    transactionCategory?: TransactionCategory;
    action?: boolean;
    hasNavigation?: boolean;
    descriptionCode: string;
    onNavigationClick?(e: MouseEvent<HTMLElement>): void;
}

export const ActivityListItem: FC<ActivityListItemProps> = ({
    dealId,
    amount,
    product,
    date,
    paymentType,
    action,
    descriptionCode,
    transactionCategory,
    hasNavigation = false,
    onNavigationClick,
}) => {

    let formattedAmount = descriptionCode !== '111' ? amount :
        amount === 0 ? amount : amount * -1

    return (
        <ListItem
            // button
            component="a"
            sx={{ py: 2, px: 1, width: '100%', boxSizing: 'border-box' }}
            // secondaryAction={
            //     action ? (
            //         <IconButton edge="end">
            //             <ArrowForwardIosIcon />
            //         </IconButton>
            //     ) : null
            // }
            disableGutters
            divider
        >
            <Box flex="1">
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Typography variant="body2" color="text.primary">
                        {formatDateLong(date)}
                    </Typography>
                </Box>

                <Stack direction="row" spacing={1}>
                    <Chip
                        sx={{
                            bgcolor: 'rgba(46, 49, 146, 0.08)',
                            color: 'primary.main'
                        }}
                        size="small"
                        key={dealId}
                        label={dealId}
                    />
                    <PaymentBadge descriptionCode={descriptionCode} />
                </Stack>
            </Box>

            <Box display="flex" flexDirection="column" alignItems="flex-end" justifyContent="flex-start" height="100%">
                <Typography variant="body2" color="text.primary">
                    {currencyFormatter(formattedAmount)}
                </Typography>
                <ProductBadge transactionType={product} />
            </Box>

            {hasNavigation && (
                <IconButton size="large" onClick={onNavigationClick}>
                    <ArrowForwardIosIcon />
                </IconButton>
            )}
        </ListItem>
    );
};
