import { useNavigate, useParams } from 'react-router-dom';
import { FeatureLayout, PayLaterLogo, ImgWithFallback } from '@lendica/components';
import { BillCard } from '../components/BillCard';
import { PaymentDetails } from '../components/PaymentDetails';
import { useEffect, useState } from 'react';
import { payLaterAPI } from '../payLaterAPI';
import { ChevronLeft } from '@mui/icons-material';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { UpdatedTermCard } from '../components/UpdatedTermCard';

import { useContext } from 'react';
import { SplitContext, useSplitTreatments } from '@splitsoftware/splitio-react';

const BillHeader = ({ bill, partnerName }) => {
    const {
        treatments: { paylater_mkt_offer_split },
        isReady,
    } = useSplitTreatments({ names: ['paylater_mkt_offer_split'] });
    if (!isReady) return <div>Loading ...</div>;

    if (paylater_mkt_offer_split.treatment === 'new') {
        return (
            <>
                {/* <Typography
                variant="h5"
                textAlign="center"
                fontSize={26}
                fontWeight={600}
                color="text.primary"
                mt={1}
                mb={1}
            >
                Pay this bill over time
            </Typography> */}
                <Box
                    sx={{
                        boxSizing: 'border-box',
                        width: 294, // control width with container
                        height: 29, // control height with container
                        overflow: 'hidden',
                        background: 'none',
                        mt: 2,
                        mb: 1,
                    }}
                >
                    <ImgWithFallback
                        src={`https://static.golendica.com/assets/img/pay_over_time.webp`}
                        fallback={`https://static.golendica.com/assets/img/pay_over_time.png`}
                    />
                </Box>
                <Typography
                    variant="h5"
                    textAlign="center"
                    fontSize={20}
                    maxWidth={280}
                    color="text.secondary"
                >
                    This tool can help your business manage cash flow.
                </Typography>
                <Box
                    width={'100%'}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'fex-start'}
                    alignSelf={'flex-start'}
                    mt={1}
                    mb={1}
                >
                    <Typography fontSize={16} fontWeight={700} variant="subtitle1" my={1}>
                        How does it work?
                    </Typography>
                    <Box my={1} mx="auto">
                        <img
                            src="https://lendica-public.s3.amazonaws.com/how_it_works_4_steps.svg"
                            width={340}
                            height={88}
                        ></img>
                    </Box>
                    <Box
                        sx={{
                            px: 2,
                            py: 2,
                            mt: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-evenly',
                            maxWidth: 480,
                            alignItems: 'flex-start',
                            borderRadius: 3,
                            border: '1px solid #EDEDF0',
                            background: `linear-gradient(49deg, rgba(239, 239, 246, 0.60) 24.51%, rgba(244, 244, 246, 0.10) 100.04%)`,
                        }}
                    >
                        <Typography
                            fontSize={14}
                            fontWeight={600}
                            color="text.primary"
                            variant="subtitle1"
                            mx={1}
                        >
                            "PayLater helped me improve my margins."
                        </Typography>
                        <Typography
                            fontSize={13}
                            fontWeight={400}
                            color="text.secondary"
                            variant="subtitle1"
                            my={0.5}
                            mx={1}
                        >
                            See how customers like you use Lendica to hit quantity discounts, order
                            premium products, and expand into new markets.
                        </Typography>
                        <Button
                            href={`https://learn.golendica.com/partner/${partnerName}?utm_source=${partnerName}&utm_medium=ibranch&utm_content=paylater_mkt_offer_case_study#casestudy`}
                            underline="hover"
                            variant="text"
                            color="primary"
                            target="_blank"
                            rel="opener"
                        >
                            Learn from the experts
                        </Button>
                    </Box>
                </Box>
            </>
        );
    } else {
        return (
            <>
                <Typography variant="h5" color="text.secondary" mt={4} mb={1}>
                    PayLater frees up your cash flow!
                </Typography>
                <Typography variant="h1" color="text.secondary" mt={1} mb={1}>
                    🦦
                </Typography>
                {bill && <BillCard bill={bill} />}
            </>
        );
    }
};

export const PayLaterMarketingOffer = ({ apply = false, activate = false }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [selectedOffer, setSelectedOffer] = useState(null);

    const { id } = useParams();

    const { isReady, isTimedout } = useContext(SplitContext);

    const getPartnerName = () => {
        const partner = lendicaConfig?.partner_name || 'demo';
        return partner.replace(/_.*$/, '');
    };

    useEffect(() => {
        const getInvoiceDetails = async () => {
            setLoading(true);
            const res = await payLaterAPI.getBillOffers(id);
            setData(res);
            setSelectedOffer(res.offers[0]);
            setLoading(false);
        };
        getInvoiceDetails();
    }, []);

    const goToApplication = () => {
        navigate(
            `/application/application?utm_source=${getPartnerName()}&utm_medium=ibranch&utm_content=paylater_mkt_offer_apply`
        );
    };

    const activatePayLater = async () => {
        try {
            await payLaterAPI.activatePaylater();
            navigate('/paylater');
        } catch (e) {
            console.log(e);
        }
    };

    const onSelect = offer => {
        setSelectedOffer(offer);
    };

    return (
        <FeatureLayout loading={loading} fullHeight>
            <Box
                sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    boxSizing: 'border-box',
                    width: '100%',
                }}
            >
                <Box
                    sx={{
                        // height: '100%',
                        width: '100%',
                        padding: 2,
                        boxSizing: 'border-box',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-evenly',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Box flex={1}>
                            <IconButton
                                onClick={() => navigate(-2)}
                                sx={{ width: 'fit-content', alignSelf: 'flex-end' }}
                                color="secondary"
                                size="small"
                            >
                                <ChevronLeft />
                            </IconButton>
                        </Box>

                        <Box flex={2} textAlign="center">
                            <Typography variant="h6" fontSize={14}>
                                Select payment terms
                            </Typography>
                        </Box>

                        <Box flex={1}></Box>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-evenly',
                            height: '100%',
                            alignItems: 'center',
                        }}
                    >
                        {/* <SplitFactory config={splitConfig}> */}
                        {data && isReady && !isTimedout && (
                            <BillHeader bill={data.bill} partnerName={getPartnerName()} />
                        )}
                        {/* </SplitFactory> */}
                        <Box
                            display={'flex'}
                            alignItems="center"
                            alignSelf={'flex-start'}
                            mt={2}
                            mb={1}
                        >
                            <Typography fontSize={16} fontWeight={700} variant="subtitle1" mr={1}>
                                Select a
                            </Typography>
                            <PayLaterLogo height={18} />
                            <Typography fontSize={16} fontWeight={700} variant="subtitle1" ml={1}>
                                term
                            </Typography>
                        </Box>

                        {data &&
                            data.offers.map((x, i) => (
                                <UpdatedTermCard
                                    key={x.id}
                                    offer={x}
                                    dueDate={data.bill.due_date}
                                    selected={x.id === selectedOffer?.id}
                                    onSelect={onSelect}
                                    lowestWeekly={i === data.offers.length - 1}
                                    lowestFees={i === 0}
                                />
                            ))}
                        {data && <PaymentDetails offer={selectedOffer} bill={data.bill} />}
                    </Box>
                </Box>

                <Box
                    sx={{
                        position: 'sticky',
                        bottom: 0,
                        mb: 2,
                        boxSizing: 'border-box',
                        px: 2,
                        width: '100%',
                        py: 1,
                        background: '#FCFCFD',
                        borderTop: '1px solid #E5E5E5',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 1,
                    }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={apply ? goToApplication : activatePayLater}
                    >
                        {apply ? 'Continue to apply' : 'Activate PayLater'}
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        fullWidth
                        href="https://calendly.com/lendica/intro"
                        target="_blank"
                        rel="opener"
                    >
                        Schedule a call
                    </Button>
                </Box>
            </Box>
        </FeatureLayout>
    );
};
