import { useFormikContext } from 'formik';
import { Box } from '@mui/material';
import { Alert } from '@mui/material';

import { useFormContext } from '../FormContext';
import { Fields } from './Fields';

export const Body = () => {
    const {
        data: {
            application: {
                screen: [screen],
            },
        },
    } = useFormContext();
    const formik = useFormikContext();

    return (
        <Box mt={2} px={2} pb={4}>
            <form onSubmit={formik.handleSubmit}>
                {formik.submitCount > 0 && formik.errors.form && (
                    <Alert severity="error">{formik.errors.form}</Alert>
                )}
                <Fields fields={screen.fields} />
            </form>
        </Box>
    );
};
