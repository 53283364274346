import { FC } from 'react';
import { Box } from '@mui/material';

interface StatusBadgeProps {
    status: String
}

const StatusBadge: FC<StatusBadgeProps> = ({ status }) => {
    let color;
    let label;

    if (status == "In-Process") {
        color = 'rgba(46, 49, 146, 0.08)'
        label = 'In-Process'
    } else if (status == "Cleared") {
        color = 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), linear-gradient(0deg, #58A10E, #58A10E)'
        label = 'Cleared'
    } else {
        color = 'rgba(46, 49, 146, 0.08)'
        label = status
    }
    // } else if (status == "Cancelled") {
    //     color = 'rgba(250, 175, 109, 0.16)'
    //     label = 'Cancelled'
    // }

    return (
        <Box
            className="product-badge"
            style={{
                marginLeft: '0.5rem',
                background: color,
                borderRadius: '4px',
                padding: '0 0.5rem',
                fontSize: '12px'
            }}
        >
            {label}
        </Box>
    )
}
export default StatusBadge