import { ReactNode } from 'react';
import { Route, Routes } from 'react-router-dom';
import { MyApprovalsView } from './MyApprovalsView';

export interface ApprovalsProps {
    onError: () => void;
    placeholder?: ReactNode;
}

export const Approvals = ({ onError, placeholder }: ApprovalsProps) => {
    return (
        <Routes>
            <Route path="*" element={<MyApprovalsView onError={onError} />} />
        </Routes>
    );
};
