import { useEffect, useState } from 'react';
import axios from 'axios';
import { CompanySummaryResponse } from '../sharedTypes';
import { accessToken, setAxiosInterceptors } from '@lendica/auth';
// Configured per https://github.com/Lendica/pod-widget/commit/0ff23c5a25f726f6559f8b4eb07715bf63da8e41 (Aleh Buyalski 9/13/2022) and https://github.com/Lendica/pod-widget/commit/891c6113ce9e2f094589be71a6b48d294344b5f1 (Shiyi Peng 9/8/2022)
// export const baseURL = 'https://api-dev.golendica.com/api/v1';
// export const axiosInstance = axios.create({ baseURL });
// setAxiosInterceptors(baseURL);



export const useActivityCenter = () => {
    const [activityCenter, setActivityCenter] = useState({ payments: [], payment_schedules: [], approvals: [] });
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchActivityCenter = async () => {
            try {
                // const { data } = await axios.get<CompanySummaryResponse>('company/summary')
                const payment_schedules = (await axios.get('/company/payment_schedules')).data
                const payments = (await axios.get('/company/payments')).data
                const approvals = (await axios.get('/company/approvals')).data
                setActivityCenter({ payment_schedules, payments, approvals });
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        };

        fetchActivityCenter();
    }, []);

    return {
        isLoading: isLoading,
        payments: activityCenter.payments,
        payment_schedules: activityCenter.payment_schedules,
        approvals: activityCenter.approvals
    }
}