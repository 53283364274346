import { currencyFormatter, percentageFormatter } from '@lendica/utils';
import { Box, IconButton, Typography } from '@mui/material';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { parseISO, format, addWeeks, addDays } from 'date-fns';

export const UpdatedTermCard = ({ disabled, selected, dueDate, offer, onSelect }) => {
    const getTotalFees = (interest, originationFee) => {
        return interest + originationFee;
    };

    const getTotalPayment = (totalPeriodicPayments, totalPrice, advanceAmount, origination_fee) => {
        return totalPeriodicPayments + totalPrice - advanceAmount + origination_fee;
    };

    const formatDueDate = (dueDate, firstPaymentDelay) => {
        if (dueDate) {
            return format(addDays(parseISO(dueDate), firstPaymentDelay), 'MMM d, yyyy');
        } else {
            return format(addDays(new Date(), firstPaymentDelay), 'MMM d, yyyy');
        }
    };

    const handleSelect = () => {
        if (!disabled) {
            onSelect(offer);
        }
    };

    const mapPaymentFrequency = frequency => {
        if (frequency === 'W') {
            return 'week';
        } else if (frequency === 'M') {
            return 'month';
        } else {
            return 'day';
        }
    };

    return (
        !!offer && (
            <Box
                sx={theme => ({
                    'display': 'flex',
                    'flexDirection': 'column',
                    'width': '100%',
                    'px': 2,
                    'py': 1.5,
                    'background': selected ? 'rgba(46, 49, 146, 0.04)' : 'inherit',
                    'borderRadius': 2,
                    'border': selected
                        ? `2px solid ${theme.palette.primary.main}`
                        : '2px solid #D8D8DF',
                    'boxSizing': 'border-box',
                    'mb': 1,
                    'cursor': disabled ? 'default' : 'pointer',
                    '&:hover': {
                        background: 'rgba(46, 49, 146, 0.04)',
                        // border: '2px solid #2E3192'
                    },
                })}
                onClick={handleSelect}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 1.5,
                    }}
                >
                    <Box display="flex" alignItems="center">
                        <Box
                            className="badge"
                            sx={theme => ({
                                background: selected
                                    ? `${theme.palette.primary.main}`
                                    : `${theme.palette.info.light}`,
                                height: 'fit-content',
                                borderRadius: '4px',
                                px: 1,
                                mr: 1,
                                color: '#FCFCFD',
                            })}
                        >
                            <Typography
                                fontSize={14}
                                fontWeight={700}
                            >{`Extend ${offer.expected_days_outstanding} days`}</Typography>
                        </Box>
                    </Box>

                    <Box display="flex" alignItems={'center'}>
                        <Box
                            className="badge"
                            sx={{
                                // background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #58A10E',
                                background: 'rgba(46, 49, 146, 0.08)',
                                borderRadius: '4px',
                                height: 'fit-content',
                                px: 0.5,
                                // py: 0.5,
                                // color: '#58A10E',
                                mr: 0.5,
                            }}
                        >
                            <Typography fontSize={12} fontWeight={500} color="text.secondary">
                                {offer.number_of_payments === 1
                                    ? `1 payment`
                                    : `${offer.number_of_payments} payments`}
                            </Typography>
                        </Box>
                        {onSelect && (
                            <IconButton onClick={handleSelect} sx={{ p: 0 }}>
                                {selected ? (
                                    <RadioButtonCheckedIcon color="primary" />
                                ) : (
                                    <RadioButtonUncheckedIcon />
                                )}
                            </IconButton>
                        )}
                    </Box>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                        <Typography
                            color={selected ? 'primary' : 'secondary.dark'}
                            variant="h4"
                            fontSize={24}
                            fontWeight={800}
                        >
                            {currencyFormatter(offer.periodic_payment)}
                        </Typography>
                        {offer.number_of_payments > 1 && (
                            <Typography
                                color={selected ? 'primary' : 'secondary.dark'}
                                variant="subtitle2"
                                fontWeight={500}
                                fontSize={14}
                                ml={1}
                            >{`/ ${mapPaymentFrequency(offer.payment_frequency)}`}</Typography>
                        )}
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'flex-end', mr: 1 }}>
                        <Typography
                            color={selected ? 'primary' : 'secondary.dark'}
                            fontSize={14}
                            fontWeight={700}
                        >
                            {offer.number_of_payments === 1
                                ? `on ${formatDueDate(dueDate, offer.first_payment_delay)}`
                                : `starts ${formatDueDate(dueDate, offer.first_payment_delay)}`}
                        </Typography>
                    </Box>
                </Box>

                {selected && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            mt: 1.5,
                        }}
                    >
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography
                                color={'text.secondary'}
                                variant="caption"
                                fontWeight={400}
                                fontSize={12}
                            >
                                Rate
                            </Typography>
                            <Typography
                                color={'text.primary'}
                                variant="h4"
                                fontSize={14}
                                fontWeight={600}
                            >
                                {percentageFormatter(offer.rate)}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography
                                color={'text.secondary'}
                                variant="caption"
                                fontWeight={400}
                                fontSize={12}
                                textAlign="left"
                            >
                                Total fees
                            </Typography>
                            <Typography
                                color={'text.primary'}
                                variant="h4"
                                fontSize={14}
                                fontWeight={600}
                                textAlign="left"
                            >
                                {currencyFormatter(
                                    getTotalFees(offer.interest, offer.origination_fee)
                                )}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography
                                color={'text.secondary'}
                                variant="caption"
                                fontWeight={400}
                                fontSize={12}
                                textAlign="right"
                            >
                                Total payments
                            </Typography>
                            <Typography
                                color={'text.primary'}
                                variant="h4"
                                fontSize={14}
                                fontWeight={600}
                                textAlign="right"
                            >
                                {currencyFormatter(
                                    getTotalPayment(
                                        offer.total_payments,
                                        offer.total_price,
                                        offer.advance_amount,
                                        offer.origination_fee
                                    )
                                )}
                            </Typography>
                        </Box>
                    </Box>
                )}
            </Box>
        )
    );
};
