import { Box, CircularProgress, StepContent, Typography } from '@mui/material';
import { FC } from 'react';

interface StepProgressContentProps {
    label: string;
    description: string;
    error?: boolean;
}

export const StepProgressContent: FC<StepProgressContentProps> = ({
    label,
    description,
    error,
}) => {
    return (
        <StepContent>
            <Typography variant="h5" sx={{ mt: 4 }}>
                {label}
            </Typography>
            <Typography variant="subtitle1" fontWeight="regular" sx={{ mt: 3 }}>
                {description}
            </Typography>
            {!error && (
                <Box sx={{ display: 'flex', justifyContent: 'center', pt: 12, pb: 20 }}>
                    <CircularProgress />
                </Box>
            )}
        </StepContent>
    );
};
