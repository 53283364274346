import { Box } from '@mui/material';
import { FC } from 'react';

interface ProductBadgeProps {
    transactionType: String
}

const ProductBadge: FC<ProductBadgeProps> = ({
    transactionType
}) => {
    let color;
    let label;

    if (transactionType == "paylater") {
        color = 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), linear-gradient(0deg, #58A10E, #58A10E)';
        label = 'PayLater'
    } else if (transactionType == "fundnow") {
        color = 'rgba(250, 175, 109, 0.16)'
        label = 'FundNow'
    } else if (transactionType == "drawdown") {
        color = 'rgba(46, 49, 146, 0.08)'
        label = 'DrawDown'
    }

    return (
        <Box
            className="badge"
            sx={{
                marginLeft: '0.5rem',
                background: color,
                borderRadius: '6px',
                padding: '0 0.5rem',
                fontSize: '12px',
                fontWeight: 500
            }}
            color="primary.main"
        >
            {label}
        </Box>
    )
}
export default ProductBadge