import { Route, Routes, useNavigate } from 'react-router-dom';
import { IconButton, Box, Button, Typography, List, Divider } from '@mui/material';
import { Logo, Body, FeatureHeader, DrawDownLogo, FeatureLayout } from '@lendica/components';
import React, { useState, useRef, useEffect } from 'react';
import { WithdrawalWidget } from './views/Withdrawal/WithdrawalWidget';
import { PushMsg } from './components';
import { PaymentWidget } from './views/Payment/PaymentWidget';
import { ActivityItem } from './views/Activities/ActivityItem';
import { makeStyles } from '@mui/styles';
import { usePayment } from './views/Payment/hooks/usePayment';
import { MyDeals } from './components/MyDeals';
import { DealSummaryView } from './views/DealSummaryView';
import * as api from './api/index';

const useStyles = makeStyles(theme => ({
    // productName: {
    //     color: theme.palette.background.paper,
    //     backgroundColor: theme.palette.primary.light,
    //     borderRadius: 2,
    // },
    card: {
        borderRadius: 12,
        borderColor: theme.palette.background.cardBorder,
        borderWidth: 1,
        backgroundColor: theme.palette.background.cardLight,
        borderStyle: 'solid',
    },
    underline: {
        width: '100%',
        height: 1,
        backgroundColor: theme.palette.background.cardBorder,
    },
    divider: {
        'marginRight': theme.spacing(2),
        '&:last-child': {
            display: 'none',
            visibility: 'hidden',
        },
    },
    button: {
        borderRadius: 30,
        boxShadow: 'none',
        width: '100%',
    },
    alert: {
        marginBottom: theme.spacing(3),
        background:
            "center / cover url('https://lendicapublic.s3.amazonaws.com/assets/illustrations/unsplash_ZKVBM2_Dp84.png')",
        boxShadow: 'inset 0 0 0 1000px rgba(255,255,255,.7)',
    },
}));

const pushMsg = {
    title: 'Cash Conversion. Solved.',
    content:
        'Did you know that Lendica’s PayLater product can delay vendor payments and dramatically increase your cash conversion? We’ll show you how.',
    to: 'https://calendly.com/lendica/intro',
};

export const DrawDownView = ({
    deals,
    rates,
    approval,
    placeholder,
    setWithdrawal,
    withdrawalData,
    setDealCreated,
    rate,
}) => {
    const [pushMsgOpen, setPushMsgOpen] = useState(true);
    const classes = useStyles();
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    const handleClose = () => {
        setPushMsgOpen(false);
    };

    const handleDealCreated = () => {
        setDealCreated(true);
    };

    useEffect(() => {
        (async () => {
            const company = await api.getCompanyDetails();
            if (company.status === 2) {
                if (company.drawdown_status === 0) {
                    navigate('/drawdown/waitlist');
                } else if (company.drawdown_status === 1) {
                    navigate('/drawdown/product-pending');
                } else if (company.drawdown_status === 3) {
                    navigate('/drawdown/product-paused');
                }
            }
            setLoading(false);
        })();
    }, []);

    return (
        <>
            <FeatureLayout loading={loading}>
                <Box
                    sx={{
                        pb: 4,
                        px: 4,
                        pt: 2,
                        height: '100%',
                        boxSizing: 'border-box',
                    }}
                >
                    <Box sx={{ mt: 2, pb: 2, boxSizing: 'border-box' }}>
                        <FeatureHeader
                            name={<DrawDownLogo />}
                            available={approval?.funds_available ?? 0}
                            total={approval?.qualified_amount ?? 0}
                            unpaid={approval?.total_payoff_amount ?? 0}
                            entityNumber={approval?.number_of_active_deals ?? 0}
                            entity="withdrawals"
                        />
                        <WithdrawalWidget
                            approval={approval}
                            available={approval?.funds_available ?? 0}
                            withdrawalData={withdrawalData}
                            setWithdrawal={setWithdrawal}
                            gradient={false}
                            setDealCreated={handleDealCreated}
                            rate={rate}
                            rates={rates}
                        />
                        <MyDeals deals={deals} />
                        {/* <>
                            <PaymentWidget
                                payment={nextDue?.amount ?? 0}
                                due={nextDue?.date ?? ''}
                                expire={data?.summary?.credit_days_remaining ?? 0}
                            />
                            <PushMsg
                                data={pushMsg}
                                open={pushMsgOpen}
                                onClose={handleClose}
                                classes={{ root: classes.alert }}
                            /> 
                            <>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    mb={2}
                                >
                                    <Typography variant="h5" color="text.primary">
                                        Latest Activities
                                    </Typography>
                                    <Button onClick={() => navigate('activities')} size="small">
                                        See all
                                    </Button>
                                </Box>
                                <List component="nav" className={classes.card} px={2} py={1}>
                                    {data.activities
                                        .filter(d => !(d.activity_type === 1 && d.status === 0))
                                        .reverse()
                                        .slice(0, 4)
                                        .map((activity, index) => (
                                            <React.Fragment key={index}>
                                                <ActivityItem activity={activity} />
                                                <Divider
                                                    variant="inset"
                                                    component="li"
                                                    className={classes.divider}
                                                />
                                            </React.Fragment>
                                        ))}
                                </List>
                            </>
                        </> */}
                    </Box>
                </Box>
            </FeatureLayout>

            <Routes>
                <Route path="*" element={placeholder} />
                <Route path="deal/:dealId" element={<DealSummaryView deals={deals} />} />
            </Routes>
        </>
    );
};
