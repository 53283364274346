import { useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CloverLogo, SquareLogo, StripeLogo, LightspeedLogo } from '@lendica/components';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import axios from 'axios';

const services = [
    {
        logo: SquareLogo,
        key: 'square'
    },
    {
        logo: StripeLogo,
        key: 'stripe'
    },
    {
        logo: LightspeedLogo,
        key: 'lightspeed'
    },
    // {
    //     logo: CloverLogo,
    //     key: 'clover'
    // },
]

export const PointOfSale = ({ company_id, env }: { company_id: string, env: string }) => {
    const [expanded, setExpanded] = useState(false);
    const [connections, setConnections] = useState([])
    const [stripeConnnected, setStripeConnected] = useState(false)
    const [squareConnected, setSquareConnected] = useState(false)
    const [lightspeedConnected, setLightspeedConnected] = useState(false)
    const [popupClosed, setPopupClosed] = useState(false)

    const launchPopup = (service: string) => {
        let win: any;
        let parameters = "location=1,width=800,height=650";
        parameters += ",left=" + (screen.width - 800) / 2 + ",top=" + (screen.height - 650) / 2;

        setPopupClosed(false)
        // win = window.open(`http://localhost:5000/oauth/${service}?company_id=${company_id}`, 'connectPopup', parameters);
        // win = window.open(`https://micro-oauth-dev.herokuapp.com/oauth/${service}?company_id=${company_id}`, 'connectPopup', parameters);
        // win = window.open(`https://oauth.app.golendica.com/oauth/${service}?company_id=${company_id}`, 'connectPopup', parameters);

        if (env === 'idev') {
            win = window.open(`https://micro-oauth-dev.herokuapp.com/oauth/${service}?company_id=${company_id}`, 'connectPopup', parameters);
        } else if (env === 'prod') {
            win = window.open(`https://oauth.app.golendica.com/oauth/${service}?company_id=${company_id}`, 'connectPopup', parameters);
        }
        let interval = setInterval(() => {
            if (win.closed) {
                setPopupClosed(true)
                clearInterval(interval)
            }
        }, 1000)
    }

    useEffect(() => {
        const getConnections = async () => {
            const res = (await axios.get('/company/connections')).data

            let isStripeConnected = res.find((x: any) => x.active === true && x.saas_name === 'stripe')
            let isSquareConnected = res.find((x: any) => x.active === true && x.saas_name === 'square')
            let isLightspeedConnected = res.find((x: any) => x.active === true && x.saas_name === 'lightspeed')

            if (isStripeConnected) {
                setStripeConnected(true)
            }
            if (isSquareConnected) {
                setSquareConnected(true)
            }
            if (isLightspeedConnected) {
                setLightspeedConnected(true)
            }
            setConnections(res)
        }
        getConnections()
    }, [popupClosed])

    const checkConnected = (service: string) => {
        if (service === 'stripe') {
            return (stripeConnnected ? <CheckCircleIcon color="success" /> : <AddIcon />)
        } else if (service === 'square') {
            return (squareConnected ? <CheckCircleIcon color="success" /> : <AddIcon />)
        } else if (service === 'lightspeed') {
            return (lightspeedConnected ? <CheckCircleIcon color="success" /> : <AddIcon />)
        }
    }

    const handleClick = (service: string) => {
        if (service === 'stripe' && !stripeConnnected) {
            launchPopup(service)
        } else if (service === 'square' && !squareConnected) {
            launchPopup(service)
        } else if (service === 'lightspeed' && !lightspeedConnected) {
            launchPopup(service)
        }
    }

    return (
        <Accordion
            expanded={expanded}
            onChange={() => setExpanded(!expanded)}
            sx={{
                '&:before': {
                    display: 'none',
                },
                borderRadius: 1,
                boxShadow: 'none',
                paddingTop: 1,
                background: '##EDEDF4',
                border: '1px solid #EDEDF4',
                mt: 1,
                mb: 1,
                paddingBottom: expanded ? 0 : 1
            }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                color="#FFF"
                sx={{
                    border: 'none',
                    boxShadow: 'none',
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ShoppingBagOutlinedIcon color="primary" />
                    <Typography fontWeight={500} ml={2}>Point of Sale</Typography>
                </Box>

            </AccordionSummary>
            <AccordionDetails
                sx={{
                    padding: '0',
                }}
            >
                <Box>
                    {services.map((service, index) => {
                        const Logo = service.logo;

                        return (
                            <Box
                                key={index}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    padding: '1rem 1rem',
                                    background: '#f2f2f8',
                                    borderRadius: 1,
                                    marginY: 1,
                                    marginX: 2,
                                    cursor: 'pointer',
                                    // height: 28
                                }}
                                component="div"
                                onClick={() => {
                                    handleClick(service.key)
                                }}
                            >
                                <Logo />
                                {checkConnected(service.key)}
                            </Box>
                        )
                    })}
                </Box>
            </AccordionDetails>
        </Accordion >
    )
}