import axios from 'axios';

export const getCompany = async () => {
    const response = await axios.get('/company/details')
    return response.data
}

export const getAccounts = async () => {
    const response = await axios.get('/company/connections')
    return response.data
}