import { validateYupSchema, yupToFormErrors } from 'formik';
import { useMemo, useCallback } from 'react';
import * as yup from 'yup';

export function getValidatorType(field) {
    if (field.text_formatter === 'percentage' || field.text_formatter === 'currency') {
        return yup.number().nullable();
    }
    if (field.field_type === 'dropzone') {
        return yup.array().nullable();
    }
    if (field.field_type === 'modal') {
        return yup.object().nullable();
    }
    return yup.string().nullable();
}

export function getValidator(field, overrideRequiredWith) {
    let validator = getValidatorType(field);
    if (field.text_formatter === 'email') {
        validator = validator.email('Must be a valid email');
    }
    if (field.text_formatter === 'phone') {
        validator = validator.length(10, 'Must be a valid phone number');
    }
    if (field.text_formatter === 'EIN') {
        validator = validator.length(9, 'Must be a valid EIN');
    }
    if (field.text_formatter === 'percentage') {
        validator = validator
            .min(0, 'Must be more than or equal to 0')
            .max(100, 'Must be less than or equal to 100');
    }
    if (field.text_formatter === 'SSN') {
        validator = validator.length(9, 'Must be a valid SSN');
    }
    if (field.field_type === 'dropzone') {
        validator = validator.min(1, 'Upload at least one file');
    }

    if ((overrideRequiredWith === undefined && field.required) || overrideRequiredWith) {
        validator = validator.required(
            field.field_type === 'dropzone' ? 'Upload at least one file' : 'Required field'
        );
    }

    return validator;
}

export function getInitialValue() {
    return null;
}

function parseFieldValue(field) {
    if (field.field_type === 'dropzone') {
        return (field.field_value ?? []).map(item => {
            const indexOfUnderscore = item.indexOf('_');
            const filename = item.substr(indexOfUnderscore === -1 ? 0 : indexOfUnderscore + 1);
            return { key: item, path: filename };
        });
    }
    return field.field_value;
}

export function useFormikParams(screen) {
    const [initialValues, validationSchema, requiredGroupSchema] = useMemo(() => {
        const values = {};
        const schema = {};
        const requiredGroupSchema = {};
        const requredGroupFields = new Set(screen?.requiredGroup?.fields ?? []);
        screen.fields.forEach(field => {
            const isInRequiredGroup = requredGroupFields.has(field.field_name);

            values[field.field_name] = parseFieldValue(field) ?? getInitialValue(field);

            if (field.field_type === 'modal' && field.children) {
                field.children.forEach(item => {
                    values[item.field_name] = parseFieldValue(item) ?? getInitialValue(item);

                    schema[item.field_name] = getValidatorType(item).when(
                        field.field_name + '_isFailed',
                        {
                            is: value => value,
                            then: getValidator(item, field.required),
                            otherwise: getValidatorType(item).nullable(),
                        }
                    );

                    if (isInRequiredGroup) {
                        requiredGroupSchema[item.field_name] = getValidatorType(item).when(
                            field.field_name + '_isFailed',
                            {
                                is: value => value,
                                then: getValidator(item),
                                otherwise: getValidatorType(item).nullable(),
                            }
                        );
                    }
                });
                // If any of the child values was filled before
                // then integration failed before, hence setting _isFailed to true
                if (
                    field.children
                        .map(item => item.field_value)
                        .some(item => (Array.isArray(item) ? item.length > 0 : item))
                ) {
                    values[field.field_name + '_isFailed'] = true;
                }
                schema[field.field_name] = getValidatorType(field).when(
                    field.field_name + '_isFailed',
                    {
                        is: value => !value,
                        then: getValidator(field),
                        otherwise: getValidatorType(field).nullable(),
                    }
                );
                if (isInRequiredGroup) {
                    requiredGroupSchema[field.field_name] = getValidatorType(field).when(
                        field.field_name + '_isFailed',
                        {
                            is: value => !value,
                            then: getValidator(field, true),
                            otherwise: getValidatorType(field).nullable(),
                        }
                    );
                }
            } else {
                schema[field.field_name] = getValidator(field);
                if (isInRequiredGroup) {
                    requiredGroupSchema[field.field_name] = getValidator(field, true);
                }
            }
        });

        return [values, yup.object(schema), yup.object(requiredGroupSchema)];
    }, [screen]);

    const validate = useCallback(
        values => {
            if (
                Object.values(values).some(
                    value =>
                        Array.isArray(value) &&
                        value.some(item => item.path && !item.key && !item.error)
                )
            ) {
                return {
                    form: 'File upload is still in progress',
                };
            }

            if (
                Object.values(values).some(
                    value => Array.isArray(value) && value.some(item => item.path && item.error)
                )
            ) {
                return {
                    form: "Some files couldn't upload. Please retry.",
                };
            }

            const requredGroupFieldNames = new Set(screen?.requiredGroup?.fields ?? []);
            const requiredGroupLimit = screen?.requiredGroup?.limit ?? 0;
            const requredGroups = screen.fields
                .filter(field => requredGroupFieldNames.has(field.field_name))
                .map(field => [
                    field.field_name,
                    ...(field.children ?? []).map(item => item.field_name),
                ]);
            const requredFieldLabels = screen.fields
                .filter(field => requredGroupFieldNames.has(field.field_name))
                .map(field => field.label);

            try {
                validateYupSchema(values, requiredGroupSchema, true);
            } catch (e) {
                const errors = yupToFormErrors(e);

                let validGroupCounter = 0;

                requredGroups.forEach(group => {
                    const isValid = group.every(item => !errors[item]);
                    if (isValid) {
                        validGroupCounter++;
                    }
                });

                return validGroupCounter >= requiredGroupLimit
                    ? {}
                    : {
                          form: `Provide at least ${requiredGroupLimit} value(-s) to ${requredFieldLabels.join(
                              ', '
                          )} fields`,
                      };
            }

            return {};
        },
        [screen, requiredGroupSchema]
    );

    return [initialValues, validate, validationSchema];
}
