import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Button, Chip, IconButton } from '@mui/material';
import CallMadeIcon from '@mui/icons-material/CallMade';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { DealStatus } from '@lendica/utils';

import { StaticTable } from './Table';
import { ToggleChip, TagColor, Tag } from '@lendica/components';
import {
    currencyFormatter,
    percentageFormatter,
    dateFormatter,
    formatProductName,
} from '@lendica/utils';
import { useDrawDown } from '../hooks/useDrawDown';

const dealStatusToColorMapping = {
    'Current': 'success',
    'Completed': 'info',
    'Late': 'error',
    'Grace Period': 'warning',
    'Early Completed': 'info',
    'Default': 'error',
};

export const columns = [
    {
        id: 'product',
        label: 'Product',
        CellContent: ({ value }) => {
            return (
                <Chip
                    label={formatProductName(value)}
                    size="small"
                    sx={{ color: '#2E3192', fontWeight: 500 }}
                />
            );
        },
        tableCellProps: {
            padding: 'none',
        },
    },
    {
        id: 'funded_date',
        label: 'Date',
        CellContent: ({ value }) => {
            return <>{dateFormatter(value, false)}</>;
        },
    },
    {
        id: 'amount',
        label: 'Amount',
        formatter: currencyFormatter,
        tableCellProps: {
            align: 'right',
            padding: 'none',
        },
    },
    {
        id: 'paid',
        label: 'Paid',
        formatter: val => percentageFormatter(val, 0),
        tableCellProps: {
            align: 'right',
        },
    },
    {
        id: 'deal_status',
        label: 'Status',
        CellContent: ({ value }) => {
            return (
                <Tag
                    label={value.includes('Early Completed') ? 'Completed' : value}
                    color={dealStatusToColorMapping[value]}
                />
            );
        },
        tableCellProps: {
            width: 95,
            align: 'center',
            padding: 'none',
        },
    },
];

export const MyDeals = ({ deals }) => {
    // const { deals } = useDrawDown()
    const navigate = useNavigate();

    const handleTableRowClick = row => {
        navigate(`deal/${row.id}`);
    };

    if (deals && deals.length !== 0) {
        deals.sort((a, b) => {
            return new Date(b.funded_date).valueOf() - new Date(a.funded_date).valueOf();
        });
    }

    return (
        <Box mt={3.5} pt={2} sx={{ minHeight: '100%' }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center">
                    <Typography mb={0.5} variant="h5">
                        My Withdrawals
                    </Typography>
                </Box>
            </Box>
            <Box mt={2}>
                {deals ? (
                    <StaticTable
                        columns={columns}
                        data={deals.map(
                            ({
                                id,
                                invoice_display_id,
                                funded_date,
                                product_name,
                                total_collected,
                                payback_amount,
                                deal_status,
                                gross_funded_amount,
                            }) => ({
                                id,
                                invoice_display_id,
                                funded_date,
                                product: product_name,
                                amount: gross_funded_amount,
                                paid: total_collected / payback_amount,
                                deal_status,
                            })
                        )}
                        handleTableRowClick={handleTableRowClick}
                    />
                ) : (
                    <Typography variant="body1">You don't have any deals yet</Typography>
                )}
            </Box>
        </Box>
    );
};
