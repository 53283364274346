import { DropzoneFile } from '@lendica/components';
import { ChevronLeft } from '@mui/icons-material';
import { Box, Step, StepLabel, Stepper, IconButton, Button } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FeatureLayout, HeaderToolbar } from '@lendica/components';
import { payLaterAPI } from '../payLaterAPI';
import { StepProgressContent } from './StepProgressContent';

export interface ProcessInvoiceProps {
    files: DropzoneFile[];
    onExit(): void;
}

export const UploadInvoice: FC<ProcessInvoiceProps> = ({ files, onExit }) => {
    const [activeStep, setActiveStep] = useState(0);
    const navigate = useNavigate();
    const isUploadInProgress = files.some(item => !item.key);
    const hasUploadError = files.some(item => item.error);

    useEffect(() => {
        if (!isUploadInProgress && !hasUploadError) {
            setActiveStep(1);
        }
    }, [isUploadInProgress, hasUploadError]);

    useEffect(() => {
        if (activeStep === 1) {
            (async () => {
                const billId = (await payLaterAPI.processBill(files[0].key!)).bill.id;
                navigate(`bill/${billId}/confirm`);
            })();
        }
    }, [activeStep]);

    return (
        <FeatureLayout>
            <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ px: 2.5 }}>
                    <HeaderToolbar
                        label="Upload Invoice"
                        leftAction={
                            hasUploadError ? (
                                <IconButton size="large" onClick={onExit}>
                                    <ChevronLeft fontSize="inherit" />
                                </IconButton>
                            ) : undefined
                        }
                    />
                </Box>
                <Box sx={{ px: 4, pt: 1, flexGrow: 1, overflowY: 'auto' }}>
                    <Stepper
                        activeStep={activeStep}
                        orientation="vertical"
                        sx={{
                            '& > .MuiStepConnector-root span.MuiStepConnector-line': {
                                minHeight: '8px !important',
                            },
                        }}
                    >
                        <Step>
                            <StepLabel>File upload</StepLabel>
                            {hasUploadError ? (
                                <StepProgressContent
                                    label="Files failed to upload."
                                    description="Please go back and try again."
                                    error
                                />
                            ) : (
                                <StepProgressContent
                                    label="Files are uploading..."
                                    description="Please wait for invoices to finish uploading. Do not leave the page."
                                />
                            )}
                        </Step>
                        <Step>
                            <StepLabel>Processing invoice</StepLabel>
                            <StepProgressContent
                                label="Uploaded and processing..."
                                description="It takes a few moments to process the invoice. You can continue to add invoices and come back in a few minutes to confirm the details."
                            />
                        </Step>
                        <Step>
                            <StepLabel>Confirm details</StepLabel>
                        </Step>
                    </Stepper>
                </Box>
                {activeStep === 1 && (
                    <Box sx={{ px: 4, py: 1.5 }}>
                        <Button variant="contained" fullWidth onClick={() => navigate('../..')}>
                            Ok
                        </Button>
                    </Box>
                )}
            </Box>
        </FeatureLayout>
    );
};
