import Typography from '@mui/material/Typography';
import { ListItem, Divider, List, Box } from '@mui/material';
import { useAccountDataContext } from '../AccountData';

export default function AccountCard() {
    const { data, isLoading } = useAccountDataContext();

    let company = data.company;

    let name = '';

    if (company?.first_name) {
        name += company?.first_name + ' ';
    } else if (company?.last_name) {
        name += company?.last_name;
    }

    return (
        <Box sx={{ borderRadius: 2, background: '#f8f8fb' }}>
            <List sx={{ bgcolor: 'f8f8fb' }}>
                <ListItem
                    disableGutters
                    sx={{
                        paddingX: 2,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="caption" fontSize={14} color="text.primary">
                        Name
                    </Typography>
                    <Typography variant="caption" fontSize={14} color="text.primary">
                        {name || ''}
                    </Typography>
                </ListItem>
                <Divider />
                <ListItem
                    disableGutters
                    sx={{
                        paddingX: 2,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="caption" fontSize={14} color="text.primary">
                        Company Name
                    </Typography>
                    <Typography variant="caption" fontSize={14} color="text.primary">
                        {company?.company_name || ''}
                    </Typography>
                </ListItem>
                <Divider />
                <ListItem
                    disableGutters
                    sx={{
                        paddingX: 2,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="caption" fontSize={14} color="text.primary">
                        Phone Number
                    </Typography>
                    <Typography variant="caption" fontSize={14} color="text.primary">
                        {company?.phone_number || ''}
                    </Typography>
                </ListItem>
                <Divider />
                <ListItem
                    disableGutters
                    sx={{
                        paddingX: 2,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="caption" fontSize={14} color="text.primary">
                        Email
                    </Typography>
                    <Typography variant="caption" fontSize={14} color="text.primary">
                        {company?.email || ''}
                    </Typography>
                </ListItem>
                <Divider />
                <ListItem
                    disableGutters
                    sx={{
                        paddingX: 2,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="caption" fontSize={14} color="text.primary">
                        Company Address
                    </Typography>
                    <Typography variant="caption" fontSize={14} color="text.primary">
                        {company?.company_address || ''}
                    </Typography>
                </ListItem>
            </List>
        </Box>
        // <Card sx={{ maxWidth: 345, bgcolor: 'background.card' }} >
        //     {/* <CardMedia
        //         component="img"
        //         height="140"
        //         image="/static/images/cards/contemplative-reptile.jpg"
        //         alt="green iguana"
        //     /> */}
        //     <CardContent sx={{ padding: 0 }}>

        //     </CardContent>
        //     {/* <CardActions>
        //         <Button size="small">Share</Button>
        //         <Button size="small">Learn More</Button>
        //     </CardActions> */}
        // </Card >
    );
}
