import axios from 'axios';
import { BillStatus, OfferNew as Offer } from '@lendica/select-payment-terms';
import { Approval, Company, Deal, Vendor, Bill, InvoiceStatus } from '@lendica/utils';

export const awsURL = 'https://micro-awsmanager.herokuapp.com/s3';
export const bucket_name = 'lendica-pod';

export { InvoiceStatus, Bill, Offer, Approval, Company, Deal };

export interface PaylaterSummary {
    advance_rate: number;
    contract_end_date: string;
    contract_start_date: string;
    funds_available: number;
    funds_in_use: number;
    id: string;
    max_days_outstanding: number;
    number_of_active_deals: number;
    origination_fee_percentage: number;
    product: string;
    qualified_amount: number;
    term_length_in_days: number;
    total_in_process_amount: number;
    total_payoff_amount: number;
}

export interface BillDetails {
    bill: Bill;
    vendor: Vendor | null;
    company: Company;
}

export type DeepPartial<T> = T extends object
    ? {
          [P in keyof T]?: DeepPartial<T[P]>;
      }
    : T;

export interface ProcessBillReponse extends BillDetails {}

export interface ConfirmBillDetailsResponse extends BillDetails {
    confirm_success: boolean;
}

export const payLaterAPI = {
    async getPaylaterApproval(): Promise<Approval | undefined> {
        const approvalsList = (await axios.get<Approval[]>('/company/approvals')).data;
        return approvalsList.find(
            approval => approval.active === true && approval.product_name === 'paylater'
        );
    },
    async processBill(objectKey: string): Promise<ProcessBillReponse> {
        return (
            await axios.post<ProcessBillReponse>('/paylater/bill/process', {
                object_name: objectKey,
                object_folder: 'lendica-pod',
            })
        ).data;
    },
    async getBillDetails(id: string): Promise<BillDetails> {
        return (await axios.get<BillDetails>(`/paylater/bill/confirm?bill_id=${id}`)).data;
    },
    async confirmBillDetails(data: DeepPartial<BillDetails>) {
        return (
            await axios.post<ConfirmBillDetailsResponse>(
                `/paylater/bill/confirm?bill_id=${data!.bill!.id}`,
                data
            )
        ).data;
    },
    async getBillsList(): Promise<Bill[]> {
        return (await axios.get<Bill[]>(`/company/bills`)).data;
    },
    async getBill(id: string): Promise<Bill> {
        return (await axios.get<Bill>(`/company/bills?id=${id}`)).data;
    },
    async archiveBill(id: string) {
        return (await axios.get<Bill>(`/company/bills/archive?id=${id}`)).data;
    },
    async getOffers(billId: string): Promise<Offer[]> {
        return (await axios.get<{ offers: Offer[] }>(`/paylater/bill/offers?bill_id=${billId}`))
            .data.offers;
    },
    async getPaylaterDealsList(): Promise<Deal[]> {
        const dealsList = (await axios.get<Deal[]>(`/company/deals`)).data;
        return dealsList.filter(deal => deal.product_name === 'paylater');
    },
    async getDeal(id: string): Promise<Deal> {
        return (await axios.get<Deal>(`/company/deals?id=${id}`)).data;
    },
    async updateDataConsent(data_consent = true) {
        return (await axios.post('/company/consent', { data_consent })).data;
    },
    async getApplication(): Promise<any> {
        return (await axios.get<any>(`/application`)).data;
    },
    async postApplication(data: any) {
        return (await axios.post('/application', data)).data;
    },
    async getPreApproval(): Promise<any> {
        return (await axios.get<any>(`/lens/report`)).data;
    },
    async initializeDeal(bill_id: string, offer_id: string) {
        return (await axios.post('/paylater/deal/init', { bill_id, offer_id })).data.deal.id;
    },
    async getVendorsList(): Promise<Vendor[]> {
        return (await axios.get('/company/vendors')).data;
    },
    async postVendor(data: any) {
        return (await axios.post('/company/vendors', data)).data;
    },
    async postVendorToBill(data: any, billId: string) {
        return (
            await axios.post('/company/vendors', {
                bill_id: billId,
                vendor: data,
            })
        ).data;
    },
    async getCompanyDetails() {
        return (await axios.get('/company/details')).data;
    },
    async getDataConnections() {
        return (await axios.get('/company/connections')).data;
    },
    async activatePaylater() {
        return (await axios.get('/paylater/activate')).data;
    },
    async getBillOffers(billId: string): Promise<Offer[]> {
        return (await axios.get(`/paylater/bill/offers?bill_id=${billId}`)).data;
    },
    // emitSuccess() {
    //     if (!window!.emitterica) return;
    //     window.emitterica.emit('success', {
    //         eventName: 'DEAL_ACTIVATED',
    //         displayMessage: 'PayLater deal activated.',
    //     });
    // },
    // emitExit(event: EventData) {
    //     if (!window!.emitterica) return;
    //     window.emitterica.emit('exit', event);
    // },
};
