import React from 'react';

export const usePaymentBody = ({ selectedWithdrawals, summary, live_withdrawals }) => {
    const totalSaved = () => {
        return selectedWithdrawals.reduce((prevVal, currItem) => {
            return prevVal + currItem.withdrawal_early_payoff_benefit;
        }, 0);
    };

    const availableCredit = () => {
        const { credit_available } = summary;
        let credit = selectedWithdrawals.reduce((prevVal, currItem) => {
            return prevVal + currItem.withdrawal_amount - currItem.withdrawal_principal_paid;
        }, 0);

        return credit_available + credit;
    };

    const balanceLeft = () => {
        const paid = live_withdrawals.reduce((prevVal, currItem) => {
            return prevVal + currItem.withdrawal_outstanding_payment;
        }, 0);
        return Math.abs(summary.total_unpaid - paid);
    };

    return {
        unpaidBalance: balanceLeft(),
        updatedAvailableCredit: availableCredit(),
        totalSaved: totalSaved(),
    };
};
