import { FC } from 'react';
import { Box } from '@mui/material';
import { SelectOption } from '@mui/base';
import { ToggleChip } from '@lendica/components';
import { SelectFilter } from '@lendica/paylater/src/SelectFilter';
import { AllFilters, Filter, FilterItem } from './AllFilters';

export enum FilterType {
    TOGGLE_CHIP = 'ToggleChip',
    SELECT_FILTER = 'SelectFilter',
}

export interface FilterBarProps {
    initialValue: Filter;
    filters: FilterItem[];
    onChange(value: Filter): void;
}

export const FilterBar: FC<FilterBarProps> = ({ filters, initialValue, onChange }) => {
    return (
        <Box sx={{ display: 'flex', gap: 0.5, mt: 1, overflow: 'auto' }}>
            <AllFilters initialValue={initialValue} filters={filters} onChange={onChange} />
            {filters.map(item => {
                if (item.type === FilterType.SELECT_FILTER) {
                    const value = (initialValue[item.key] || item.defaultValue) as string[];
                    return (
                        <SelectFilter
                            key={item.key}
                            label={item.label}
                            options={item.options as SelectOption<string>[]}
                            value={value}
                            onChange={value => {
                                onChange({ ...initialValue, [item.key]: value });
                            }}
                        />
                    );
                }

                return (
                    <ToggleChip
                        key={item.key}
                        label={item.label}
                        value={!!(initialValue[item.key] || item.defaultValue)}
                        onChange={value => {
                            onChange({ ...initialValue, [item.key]: value });
                        }}
                    />
                );
            })}
        </Box>
    );
};
