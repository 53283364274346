import clsx from 'clsx';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CloseIcon from '@mui/icons-material/Close';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { StepIndicatorPanel } from './StepIndicatorPanel';
import { Logo } from '@lendica/components';
import { useFormContext } from '../FormContext';
import { applicationCache } from '../applicationCache';

const useStyles = makeStyles(theme => ({
    helpButton: {
        color: theme.palette.action.active,
    },
    actionButton: {
        opacity: 0,
        transition: `opacity ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut} 0ms`,
    },
    actionButtonActive: { opacity: 1 },
    title: {
        fontWeight: theme.typography.fontWeightBold,
        whiteSpace: 'pre-line',
    },
    messaging: {
        backgroundColor: theme.palette.secondary.light,
        textAlign: 'left',
        whiteSpace: 'pre-line',
        borderRadius: 10,
    },
    sticky: {
        position: 'sticky',
        top: 0,
        backgroundColor: theme.palette.background.paper,
        zIndex: theme.zIndex.appBar,
    },
}));

export const Header = () => {
    const classes = useStyles();
    const params = useParams();
    const step = +params.step;
    const {
        data: {
            application: {
                total_steps,
                screen: [screen],
            },
            submitForm,
        },
        settings: { onExit },
        setApplication,
    } = useFormContext();
    const navigate = useNavigate();

    return (
        <>
            <Box pb={2} className={classes.sticky}>
                <Box>
                    <StepIndicatorPanel />
                </Box>
                <Grid container component={Box} mt={2} px={2} justifyContent="space-between">
                    <Grid item>
                        <Box
                            flexDirection="column"
                            justifyContent="center"
                            display="flex"
                            height="100%"
                        >
                            <Logo height={20} />
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box mr={-1.25}>
                            <Button
                                startIcon={<HelpOutlineIcon />}
                                className={classes.helpButton}
                                id="btn-appl-help"
                                onClick={() => {
                                    navigate('faq');
                                }}
                            >
                                Help
                            </Button>
                            <IconButton id="btn-appl-close" onClick={onExit} size="large">
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container component={Box} mt={2} px={2} justifyContent="space-between">
                    <Grid item>
                        <Box ml={-1.25}>
                            <IconButton
                                onClick={() => {
                                    navigate(`../../${step - 1}`);
                                    setApplication(applicationCache[step - 2]);
                                }}
                                id={`btn-appl-previous-${step}`}
                                className={clsx(
                                    classes.actionButton,
                                    step !== 1 && classes.actionButtonActive
                                )}
                                disabled={step === 1}
                                size="large"
                            >
                                <ChevronLeftIcon />
                            </IconButton>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box
                            flexDirection="column"
                            justifyContent="center"
                            display="flex"
                            height="100%"
                        >
                            <Button
                                color="secondary"
                                endIcon={<ChevronRightIcon />}
                                id={`btn-appl-next-${step}`}
                                onClick={() => {
                                    submitForm?.();
                                }}
                            >
                                {step < total_steps ? 'Next' : 'Submit'}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box px={2}>
                <Typography variant="subtitle1" color="text.primary" className={classes.title}>
                    {screen.title}
                </Typography>
            </Box>
            <Box mt={2} px={2}>
                <Box p={2} className={classes.messaging}>
                    <Typography variant="h5" color="text.primary" component="h5">
                        {screen.messaging}
                    </Typography>
                </Box>
            </Box>
        </>
    );
};
