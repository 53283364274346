import { FC } from 'react';
import { Box, Button, IconButton, Paper, Popover, Typography } from '@mui/material';
import { Tune as TuneIcon } from '@mui/icons-material';
import { SelectOption } from '@mui/base';
import { ToggleChip } from '@lendica/components';
import { FilterType } from './FilterBar';
import { useAllFilters } from '../hooks/useAllFilters';

export interface FilterItem {
    key: string;
    type: FilterType;
    label: string;
    defaultValue: string[] | boolean;
    sectionLabel: string;
    options?: SelectOption<string>[] | any;
}

export interface Filter {
    [key: string]: string[] | boolean;
}

export interface AllFiltersProps {
    initialValue: Filter;
    filters: FilterItem[];
    onChange(value: Filter): void;
}

export const emptyFilter: Filter = {};

const FilterSection: FC<{ label: string }> = ({ label, children }) => {
    return (
        <>
            <Box sx={{ pt: 3 }}>
                <Typography variant="subtitle2" sx={{ fontWeight: 'medium' }}>
                    {label}
                </Typography>
            </Box>
            <Box sx={{ pt: 2, display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>{children}</Box>
        </>
    );
};

export const AllFilters: FC<AllFiltersProps> = ({ initialValue, onChange, filters }) => {
    const {
        filter,
        setFilter,
        anchorEl,
        setAnchorEl,
        handleClose,
        handleSelectFilter,
        handleToggleChip,
    } = useAllFilters({ initialValue });

    return (
        <>
            <IconButton
                size="small"
                sx={{
                    border: theme => `1px solid ${theme.palette.grey[400]}`,
                    fontSize: 20,
                }}
                onClick={e => {
                    setFilter(initialValue);
                    setAnchorEl(e.currentTarget);
                }}
            >
                <TuneIcon fontSize="inherit" />
            </IconButton>
            <Popover
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Paper sx={{ px: 3, py: 2, width: 350 }}>
                    <Box>
                        <Typography variant="h6">All Filters</Typography>
                    </Box>
                    {filters.map(filterItem => (
                        <FilterSection key={filterItem.key} label={filterItem.sectionLabel}>
                            {filterItem.type === FilterType.SELECT_FILTER ? (
                                filterItem.options?.map((item: any) => {
                                    const values = filter[filterItem.key] as string[];
                                    return (
                                        <ToggleChip
                                            key={item.value}
                                            label={item.label as string}
                                            value={values?.includes(item.value)}
                                            onChange={handleSelectFilter(filterItem, item)}
                                        />
                                    );
                                })
                            ) : (
                                <ToggleChip
                                    key={filterItem.key}
                                    label={filterItem.label}
                                    value={!!(filter[filterItem.key] || filterItem.defaultValue)}
                                    onChange={handleToggleChip(filterItem)}
                                />
                            )}
                        </FilterSection>
                    ))}
                    <Box sx={{ pt: 4, display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                        <Button
                            onClick={() => {
                                onChange(emptyFilter);
                                handleClose();
                            }}
                        >
                            Clear
                        </Button>
                        <Button
                            onClick={() => {
                                onChange(filter);
                                handleClose();
                            }}
                        >
                            Apply
                        </Button>
                    </Box>
                </Paper>
            </Popover>
        </>
    );
};
