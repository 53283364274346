import { Deal, currencyFormatter, dateFormatter, percentageFormatter, formatProductName } from '@lendica/utils';

export const mapDealToSummary = (deal: Deal | undefined) => {
    return [
        {
            label: 'Deal ID',
            value: deal?.id ? deal.id.substring(0, 8) : '',
        },
        {
            label: 'Invoice No.',
            value: deal?.invoice_number ? deal.invoice_number : '',
        },
        {
            label: 'Product',
            value: deal?.product_name ? formatProductName(deal.product_name) : ''
        },
        {
            label: 'Funded Date',
            value: dateFormatter(deal?.funded_date ?? ''),
        },
        {
            label: 'Amount',
            value: currencyFormatter(deal?.gross_funded_amount ?? 0),
        },
        {
            label: 'Status',
            value: deal?.deal_status ?? ''
        },
        {
            label: 'Vendor Name',
            value: deal?.vendor_name ?? '',
        },
        {
            label: 'Origination Fee Amount',
            value: currencyFormatter(deal?.origination_fee_amount ?? 0),
        },
        {
            label: 'Advance Amount',
            value: currencyFormatter(deal?.advance_amount ?? 0),
        },
        {
            label: 'Payment Frequency',
            value: deal?.payment_frequency ?? '',
        },
        {
            label: 'Number of Payments',
            value: deal?.number_of_payments ?? '',
        },
        {
            label: 'Expected Rate',
            value: percentageFormatter(deal?.expected_rate ?? 0),
        },
        {
            label: 'Periodic Payment Amount',
            value: currencyFormatter(deal?.periodic_collection_from_company ?? 0),
        },
        {
            label: 'First Payment Date',
            value: dateFormatter(deal?.first_payment_date ?? ''),
        },
        {
            label: 'Total Payback Amount',
            value: currencyFormatter(deal?.payback_amount ?? 0),
        },
        {
            label: 'Total Collected',
            value: currencyFormatter(deal?.total_collected ?? 0),
        },
        {
            label: 'Total Remaining',
            value: currencyFormatter(deal?.total_remaining ?? 0),
        },
    ];
};