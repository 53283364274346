import { ReactNode, FC, createContext, useContext, useState, useEffect } from 'react';
import { accessToken, refreshToken } from '@lendica/auth';
import { InitResponse, walletAPI } from './walletAPI';

interface WalletContextInterface {
    isLoading: boolean;
    data: InitResponse;
    getSummary?: () => Promise<void>;
}

interface WalletDataProps {
    children: ReactNode;
}

const defaultState = {
    isLoading: false,
    data: {},
};

const WalletDataContext = createContext<WalletContextInterface>(defaultState);

export const useWalletDataContext = () => {
    return useContext(WalletDataContext);
};

export const WalletData: FC<WalletDataProps> = ({ children }) => {
    const [state, setState] = useState(defaultState);

    useEffect(() => {
        (async () => {
            setState(currentState => ({ ...currentState, isLoading: true }));
            try {
                const data = await walletAPI.init();

                // if (data.company && data.company?.status === 0) {
                //     throw new Error("not approved");
                // }
                setState(currentState => ({ ...currentState, data }));
            } catch (error: any) {
                // if (error?.message === "not approved") {
                //     refreshToken.remove();
                //     accessToken.remove();

                //     window.location.replace('https://apply.app.golendica.com');
                // }
                setState(currentState => ({ ...currentState, data: {} }));
            } finally {
                setState(currentState => ({ ...currentState, isLoading: false }));
            }
        })();
    }, []);

    if (process.env.NODE_ENV === 'development') {
        useEffect(() => {
            setTimeout(() => {
                localStorage.setItem('walletState', JSON.stringify(state));
            }, 0);
        }, [state]);
    }

    return (
        <WalletDataContext.Provider
            value={{
                ...state,
            }}
        >
            {children}
        </WalletDataContext.Provider>
    );
};
