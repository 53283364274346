import { Box } from '@mui/material';
import { FC } from 'react';
import { matchHostname } from '@lendica/utils';

import { Logo, ErrorMessage, FeatureLayout, StatusPage } from '@lendica/components';

export const NoApproval: FC = () => {
    return (
        <FeatureLayout fullHeight>
            <Box sx={{ px: 4, height: '100%' }}>
                <StatusPage
                    title="Application not approved"
                    subtitle="Seems like you haven't completed your application"
                    duration={10}
                    action={() =>
                        window.location.replace(`https://apply.${matchHostname()}/application`)
                    }
                    actionLabel="Go to Application"
                />
            </Box>
        </FeatureLayout>
    );
};
