import { CompletedPayment } from './../sharedTypes';
import { useState, useEffect } from 'react';
import { FilterItem } from '../components/AllFilters';
import { FilterType } from '../components/FilterBar';

// TODO: in this hook should be done all data transformation such as, filtering, sorting etc
// *key and *type properties in FilterItem interface should/can be the properties which can help do the proper filtering, sorting etc.
export const useHistory = ({ payments }: { payments: CompletedPayment[] }) => {
    const [filtersList, setFiltersList] = useState<FilterItem[]>([
        {
            key: 'transactionCategory',
            type: FilterType.SELECT_FILTER,
            label: 'Category',
            defaultValue: [],
            sectionLabel: 'Filter by transaction category',
            options: [
                {
                    value: 'Payment',
                    label: 'Payment',
                },
                {
                    value: 'Funding',
                    label: 'Funding',
                },
            ]
        },
        {
            key: 'status',
            type: FilterType.SELECT_FILTER,
            label: 'Status',
            defaultValue: [],
            sectionLabel: 'Filter by transaction status',
            options: [
                {
                    value: 'In-Process',
                    label: 'In-Process',
                },
                {
                    value: 'Cleared',
                    label: 'Cleared',
                }
            ]
        },
        {
            key: 'earliestFirst',
            type: FilterType.TOGGLE_CHIP,
            label: 'Earliest first',
            defaultValue: false,
            sectionLabel: 'Sort by date',
        }
    ]);

    const getDealIds = () => {
        let deals: { [key: string]: any } = {}
        payments.forEach((payment: CompletedPayment) => {
            let dealId = payment.deal.substring(0, 8)
            if (deals[dealId] == undefined) {
                deals[dealId] = {
                    label: dealId,
                    value: payment.deal
                }
            }
        })
        return Object.values(deals)
    }

    useEffect(() => {
        let dealIdFilter: FilterItem = {
            key: 'dealId',
            type: FilterType.SELECT_FILTER,
            label: 'Deal ID',
            defaultValue: [],
            sectionLabel: 'Filter by deal ID',
            options: getDealIds()
        }
        setFiltersList(prev => [...prev, dealIdFilter])
    }, [])

    return {
        filtersList,
    }
}
