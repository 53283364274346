import { FC } from 'react';
import { Chip } from '@mui/material'

interface PaymentBadgeProps {
    descriptionCode: string
}

const PaymentBadge: FC<PaymentBadgeProps> = ({ descriptionCode }) => {
    let label;

    if (descriptionCode === "002" || descriptionCode === "005") {
        label = 'Periodic'
    } else if (descriptionCode === "111") {
        label = 'Origination Fee'
    }

    return (
        <Chip
            sx={{
                bgcolor: 'rgba(46, 49, 146, 0.08)',
                color: 'primary.main'
            }}
            size="small"
            label={label}
        />
    )
}
export default PaymentBadge