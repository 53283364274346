import { FC, ReactNode } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import { currencyFormatter } from '@lendica/utils';

interface ProgressCardProps {
    product: string | ReactNode;
    funds_available: number;
    total_payoff_amount: number;
    qualified_amount: number;
}

const ProgressCard: FC<ProgressCardProps> = ({
    product,
    funds_available,
    total_payoff_amount,
    qualified_amount,
}) => {
    return (
        <Box mt={2.5}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                {typeof product === 'string' ? (
                    <Typography variant="subtitle1" color="primary">
                        {product}
                    </Typography>
                ) : (
                    product
                )}
            </Box>

            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                sx={{
                    mt: 1,
                    p: 2,
                    bgcolor: '#F2F2F8',
                    minHeight: '40px',
                    borderRadius: 2,
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        pt: 0.5,
                        // pr: 2
                    }}
                >
                    <LinearProgress
                        color="secondary"
                        variant="determinate"
                        value={
                            funds_available === 0
                                ? 100
                                : (total_payoff_amount / qualified_amount) * 100
                        }
                    />
                    <Box
                        pt={1}
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignContent="flex-end"
                    >
                        <Typography variant="caption" color="text.primary" fontWeight={500}>
                            {`Balance ${currencyFormatter(total_payoff_amount)}`}
                        </Typography>

                        <Typography variant="caption" color="text.primary" fontWeight={500}>
                            {`Credit Available ${currencyFormatter(funds_available)}`}
                        </Typography>
                    </Box>
                </Box>
                <Box display="flex" alignItems="center" justifyContent="center">
                    {/* <IconButton
                        size="medium"
                        sx={{ width: '40px', height: '40px' }}
                        onClick={() => navigate('/paylater')}
                    >
                        <ArrowForwardIosIcon />
                    </IconButton> */}
                </Box>
            </Box>
        </Box>
    );
};

export default ProgressCard;
