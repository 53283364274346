import axios from 'axios';
import { Approval, Deal, Company } from '@lendica/utils';
export interface InitResponse {
    company?: Company;
    deals?: Deal[];
    approvals?: Approval[];
}
export interface AccountSummary {
    approvals?: Approval[];
    deals?: Deal[][];
}
export interface AccountSummaryResponse {
    company?: {
        id?: string;
        name?: string;
        first_name?: string;
        last_name?: string;
        email?: string;
        activated_paylater?: boolean;
        activated_drawdown?: boolean;
        activated_fundnow?: boolean;
    };
    account_summary?: AccountSummary;
}

export const walletAPI = {
    async init() {
        let company = (await axios.get('/company/details')).data
        let deals = (await axios.get('/company/deals')).data
        let approvals = (await axios.get('/company/approvals')).data
        return {
            company: company,
            deals: deals,
            approvals: approvals
        }
    },
    async getCompanySummary() {
        return (await axios.get('/company/summary')).data;
    },
    async getDealDetails(dealId: string) {
        return (await axios.get('/company/deals', { params: { id: dealId } })).data
    }
};
