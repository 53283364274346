import { FC, ReactNode } from 'react';
import { Route, Routes } from 'react-router-dom';
import { DataConnectionView } from './views/DataConnectionView';

export interface DataConnectionProps {
    placeholder?: ReactNode;
    env: string;
}

export const DataConnection: FC<DataConnectionProps> = ({ placeholder, env }) => {
    return (
        <Routes>
            <Route path="*" element={<DataConnectionView env={env} placeholder={placeholder} />} />
        </Routes>
    );
};