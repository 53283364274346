import { Box, Typography } from '@mui/material';
import { PlaidLogo } from '@lendica/components';
import AddIcon from '@mui/icons-material/Add';

export const AddPlaid = ({ handleClick }: { handleClick: () => void }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '0.5rem 1rem',
                background: '#FFF',
                borderRadius: 1,
                marginY: 1,
                cursor: 'pointer',
                border: '1px dashed #8C8CA1',
                boxSizing: 'border-box',
            }}
            component="div"
            onClick={handleClick}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    mr: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <AddIcon color="primary" />
                <Typography variant="h5" fontWeight={500} fontSize={16} color="primary">
                    ADD NEW VIA
                </Typography>
            </Box>
            <PlaidLogo />
        </Box>
    );
};
