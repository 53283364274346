import { currencyFormatter, dateFormatter, formatDateShort } from '@lendica/utils';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { format, addDays, parseISO } from 'date-fns';

export const PaymentDetails = ({ open = false, offer, bill, darkMode = false }) => {
    const getTotalFees = (interest, originationFee) => {
        return interest + originationFee;
    };

    const getPaymentOnDueDate = (totalPrice, advanceAmount, originationFee) => {
        return totalPrice - advanceAmount + originationFee;
    };

    const getTotalPayment = (totalPeriodicPayments, totalPrice, advanceAmount, origination_fee) => {
        return totalPeriodicPayments + totalPrice - advanceAmount + origination_fee;
    };

    const parseTimeline = dueDate => {
        const parsedDueDate = dueDate ? parseISO(dueDate) : new Date();
        let firstPaymentDate = format(
            addDays(parsedDueDate, offer.first_payment_delay),
            'MMMM d, yyyy'
        );
        let lastPaymentDate = format(
            addDays(parsedDueDate, offer.first_payment_delay * offer.number_of_payments),
            'MMMM d, yyyy'
        );
        return [firstPaymentDate, lastPaymentDate];
    };

    const mapPaymentFrequency = frequency => {
        if (frequency === 'W') {
            return 'week';
        } else if (frequency === 'M') {
            return 'month';
        } else {
            return 'day';
        }
    };

    return (
        !!offer &&
        !!bill && (
            <Accordion
                sx={{
                    'boxShadow': 0,
                    'borderRadius': 1,
                    'border': 'none',
                    'background': darkMode ? '#282761' : '#F4F4F6',
                    'width': '100%',
                    'boxSizing': 'border-box',
                    '&:before': {
                        display: 'none',
                    },
                    'padding': 1,
                }}
                disableGutters={true}
                defaultExpanded={open}
            >
                <AccordionSummary
                    expandIcon={
                        <ArrowDropDown color={darkMode ? 'text.disabled' : 'text.secondary'} />
                    }
                >
                    <Typography
                        fontSize={13}
                        fontWeight={500}
                        color={darkMode ? '#FCFCFD' : 'info.main'}
                    >
                        Payment Schedule
                    </Typography>
                </AccordionSummary>

                <AccordionDetails>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            mb: 2,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Box mb={1}>
                                <Typography
                                    color={darkMode ? 'text.disabled' : 'text.secondary'}
                                    variant="caption"
                                    fontSize={12}
                                    mb={1}
                                >
                                    Payment on invoice due date
                                </Typography>
                                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                    <Typography
                                        color={darkMode ? '#FCFCFD' : 'text.primary'}
                                        variant="h4"
                                        fontWeight={600}
                                        fontSize={16}
                                    >
                                        {currencyFormatter(
                                            getPaymentOnDueDate(
                                                offer.total_price,
                                                offer.advance_amount,
                                                offer.origination_fee
                                            )
                                        )}
                                    </Typography>
                                    {bill.due_date && (
                                        <Typography
                                            color={darkMode ? '#FCFCFD' : 'text.primary'}
                                            variant="body1"
                                            fontWeight={400}
                                            fontSize={14}
                                            ml={1}
                                        >
                                            {`on ${dateFormatter(bill.due_date)}`}
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            borderBottom: '1px solid rgba(74, 74, 104, 0.5)',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Box mb={2}>
                                <Typography
                                    color={darkMode ? 'text.disabled' : 'text.secondary'}
                                    variant="caption"
                                    fontSize={12}
                                    mb={1}
                                >
                                    Installments
                                </Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography
                                        color={darkMode ? '#FCFCFD' : 'text.primary'}
                                        fontWeight={600}
                                        fontSize={16}
                                    >
                                        {currencyFormatter(offer.periodic_payment)}
                                    </Typography>
                                    {offer.number_of_payments === 1 ? (
                                        <Typography
                                            color={darkMode ? '#FCFCFD' : 'text.primary'}
                                            variant="body1"
                                            fontWeight={400}
                                            fontSize={14}
                                            ml={1}
                                        >
                                            {`on ${firstPaymentDate}`}
                                        </Typography>
                                    ) : (
                                        <Typography
                                            color={darkMode ? '#FCFCFD' : 'text.primary'}
                                            variant="body1"
                                            fontWeight={400}
                                            fontSize={14}
                                            ml={1}
                                        >
                                            {`every ${mapPaymentFrequency(
                                                offer.payment_frequency
                                            )}, for ${
                                                offer.number_of_payments
                                            } ${mapPaymentFrequency(offer.payment_frequency)}s`}
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                        {offer.number_of_payments !== 1 && bill.due_date && (
                            <Box sx={{ alignSelf: 'flex-end' }}>
                                <PaymentTimeline
                                    offer={offer}
                                    timeline={parseTimeline(bill.due_date)}
                                    darkMode={darkMode}
                                />
                            </Box>
                        )}
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            mt: 2,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Box>
                                <Typography
                                    color={darkMode ? 'text.disabled' : 'text.secondary'}
                                    variant="caption"
                                    fontSize={12}
                                    mb={0.5}
                                >
                                    Total fees
                                </Typography>
                                <Typography
                                    color={darkMode ? '#FCFCFD' : 'text.primary'}
                                    variant="h4"
                                    fontSize={16}
                                >{`${currencyFormatter(
                                    getTotalFees(offer.interest, offer.origination_fee)
                                )}*`}</Typography>
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Box>
                                <Typography
                                    color={darkMode ? 'text.disabled' : 'text.secondary'}
                                    variant="caption"
                                    fontSize={12}
                                    mb={0.5}
                                >
                                    Total payments
                                </Typography>
                                <Typography
                                    color={darkMode ? '#FCFCFD' : 'text.primary'}
                                    variant="h4"
                                    fontSize={16}
                                    textAlign="right"
                                >
                                    {currencyFormatter(
                                        getTotalPayment(
                                            offer.total_payments,
                                            offer.total_price,
                                            offer.advance_amount,
                                            offer.origination_fee
                                        )
                                    )}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>

                    <Typography
                        mt={2}
                        color={darkMode ? 'text.disabled' : 'text.secondary'}
                        variant="caption"
                        fontWeight={400}
                        fontSize={12}
                        textAlign="left"
                    >{`*${currencyFormatter(
                        offer.origination_fee
                    )} processing fee + ${currencyFormatter(offer.interest)} interest`}</Typography>
                </AccordionDetails>
            </Accordion>
        )
    );
};

const PaymentTimeline = ({ offer, timeline, darkMode, totalPaymentAmount }) => {
    return (
        <Timeline sx={{ margin: 0, p: 0 }}>
            <TimelineItem key={'first-payment'}>
                <TimelineSeparator>
                    <TimelineDot sx={{ fill: darkMode ? 'text.disabled' : 'text.secondary' }} />
                    <TimelineConnector
                        sx={{ fill: darkMode ? 'text.disabled' : 'text.secondary' }}
                    />
                </TimelineSeparator>
                <TimelineContent>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            width: 250,
                        }}
                    >
                        <Typography
                            variant="body1"
                            color={darkMode ? 'text.disabled' : 'text.secondary'}
                            fontSize={16}
                            fontWeight={400}
                        >
                            {timeline[0]}
                        </Typography>
                        <Typography
                            color={darkMode ? 'text.disabled' : 'text.secondary'}
                            variant="caption"
                            fontSize={16}
                            fontWeight
                        >
                            {currencyFormatter(offer.periodic_payment)}
                        </Typography>
                    </Box>
                    <Typography
                        color={darkMode ? 'text.disabled' : 'text.secondary'}
                        variant="body1"
                        fontSize={12}
                    >
                        First installment
                    </Typography>
                </TimelineContent>
            </TimelineItem>

            {offer.number_of_payments > 2 && (
                <TimelineItem key={'placeholder'}>
                    <TimelineSeparator>
                        <TimelineDot sx={{ fill: darkMode ? 'text.disabled' : 'text.secondary' }} />
                        <TimelineConnector
                            sx={{ fill: darkMode ? 'text.disabled' : 'text.secondary' }}
                        />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                width: 250,
                            }}
                        >
                            <Typography
                                color={darkMode ? 'text.disabled' : 'text.secondary'}
                                variant="caption"
                                fontSize={16}
                            >
                                ...
                            </Typography>
                        </Box>
                    </TimelineContent>
                </TimelineItem>
            )}

            <TimelineItem key={'last-payment'}>
                <TimelineSeparator>
                    <TimelineDot sx={{ fill: darkMode ? 'text.disabled' : 'text.secondary' }} />
                </TimelineSeparator>
                <TimelineContent>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            width: 250,
                        }}
                    >
                        <Typography
                            variant="body1"
                            color={darkMode ? 'text.disabled' : 'text.secondary'}
                            fontSize={16}
                            fontWeight={400}
                        >
                            {timeline[1]}
                        </Typography>
                        <Typography
                            color={darkMode ? 'text.disabled' : 'text.secondary'}
                            variant="caption"
                            fontSize={16}
                        >
                            {currencyFormatter(offer.periodic_payment)}
                        </Typography>
                    </Box>
                    <Typography
                        color={darkMode ? 'text.disabled' : 'text.secondary'}
                        variant="body1"
                        fontSize={12}
                    >
                        Last installment
                    </Typography>
                </TimelineContent>
            </TimelineItem>
        </Timeline>
    );
};
