import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { percentageFormatter, currencyFormatter } from '@lendica/utils';

const useTermDetailsStyles = makeStyles(theme => ({
    boldText: {
        fontWeight: theme.typography.fontWeightBold,
    },
    rightAligned: {
        textAlign: 'right',
    },
}));

export const TermDetails = ({ title, value, rightAligned = false }) => {
    const classes = useTermDetailsStyles();
    return (
        <Box display="flex" justifyContent="space-between" flexDirection="column">
            <Typography variant="caption" color="text.secondary">
                {title}
            </Typography>
            <Typography
                variant="caption"
                className={clsx(classes.boldText, rightAligned && classes.rightAligned)}
                color="text.secondary"
            >
                {value}
            </Typography>
        </Box>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        'backgroundColor': theme.palette.background.card,
        'border': `1px solid ${theme.palette.background.card}`,
        'borderRadius': 2,
        'cursor': 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.background.cardLight,
        },
    },
    checked: {
        border: `1px solid ${theme.palette.secondary.main}`,
    },
    termLength: {
        textTransform: 'uppercase',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        borderRadius: 4,
        width: 75,
        boxShadow: theme.shadows[2],
    },
    payment: {
        fontWeight: theme.typography.fontWeightBold,
        fontSize: theme.typography.h4.fontSize,
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.typography.h6.fontSize,
        },
    },
    semiboldText: {
        fontWeight: theme.typography.fontWeightSemibold,
    },
}));

export const PaymentOffer = ({ checked, onClick, className, offer }) => {
    const classes = useStyles();

    return (
        <Box
            px={3}
            py={2}
            className={clsx(classes.root, checked && classes.checked, className)}
            onClick={onClick}
        >
            <Box display="flex" justifyContent="space-between">
                <Box display="flex" alignItems="center">
                    <Typography color="primary" className={classes.payment}>
                        {currencyFormatter(offer.weekly_payment)}
                    </Typography>
                    <Box pl={0.5}>
                        <Typography
                            variant="caption"
                            color="primary"
                            className={classes.semiboldText}
                        >
                            {' '}
                            /week
                        </Typography>
                    </Box>
                </Box>
                <Box display="flex" alignItems="center">
                    <Box
                        className={classes.termLength}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Typography variant="caption" color="inherit">
                            {offer.number_of_payments} weeks
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box mt={2} display="flex" justifyContent="space-between">
                <TermDetails title="Rate(%)" value={percentageFormatter(offer.rate)} />
                <TermDetails title="Rate($)" value={currencyFormatter(offer.interest)} />
                <TermDetails title="Weeks" value={offer.number_of_payments} rightAligned />
                <TermDetails
                    title="Total"
                    value={currencyFormatter(
                        offer.total_price -
                        offer.advance_amount +
                        offer.origination_fee +
                        offer.total_payments
                    )}
                    rightAligned
                />
            </Box>
        </Box>
    );
};
