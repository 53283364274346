import { useState } from 'react';

import { Company } from '@lendica/utils';
import { Box, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckIcon from '@mui/icons-material/Check';
import { DataConnection } from '../accountAPI';
import { BankAccountCard } from '../components/BankAccountCard';
import { AddPlaid } from '../components/AddPlaid';
import { usePlaid } from '../hooks/usePlaid';
import { BankService } from '@lendica/components';

export const BankAccount = ({
    company_id,
    env,
    dataConnections,
    company,
}: {
    env: string;
    company_id: string;
    dataConnections: DataConnection[];
    company: Company;
}) => {
    const [sendStatus, setSendStatus] = useState('idle');
    const { openPlaid, ready } = usePlaid({ company_id, env });

    const handlePlaidClick = () => {
        if (ready) {
            openPlaid();
        }
    };

    let cards: any = [];
    let plaidConnections = dataConnections.filter((d: DataConnection) => d.saas_name === 'plaid');

    let index = 0;
    plaidConnections.forEach(connection => {
        let details = connection.display_data;
        details.forEach((d: any) => {
            cards.push(
                <BankAccountCard
                    key={`bank-card-${index}`}
                    handlePlaidClick={handlePlaidClick}
                    primary={false}
                    account_number={d.account_number}
                    institution={d.institution}
                    name={d.name}
                    active={connection.active}
                />
            );
            index++;
        });
    });

    return (
        <Box>
            <Box>
                <Box
                    sx={{
                        mt: 4,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        // mb: 2,
                    }}
                >
                    <Typography variant="h4" fontSize={20}>
                        Bank Account
                    </Typography>
                </Box>
            </Box>
            <Typography variant="subtitle1" color="textSecondary" mt={2}>
                Default bank
            </Typography>
            {company.bank_name && company.bank_name !== '' ? (
                <BankAccountCard
                    key={`bank-card-default`}
                    handlePlaidClick={handlePlaidClick}
                    primary={true}
                    account_number={''}
                    institution={company.bank_name || ''}
                    name={''}
                    active={true}
                />
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '1rem 1rem',
                        background: '#f8f8fb',
                        borderRadius: 2,
                        marginY: 0.5,
                    }}
                >
                    <Typography variant="subtitle2" color="textSecondary">
                        No default bank selected.
                    </Typography>
                    <LoadingButton
                        loading={sendStatus === 'submitting'}
                        endIcon={sendStatus === 'success' ? <CheckIcon /> : null}
                        onClick={async () => {
                            try {
                                setSendStatus('submitting');
                                await BankService.contactSupport(
                                    `🏦 ${company.company_name} needs help setting their default bank on account page.`,
                                    `Company: ${company.company_name} \n Company ID: ${company.id} \n First Name: ${company.first_name} \n Last Name: ${company.last_name} \n Email: ${company.email} \n Phone: ${company.phone_number}`
                                );
                                setTimeout(() => {
                                    setSendStatus('success');
                                }, 600);
                            } catch (e) {
                                console.log(e);
                            }
                        }}
                    >
                        {sendStatus === 'success' ? `Request sent` : `Notify us`}
                    </LoadingButton>
                </Box>
            )}
            <Typography variant="subtitle1" color="textSecondary" mt={2}>
                Connected accounts
            </Typography>
            <Box
                sx={{
                    overflowY: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    height: '100%',
                    // px: 4,
                }}
            >
                {cards}
                <AddPlaid handleClick={handlePlaidClick} />
            </Box>
        </Box>
    );
};
