import { SidePanelLayout } from '@lendica/components';
import { FC } from 'react';

export const VideoViewer: FC<{ videoUrl: string }> = ({ videoUrl }) => {
    return (
        <SidePanelLayout redirectRoute="/paylater" loading={!videoUrl}>
            <embed src={videoUrl} style={{ flexGrow: 1 }} />
        </SidePanelLayout>
    );
};
