import { useNavigate, useParams } from 'react-router-dom';

import { Form } from '@lendica/loan-form';
import { Body } from '@lendica/components';

import { DrawDownContainer } from '../../DrawDownContainer';
import { useApi } from '../../api';

const applicationStatuses = {
    inProgress: 0,
    submitted: 1,
    approved: 2,
    declined: 3,
};

export const Application = () => {
    const { applicationId } = useParams();
    const {
        globalContext: { environment, setError },
        activateOffer,
        updateDrawDown,
    } = DrawDownContainer.useContainer();
    const api = useApi(environment);

    const navigate = useNavigate();

    const getOffer = async () => {
        try {
            await activateOffer();
            updateDrawDown();
            navigate(`..`);
        } catch (error) {
            setError(`${error}`);
        }
    };

    const handleFormComplete = ({ application_status }) => {
        if (application_status === applicationStatuses.approved) {
            getOffer();
            return;
        }
        navigate('../submitted');
    };

    return (
        <>
            <Body>
                <Form
                    applicationId={applicationId}
                    baseURL={api.baseUrl}
                    onExit={() => {
                        navigate('..');
                    }}
                    onComplete={handleFormComplete}
                />
            </Body>
        </>
    );
};
