import { Card, CardContent, CardHeader, IconButton, Typography, Box, Chip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useCallback } from 'react';
import { useFormikContext } from 'formik';

import { Fields } from './Fields';
import { uploadManager } from './uploadManager';

const useStyles = makeStyles(theme => ({
    'root': {
        backgroundColor: theme.palette.secondary.light,
        minHeight: 120,
        cursor: 'pointer',
        border: hasError =>
            `1px solid ${hasError ? theme.palette.error.main : theme.palette.divider}`,
    },
    'title': {
        color: theme.palette.text.secondary,
    },
    'content': {
        '& > div': {
            marginBottom: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
    },
    'chip': {
        color: theme.palette.action.disabled,
    },
    'dropzone': {
        border: `0.5px solid ${theme.palette.divider}`,
    },
    'progress': {
        color: theme.palette.info.main,
        animation: '$spin 4s linear infinite',
    },
    'error': {
        color: theme.palette.error.main,
    },
    '@keyframes spin': {
        '100%': {
            transform: 'rotate(360deg)',
        },
    },
}));

export const ModalCard = ({
    connectHandler,
    field: { field_name, label, children },
    showLabel = true,
    Logo,
    actionId = '',
}) => {
    const formik = useFormikContext();

    const classes = useStyles(!!formik.errors[field_name] && !!formik.touched[field_name]);

    const onConnect = useCallback(async () => {
        try {
            formik.setFieldValue(field_name, await connectHandler());
            formik.setFieldTouched(field_name);
            formik.setFieldValue(field_name + '_isFailed', false);
        } catch (e) {
            formik.setFieldTouched(field_name);
            formik.setFieldValue(field_name + '_isFailed', true);
        }
    }, [connectHandler, formik]);

    let uploads = [];
    let dropzoneField;

    if (children) {
        dropzoneField = children.find(item => item.field_type === 'dropzone');
        uploads = formik.values[dropzoneField?.field_name] ?? [];
    }

    return (
        <>
            <Card className={classes.root} elevation={0} id={actionId} onClick={onConnect}>
                <CardHeader
                    title={
                        <Box display="flex" justifyContent="space-between">
                            <Box display="flex" alignItems="center">
                                {Logo && <Logo />}
                                {showLabel && (
                                    <Box ml={1}>
                                        <Typography variant="subtitle1">{label}</Typography>
                                    </Box>
                                )}
                            </Box>
                            <IconButton size="large">
                                <ArrowForwardIcon />
                            </IconButton>
                        </Box>
                    }
                    className={classes.title}
                />
                <CardContent className={classes.content}>
                    {formik.errors[field_name] && formik.touched[field_name] && (
                        <Typography variant="subtitle1" color="error">
                            {formik.errors[field_name]}
                        </Typography>
                    )}
                    {dropzoneField &&
                        formik.errors[dropzoneField.field_name] &&
                        formik.touched[dropzoneField.field_name] && (
                            <Typography variant="subtitle1" color="error">
                                {formik.errors[dropzoneField.field_name]}
                            </Typography>
                        )}
                    {formik.values[field_name] && (
                        <Chip
                            icon={
                                <CheckCircleIcon
                                    sx={{ '&.MuiChip-icon': { color: 'success.main' } }}
                                />
                            }
                            label={`Connected - ${label}`}
                            className={classes.chip}
                        />
                    )}
                    {uploads.map((upload, idx) => {
                        const label = upload.error
                            ? `${upload.path} - upload error`
                            : upload.key
                            ? upload.path
                            : `${upload.path} - ${upload.progress}%`;
                        const icon = upload.error ? (
                            <ErrorIcon className={classes.error} />
                        ) : upload.key ? (
                            <CheckCircleIcon sx={{ '&.MuiChip-icon': { color: 'success.main' } }} />
                        ) : (
                            <AutorenewIcon className={classes.progress} />
                        );
                        return (
                            <Chip
                                key={`${upload.path}-${idx}`}
                                icon={icon}
                                label={label}
                                onDelete={() => {
                                    const source = uploadManager.get(upload);
                                    if (source) {
                                        source.cancel();
                                    }
                                    uploadManager.delete(upload);

                                    const newValue = uploads.filter(item => item !== upload);
                                    formik.setFieldValue(
                                        dropzoneField.field_name,
                                        newValue.length ? newValue : null
                                    );
                                }}
                                className={classes.chip}
                            />
                        );
                    })}
                </CardContent>
            </Card>
            {!formik.values[field_name] &&
                ((formik.values[field_name + '_isFailed'] && children && children.length) ||
                    uploads.length > 0) && <Fields fields={children} />}
        </>
    );
};
