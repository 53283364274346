import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import { CustomerForm } from './CustomerForm';
import { Customer } from '@lendica/utils';

interface CustomerFormPopup {
    customer: Customer | {} | null | undefined;
    open: boolean;
    handleClose: any;
    handleSubmit: any;
}

export default function CustomerFormPopup({
    customer,
    open,
    handleClose,
    handleSubmit,
}: CustomerFormPopup) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{customer ? 'Edit customer info' : 'Add a new Customer'}</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 12,
                    top: 12,
                    color: theme => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <CustomerForm
                    customer={customer}
                    callback={handleSubmit}
                    handleClose={handleClose}
                />
            </DialogContent>
        </Dialog>
    );
}
