import { useState, useEffect } from "react";
import * as api from '../api/index'

export const useDrawDown = () => {
    const [drawDown, setDrawDown] = useState({
        isLoading: true,
        dataLoaded: false,
        withdrawalData: {
            "withdrawal_amount": 0,
            "net_funded_amount": 0,
            "total_pmt": 0,
            "factor_rate": 1.21,
            "origination_fee_percentage": 0.05,
            "term_length": 180
        }
    });

    const setWithdrawal = (amount, fee, rate, term) => {
        setDrawDown(currentState => ({
            ...currentState,
            withdrawalData: {
                ...currentState.withdrawalData,
                withdrawal_amount: amount,
                net_funded_amount: amount * (1 - fee),
                total_pmt: amount * rate,
                factor_rate: rate,
                origination_fee_percentage: fee,
                term_length: term,
            },
        }));
    }
    // const [company, setCompany] = useState({});
    // const [approvals, setApprovals] = useState({});
    const updateDrawDown = (values) => {
        setDrawDown(currentState => ({
            ...currentState,
            values
        }))
    }

    const setApproval = (approval) => {
        setDrawDown(currentState => ({
            ...currentState,
            approval: approval
        }))
    }

    useEffect(() => {
        const fetchDrawDown = async () => {
            try {
                // const company = await api.getCompanyDetails()
                // const dealsData = await api.getDeals()
                // const approvals = await api.getDrawDownApproval()

                const data = await Promise.all([
                    api.getCompanyDetails(),
                    api.getDeals(),
                    api.getDrawDownApproval()
                ])

                const drawdownApproval = data[2].find(x => x.active)


                setDrawDown(currentState => ({
                    ...currentState,
                    isLoading: false,
                    company: data[0],
                    approval: drawdownApproval,
                    deals: data[1],
                    // withdrawalData: {
                    //     "withdrawal_amount": 0,
                    //     "net_funded_amount": 0,
                    //     "total_pmt": 0,
                    //     "factor_rate": 1.21,
                    //     "origination_fee_percentage": 0.05,
                    //     "term_length": 180
                    // },
                    dataLoaded: true
                }));

            } catch (error) {
                setIsLoading(false);
            }
        };
        // if (!drawDown.dataLoaded) {
        fetchDrawDown();
        // }
    }, []);

    return {
        isLoading: drawDown.isLoading,
        company: drawDown.company,
        approval: drawDown.approval,
        deals: drawDown.deals,
        withdrawalData: drawDown.withdrawalData,
        setWithdrawal: setWithdrawal,
        updateDrawDown: updateDrawDown,
        setApproval: setApproval
    }
}