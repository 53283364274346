import React, { useState, useEffect, useRef } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { IconButton, Box, Button, Typography, TextField, Popover, Link, Chip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import InputAdornment from '@mui/material/InputAdornment';
import NumberFormat from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import { percentageFormatter, currencyFormatter } from '@lendica/utils';
import { useWithdraw } from './useWithdraw';
import * as api from '../../api/index';

const useStyles = makeStyles(theme => ({
    card: {
        'borderRadius': 12,
        'borderColor': theme.palette.background.cardBorder,
        'borderWidth': gradient => (gradient ? 0 : 1),
        'backgroundColor': theme.palette.background.cardLight,
        'borderStyle': 'solid',
        'position': 'relative',
        '&:after': {
            content: '""',
            position: 'absolute',
            height: gradient => (gradient ? '100%' : 0),
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1,
            background: `linear-gradient(rgba(252, 252, 253, 0), ${theme.palette.background.paper})`,
        },
    },
    actionArea: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    amountButton: {
        'borderRadius': 20,
        'minWidth': 20,
        'padding': '3px 10px',
        'marginRight': theme.spacing(1),

        '& > span > p': {
            fontSize: 13,
        },
    },
    iconButton: {
        width: 60,
        height: 60,
    },
    draw: {
        width: 212,
        height: 36,
        marginLeft: theme.spacing(4),
    },
    modal: {
        maxWidth: 345,
    },
    rounded: {
        borderRadius: 12,
    },
    tooltip: {
        'position': 'relative',
        'display': 'inline-block',
        'borderBottom': '1px dotted black',
        '&:hover': {
            '& > span': {
                visibility: 'visible !important',
            },
        },
    },
    scroll: {
        height: '160px',
        overflowY: 'auto',
    },
    item: {
        'display': 'flex',
        'alignItems': 'center',
        'justifyContent': 'space-between',
        'padding': '2px 0',
        'borderBottom': '1px solid #ccc',
        '&:last-child': {
            borderBottom: 0,
        },
    },
    bold: {
        fontWeight: 600,
    },
    noWrap: {
        whiteSpace: 'pre-line',
    },
}));

export const WithdrawalWidget = ({
    available,
    rate,
    rates,
    gradient = false,
    withdrawalData,
    setWithdrawal,
    setDealCreated,
}) => {
    const [amount, setAmount] = useState(0);
    const [values, setValues] = useState([1000, 2000, 5000, 10000]);
    const classes = useStyles(gradient);
    const [anchorEl, setAnchorEl] = useState(null);
    const [error, setError] = useState(false);
    const navigate = useNavigate();
    const inputRef = useRef(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleConfirm = async withdrawal_amount => {
        const res = await api.withdraw(withdrawal_amount);
        let deal = res.deal;
        setDealCreated(true);
        navigate(`/drawdown/withdrawal/success/${res.deal.id}`);
    };

    let factor_rates = {}

    rates.forEach(rate => {
        let day = rate.day
        let daily_rate = rate.daily_rate

        factor_rates[day] = (day * daily_rate + 1).toFixed(3)
    })

    const open = Boolean(anchorEl);
    const id = open ? 'withdrawal-popover' : undefined;

    useEffect(() => {
        let num = parseFloat(available);
        if (!isNaN(num) && num > 0) {
            setValues([getRounded(0.1 * num), getRounded(0.2 * num), getRounded(0.4 * num), num]);
        }
    }, [available]);

    useEffect(() => {
        setWithdrawal(!!amount ? amount : 0);
    }, [amount]);

    const getRounded = num => {
        if (num < 1000) return num;
        return Math.floor(num / 1000) * 1000;
    };

    const displayNumber = num => {
        if (num === parseFloat(available)) return 'Max';
        const lookup = [
            { value: 1, symbol: '' },
            { value: 1e3, symbol: 'k' },
            { value: 1e6, symbol: 'M' },
            { value: 1e9, symbol: 'G' },
            { value: 1e12, symbol: 'T' },
            { value: 1e15, symbol: 'P' },
            { value: 1e18, symbol: 'E' },
        ];
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        var item = lookup
            .slice()
            .reverse()
            .find(function (item) {
                return num >= item.value;
            });
        return item ? (num / item.value).toFixed().replace(rx, '$1') + item.symbol : '0';
    };

    const handleChange = e => {
        setError(false);
        const num =
            Math.abs(parseFloat(e.target.value.replace(/,/g, '')).toFixed(2)) > available
                ? available
                : Math.abs(parseFloat(e.target.value.replace(/,/g, '')).toFixed(2));
        if (!num || num === '' || isNaN(num) || num < 0.01) {
            setAmount(0);
            return;
        }
        setAmount(num);
    };
    const handleBlur = e => {
        if (parseFloat(e.target.value.replace(/,/g, '')).toFixed(2) > parseFloat(available))
            setError(true);
        else setError(false);
        // if (!e.target.value) setError(true);
    };
    const handlePlus = () => {
        setError(false);
        setAmount(amount + 5000 <= parseFloat(available) ? amount + 5000 : available);
    };
    const handleMinus = () => {
        setError(false);
        setAmount(amount - 5000 > 0 ? amount - 5000 : 0);
    };
    const handleAmount = val => {
        setError(false);
        setAmount(val);
    };

    const tooltipTextStyle = {
        visibility: 'hidden',
        width: '192px',
        backgroundColor: '#616161',
        opacity: 0.9,
        color: '#fff',
        textAlign: 'left',
        borderRadius: '6px',
        padding: '6px 12px',
        fontFamily:
            '-apple-system, BlinkMacSystemFont, Inter, "Segoe UI", "Helvetica Neue", sans-serif',
        fontSize: '12px',

        /* Position the tooltip */
        position: 'absolute',
        zIndex: 9999,
        top: '102%',
    };

    return (
        <Box className={classes.card} mb={3}>
            <Box p={2} borderBottom="1px solid #EDEDF4">
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    className={classes.actionArea}
                    onClick={() => {
                        navigate('/drawdown/withdrawal');
                    }}
                >
                    <Typography variant="subtitle1" color="text.primary">
                        Withdrawal
                    </Typography>
                    <IconButton
                        id="btn-withdrawal-arrow"
                        aria-label="Open withdrawal"
                        size="small"
                        onClick={() => navigate('/drawdown/withdrawal')}
                    >
                        <ArrowForwardIosIcon fontSize="small" />
                    </IconButton>
                </Box>
                <Box display="flex" mt={3} pr={1}>
                    <IconButton onClick={handleMinus} className={classes.iconButton} size="large">
                        <RemoveCircleOutlineIcon fontSize="large" />
                    </IconButton>
                    <Box flex="1">
                        <NumberFormat
                            variant="standard"
                            label="Amount"
                            isAllowed={values => {
                                const { floatValue } = values;
                                return !floatValue || (floatValue >= 0 && floatValue <= available);
                            }}
                            ref={inputRef}
                            thousandSeparator={true}
                            allowNegative={false}
                            isNumericString={true}
                            decimalScale={2}
                            customInput={TextField}
                            fullWidth
                            value={amount}
                            onChange={handleChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AttachMoneyIcon fontSize="small" color="disabled" />
                                    </InputAdornment>
                                ),
                            }}
                            helperText={error ? 'Please input a valid number' : ''}
                            onBlur={handleBlur}
                            error={error}
                        />
                    </Box>
                    <IconButton onClick={handlePlus} className={classes.iconButton} size="large">
                        <ControlPointIcon fontSize="large" />
                    </IconButton>
                </Box>
                <Box display="flex" mt={1} justifyContent="flex-end" pr={8}>
                    {values.map((value, index) => (
                        <Box key={Number(index) + 1} ml={1}>
                            <Chip
                                label={displayNumber(value)}
                                clickable
                                onClick={() => handleAmount(value)}
                            />
                        </Box>
                    ))}
                </Box>
            </Box>

            <Box p={2} display="flex" alignItems="center">
                <Box flex="1">
                    <Box display="flex" justifyContent="space-between">
                        <Typography variant="caption" color="text.primary">
                            Withdrawal Fee
                        </Typography>
                        <Typography variant="caption" color="text.primary">
                            {percentageFormatter(withdrawalData?.origination_fee_percentage ?? '--')}
                        </Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                        <Box className={classes.tooltip}>
                            <Typography component="p" variant="caption" color="text.primary">
                                Rate &#9432;
                            </Typography>
                            <span style={tooltipTextStyle}>
                                Faster paybacks mean lower factor rates. Pay off early and save.
                                <br />
                                <br />
                                <Box className={classes.item}>
                                    <Typography
                                        variant="caption"
                                        className={classes.bold}
                                        color="inherit"
                                        component="div"
                                    >
                                        No. of Days
                                    </Typography>
                                    <Typography
                                        variant="caption"
                                        className={classes.bold}
                                        color="inherit"
                                        component="div"
                                    >
                                        Rate
                                    </Typography>
                                </Box>
                                {Object.entries(factor_rates).map(([key, value], index) => (
                                    <Box className={classes.item} key={`rate-table-${index}`}>
                                        <Typography
                                            variant="caption"
                                            color="inherit"
                                            component="div"
                                        >
                                            {key} days
                                        </Typography>
                                        <Typography
                                            variant="caption"
                                            color="inherit"
                                            component="div"
                                        >
                                            {value}
                                        </Typography>
                                    </Box>
                                ))}
                            </span>
                        </Box>
                        <Typography variant="caption" color="text.primary">
                            {withdrawalData.factor_rate.toFixed(3)}
                        </Typography>
                    </Box>
                </Box>
                <Button
                    id="btn-quick-draw"
                    variant="outlined"
                    className={classes.draw}
                    disabled={!amount || amount > available || amount < 1000}
                    onClick={handleClick}
                >
                    Quick Draw
                </Button>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    classes={{ paper: classes.rounded }}
                >
                    <Box p={2} className={classes.modal}>
                        <Typography
                            component={Box}
                            mb={1}
                            variant="subtitle1"
                            color="text.primary"
                            paragraph
                        >
                            Summary
                        </Typography>
                        <Box py={1} className={classes.scroll}>
                            {/* <Typography
                                    variant="subtitle2"
                                    className={classes.bold}
                                    color="text.primary"
                                    gutterBottom
                                >
                                    Summary
                                </Typography>
                                <Divider /> */}
                            <Box mt={1} display="flex" flex="0 0 auto">
                                <Box flex="1">
                                    <Typography variant="caption">Rate</Typography>
                                    <Typography
                                        variant="body2"
                                        component={Box}
                                        className={classes.bold}
                                    >
                                        {withdrawalData?.factor_rate.toFixed(3)}
                                        {/* {withdrawalData?.factor_rate ?? 1.21} */}
                                    </Typography>
                                </Box>
                                <Box flex="1" textAlign="left">
                                    <Typography variant="caption">Withdrawal Fee</Typography>
                                    <Typography
                                        variant="body2"
                                        component={Box}
                                        className={classes.bold}
                                    >
                                        {percentageFormatter(
                                            withdrawalData?.origination_fee_percentage ?? 0.05
                                        )}
                                    </Typography>
                                </Box>
                                <Box flex="1" textAlign="right">
                                    <Typography variant="caption">Amount Funded</Typography>
                                    <Typography
                                        variant="body2"
                                        component={Box}
                                        className={classes.bold}
                                    >
                                        {currencyFormatter(withdrawalData?.net_funded_amount ?? 0)}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box my={1} display="flex" flex="0 0 auto">
                                <Box flex="1">
                                    <Typography variant="caption">Term Length</Typography>
                                    <Typography
                                        variant="body2"
                                        component={Box}
                                        className={classes.bold}
                                    >
                                        {`${withdrawalData?.term_length} days` ?? '--'}
                                    </Typography>
                                </Box>
                                <Box flex="1" textAlign="left">
                                    <Typography variant="caption">Weekly Payment</Typography>
                                    <Typography
                                        variant="body2"
                                        component={Box}
                                        className={classes.bold}
                                    >
                                        {currencyFormatter(withdrawalData?.weekly_pmt ?? 0)}
                                    </Typography>
                                </Box>
                                <Box flex="1" textAlign="right">
                                    <Typography variant="caption">Total Payment</Typography>
                                    <Typography
                                        variant="body2"
                                        component={Box}
                                        className={classes.bold}
                                    >
                                        {currencyFormatter(withdrawalData?.total_pmt ?? 0)}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box mt={4}>
                                <Typography
                                    variant="body2"
                                    color="text.primary"
                                    component="p"
                                    paragraph
                                    className={classes.noWrap}
                                >
                                    *By clicking “AGREE AND DRAW” you agree to the{' '}
                                    <Link
                                        variant="inherit"
                                        color="text.primary"
                                        href="https://home.golendica.com/agreement-drawdown"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        underline="always"
                                    >
                                        terms and conditions
                                    </Link>{' '}
                                    pursuant to the Future Receivables Sales Agreement.
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            display="flex"
                            mt={1}
                            sx={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px -6px 4px -6px' }}
                        >
                            <Button
                                id="btn-agree-and-draw"
                                color="primary"
                                onClick={() => handleConfirm(withdrawalData?.withdrawal_amount)}
                            >
                                Agree and Draw
                            </Button>
                            <Button color="primary" onClick={handleClose}>
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                </Popover>
            </Box>
        </Box>
    );
};
