import { Box, Button, IconButton, Paper, Popover, Typography } from '@mui/material';
import { FC, useMemo, useState } from 'react';
import { Tune as TuneIcon } from '@mui/icons-material';
import { ToggleChip } from '@lendica/components';
import { InvoiceStatus, DealStatus } from '@lendica/utils';

export interface Filter {
    liveDeals: boolean;
    pastDeals: boolean;
    notActivated: boolean;
    earliestFirst: boolean;
    status: (DealStatus | InvoiceStatus)[];
    customer: string[];
}

export const emptyFilter: Filter = {
    liveDeals: false,
    pastDeals: false,
    notActivated: false,
    earliestFirst: false,
    status: [],
    customer: [],
};

export interface AllFiltersProps {
    initialValue: Filter;
    onChange(value: Filter): void;
    statuses: {
        label: string;
        value: InvoiceStatus | DealStatus;
    }[];
    customers: {
        label: string;
        value: string;
    }[];
}

const FilterSection: FC<{ label: string } & React.HTMLAttributes<HTMLDivElement>> = ({
    label,
    children,
    ...rest
}) => {
    return (
        <>
            <Box sx={{ pt: 3 }}>
                <Typography variant="subtitle2" sx={{ fontWeight: 'medium' }}>
                    {label}
                </Typography>
            </Box>
            <Box sx={{ pt: 2, display: 'flex', gap: 0.5, flexWrap: 'wrap' }} {...rest}>
                {children}
            </Box>
        </>
    );
};

export const AllFilters: FC<AllFiltersProps> = ({
    initialValue,
    onChange,
    statuses,
    customers,
}) => {
    const [filter, setFilter] = useState(initialValue);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();

    const handleClose = () => {
        setAnchorEl(undefined);
    };

    const statusSet = useMemo(() => new Set(filter.status), [filter]);
    const vendorSet = useMemo(() => new Set(filter.customer), [filter]);

    return (
        <>
            <IconButton
                size="small"
                sx={{
                    border: theme => `1px solid ${theme.palette.grey[400]}`,
                    fontSize: 20,
                }}
                onClick={e => {
                    setFilter(initialValue);
                    setAnchorEl(e.currentTarget);
                }}
            >
                <TuneIcon fontSize="inherit" />
            </IconButton>
            <Popover
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Paper sx={{ px: 3, py: 2, width: 350 }}>
                    <Box>
                        <Typography variant="h6">All Filters</Typography>
                    </Box>
                    <FilterSection label="Filter by live deals">
                        <ToggleChip
                            label="Live Deals"
                            value={filter.liveDeals}
                            onChange={value => {
                                setFilter({ ...filter, liveDeals: value });
                            }}
                        />
                        <ToggleChip
                            label="Past Deals"
                            value={filter.pastDeals}
                            onChange={value => {
                                setFilter({ ...filter, pastDeals: value });
                            }}
                        />
                        <ToggleChip
                            label="Not Activated"
                            value={filter.notActivated}
                            onChange={value => {
                                setFilter({ ...filter, notActivated: value });
                            }}
                        />
                    </FilterSection>
                    <FilterSection label="Filter by invoice status">
                        {statuses.map(item => (
                            <ToggleChip
                                key={item.label}
                                label={item.label}
                                value={statusSet.has(item.value)}
                                onChange={value => {
                                    if (value) {
                                        setFilter({
                                            ...filter,
                                            status: [...filter.status, item.value],
                                        });
                                        return;
                                    }
                                    setFilter({
                                        ...filter,
                                        status: filter.status.filter(
                                            status => status !== item.value
                                        ),
                                    });
                                }}
                            />
                        ))}
                    </FilterSection>
                    <FilterSection label="Filter by customer name">
                        {customers.map(item => (
                            <ToggleChip
                                key={item.label}
                                label={item.label}
                                value={vendorSet.has(item.value)}
                                onChange={value => {
                                    if (value) {
                                        setFilter({
                                            ...filter,
                                            customer: [...filter.customer, item.value],
                                        });
                                        return;
                                    }
                                    setFilter({
                                        ...filter,
                                        customer: filter.customer.filter(
                                            customer => customer !== item.value
                                        ),
                                    });
                                }}
                            />
                        ))}
                    </FilterSection>
                    <FilterSection label="Sort by date">
                        <ToggleChip
                            label="Earliest first"
                            value={filter.earliestFirst}
                            onChange={value => {
                                setFilter({ ...filter, earliestFirst: value });
                            }}
                        />
                    </FilterSection>
                    <Box sx={{ pt: 4, display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                        <Button
                            onClick={() => {
                                onChange(emptyFilter);
                                handleClose();
                            }}
                        >
                            Clear
                        </Button>
                        <Button
                            onClick={() => {
                                onChange(filter);
                                handleClose();
                            }}
                        >
                            Apply
                        </Button>
                    </Box>
                </Paper>
            </Popover>
        </>
    );
};
