import { Logo, RoundedButton } from '@lendica/components';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const CompanyStatusHandler = () => {
    const navigate = useNavigate();

    return (
        <Box
            sx={{
                height: '100%',
                minHeight: '100vh',
                width: '100%',
                maxWidth: 480,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                paddingTop: '1.5rem',
                paddingBottom: '3rem',
                boxSizing: 'border-box',
                background: '#0E0E2C',
                overflow: 'scroll',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    width: '100%',
                    maxWidth: 480,
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    boxSizing: 'border-box',
                    px: 4,
                    overflow: 'scroll',
                }}
            >
                <Logo height={24} dark={false} />
                <Typography variant="h5" color="#FFF" sx={{ mt: 4 }}>
                    Company Status
                </Typography>

                <RoundedButton
                    size="medium"
                    variant="contained"
                    sx={{ mt: 4, mx: 2, background: '#6E6E80' }}
                    onClick={() => navigate('../waitlist')}
                >
                    0 - Lead
                </RoundedButton>
                <RoundedButton
                    size="medium"
                    variant="contained"
                    sx={{ mt: 4, mx: 2, background: '#6E6E80' }}
                    onClick={() => navigate('../pending')}
                >
                    1 - Pending
                </RoundedButton>
                <RoundedButton
                    size="medium"
                    variant="contained"
                    sx={{ mt: 4, mx: 2, background: '#6E6E80' }}
                    onClick={() => navigate('../activate')}
                >
                    2 - Approved (FundNow not active)
                </RoundedButton>
                <RoundedButton
                    size="medium"
                    variant="contained"
                    sx={{ mt: 4, mx: 2, background: '#6E6E80' }}
                    onClick={() => navigate('../marketing')}
                >
                    2 - Approved (FundNow active)
                </RoundedButton>

                <RoundedButton
                    size="medium"
                    variant="contained"
                    sx={{ mt: 4, mx: 2, background: '#6E6E80' }}
                    onClick={() => navigate('../deal-pending')}
                >
                    Company Approved (2), product pending (1)
                </RoundedButton>

                <RoundedButton
                    size="medium"
                    variant="contained"
                    sx={{ mt: 4, mx: 2, background: '#6E6E80' }}
                    onClick={() => navigate('../deal-pending')}
                >
                    Company Approved (2), deal pending (1)
                </RoundedButton>

                <RoundedButton
                    size="medium"
                    variant="contained"
                    sx={{ mt: 4, mx: 2, background: '#6E6E80' }}
                    onClick={() => navigate('../product-paused')}
                >
                    Product Paused (3)
                </RoundedButton>

                <RoundedButton
                    size="medium"
                    variant="contained"
                    sx={{ mt: 4, mx: 2, background: '#6E6E80' }}
                    onClick={() => navigate('../exceeded-limit')}
                >
                    Exceeded credit limit
                </RoundedButton>

                <RoundedButton
                    size="medium"
                    variant="contained"
                    sx={{ mt: 4, mx: 2, background: '#6E6E80' }}
                    onClick={() => navigate(-1)}
                >
                    Go Back
                </RoundedButton>
            </Box>
        </Box>
    );
};
