import { SyntheticEvent, MutableRefObject, useState } from 'react';
import { Tabs, Tab } from '@mui/material';

function a11yProps(index: number) {
    return {
        'id': `nav-tab-${index}`,
        'aria-controls': `nav-tabpanel-${index}`,
    };
}

export const ScrollToTabs = ({
    labels,
    containerRef,
    toRefs,
}: {
    labels: string[];
    containerRef: MutableRefObject<any>;
    toRefs: MutableRefObject<any>[];
}) => {
    const [tabValue, setTabValue] = useState(0);

    const executeScroll = (container: MutableRefObject<any>, to: MutableRefObject<any>) =>
        container.current.scroll({
            top: to.current.offsetTop,
            behavior: 'smooth',
        });

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
        executeScroll(containerRef, toRefs[newValue]);
    };

    return (
        <Tabs
            value={tabValue}
            onChange={handleChange}
            variant="fullWidth"
            aria-label="scroll-to tabs"
            textColor="primary"
            indicatorColor="primary"
        >
            {labels!.map((label, index) => (
                <Tab key={`tab-menu-${index}`} label={label} {...a11yProps(index)} />
            ))}
        </Tabs>
    );
};
