import { useNavigate } from 'react-router-dom'
import * as api from '../api/index'
import { FeatureLayout, RoundedButton } from '@lendica/components'
import { Box, Typography } from '@mui/material'

export const ActivateFundNow = () => {
    const navigate = useNavigate()

    const activate = async () => {
        try {
            await api.activateFundnow()
            navigate('/fundnow')
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <FeatureLayout>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', textAlign: 'center', boxSizing: 'border-box', px: 4 }}>

                <Typography variant="h5" sx={{ mt: 4 }}>FundNow is currently not active for your account.</Typography>

                <RoundedButton
                    size="medium"
                    sx={{ mt: 4, mx: 2 }}
                    variant="contained"
                    onClick={activate}
                    color="primary"
                >
                    Activate FundNow
                </RoundedButton>
            </Box>
        </FeatureLayout>
    )
}