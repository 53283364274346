import { Box } from '@mui/material';
import { FC, ReactNode } from 'react';
import { FeatureLayout } from '@lendica/components';

import { ScrollToTabs } from './components/ScrollToTabs';
import { useWalletDataContext } from './WalletData';
import { useWalletElementContext } from './views/WalletView';

export const WalletContainer: FC<{ children: ReactNode }> = ({ children }) => {
    const { isLoading } = useWalletDataContext();
    const { containerRef, walletRef, myDealsRef } = useWalletElementContext();

    return (
        <FeatureLayout loading={isLoading}>
            <Box
                sx={{
                    bgcolor: 'background.paper',
                    position: 'sticky',
                    top: 0,
                    zIndex: 0,
                    px: 4,
                    pt: 1,
                }}
            >
                <ScrollToTabs
                    labels={['Wallet', 'My Deals']}
                    containerRef={containerRef}
                    toRefs={[walletRef, myDealsRef]}
                />
            </Box>
            <Box
                ref={containerRef}
                sx={{
                    overflowY: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    height: '100%',
                    px: 4,
                }}
            >
                {children}
            </Box>
        </FeatureLayout>
    );
};
