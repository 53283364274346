import axios from 'axios';

export const getDeals = async () => {
    const response = await axios.get('/company/deals');
    return response.data.filter(d => d.product_name === 'drawdown');
}

export const getCompanyDetails = async () => {
    const response = await axios.get('/company/details');
    return response.data;
}

export const getDrawDownApproval = async () => {
    const response = await axios.get('/company/approvals', { product_name: 'drawdown' });
    return response.data;
}

export const getApprovalDetails = async (approvalId) => {
    const response = await axios.get(`/company/approvals?id=${approvalId}`);
    return response.data;
};

export const getDealById = async (id) => {
    const response = await axios.get('/company/deals', { params: { id: id } });
    return response.data;
}

export const approvalInit = async () => {
    const response = await axios.post('/drawdown/approval/init');
    return response.data;
}

export const withdraw = async (amount) => {
    const response = await axios.post('/drawdown/deal/init', { withdrawal_amount: amount });
    return response.data;
}

export const getRateCurves = async () => {
    const response = await axios.get('/company/ratecurve_backbones');
    return response.data;
}

export const activateDrawdown = async () => {
    return (await axios.get('/drawdown/activate')).data
}
// export const forgotPassword = async (username: string): Promise<any> => {
//     await axiosInstance.post("/auth/forgot_password", { username });
// }

// export const resetPassword = async (
//     username: string,
//     old_password: string,
//     password: string,
//     password2: string,
// ): Promise<any> => {
//     const response = await axiosInstance.post("/auth/change_password", {
//         username,
//         old_password,
//         password,
//         password2
//     });
//     return response.data;
// }