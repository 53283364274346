import { useEffect, useState, useCallback } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Typography, Card } from '@mui/material'
import * as api from '../api/index'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Dropzone, DropzoneFile, FeatureHeader } from '@lendica/components';
import { QuickbooksLogo } from '@lendica/components'
import ReceiptIcon from '@mui/icons-material/Receipt';
import { UploadList } from './UploadList';
import { UnableToConnect } from './UnableToConnect';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import ModeStandby from '@mui/icons-material/ModeStandby';
import { ReceiptOutlined } from '@mui/icons-material';

export const Quickbooks = ({ company_id, env }: { company_id: string | undefined, env: string }) => {
    const [expanded, setExpanded] = useState(false)
    const [uploadedFiles, setUploadedFiles] = useState<DropzoneFile[]>([]);
    const [connectedAccounts, setConnectedAccounts] = useState([])
    const [popupClosed, setPopupClosed] = useState(false)
    const [accountConnected, setAccountConnected] = useState(false)

    function launchPopup() {
        var win: any;
        var parameters = "location=1,width=800,height=650";
        parameters += ",left=" + (screen.width - 800) / 2 + ",top=" + (screen.height - 650) / 2;

        // Launch Popup
        if (env === 'idev') {
            win = window.open(`https://micro-oauth-dev.herokuapp.com/oauth/quickbooks?company_id=${company_id}`, 'connectPopup', parameters);
        } else if (env === 'prod') {
            win = window.open(`https://oauth.app.golendica.com/oauth/quickbooks?company_id=${company_id}`, 'connectPopup', parameters);
        }

        let interval = setInterval(() => {
            if (win.closed) {
                console.log('closed')
                setPopupClosed(true)
                clearInterval(interval)
            }
        }, 1000)
    }

    const handleFileUpload = (file: any) => {
        setUploadedFiles(file)
    }

    useEffect(() => {
        const getDataConnections = async () => {
            const res = await api.getDataConnections()
            let isConnected = res.find((x: any) => x.active === true && x.saas_name === 'quickbooks')
            setAccountConnected(isConnected)
        }
        getDataConnections()
    }, [popupClosed])

    return (
        <Accordion
            expanded={expanded}
            onChange={() => setExpanded(!expanded)}
            sx={{
                '&:before': {
                    display: 'none',
                },
                borderRadius: 1,
                boxShadow: 'none',
                paddingTop: 1,
                background: '##EDEDF4',
                border: '1px solid #EDEDF4',
                mt: 1,
                mb: 1,
                paddingBottom: expanded ? 0 : 1
            }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                color="#FFF"
                sx={{
                    border: 'none',
                    boxShadow: 'none',
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ReceiptOutlined color='primary' />
                    <Typography fontWeight={500} ml={2}>Accounting</Typography>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        paddingX: '1rem',
                        alignItems: 'center',
                        width: '100%'
                    }}
                >
                    {accountConnected && <CheckCircleIcon color="success" />}
                </Box>
            </AccordionSummary>

            <AccordionDetails
                sx={{
                    padding: '0',
                }}
            >
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            padding: '1rem 1rem',
                            background: '#f2f2f8',
                            borderRadius: 1,
                            marginY: 1,
                            marginX: 2,
                            cursor: 'pointer'
                        }}
                        component="div"
                        onClick={() => {
                            !accountConnected && launchPopup()
                        }}
                    >
                        <QuickbooksLogo />
                        {accountConnected ?
                            <Box sx={{ display: 'flex' }}>
                                <Typography mr={1}>connected</Typography>
                                <ModeStandby color="success" />
                            </Box> :
                            <SyncAltIcon />
                        }
                    </Box>
                </Box>
            </AccordionDetails>
        </Accordion >
    )
}