import axios from 'axios';
import { useEffect, useState } from 'react';
import { createContainer } from 'unstated-next';

import { stateObject } from './stateObject';
import * as api from './api/index';

// For simplicity this container has state & methods for sub-pages within DrawDown feature like Withdraw or Payment
// When it grows bigger split out sub-page state to its own container
const useDrawDownContainer = () => {
    const [state, setState] = useState(stateObject);
    const [companySummary, setCompanySummary] = useState({});
    const [dealsData, setDealsData] = useState({});
    const [approval, setApproval] = useState({});

    const updateData = data => {
        setState(currentState => ({
            ...currentState,
            data: {
                ...currentState.data,
                ...data,
            },
        }));
    };

    const updateDrawDown = async () => {
        setState(currentState => ({
            ...currentState,
            isLoading: true,
        }));
        const dealsData = await axios.get('/company/deals').then(response => response.data);
        const approvalData = await api.getDrawDownApproval();
        setState(currentState => ({
            ...currentState,
            approval: approvalData,
            deals: dealsData,
            isLoading: false,
        }));
    };

    useEffect(() => {
        const getCompanySummary = async () => {
            setState(currentState => ({ ...currentState, isLoading: true }));
            const company = await api.getCompanyDetails();
            const dealsData = await api.getDeals();
            const approvals = await api.getDrawDownApproval();
            const drawdownApproval = approvals.find(x => x.active);

            setState(currentState => ({
                ...currentState,
                company: company,
                approval: drawdownApproval,
                deals: dealsData,
                isLoading: false,
            }));
        };
        getCompanySummary();
    }, []);

    if (process.env.NODE_ENV === 'development') {
        useEffect(() => {
            localStorage.setItem('DrawDown', JSON.stringify(state));
        }, [state]);
    }

    return {
        ...state,
        async withdraw(withdrawalData) {
            setState(currentState => ({ ...currentState, isLoading: true }));
            try {
                const res = await api.withdraw(
                    { lendica_token: lendica_token ?? state?.data?.company?.lendica_token },
                    withdrawalData
                );
                if (res.successful) {
                    setState(currentState => ({
                        ...currentState,
                        data: {
                            ...currentState.data,
                            approval: {
                                ...currentState.data.approval,
                                ...res.data.approval,
                            },
                        },
                        withdrawalData: {
                            ...currentState.withdrawalData,
                            ...res.data.withdrawal,
                        },
                    }));
                } else {
                    throw new Error(
                        'We have trouble connecting to the server. Please try again later.'
                    );
                }
            } catch (e) {
                setError(e.message);
                throw new Error(e.message);
            } finally {
                setState(currentState => ({ ...currentState, isLoading: false }));
            }
        },
        async pay(withdrawals) {
            setState(currentState => ({ ...currentState, isLoading: true }));
            try {
                const res = await api.pay(
                    { lendica_token: lendica_token ?? state?.data?.company?.lendica_token },
                    withdrawals
                );
                if (res.successful) {
                    setState(currentState => ({
                        ...currentState,
                        data: {
                            ...currentState.data,
                            approval: {
                                ...currentState.data.approval,
                                ...res.data.approval,
                            },
                            early_payment: res.data.early_payment,
                        },
                    }));
                } else {
                    throw new Error(
                        'We have trouble connecting to the server. Please try again later.'
                    );
                }
            } catch (e) {
                setError(e.message);
                throw new Error(e.message);
            } finally {
                setState(currentState => ({ ...currentState, isLoading: false }));
            }
        },
        updateData,
        updateDrawDown,
        selectActivity(activity) {
            setState(currentState => ({ ...currentState, selectedActivity: activity }));
        },
        setWithdrawal(amount, fee, rate, term) {
            setState(currentState => ({
                ...currentState,
                withdrawalData: {
                    ...currentState.withdrawalData,
                    withdrawal_amount: amount,
                    net_funded_amount: amount * (1 - fee),
                    total_pmt: amount * rate,
                    factor_rate: rate,
                    origination_fee_percentage: fee,
                    term_length: term,
                },
            }));
        },
        setSelectedWithdrawals(items) {
            setState(currentState => ({ ...currentState, selectedWithdrawals: items }));
        },
    };
};

export const DrawDownContainer = createContainer(useDrawDownContainer);
