import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Backdrop, CircularProgress, Box } from '@mui/material';

import { makeStyles } from '@mui/styles';

import { FormContext } from './FormContext';
import { FormikData } from './FormikData';
import { formAPI } from './formAPI';

const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer,
        color: theme.palette.common.white,
        position: 'absolute',
    },
}));

export const applicationStatuses = {
    inProgress: 0,
    submitted: 1,
    approved: 2,
    declined: 3,
};

export const FormData = ({ children, ...props }) => {
    const [state, setState] = useState({
        isLoading: true,
    });
    const classes = useStyles();
    const params = useParams();
    const step = +params.step;
    const navigate = useNavigate();

    useEffect(() => {
        setState(currentState => ({ ...currentState, isLoading: true }));
        (async function () {
            const { application } = await formAPI.getApplication(props.applicationId);
            if (application.application_status !== applicationStatuses.inProgress) {
                props.onComplete(application);
                return;
            }
            setState(currentState => ({
                ...currentState,
                application,
                isLoading: false,
            }));

            if (step !== application.current_step) {
                navigate(`../${application.current_step}`);
            }
        })();
    }, []);

    return (
        <FormContext.Provider
            value={{
                settings: props,
                data: state,
                setSubmitForm(submitForm) {
                    setState(currentState => ({ ...currentState, submitForm }));
                },
                toggleIsLoading() {
                    setState(currentState => ({
                        ...currentState,
                        isLoading: !currentState.isLoading,
                    }));
                },
                setApplication(application) {
                    setState(currentState => ({
                        ...currentState,
                        application,
                    }));
                },
            }}
        >
            <Box
                sx={{ position: 'relative', bgcolor: 'background.paper' }}
                display="flex"
                flexDirection="column"
                flex={1}
            >
                <Backdrop open={state.isLoading} className={classes.backdrop}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                {state.application && <FormikData>{children}</FormikData>}
            </Box>
        </FormContext.Provider>
    );
};
