import { Box } from "@mui/material"
import { RoundedButton } from "@lendica/components"

export const BottomBar = ({ action, label }) => {
    return (
        <Box
            sx={{
                position: 'sticky',
                bottom: 0,
                mb: 2,
                boxSizing: 'border-box',
                width: '100%',
                py: 1,
                background: '#F8F8FB',
                zIndex: 1
            }}
        >
            <RoundedButton
                variant="contained"
                fullWidth
                onClick={action}
            >
                {label}
            </RoundedButton>
        </Box>
    )
}