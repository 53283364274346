import axios from 'axios';
import { getApprovalDetails } from '@lendica/api';

// const apiKey = 'AIzaSyDpaTbeSIERqaAMp2QhGK--Bpx2tmufWWU';

export const awsURL = 'https://micro-awsmanager.herokuapp.com/s3';
export const bucket_name = 'lendica-pod';

export const getCompanyDetails = async (): Promise<any> => {
    const response = await axios.get('/company/details');
    return response.data;
};

export const getDataConnections = async (): Promise<any> => {
    return (await axios.get('/company/connections')).data;
};

export const getCompany = async (): Promise<any> => {
    const response = await axios.get('/company/summary');
    return response.data;
};

export const getInvoicesList = async () => {
    const response = await axios.get('/company/invoices');
    return response.data;
};

export const getFundnowApproval = async () => {
    const approvalsList = (await axios.get('/company/approvals')).data;
    return approvalsList.find(
        (approval: any) => approval.active === true && approval.product_name === 'fundnow'
    );
};

export const getFundnowFullApproval = async () => {
    const approvalId = (await getFundnowApproval()).id;
    return await getApprovalDetails(approvalId);
};

export const getDealsList = async () => {
    const dealsList = (await axios.get(`/company/deals`)).data;
    return dealsList.filter((deal: any) => deal.product_name === 'fundnow');
};

export const archiveInvoice = async (id: string) => {
    const response = await axios.get(`/company/invoices/archive?id=${id}`);
    return response.data;
};

// FundNow

export const getInvoice = async (invoiceId: string) => {
    const response = await axios.get(`/company/invoices?id=${invoiceId}`);
    return response.data;
};

export const getInvoices = async () => {
    const response = await axios.get('/company/invoices');
    return response.data;
};

export const getInvoiceDetails = async (invoiceId: string) => {
    const response = await axios.get(`/fundnow/invoice/confirm?invoice_id=${invoiceId}`);
    return response.data;
};

export const getOffers = async (invoiceId: string) => {
    const response = await axios.get(`/fundnow/invoice/offer?invoice_id=${invoiceId}`);
    return response.data;
};

export const confirmInvoiceDetails = async (data: any) => {
    const response = await axios.post(
        `/fundnow/invoice/confirm?invoice_id=${data!.invoice!.id}`,
        data
    );
    return response.data;
};

export const getCustomersList = async () => {
    const response = await axios.get('/company/customers');
    return response.data;
};

export const postCustomer = async (data: any) => {
    return (await axios.post('/company/customers', data)).data;
};

export const postCustomerToInvoice = async (data: any, invoiceId: string) => {
    return (
        await axios.post('/company/customers', {
            invoice_id: invoiceId,
            customer: data,
        })
    ).data;
};

export const createFundnowDeal = async (invoiceId: string) => {
    return (
        await axios.post('/fundnow/deal/init', {
            invoice_id: invoiceId,
        })
    ).data;
};

export const activateFundnow = async () => {
    const res = await axios.get('/fundnow/activate');
};

export const getDealDetails = async (id: string) => {
    const response = await axios.get(`/company/deals?id=${id}`);
    return response.data;
};

export const processInvoice = async (objectKey: string) => {
    const response = await axios.post('/fundnow/invoice/process', {
        object_name: objectKey,
        object_folder: 'lendica-pod',
    });
    return response.data;
};
