import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const usePayEarly = ({ live_withdrawals, summary, setSelectedWithdrawals }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [amount, setAmount] = useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const navigate = useNavigate();

    const open = Boolean(anchorEl);

    const available = () => {
        const unpaidTotal = live_withdrawals.reduce(
            (prevVal, currItem) => prevVal + currItem.withdrawal_early_payoff_amount,
            0
        );
        return +parseFloat(unpaidTotal).toFixed(2);
    };

    const totalSaved = () => {
        return live_withdrawals.reduce((prevVal, currItem, index) => {
            return currentStep >= index + 1
                ? prevVal + currItem.withdrawal_early_payoff_benefit
                : prevVal;
        }, 0);
    };

    const availableCredit = () => {
        const { credit_available } = summary;
        let credit = live_withdrawals.reduce((prevVal, currItem, index) => {
            return currentStep >= index + 1
                ? prevVal + currItem.withdrawal_early_payoff_principal
                : prevVal;
        }, 0);

        return credit_available + credit;
    };

    const getMarks = () => {
        let steps = [];

        live_withdrawals.forEach((item, index) => {
            if (index > 0) {
                steps[index] = item.withdrawal_early_payoff_amount + steps[index - 1];
            } else {
                steps[index] = item.withdrawal_early_payoff_amount;
            }
        });

        return steps.map(value => ({ value }));
    };

    const valueLabelFormat = value => {
        if (!value) {
            return '0 rate';
        }

        const index = getMarks().findIndex(item => item.value === value);

        return `${live_withdrawals[index]?.withdrawal_early_payoff_rate ?? ''} rate`;
    };

    const handlePopoverOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const handlePlus = () => {
        if (currentStep < live_withdrawals.length) {
            setAmount(
                +parseFloat(
                    amount + live_withdrawals[currentStep].withdrawal_early_payoff_amount
                ).toFixed(2)
            );
            setCurrentStep(currentStep + 1);
        }
    };

    const handleMinus = () => {
        if (currentStep >= 1) {
            setAmount(
                +parseFloat(
                    amount - live_withdrawals[currentStep - 1].withdrawal_early_payoff_amount
                ).toFixed(2)
            );
            setCurrentStep(currentStep - 1);
        }
    };

    const handlePayOffAll = () => {
        setCurrentStep(live_withdrawals.length);
        setAmount(available);
    };

    const handleSlider = (evt, value) => {
        const index = getMarks().findIndex(item => item.value === value);

        setCurrentStep(index + 1);
        setAmount(value);
    };

    const handlePayNow = () => {
        setSelectedWithdrawals(live_withdrawals.slice(0, currentStep));
        navigate('../payment/review');
    };

    return {
        amount,
        marks: getMarks(),
        totalSaved: totalSaved(),
        availableCredit: availableCredit(),
        available: available(),
        currentStep,
        open,
        anchorEl,
        valueLabelFormat,
        handlePlus,
        handleMinus,
        handleSlider,
        handlePayOffAll,
        handlePopoverOpen,
        handlePopoverClose,
        handlePayNow,
    };
};
