import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FixedSizeList } from 'react-window';
import { DatePicker } from '@mui/x-date-pickers';
import {
    Tabs,
    Tab,
    IconButton,
    Box,
    Button,
    Divider,
    Typography,
    List,
    ListItem,
    TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ChevronLeft as ChevronLeftIcon, MoreVert as MoreVertIcon } from '@mui/icons-material';
import LinearProgress from '@mui/material/LinearProgress';
import { DrawDownContainer } from '../../DrawDownContainer';
import { Header, Body, Toolbar, FeatureLayout } from '@lendica/components';
import { usePayment } from './hooks/usePayment';
import { currencyFormatter, stringFormatter } from '@lendica/utils';

const useStyles = makeStyles(theme => ({
    scheduleContainer: {
        overflowY: 'auto',
    },
    root: {
        width: '100%',
        maxWidth: 900,
        // minHeight: 200,
        flex: 1,
    },
    subtitle: {
        fontSize: '0.8rem',
        fontWeight: 600,
    },
    bold: {
        fontWeight: 600,
    },
    body: {
        overflowY: 'auto',
        position: 'relative',
        bottom: 0,
    },
    button: {
        color: theme.palette.action.active,
    },
    cardContainer: {
        flexWrap: 'wrap',
        gap: 8,
    },
    card: {
        borderRadius: 12,
        borderColor: theme.palette.background.cardBorder,
        borderWidth: 1,
        backgroundColor: theme.palette.background.cardLight,
        borderStyle: 'solid',
    },
    payButton: {
        width: '100%',
        display: 'flex',
        borderRadius: 20,
        boxShadow: 'none',
    },
    linear: {
        height: 32,
        borderRadius: 4,
    },
    linearValue: {
        color: theme.palette.common.white,
        position: 'relative',
        top: -26,
        zIndex: 1,
        textAlign: 'center',
    },
    rate: {
        backgroundColor: theme.palette.background.cardBorder,
        padding: '1px 4px',
        borderRadius: 4,
    },
}));

const optionsLong = { year: 'numeric', month: 'long', day: 'numeric' };
const optionsShort = { year: 'numeric', month: 'short', day: 'numeric' };

const ScheduleItem = props => {
    const classes = useStyles();
    const schedule = props.data[props.index];

    return (
        <Box style={props.style}>
            <ListItem component="a" key={`payment-schedule-${props.index}`} disableGutters>
                <Box flex="1">
                    <Typography>
                        {new Date(schedule?.activity_date).toLocaleDateString('en-US', optionsLong)}
                    </Typography>
                    <Typography variant="caption" component="div">
                        {`For w${schedule?.activity_id?.slice(0, 8)}, ${stringFormatter(
                            schedule?.payment_type ?? ''
                        )}`}
                    </Typography>
                </Box>
                <Box display="flex" flexDirection="column" alignItems="flex-end">
                    <Typography>{currencyFormatter(schedule?.activity_amount ?? 0)}</Typography>
                    <Typography variant="caption" component="div" className={classes.rate}>
                        {schedule?.factor_rate}
                    </Typography>
                </Box>
            </ListItem>
            <Divider component="li" />
        </Box>
    );
};

export const Payment = () => {
    const navigate = useNavigate();
    const classes = useStyles();
    const listRef = useRef();

    const {
        data: { approval = {}, activities = [], summary = {}, live_withdrawals = [] },
    } = DrawDownContainer.useContainer();
    const {
        showAll,
        activeTab,
        livePaid,
        progress,
        dueToday,
        lastPayment,
        schedules,
        selectedDate,
        selectedDateSchedules,
        pageRef,
        progressRef,
        calendarRef,
        schedulesRef,
        handleTabChange,
        handleDateChange,
        handleMore,
        onScroll,
    } = usePayment({ approval, activities, summary, live_withdrawals, listRef });

    return (
        <FeatureLayout>
            <Header
                title="Payment"
                showLogo={false}
                showClose={false}
                leftActions={
                    <IconButton size="small" onClick={() => navigate('..')}>
                        <ChevronLeftIcon />
                    </IconButton>
                }
            />

            {/* <Tabs
                value={activeTab}
                onChange={handleTabChange}
                variant="fullWidth"
                indicatorColor="primary"
            >
                <Tab label="Progress" value={0} />
                <Tab label="Calendar" value={1} />
                <Tab label="Schedules" value={2} />
            </Tabs> */}

            <Body display="flex" flexDirection="column" ref={pageRef} className={classes.body}>
                <Box ref={progressRef} flexGrow="1" flexDirection="column" display="flex" p={3}>
                    <Typography
                        variant="h5"
                        component="p"
                        align="left"
                        style={{ marginBottom: 24 }}
                    >
                        Payment Progress
                    </Typography>
                    <Box display="flex" justifyContent="space-between" mb={1}>
                        <Typography variant="body2">{currencyFormatter(livePaid)} Paid</Typography>
                        <Typography variant="body2">
                            {currencyFormatter(livePaid + summary?.total_unpaid ?? 0)} Total
                        </Typography>
                    </Box>

                    <LinearProgress
                        color="primary"
                        variant="determinate"
                        value={progress}
                        className={classes.linear}
                    />
                    <Typography className={classes.linearValue}>{progress}%</Typography>

                    <Box display="flex" className={classes.cardContainer}>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            className={classes.card}
                            flex="1"
                        >
                            <Box p={1} display="flex" justifyContent="center">
                                <Box textAlign="center">
                                    <Typography
                                        variant="subtitle2"
                                        color="text.primary"
                                        className={classes.subtitle}
                                    >
                                        Due Today
                                    </Typography>
                                    <Typography variant="body1">
                                        {currencyFormatter(dueToday ?? 0)}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            className={classes.card}
                            flex="1"
                        >
                            <Box p={1} display="flex" justifyContent="center">
                                <Box textAlign="center">
                                    <Typography
                                        variant="subtitle2"
                                        color="text.primary"
                                        className={classes.subtitle}
                                    >
                                        Total Unpaid
                                    </Typography>
                                    <Typography variant="body1">
                                        {currencyFormatter(summary?.total_unpaid ?? 0)}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            className={classes.card}
                            flex="1"
                        >
                            <Box p={1} display="flex" justifyContent="center">
                                <Box textAlign="center">
                                    <Typography
                                        variant="subtitle2"
                                        color="text.primary"
                                        className={classes.subtitle}
                                    >
                                        Progress
                                    </Typography>
                                    <Typography variant="body1">{progress}%</Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            className={classes.card}
                            flex="1"
                        >
                            <Box p={1} display="flex" justifyContent="center">
                                <Box textAlign="center">
                                    <Typography
                                        variant="subtitle2"
                                        color="text.primary"
                                        className={classes.subtitle}
                                    >
                                        Final Payment
                                    </Typography>
                                    <Typography variant="body1">{lastPayment}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    <Box
                        ref={calendarRef}
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between"
                        mt={4}
                        pt={3}
                    >
                        <Box display="flex" justifyContent="space-between">
                            <Typography
                                variant="h5"
                                component="p"
                                align="left"
                                style={{ marginBottom: 24 }}
                            >
                                Calendar View
                            </Typography>

                            <DatePicker
                                value={selectedDate}
                                onChange={handleDateChange}
                                renderInput={props => <TextField variant="standard" {...props} />}
                                PopperProps={{
                                    disablePortal: true,
                                    onClick: e => e.preventDefault(),
                                }}
                            />
                        </Box>

                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            flex="1"
                            className={classes.card}
                            p={1}
                        >
                            <Box p={1} display="flex" justifyContent="space-between" flex="1">
                                <Box pr={1.5}>
                                    <Typography
                                        variant="subtitle2"
                                        color="text.primary"
                                        className={classes.subtitle}
                                    >
                                        {new Date(selectedDate).toLocaleString('en-us', {
                                            weekday: 'long',
                                        })}
                                    </Typography>
                                    <Typography className={classes.bold}>
                                        {new Date(selectedDate).toLocaleDateString(
                                            'en-US',
                                            optionsShort
                                        )}
                                    </Typography>
                                </Box>
                                <Divider orientation="vertical" flexItem />
                                {selectedDateSchedules.length ? (
                                    <Box flex="1" pl={1.5}>
                                        <Box display="flex" flex="1">
                                            <Box
                                                display="flex"
                                                justifyContent="flex-start"
                                                flex="1"
                                            >
                                                <Typography
                                                    variant="subtitle2"
                                                    color="text.primary"
                                                    className={classes.subtitle}
                                                >
                                                    Due Today
                                                </Typography>
                                            </Box>
                                            <Box display="flex" justifyContent="center" flex="1">
                                                <Typography
                                                    variant="subtitle2"
                                                    color="text.primary"
                                                    className={classes.subtitle}
                                                >
                                                    Withdrawal
                                                </Typography>
                                            </Box>
                                            <Box display="flex" justifyContent="flex-end" flex="1">
                                                <Typography
                                                    variant="subtitle2"
                                                    color="text.primary"
                                                    className={classes.subtitle}
                                                >
                                                    Rate
                                                </Typography>
                                            </Box>
                                        </Box>
                                        {selectedDateSchedules.map((item, index) => (
                                            <Box
                                                key={`dayschedule-${index}-${item.activity_id}`}
                                                display="flex"
                                                flex="1"
                                            >
                                                <Box
                                                    display="flex"
                                                    justifyContent="flex-start"
                                                    flex="1"
                                                >
                                                    <Typography
                                                        variant="subtitle2"
                                                        className={classes.subtitle}
                                                    >
                                                        {currencyFormatter(
                                                            item?.activity_amount ?? 0
                                                        )}
                                                    </Typography>
                                                </Box>
                                                <Box
                                                    display="flex"
                                                    justifyContent="center"
                                                    flex="1"
                                                >
                                                    <Typography
                                                        variant="subtitle2"
                                                        className={classes.subtitle}
                                                    >
                                                        {`w${item.withdrawal_id.slice(0, 8)}`}
                                                    </Typography>
                                                </Box>
                                                <Box
                                                    display="flex"
                                                    justifyContent="flex-end"
                                                    flex="1"
                                                >
                                                    <Typography
                                                        variant="subtitle2"
                                                        className={classes.subtitle}
                                                    >
                                                        {item.factor_rate ? item.factor_rate : '-'}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        ))}
                                    </Box>
                                ) : (
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        flex="1"
                                        mt={0.5}
                                    >
                                        <Typography
                                            variant="subtitle2"
                                            className={classes.subtitle}
                                        >
                                            No Payment Due Today
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Box>

                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-start"
                        className={classes.scheduleContainer}
                        pt={3}
                        mt={4}
                    >
                        <Typography variant="h5" component="p" align="left" mb={3} paragraph>
                            Upcoming Schedules
                        </Typography>
                        {(!schedules || schedules.length === 0) && (
                            <Typography variant="body2" component="p" align="left">
                                No upcoming schedules
                            </Typography>
                        )}

                        <List component="nav" className={classes.root} ref={schedulesRef}>
                            {schedules &&
                                schedules.length > 0 &&
                                schedulesRef?.current?.clientHeight && (
                                    <FixedSizeList
                                        height={384}
                                        width={'100%'}
                                        itemSize={64}
                                        itemCount={schedules.length}
                                        itemData={schedules}
                                        ref={listRef}
                                        onScroll={onScroll}
                                    >
                                        {ScheduleItem}
                                    </FixedSizeList>
                                )}
                        </List>

                        {schedules && schedules.length > 0 && showAll ? (
                            <Button fullWidth onClick={handleMore}>
                                Load more
                            </Button>
                        ) : (
                            <Box height={36}></Box>
                        )}
                    </Box>
                </Box>
            </Body>

            <Toolbar
                action={
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.payButton}
                        onClick={() => navigate('../payment/pay-early')}
                    >
                        PAY EARLY
                    </Button>
                }
            />
        </FeatureLayout>
    );
};
