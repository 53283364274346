import React from 'react';
import { useNavigate } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { IconButton, Box, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { Header, Body, Toolbar } from '@lendica/components';
import { DrawDownContainer } from '../../DrawDownContainer';
import { PaymentBody } from './PaymentBody';
import { usePayEarlyReview } from './hooks/usePayEarlyReview';
import { currencyFormatter } from '@lendica/utils';

const useStyles = makeStyles(theme => ({
    popover: {
        pointerEvents: 'none',
    },
    totalSaved: {
        fontWeight: 600,
        paddingRight: 56,
    },
    button: {
        color: theme.palette.action.active,
    },
    toolbar: {
        backgroundColor: theme.palette.primary.light,
    },
    amountButton: {
        'borderRadius': 20,
        'minWidth': 20,
        'padding': '3px 10px',
        'marginRight': theme.spacing(1),
        'height': 25,

        '& > span > p': {
            fontSize: 13,
        },
    },
    label: {
        'backgroundColor': '#757575',
        'paddingLeft': 15,
        'paddingRight': 15,
        'left': 'calc(-50% - 19px)',
        'borderRadius': 4,

        '& > span': {
            backgroundColor: 'inherit',
        },
        '& > span > span': {
            whiteSpace: 'nowrap',
        },
    },
    number: {
        fontSize: 40,
        fontWeight: 800,
        fontFamily: theme.typography.fontFamily,
    },
    confirmButton: {
        width: '70%',
        height: 42,
        borderRadius: 30,
        boxShadow: 'none',
    },
    dollar: {
        fontWeight: 800,
    },
    withdrawalItemContainer: {
        position: 'relative',
    },
    withdrawalItem: {
        padding: '16px 0',
        paddingLeft: 32,
        position: 'relative',
        left: -32,
        right: 26,
        width: '100%',
        borderTopRightRadius: 12,
        borderBottomRightRadius: 12,
    },
    paid: {
        'backgroundColor': theme.palette.background.green,
        '& span': {
            color: theme.palette.common.white,
            fontSize: '0.875rem',
            fontWeight: theme.typography.fontWeightSemibold,
        },
    },
    pending: {
        'backgroundColor': theme.palette.background.cardLight,
        '& span': {
            color: theme.palette.primary.dark,
            fontSize: '0.875rem',
            fontWeight: theme.typography.fontWeightSemibold,
        },
    },
    tooltip: {
        padding: '4px 8px',
        fontSize: 10,
        backgroundColor: 'rgba(97, 97, 97, 0.9)',
        width: 170,
        color: theme.palette.common.white,
    },
    infoIconButton: {
        'padding': 0,
        'paddingLeft': 6,
        '& svg': {
            fontSize: '1.1rem',
        },
    },
    checkCircleIconButton: {
        'color': theme.palette.background.green,
        'position': 'absolute',
        'right': -56,
        'top': 0,
        'zIndex': 1,
        '& svg': {
            fontSize: '2rem',
        },
    },
}));

export const PayEarlyReview = () => {
    const navigate = useNavigate();
    const classes = useStyles();
    const {
        data: { summary },
        pay,
        selectedWithdrawals,
    } = DrawDownContainer.useContainer();
    const { amount, availableCredit, handleConfirm } = usePayEarlyReview({
        selectedWithdrawals,
        summary,
        pay,
    });

    return (
        <>
            <Header
                title="Pay Early"
                showLogo={false}
                showClose={false}
                leftActions={
                    <IconButton size="small" onClick={() => navigate('../payment/pay-early')}>
                        <ChevronLeftIcon />
                    </IconButton>
                }
                rightActions={
                    <Button
                        onClick={() => navigate('../payment/pay-early')}
                        className={classes.button}
                    >
                        Cancel
                    </Button>
                }
            />
            <Body display="flex" flexDirection="column">
                <Box flexDirection="column" display="flex" p={4}>
                    <Box display="flex" alignItems="center" flexDirection="column">
                        <Typography
                            variant="h5"
                            component={Box}
                            textAlign="center"
                            style={{ marginBottom: 27 }}
                        >
                            Review
                        </Typography>
                        <Typography variant="caption" style={{ marginBottom: 16 }}>
                            {currencyFormatter(availableCredit ?? 0)} credit available after payment
                        </Typography>
                        <Typography
                            variant="caption"
                            color="text.primary"
                            // style={{ marginBottom: 0, paddingLeft: 24 }}
                        >
                            Amount Deducted
                        </Typography>
                    </Box>

                    <Box display="flex" pr={1} mb={5}>
                        <Box
                            flex="1"
                            display="flex"
                            justifyContent="center"
                            alignItems="flex-start"
                        >
                            <Typography variant="h5" component={Box} className={classes.dollar}>
                                $
                            </Typography>
                            <NumberFormat
                                displayType="text"
                                thousandSeparator={true}
                                value={amount}
                                className={classes.number}
                            />
                        </Box>
                    </Box>

                    <PaymentBody />

                    <Typography variant="caption">
                        *By clicking ‘PAY NOW’ you agree to all terms and conditions of DrawDown
                        product.
                    </Typography>
                </Box>
            </Body>
            <Box display="flex" flexDirection="column">
                <Toolbar
                    title="Bank Account"
                    value="Capital One ****1234"
                    action={
                        <IconButton size="small" onClick={() => navigate('..')}>
                            <ChevronRightIcon />
                        </IconButton>
                    }
                    className={classes.toolbar}
                />
                <Toolbar
                    title="Total"
                    value={currencyFormatter(amount ?? 0)}
                    action={
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.confirmButton}
                            onClick={handleConfirm}
                        >
                            Confirm
                        </Button>
                    }
                />
            </Box>
        </>
    );
};
