import { FC } from 'react';
// import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
// import CallMadeIcon from '@mui/icons-material/CallMade';
import { useWalletDataContext } from '../WalletData';
import { useWalletElementContext } from '../views/WalletView';
import { currencyFormatter } from '@lendica/utils';
import { UsageChart, PayLaterLogo, FundNowLogo, DrawDownLogo } from '@lendica/components';
import { keyframes } from '@emotion/react';
import ProgressCard from './ProgressCard';

const gradient = keyframes`
	0% {
		background-position: 5% 5%;
	}
	12.5% {
		background-position: 50% 0%;
	}
	25% {
		background-position: 95% 5%;
	}
    37.5% {
		background-position: 100% 50%;
	}
    50% {
        background-position: 95% 95%;
    }
    62.5% {
        background-position: 50% 100%;
    }
    75% {
        background-position: 5% 95%;
    }
    87.5% {
        background-position: 0% 50%;
    }
    100% {
		background-position: 5% 5%;
	}
`;

export const MyCard: FC = () => {
    const { data } = useWalletDataContext();
    const { walletRef } = useWalletElementContext();
    // const navigate = useNavigate();

    const applicationStatus = data?.company?.status;
    // const productStatus =
    //     data?.company?.fundnow_status !== 2 &&
    //     data?.company?.paylater_status !== 2 &&
    //     data?.company?.drawdown_status !== 2;

    const paylaterApproval = data?.approvals?.find(
        approval => approval.product_name === 'paylater' && approval.active
    );
    const drawdownApproval = data?.approvals?.find(
        approval => approval.product_name == 'drawdown' && approval.active
    );
    const fundnowApproval = data?.approvals?.find(
        approval => approval.product_name == 'fundnow' && approval.active
    );

    const paylater_total_payoff_amount = paylaterApproval
        ? paylaterApproval.total_payoff_amount
        : 0;
    const paylater_funds_available = paylaterApproval ? paylaterApproval.funds_available : 0;
    const paylater_qualified_amount = paylaterApproval ? paylaterApproval.qualified_amount : 0;

    const drawdown_total_payoff_amount = drawdownApproval
        ? drawdownApproval.total_payoff_amount
        : 0;
    const drawdown_funds_available = drawdownApproval ? drawdownApproval.funds_available : 0;
    const drawdown_qualified_amount = drawdownApproval ? drawdownApproval.qualified_amount : 0;

    const fundnow_total_payoff_amount = fundnowApproval ? fundnowApproval.total_payoff_amount : 0;
    const fundnow_funds_available = fundnowApproval ? fundnowApproval.funds_available : 0;
    const fundnow_qualified_amount = fundnowApproval ? fundnowApproval.qualified_amount : 0;

    const balanceTotal =
        paylater_total_payoff_amount + drawdown_total_payoff_amount + fundnow_total_payoff_amount;
    const availableCreditTotal =
        paylater_funds_available + drawdown_funds_available + fundnow_funds_available;
    const qualifiedAmountTotal =
        paylater_qualified_amount + drawdown_qualified_amount + fundnow_qualified_amount;

    // console.log('total balance: ', balanceTotal)
    // console.log('available credit: ', availableCreditTotal)
    // console.log('qualified amount: ', qualifiedAmountTotal)
    return (
        <Box pt={2} ref={walletRef}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h5">Wallet</Typography>
                {/* <Button
                    variant="text"
                    color="info"
                    endIcon={<CallMadeIcon />}
                    disabled={applicationStatus != 2 || productStatus}
                    onClick={() => navigate('/wallet/account')}
                    id="portal-wallet-summary-button"
                    data-testid="account-summary-button"
                >
                    Account Summary
                </Button> */}
            </Box>

            <Box
                sx={{
                    mt: 2,
                    p: 4,
                    minHeight: '80px',
                    borderRadius: 3,
                    color: 'primary.contrastText',
                    background:
                        'radial-gradient(ellipse at top, #4a4a68, transparent), radial-gradient(ellipse at left, #ee7752, transparent), radial-gradient(ellipse at bottom, #23d5ab, transparent), radial-gradient(ellipse at right, #23a6d5, transparent)',
                    backgroundSize: '145% 185%',
                    animation: `${gradient} 24s linear infinite`,
                    boxShadow: theme => theme.shadows[1],
                }}
            >
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                >
                    <Typography variant="h5">{data?.company?.company_name ?? ''}</Typography>
                    <Box sx={{ m: -1, width: '116px', height: '116px' }}>
                        <UsageChart
                            total={qualifiedAmountTotal}
                            available={availableCreditTotal}
                            color="#FCFCFDE6"
                            bgColor="#3B3B53E6"
                        />
                    </Box>
                </Box>

                <Box mt={4} display="flex" flexDirection="row" justifyContent="space-between">
                    <Box>
                        <Typography variant="body2">Balance</Typography>
                        <Typography variant="h6" fontWeight={700} data-testid="total-balance">
                            {applicationStatus === 1 ? 'Pending' : currencyFormatter(balanceTotal)}
                        </Typography>
                    </Box>
                    <Box display="flex" flexDirection="column" alignItems="flex-end">
                        <Typography variant="body2">Credit Available</Typography>
                        <Typography variant="h6" fontWeight={700} data-testid="credit-available">
                            {applicationStatus === 1
                                ? 'Pending'
                                : currencyFormatter(availableCreditTotal)}
                        </Typography>
                        <Typography variant="caption" color={'#FFF'} data-testid="credit-line">
                            of {currencyFormatter(qualifiedAmountTotal, true)} credit line
                        </Typography>
                    </Box>
                </Box>
            </Box>

            <Box mt={2.5}>
                {paylaterApproval && (
                    <ProgressCard
                        product={<PayLaterLogo height={20} />}
                        total_payoff_amount={paylater_total_payoff_amount}
                        funds_available={paylater_funds_available}
                        qualified_amount={paylater_qualified_amount}
                    />
                )}

                {drawdownApproval && (
                    <ProgressCard
                        product={<DrawDownLogo height={16} />}
                        total_payoff_amount={drawdown_total_payoff_amount}
                        funds_available={drawdown_funds_available}
                        qualified_amount={drawdown_qualified_amount}
                    />
                )}

                {fundnowApproval && (
                    <ProgressCard
                        product={<FundNowLogo height={17} />}
                        total_payoff_amount={fundnow_total_payoff_amount}
                        funds_available={fundnow_funds_available}
                        qualified_amount={fundnow_qualified_amount}
                    />
                )}
            </Box>
        </Box>
    );
};
