import { useState } from 'react';
import { SelectOption } from '@mui/base';
import { AllFiltersProps, FilterItem } from '../components/AllFilters';

export const useAllFilters = ({ initialValue }: Omit<AllFiltersProps, 'filters' | 'onChange'>) => {
    const [filter, setFilter] = useState(initialValue);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();

    const handleClose = () => {
        setAnchorEl(undefined);
    };

    const handleSelectFilter = (filterItem: FilterItem, item: SelectOption<string>) => (value: boolean) => {
        const currentValues = (filter[filterItem.key] ?? []) as string[];
        if (value) {
            setFilter({
                ...filter,
                [filterItem.key]: [...currentValues, item.value],
            });
            return;
        }
        setFilter({
            ...filter,
            [filterItem.key]: currentValues.filter((name: string) => name !== item.value),
        });
    }

    const handleToggleChip = (filterItem: FilterItem) => (value: boolean) => {
        setFilter({ ...filter, [filterItem.key]: value });
    }

    return {
        filter,
        setFilter,
        anchorEl,
        setAnchorEl,
        handleClose,
        handleSelectFilter,
        handleToggleChip,
    }
}