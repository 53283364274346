import { useState } from 'react';
import { Card, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useField } from 'formik';
import { Dropzone } from '@lendica/components';

import { useFormContext } from '../FormContext';

const useStyles = makeStyles(theme => ({
    root: {
        border: `0.5px solid ${theme.palette.divider}`,
    },
    accordionDetails: {
        flexDirection: 'column',
    },
}));

export const DropzoneField = ({ field: { field_name, label, helperText } }) => {
    const [field, , helpers] = useField(field_name);
    const classes = useStyles();
    const {
        data: {
            application: { application_id },
        },
    } = useFormContext();

    return (
        <Card className={classes.root} elevation={0}>
            <Accordion id={`dropzone-accordion-appl-${field_name}`}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography color="secondary" variant="subtitle1">
                        {label}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                    <Typography className={classes.disabledText} variant="subtitle2">
                        {helperText}
                    </Typography>
                    <Dropzone
                        value={field.value ?? []}
                        onChange={value => {
                            helpers.setValue(value);
                            helpers.setTouched();
                        }}
                        api={{
                            presignedUrl: '/application/s3/signed_url',
                            getObjectKey(fileName) {
                                return `pod/${application_id}/${fileName}`;
                            },
                            presignedUrlParams: {
                                application_id,
                                field_name,
                            },
                        }}
                    />
                </AccordionDetails>
            </Accordion>
        </Card>
    );
};
