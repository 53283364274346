import { Modal } from 'react-metrc-link';
import { createPortal } from 'react-dom';
import { makeStyles, StylesProvider, jssPreset } from '@mui/styles';
import { useRef, useCallback, useState } from 'react';
import { create } from 'jss';

import logo from './assets/metrcLogo.webp';

import { ModalCard } from './ModalCard';
import { useFormContext } from '../FormContext';

const jss = create({
    ...jssPreset(),
    insertionPoint: document.body,
});

const useStyles = makeStyles(theme => ({
    '@global': {
        '.metrc-modal': { zIndex: theme.zIndex.modal },
    },
}));

const ModalPortal = props => {
    useStyles();

    return createPortal(<Modal {...props} />, document.body);
};

export const MetrcModalCard = props => {
    const promiseHandlers = useRef();

    const [open, setOpen] = useState(false);
    const [screen, setScreen] = useState('privacy');

    const onConnect = useCallback(() => {
        setOpen(true);
        return new Promise((res, rej) => {
            promiseHandlers.current = { res, rej };
        });
    }, []);

    const {
        settings: { baseURL },
    } = useFormContext();

    return (
        <>
            <ModalCard
                {...props}
                Logo={() => <img src={logo} width="75" />}
                showLabel={false}
                connectHandler={onConnect}
            />
            <StylesProvider jss={jss}>
                <ModalPortal
                    open={open}
                    closeModal={() => {
                        setOpen(false);
                        setScreen('privacy');
                        promiseHandlers.current.rej();
                    }}
                    screen={screen}
                    handleSetScreen={screenName => {
                        setScreen(screenName);
                    }}
                    callback={response => {
                        promiseHandlers.current.res(response);
                    }}
                    states={['CO', 'OR', 'CA', 'AK']}
                    backendUrl={`${baseURL}/application/metrc/validate_metrc_key`}
                    // TODO: pass real company name
                    companyName={'Lendica'}
                />
            </StylesProvider>
        </>
    );
};
