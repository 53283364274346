import { FeatureLayout, RoundedButton } from '@lendica/components';
import { Box, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import { SummaryList } from '@lendica/components';
import {
    currencyFormatter,
    dateFormatter,
    percentageFormatter,
    formatProductName,
} from '@lendica/utils';
import { useEffect, useState } from 'react';
import * as api from '../api/index';

export const mapDealToSummary = deal => {
    return [
        {
            label: 'Deal ID',
            value: deal?.id ? deal.id.substring(0, 8) : '',
        },
        {
            label: 'Invoice No.',
            value: deal?.invoice_number ? deal.invoice_number : '',
        },
        {
            label: 'Product',
            value: deal?.product_name ? formatProductName(deal.product_name) : '',
        },
        {
            label: 'Funded Date',
            value: dateFormatter(deal?.funded_date ?? ''),
        },
        {
            label: 'Amount',
            value: currencyFormatter(deal?.gross_funded_amount ?? 0),
        },
        {
            label: 'Status',
            value: deal?.deal_status ?? '',
        },
        {
            label: 'Vendor Name',
            value: deal?.vendor_name ?? '',
        },
        {
            label: 'Origination Fee Amount',
            value: currencyFormatter(deal?.origination_fee_amount ?? 0),
        },
        {
            label: 'Advance Amount',
            value: currencyFormatter(deal?.advance_amount ?? 0),
        },
        {
            label: 'Payment Frequency',
            value: deal?.payment_frequency ?? '',
        },
        {
            label: 'Number of Payments',
            value: deal?.number_of_payments ?? '',
        },
        {
            label: 'Expected Rate',
            value: percentageFormatter(deal?.expected_rate ?? 0),
        },
        {
            label: 'Periodic Payment Amount',
            value: currencyFormatter(deal?.periodic_collection_from_company ?? 0),
        },
        {
            label: 'First Payment Date',
            value: dateFormatter(deal?.first_payment_date ?? ''),
        },
        {
            label: 'Total Payback Amount',
            value: currencyFormatter(deal?.payback_amount ?? 0),
        },
        {
            label: 'Total Collected',
            value: currencyFormatter(deal?.total_collected ?? 0),
        },
        {
            label: 'Total Remaining',
            value: currencyFormatter(deal?.total_remaining ?? 0),
        },
    ];
};

export const DealSummary = () => {
    const [deal, setDeal] = useState(null);
    const navigate = useNavigate();

    const { id } = useParams();

    useEffect(() => {
        const getDeal = async () => {
            const response = await api.getDealDetails(id);
            setDeal(response);
        };
        getDeal();
    }, []);

    return (
        <FeatureLayout loading={!deal}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    boxSizing: 'border-box',
                    px: 6,
                    py: 2,
                }}
            >
                <Box
                    sx={{
                        maxWidth: 600,
                        height: '100%',
                        width: '100%',
                        // bgcolor: 'background.default',
                    }}
                >
                    <SummaryList
                        title={
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography variant="subtitle1">Deal Summary</Typography>
                                {/* <Tooltip title="Download as CSV">
                                <IconButton
                                    color="secondary"
                                    aria-label="download report"
                                    size="small"
                                    onClick={() => convertToCSV(deal)}
                                >
                                    <DownloadIcon />
                                </IconButton>
                            </Tooltip> */}
                                <RoundedButton
                                    size="medium"
                                    startIcon={<ArrowBackIcon />}
                                    onClick={() => navigate('/fundnow')}
                                >
                                    Go Back
                                </RoundedButton>
                            </Box>
                        }
                        data={mapDealToSummary(deal)}
                    />
                </Box>
            </Box>
        </FeatureLayout>
    );
};
