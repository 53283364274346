import { Chip, Menu, MenuItem } from '@mui/material';
import { SelectOption } from '@mui/base';
import { FC, useMemo, useState } from 'react';
import { ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material';

export interface SelectFilterProps<T> {
    label: string;
    options: SelectOption<T>[];
    value?: T[];
    onChange?: (value: T[]) => void;
}

export function SelectFilter<T>({
    label,
    options,
    value,
    onChange,
}: SelectFilterProps<T>): ReturnType<FC> {
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement>();

    const handleClose = () => {
        setAnchorEl(undefined);
    };

    const valueSet = useMemo(() => new Set(value), [value]);

    const hasValue = value?.length !== 0;

    return (
        <>
            <Chip
                label={label}
                variant={hasValue ? 'filled' : 'outlined'}
                color={hasValue ? 'primary' : 'default'}
                sx={
                    hasValue
                        ? {
                            border: '1px solid',
                        }
                        : {}
                }
                deleteIcon={<ArrowDropDownIcon />}
                onDelete={(e) => {
                    setAnchorEl(e.target.parentNode)
                }}
                onClick={e => {
                    setAnchorEl(e.currentTarget);
                }}
            />
            <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
                {options.map((item, index) => (
                    <MenuItem
                        key={index}
                        onClick={() => {
                            if (valueSet.has(item.value)) {
                                onChange?.(value?.filter(v => v !== item.value) ?? []);
                            } else {
                                onChange?.([...(value ?? []), item.value]);
                            }
                        }}
                        selected={valueSet.has(item.value)}
                        sx={valueSet.has(item.value) ? { fontWeight: 'medium' } : {}}
                    >
                        {item.label}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}
