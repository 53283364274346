import { useTheme } from '@mui/material';

export const SquareLogo = () => {
    const theme = useTheme();

    return (
        <svg
            width="75"
            height="19"
            viewBox="0 0 75 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.4342 1.14602e-07H3.09694C2.27558 1.14602e-07 1.48786 0.326284 0.907072 0.907072C0.326284 1.48786 0 2.27558 0 3.09694V15.4342C0 16.2555 0.326284 17.0432 0.907072 17.624C1.48786 18.2048 2.27558 18.5311 3.09694 18.5311H15.4342C15.8409 18.5312 16.2436 18.4511 16.6194 18.2955C16.9951 18.1399 17.3366 17.9117 17.6241 17.6241C17.9117 17.3366 18.1399 16.9951 18.2955 16.6194C18.4511 16.2436 18.5312 15.8409 18.5311 15.4342V3.09694C18.5312 2.69021 18.4512 2.28745 18.2956 1.91166C18.14 1.53587 17.9119 1.19442 17.6243 0.906826C17.3367 0.619226 16.9952 0.391112 16.6194 0.235515C16.2436 0.0799186 15.8409 -0.000110544 15.4342 1.14602e-07ZM15.1621 14.1855C15.1621 14.4446 15.0592 14.6932 14.876 14.8764C14.6927 15.0596 14.4442 15.1625 14.1851 15.1625H4.34809C4.21976 15.1626 4.09267 15.1374 3.97408 15.0883C3.8555 15.0392 3.74774 14.9673 3.65698 14.8765C3.56621 14.7858 3.4942 14.6781 3.44508 14.5595C3.39595 14.4409 3.37067 14.3139 3.37067 14.1855V4.34809C3.37061 4.21972 3.39586 4.0926 3.44496 3.97398C3.49406 3.85537 3.56605 3.7476 3.65683 3.65683C3.7476 3.56605 3.85537 3.49406 3.97398 3.44496C4.0926 3.39586 4.21972 3.37061 4.34809 3.37067H14.1851C14.3134 3.37067 14.4405 3.39595 14.5591 3.44508C14.6777 3.4942 14.7854 3.56621 14.8761 3.65698C14.9668 3.74774 15.0388 3.8555 15.0879 3.97408C15.1369 4.09267 15.1622 4.21976 15.1621 4.34809V14.1855Z"
                fill={theme.palette.text.secondary}
            />
            <path
                d="M7.29934 11.7927C6.98939 11.7927 6.7384 11.54 6.7384 11.2288V7.30227C6.7384 6.99106 6.98939 6.73839 7.29934 6.73839H11.2318C11.5426 6.73839 11.7927 6.99106 11.7927 7.30227V11.2288C11.7927 11.54 11.5426 11.7927 11.2318 11.7927H7.29934Z"
                fill={theme.palette.text.secondary}
            />
            <path
                d="M28.8449 8.42246C28.2553 8.25401 27.75 8.08556 27.3289 7.91711C26.4866 7.58021 26.1497 7.07487 26.1497 6.40107C26.1497 5.1377 27.4131 4.54813 28.6765 4.54813C29.8556 4.54813 30.8663 5.05348 31.6243 5.89572L31.7086 5.97995L32.7192 5.22193L32.635 5.1377C31.7086 3.95856 30.361 3.28476 28.7607 3.28476C27.75 3.28476 26.7393 3.53743 26.0655 4.04278C25.2233 4.71658 24.8021 5.4746 24.8021 6.48529C24.8021 8.75936 26.992 9.34893 28.6765 9.77005C30.4452 10.2754 31.5401 10.6123 31.5401 11.9599C31.5401 13.3075 30.4452 14.1497 28.8449 14.1497C28.0027 14.1497 26.4866 13.8971 25.5602 12.4652L25.4759 12.381L24.4652 13.139V13.2233C25.3917 14.5709 26.9078 15.3289 28.7607 15.3289C31.2032 15.3289 32.8877 13.9813 32.8877 11.8757C32.8877 9.51738 30.6136 8.92781 28.8449 8.42246Z"
                fill={theme.palette.text.secondary}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M40.889 8.00134V6.73797H42.0682V18.5294H40.889V13.8971C40.2152 14.8235 39.2045 15.3289 38.0254 15.3289C35.7513 15.3289 34.2353 13.5602 34.2353 10.9492C34.2353 8.33824 35.8356 6.56952 38.0254 6.56952C39.2045 6.48529 40.2152 7.07487 40.889 8.00134ZM35.4987 10.9492C35.4987 13.3917 36.8462 14.234 38.1938 14.234C39.8783 14.234 40.9733 12.9706 40.9733 10.9492C40.9733 8.92781 39.8783 7.66444 38.1938 7.66444C36.1724 7.66444 35.4987 9.34893 35.4987 10.9492Z"
                fill={theme.palette.text.secondary}
            />
            <path
                d="M49.9011 6.73797V11.3703C49.9011 12.9706 48.8061 14.1497 47.2059 14.1497C45.9425 14.1497 45.2687 13.3917 45.2687 11.7914V6.73797H44.0896V12.1283C44.0896 14.1497 45.1845 15.3289 47.0374 15.3289C48.2166 15.3289 49.143 14.8235 49.8168 13.9813V15.1604H50.996V6.73797H49.9011Z"
                fill={theme.palette.text.secondary}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M53.3543 7.58021C54.1965 6.90642 55.3757 6.48529 56.639 6.48529C58.492 6.48529 59.6711 7.41176 59.6711 9.01203V15.1604H58.492V14.234C57.9024 14.992 57.0602 15.3289 55.881 15.3289C54.0281 15.3289 52.9331 14.3182 52.9331 12.7179C52.9331 10.6123 54.8703 10.3596 55.7126 10.1912C55.881 10.1912 55.9652 10.107 56.1337 10.107C57.3128 9.9385 58.4077 9.77005 58.4077 8.75936C58.4077 7.66444 56.9759 7.58021 56.5548 7.58021C55.7968 7.58021 54.7019 7.83289 53.9438 8.42246V8.59091L53.3543 7.58021ZM54.1123 12.7179C54.1123 14.0655 55.3757 14.234 55.9652 14.234C57.1444 14.234 58.4077 13.5602 58.4077 11.7914V10.6123C57.8182 10.9492 56.9759 11.1176 56.3021 11.2861L55.9652 11.3703C54.7019 11.4545 54.1123 11.7914 54.1123 12.7179Z"
                fill={theme.palette.text.secondary}
            />
            <path
                d="M66.4933 6.90642C66.2406 6.73797 65.7353 6.56952 65.3142 6.56952C64.3877 6.56952 63.4612 7.07487 62.9559 7.83289V6.73797H61.7767V15.1604H62.9559V10.6123C62.9559 8.59091 64.0508 7.74866 65.2299 7.74866C65.5668 7.74866 65.9037 7.83289 66.1564 7.91711L66.2406 8.00134L66.4933 6.90642Z"
                fill={theme.palette.text.secondary}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M66.8302 10.9492C66.8302 8.33823 68.4305 6.48529 70.7888 6.48529C73.0628 6.48529 74.5789 8.08556 74.5789 10.4438V11.1176H68.0936C68.0936 12.9706 69.2727 14.1497 70.9572 14.1497C71.9679 14.1497 72.8102 13.7286 73.3155 13.0548L73.3997 12.9706L74.242 13.7286L74.1577 13.8128C73.5682 14.4866 72.5575 15.3289 70.873 15.3289C68.4305 15.3289 66.8302 13.5602 66.8302 10.9492ZM70.7045 7.66444C69.2727 7.66444 68.262 8.59091 68.0936 10.107H73.3155C73.2313 8.92781 72.4733 7.66444 70.7045 7.66444Z"
                fill={theme.palette.text.secondary}
            />
        </svg>
    );
};
