import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { useCallback, useRef, useState } from 'react';

import { ModalCard } from './ModalCard';
import { useFormContext } from '../FormContext';
import { formAPI } from '../formAPI';
import { OpenPlaidLink } from './OpenPlaidLink';

export const BankModalCard = props => {
    const promiseHandlers = useRef();
    const [token, setToken] = useState();
    const { toggleIsLoading } = useFormContext();
    const onConnect = useCallback(async () => {
        toggleIsLoading();
        try {
            setToken(await formAPI.getPlaidToken());
            return new Promise((res, rej) => {
                promiseHandlers.current = { res, rej };
            });
        } finally {
            toggleIsLoading();
        }
    }, []);

    return (
        <>
            {token && <OpenPlaidLink token={token} promiseHandlersRef={promiseHandlers} />}
            <ModalCard
                {...props}
                Logo={AccountBalanceIcon}
                actionId="card-appl-bank-01"
                connectHandler={onConnect}
            />
        </>
    );
};
