import { Grid, Box, Typography } from '@mui/material';

import { useFormContext } from '../FormContext';

export const Footer = () => {
    const {
        data: {
            application: {
                screen: [screen],
            },
        },
    } = useFormContext();

    return (
        screen.foot_note && (
            <Grid
                container
                justifyContent="flex-end"
                component={Box}
                flex={1}
                alignItems="center"
                flexDirection="column"
            >
                <Grid item component={Box} py={6} sx={{ width: '70%', whiteSpace: 'pre-line' }}>
                    <Typography variant="caption">* {screen.foot_note}</Typography>
                </Grid>
            </Grid>
        )
    );
};
