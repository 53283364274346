import { Box } from '@mui/material';
import { FC, ReactNode, useRef, createContext, useContext, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AccountContainer } from '../AccountContainer';
import { AccountData } from '../AccountData';

const AccountElementContext = createContext<any>(null);
export const useAccountElementContext = () => {
    return useContext(AccountElementContext);
};

export interface AccountViewProps {
    env: string;
    placeholder?: ReactNode;
    callback?: any;
}

export const AccountView: FC<AccountViewProps> = ({ env, placeholder, callback }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const AccountRef = useRef<HTMLDivElement>(null);

    return (
        <AccountData>
            <AccountElementContext.Provider value={{ containerRef, AccountRef }}>
                <AccountContainer env={env} />
                <Routes>
                    <Route path="*" element={<Box></Box>} />
                </Routes>
            </AccountElementContext.Provider>
        </AccountData>
    );
};
