import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    IconButton,
    Button,
    Box,
    Typography,
    Popover,
    Divider,
    List,
    Stack,
    Chip,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Tune, ArrowDropDown } from '@mui/icons-material';
import { FixedSizeList } from 'react-window';
import { DrawDownContainer } from '../../DrawDownContainer';
import { Header, Body, FeatureLayout } from '@lendica/components';
import { ActivityItem } from './ActivityItem';

const useStyles = makeStyles(theme => ({
    root: {
        // width: 'calc(100% - 48px)',
        maxWidth: 900,
        width: '100%',
        minHeight: 200,
        flex: 1,
    },
    outline: {
        border: '1px solid rgba(14, 15, 44, 0.7)',
        width: 32,
        height: 32,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    button: {
        height: 32,
        padding: '0 10px',
        minWidth: 'fit-content',
        marginRight: 12,
        borderRadius: 20,
        textTransform: 'none',
        color: theme.palette.action.active,
    },
    filters: {},
    modal: {
        maxWidth: 352,
        minWidth: 280,
        width: 352,
        zIndex: 2000,
    },
    filterButton: {
        marginRight: 12,
        borderRadius: 20,
        height: 24,
        textTransform: 'none',
        marginBottom: theme.spacing(2),
        padding: '0 9px',
        boxShadow: 'none',
    },
    divider: {
        marginRight: theme.spacing(2),
    },
}));

const Row = props => {
    const activity = props.data[props.index];
    const classes = useStyles();

    return (
        <Box style={props.style}>
            <ActivityItem activity={activity} />
            <Divider variant="inset" component="li" className={classes.divider} />
        </Box>
    );
};

export const Activities = () => {
    const [filtered, setFiltered] = useState([]);
    const [category, setCategory] = useState([]);
    const [withdrawal, setWithdrawal] = useState([]);
    const [status, setStatus] = useState([]);
    const [early, setEarly] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [showMore, setShowMore] = useState(true);

    const open = Boolean(anchorEl);
    const id = open ? 'filter-popover' : undefined;

    const navigate = useNavigate();
    const classes = useStyles();
    const { data } = DrawDownContainer.useContainer();
    const listRef = useRef();
    const divRef = useRef();

    const getWithdrawalIds = activities => {
        return [...new Set(activities.map(item => item.withdrawal_id))];
    };

    useEffect(() => {
        const temp = data.activities
            .filter(d => !(d.activity_type === 1 && d.status === 0))
            .filter(d =>
                category.length === 0
                    ? true
                    : category.find(c => c === JSON.stringify(d.activity_type))
            )
            .filter(d =>
                withdrawal.length === 0 ? true : withdrawal.find(c => c === d.withdrawal_id)
            )
            .filter(d => {
                if (status.length !== 0) {
                    if (!status.includes('In Progress')) return d.status > 1;
                    else if (!status.includes('Complete')) return d.status < 2;
                }
                return true;
            });
        if (!early) temp.reverse();
        setFiltered(temp);
        if (listRef.current) {
            listRef.current.scrollToItem(0);
        }
    }, [data, category, withdrawal, status, early]);

    const handleMore = () => {
        const topIndex = (listRef.current.state.scrollOffset / 64).toFixed();
        if (topIndex < filtered.length - 8) listRef.current.scrollToItem(+topIndex + 8, 'start');
        else listRef.current.scrollToItem(filtered.length - 1, 'start');
    };

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleDelete = event => {
        setAnchorEl(event.currentTarget.parentElement);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClear = () => {
        if (anchorEl?.id === 'all') {
            setCategory([]);
            setWithdrawal([]);
            setStatus([]);
            setEarly(false);
        } else if (anchorEl?.id === 'category') {
            setCategory([]);
        } else if (anchorEl?.id === 'withdrawal') {
            setWithdrawal([]);
        } else if (anchorEl?.id === 'status') {
            setStatus([]);
        }
    };

    const onScroll = ({ scrollDirection, scrollOffset, scrollUpdateWasRequested }) => {
        const topIndex = scrollOffset / 64;

        if (filtered.length - topIndex < (divRef.current.clientHeight + 30) / 64)
            setShowMore(false);
        else setShowMore(true);
    };

    return (
        <FeatureLayout>
            <Box sx={{ height: '100%', width: '100%', paddingX: 2 }}>
                <Header
                    title="Activities"
                    showLogo={false}
                    showClose={false}
                    leftActions={
                        <IconButton size="small" onClick={() => navigate('..')}>
                            <ChevronLeftIcon />
                        </IconButton>
                    }
                />
                <Box
                    display="flex"
                    alignItems="center"
                    overflow="auto"
                    px={3}
                    className={classes.filters}
                    py={2}
                >
                    <Stack
                        direction="row"
                        alignContent="center"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={1}
                    >
                        <IconButton
                            variant="outlined"
                            color="primary"
                            onClick={handleClick}
                            className={classes.outline}
                            id="all"
                            size="small"
                        >
                            <Tune />
                        </IconButton>
                        <Chip
                            label="Category"
                            deleteIcon={<ArrowDropDown />}
                            onClick={handleClick}
                            onDelete={handleDelete}
                            variant={category.length === 0 ? 'outlined' : 'contained'}
                            color="primary"
                            id="category"
                        />
                        <Chip
                            label="Status"
                            deleteIcon={<ArrowDropDown />}
                            onClick={handleClick}
                            onDelete={handleDelete}
                            variant={status.length === 0 ? 'outlined' : 'contained'}
                            color="primary"
                            id="status"
                        />
                        <Chip
                            label="Withdrawal"
                            deleteIcon={<ArrowDropDown />}
                            onClick={handleClick}
                            onDelete={handleDelete}
                            variant={withdrawal.length === 0 ? 'outlined' : 'contained'}
                            color="primary"
                            id="withdrawal"
                        />
                        <Chip
                            label="Earliest first"
                            onClick={() => setEarly(!early)}
                            variant={!early ? 'outlined' : 'contained'}
                            color="primary"
                        />
                    </Stack>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        classes={{ paper: classes.rounded }}
                    >
                        <Box p={2} className={classes.modal}>
                            {anchorEl?.id === 'all' && (
                                <Typography variant="h6" color="text.primary" mb={2}>
                                    All Filters
                                </Typography>
                            )}
                            <Box>
                                {['all', 'category'].includes(anchorEl?.id) && (
                                    <>
                                        <Typography variant="subtitle2" color="text.primary">
                                            Category
                                        </Typography>
                                        <Box my={2}>
                                            {['0', '1'].map((item, index) => (
                                                <Button
                                                    key={`category-filter-${index}`}
                                                    size="small"
                                                    variant={
                                                        !category.includes(item)
                                                            ? 'outlined'
                                                            : 'contained'
                                                    }
                                                    color="primary"
                                                    className={classes.filterButton}
                                                    onClick={() =>
                                                        setCategory(
                                                            category.includes(item)
                                                                ? category.filter(c => c !== item)
                                                                : [...category, item]
                                                        )
                                                    }
                                                >
                                                    {item === '0' ? 'Withdrawal' : 'Payment'}
                                                </Button>
                                            ))}
                                        </Box>
                                    </>
                                )}
                                {['all', 'withdrawal'].includes(anchorEl?.id) && (
                                    <>
                                        <Typography variant="subtitle2" color="text.primary">
                                            Withdrawal
                                        </Typography>
                                        <Box my={2}>
                                            {getWithdrawalIds(data.activities).map(
                                                (item, index) => (
                                                    <Button
                                                        key={index}
                                                        size="small"
                                                        variant={
                                                            !withdrawal.includes(item)
                                                                ? 'outlined'
                                                                : 'contained'
                                                        }
                                                        color="primary"
                                                        className={classes.filterButton}
                                                        onClick={() =>
                                                            setWithdrawal(
                                                                withdrawal.includes(item)
                                                                    ? withdrawal.filter(
                                                                          c => c !== item
                                                                      )
                                                                    : [...withdrawal, item]
                                                            )
                                                        }
                                                    >
                                                        {`w${item.slice(0, 8)}`}
                                                    </Button>
                                                )
                                            )}
                                        </Box>
                                    </>
                                )}
                                {['all', 'status'].includes(anchorEl?.id) && (
                                    <>
                                        <Typography variant="subtitle2" color="text.primary">
                                            Transaction Status
                                        </Typography>
                                        <Box my={2}>
                                            {['In Progress', 'Complete'].map((item, index) => (
                                                <Button
                                                    key={index}
                                                    size="small"
                                                    variant={
                                                        !status.includes(item)
                                                            ? 'outlined'
                                                            : 'contained'
                                                    }
                                                    color={
                                                        !status.includes(item)
                                                            ? 'primary'
                                                            : 'primary'
                                                    }
                                                    className={classes.filterButton}
                                                    onClick={() =>
                                                        setStatus(
                                                            status.includes(item)
                                                                ? status.filter(c => c !== item)
                                                                : [...status, item]
                                                        )
                                                    }
                                                >
                                                    {item}
                                                </Button>
                                            ))}
                                        </Box>
                                    </>
                                )}
                                {['all'].includes(anchorEl?.id) && (
                                    <>
                                        <Typography variant="subtitle2" color="text.primary">
                                            Sort by date
                                        </Typography>
                                        <Box my={2}>
                                            <Button
                                                size="small"
                                                variant={!early ? 'outlined' : 'contained'}
                                                color={!early ? 'primary' : 'primary'}
                                                onClick={() => setEarly(!early)}
                                                className={classes.filterButton}
                                            >
                                                Earliest first
                                            </Button>
                                        </Box>
                                    </>
                                )}
                            </Box>
                            <Box display="flex" justifyContent="flex-end">
                                <Button color="primary" onClick={handleClear}>
                                    clear
                                </Button>
                                <Button color="primary" onClick={handleClose}>
                                    apply
                                </Button>
                            </Box>
                        </Box>
                    </Popover>
                </Box>
                <Body display="flex" flexDirection="column">
                    <List component="nav" className={classes.root} ref={divRef}>
                        {filtered && divRef?.current?.clientHeight && (
                            <FixedSizeList
                                height={800}
                                width={'100%'}
                                itemSize={64}
                                itemCount={filtered.length}
                                itemData={filtered}
                                ref={listRef}
                                onScroll={onScroll}
                            >
                                {Row}
                            </FixedSizeList>
                        )}
                    </List>
                </Body>
            </Box>
            {/* <Header
                title="Activities"
                showLogo={false}
                showClose={false}
                leftActions={
                    <IconButton size="small" onClick={() => navigate('..')}>
                        <ChevronLeftIcon />
                    </IconButton>
                }
            // rightActions={
            //     <IconButton size="small" onClick={() => navigate('..')}>
            //         <GetApp />
            //     </IconButton>
            // }
            />
            <Box
                display="flex"
                alignItems="center"
                overflow="auto"
                px={3}
                className={classes.filters}
                py={2}
            >
                <Stack
                    direction="row"
                    alignContent="center"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={1}
                >
                    <IconButton
                        variant="outlined"
                        color="primary"
                        onClick={handleClick}
                        className={classes.outline}
                        id="all"
                        size="small"
                    >
                        <Tune />
                    </IconButton>
                    <Chip
                        label="Category"
                        deleteIcon={<ArrowDropDown />}
                        onClick={handleClick}
                        onDelete={handleDelete}
                        variant={category.length === 0 ? 'outlined' : 'contained'}
                        color="primary"
                        id="category"
                    />
                    <Chip
                        label="Status"
                        deleteIcon={<ArrowDropDown />}
                        onClick={handleClick}
                        onDelete={handleDelete}
                        variant={status.length === 0 ? 'outlined' : 'contained'}
                        color="primary"
                        id="status"
                    />
                    <Chip
                        label="Withdrawal"
                        deleteIcon={<ArrowDropDown />}
                        onClick={handleClick}
                        onDelete={handleDelete}
                        variant={withdrawal.length === 0 ? 'outlined' : 'contained'}
                        color="primary"
                        id="withdrawal"
                    />
                    <Chip
                        label="Earliest first"
                        onClick={() => setEarly(!early)}
                        variant={!early ? 'outlined' : 'contained'}
                        color="primary"
                    />
                </Stack>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    classes={{ paper: classes.rounded }}
                >
                    <Box p={2} className={classes.modal}>
                        {anchorEl?.id === 'all' && (
                            <Typography variant="h6" color="text.primary" mb={2}>
                                All Filters
                            </Typography>
                        )}
                        <Box>
                            {['all', 'category'].includes(anchorEl?.id) && (
                                <>
                                    <Typography variant="subtitle2" color="text.primary">
                                        Category
                                    </Typography>
                                    <Box my={2}>
                                        {['0', '1'].map((item, index) => (
                                            <Button
                                                key={`category-filter-${index}`}
                                                size="small"
                                                variant={
                                                    !category.includes(item)
                                                        ? 'outlined'
                                                        : 'contained'
                                                }
                                                color="primary"
                                                className={classes.filterButton}
                                                onClick={() =>
                                                    setCategory(
                                                        category.includes(item)
                                                            ? category.filter(c => c !== item)
                                                            : [...category, item]
                                                    )
                                                }
                                            >
                                                {item === '0' ? 'Withdrawal' : 'Payment'}
                                            </Button>
                                        ))}
                                    </Box>
                                </>
                            )}
                            {['all', 'withdrawal'].includes(anchorEl?.id) && (
                                <>
                                    <Typography variant="subtitle2" color="text.primary">
                                        Withdrawal
                                    </Typography>
                                    <Box my={2}>
                                        {getWithdrawalIds(data.activities).map((item, index) => (
                                            <Button
                                                key={index}
                                                size="small"
                                                variant={
                                                    !withdrawal.includes(item)
                                                        ? 'outlined'
                                                        : 'contained'
                                                }
                                                color="primary"
                                                className={classes.filterButton}
                                                onClick={() =>
                                                    setWithdrawal(
                                                        withdrawal.includes(item)
                                                            ? withdrawal.filter(c => c !== item)
                                                            : [...withdrawal, item]
                                                    )
                                                }
                                            >
                                                {`w${item.slice(0, 8)}`}
                                            </Button>
                                        ))}
                                    </Box>
                                </>
                            )}
                            {['all', 'status'].includes(anchorEl?.id) && (
                                <>
                                    <Typography variant="subtitle2" color="text.primary">
                                        Transaction Status
                                    </Typography>
                                    <Box my={2}>
                                        {['In Progress', 'Complete'].map((item, index) => (
                                            <Button
                                                key={index}
                                                size="small"
                                                variant={
                                                    !status.includes(item)
                                                        ? 'outlined'
                                                        : 'contained'
                                                }
                                                color={
                                                    !status.includes(item) ? 'primary' : 'primary'
                                                }
                                                className={classes.filterButton}
                                                onClick={() =>
                                                    setStatus(
                                                        status.includes(item)
                                                            ? status.filter(c => c !== item)
                                                            : [...status, item]
                                                    )
                                                }
                                            >
                                                {item}
                                            </Button>
                                        ))}
                                    </Box>
                                </>
                            )}
                            {['all'].includes(anchorEl?.id) && (
                                <>
                                    <Typography variant="subtitle2" color="text.primary">
                                        Sort by date
                                    </Typography>
                                    <Box my={2}>
                                        <Button
                                            size="small"
                                            variant={!early ? 'outlined' : 'contained'}
                                            color={!early ? 'primary' : 'primary'}
                                            onClick={() => setEarly(!early)}
                                            className={classes.filterButton}
                                        >
                                            Earliest first
                                        </Button>
                                    </Box>
                                </>
                            )}
                        </Box>
                        <Box display="flex" justifyContent="flex-end">
                            <Button color="primary" onClick={handleClear}>
                                clear
                            </Button>
                            <Button color="primary" onClick={handleClose}>
                                apply
                            </Button>
                        </Box>
                    </Box>
                </Popover>
            </Box>
            <Body display="flex" flexDirection="column">
                <List component="nav" className={classes.root} ref={divRef}>
                    {filtered && divRef?.current?.clientHeight && (
                        <FixedSizeList
                            height={800}
                            width={'100%'}
                            itemSize={64}
                            itemCount={filtered.length}
                            itemData={filtered}
                            ref={listRef}
                            onScroll={onScroll}
                        >
                            {Row}
                        </FixedSizeList>
                    )}
                </List>
            </Body> */}
            {/* {showMore ? (
                <Button fullWidth onClick={handleMore}>
                    Load more
                </Button>
            ) : (
                <Box height={36}></Box>
            )} */}
        </FeatureLayout>
    );
};
