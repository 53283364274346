import {
    Box,
    IconButton,
    Typography,
    Accordion as MuiAccordion,
    AccordionSummary,
    AccordionDetails,
    Link,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';

import { useFormContext } from '../FormContext';

const useStyles = makeStyles(theme => ({
    title: {
        fontWeight: theme.typography.fontWeightBold,
    },
    phone: {
        display: 'flex',
        alignItems: 'center',
    },
    sticky: {
        position: 'sticky',
        top: 0,
        backgroundColor: 'inherit',
        zIndex: theme.zIndex.appBar,
    },
}));

const Accordion = withStyles(() => ({
    root: {
        'borderBottom': '1px solid rgba(0, 0, 0, .125)',
        'boxShadow': 'none',
        'borderRadius': 0,
        '&:last-child': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&.Mui-expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
}))(MuiAccordion);

export const FAQ = () => {
    const classes = useStyles();
    const {
        data: {
            application: { FAQ },
        },
    } = useFormContext();
    const navigate = useNavigate();

    return (
        <Box display="flex" flexDirection="column" flex={1}>
            <Box
                p={2}
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                className={classes.sticky}
            >
                <IconButton
                    onClick={() => {
                        navigate('..');
                    }}
                    size="large"
                >
                    <CloseIcon />
                </IconButton>
                <Box ml={1}>
                    <Typography variant="subtitle1" color="text.primary" className={classes.title}>
                        Help
                    </Typography>
                </Box>
            </Box>
            <Box display="flex" justifyContent="center" mt={6} px={2}>
                <Typography color="text.secondary" variant="h5">
                    FAQ
                </Typography>
            </Box>
            <Box px={2} mt={4}>
                {FAQ &&
                    FAQ.map(item => (
                        <Accordion key={item.faq_id} elevation={0}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography
                                    color="text.secondary"
                                    variant="subtitle2"
                                    className={classes.title}
                                >
                                    {item.question}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography color="text.secondary" variant="subtitle2">
                                    {item.answer}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    ))}
            </Box>
            <Box mt={8} px={2}>
                <Typography variant="subtitle1" color="text.primary" className={classes.title}>
                    Need help? Give us a call
                </Typography>
            </Box>
            <Box mt={1} px={2}>
                <Link
                    href="tel:617-286-2390"
                    variant="body1"
                    color="secondary"
                    className={classes.phone}
                >
                    <PhoneIphoneIcon color="inherit" />
                    (617) 286-2390
                </Link>
            </Box>
            <Box flex={1} display="flex" justifyContent="center" alignItems="flex-end" py={6}>
                <Box display="flex" alignItems="center">
                    <Box mr={1}>
                        <Typography variant="body2" color="text.secondary">
                            Was this page helpful?
                        </Typography>
                    </Box>
                    <IconButton size="large">
                        <ThumbUpIcon />
                    </IconButton>
                    <IconButton size="large">
                        <ThumbDownIcon />
                    </IconButton>
                </Box>
            </Box>
        </Box>
    );
};
