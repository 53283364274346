import { Company } from '@lendica/utils';
import { FC, createContext, useContext, useState, useEffect } from 'react';
import { InitResponse, dataConnectionAPI } from './dataConnectionAPI';

interface DataConnectionContextInterface {
    isLoading: boolean;
    company: Company; // TODO replace with type that accounts for API 2.0 response
    getSummary?: () => Promise<void>;
}

const defaultState = {
    isLoading: true,
    company: {},
};

const DataConnectionDataContext = createContext<DataConnectionContextInterface>(defaultState);

export const useDataConnectionDataContext = () => {
    return useContext(DataConnectionDataContext);
};

export const DataConnectionData: FC = ({ children }) => {
    const [state, setState] = useState(defaultState);

    useEffect(() => {
        (async () => {
            setState(currentState => ({ ...currentState, isLoading: true }));
            try {
                const data = await dataConnectionAPI.init();
                setState(currentState => ({ ...currentState, company: data }));
            } catch {
                setState(currentState => ({ ...currentState, company: {} }));
            } finally {
                setState(currentState => ({ ...currentState, isLoading: false }));
            }
        })();
    }, []);

    if (process.env.NODE_ENV === 'development') {
        useEffect(() => {
            setTimeout(() => {
                localStorage.setItem('dataConnectionState', JSON.stringify(state));
            }, 0);
        }, [state]);
    }

    return (
        <DataConnectionDataContext.Provider
            value={{
                ...state,
            }}
        >
            {children}
        </DataConnectionDataContext.Provider>
    );
};
