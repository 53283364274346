export const stateObject = {
    "isLoading": false,
    "data": {
        "error": [],
        "company": {
            "lendica_token": "c2tzIGNvbWFpbnN0ZW1fdWF0ODkwMzI=",
            "name": "sks co",
            "company_id": "d0585e68-f96e-11ec-8e10-0242c0a8d009",
            "company_email": "efwe@kdsfjsdk.com",
            "activated_drawdown": true
        },
        "approval": {
            "approval_id": "ca8cfd18-18e0-11ed-b701-0242c0a8d006",
            "approval_date": "2022-08-10T19:15:28.903545Z",
            "approval_end_date": "2023-08-10T19:15:28.903553Z",
            "signed": true,
            "approval_amount": 400000,
            "remaining_balance": 288000,
            "total_withdrawn": 235000,
            "total_paid": 136363.86,
            "total_principal_paid": 121695.2,
            "origination_fee_percentage": 0.05,
            "factor_rates": {
                "45": 1.07,
                "90": 1.125,
                "135": 1.17,
                "180": 1.21
            }
        },
        "summary": {
            "credit_available": 288000,
            "credit_line_total": 400000,
            "total_paid": 136363.86,
            "total_principal_paid": 121695.2,
            "total_unpaid": 135520,
            "num_withdrawals": 8,
            "credit_days_remaining": 305
        },
        "live_withdrawals": [
            {
                "withdrawal_id": "013035c8-5f76-48c4-b472-00767ef543a5",
                "withdrawal_name": null,
                "withdrawal_tags": null,
                "withdrawal_date": "2022-09-24T23:33:29.870929Z",
                "withdrawal_amount": 10000,
                "withdrawal_status": 0,
                "withdrawal_net_funded_amount": 9500,
                "withdrawal_total_payment_due": 12100,
                "withdrawal_outstanding_payment": 12100,
                "withdrawal_amount_paid": 0,
                "withdrawal_principal_paid": 0,
                "withdrawal_weekly_payment_amount": 465.38,
                "withdrawal_origination_fee_percentage": 0.05,
                "withdrawal_factor_rate": 1.21,
                "withdrawal_early_payoff_amount": 9769.24,
                "withdrawal_early_payoff_benefit": 1400,
                "withdrawal_early_payoff_factor_rate": 1.07,
                "withdrawal_early_payoff_principal": 10000
            },
            {
                "withdrawal_id": "de9a73a1-728a-4075-b7b6-a98bd4858ba8",
                "withdrawal_name": null,
                "withdrawal_tags": null,
                "withdrawal_date": "2022-09-24T23:44:37.607392Z",
                "withdrawal_amount": 5000,
                "withdrawal_status": 0,
                "withdrawal_net_funded_amount": 4750,
                "withdrawal_total_payment_due": 6050,
                "withdrawal_outstanding_payment": 6050,
                "withdrawal_amount_paid": 0,
                "withdrawal_principal_paid": 0,
                "withdrawal_weekly_payment_amount": 232.69,
                "withdrawal_origination_fee_percentage": 0.05,
                "withdrawal_factor_rate": 1.21,
                "withdrawal_early_payoff_amount": 4884.62,
                "withdrawal_early_payoff_benefit": 700,
                "withdrawal_early_payoff_factor_rate": 1.07,
                "withdrawal_early_payoff_principal": 5000
            },
            {
                "withdrawal_id": "7f0cdcc4-676c-46ab-b192-54243d977e1c",
                "withdrawal_name": null,
                "withdrawal_tags": null,
                "withdrawal_date": "2022-10-04T19:02:02.160494Z",
                "withdrawal_amount": 77000,
                "withdrawal_status": 0,
                "withdrawal_net_funded_amount": 73150,
                "withdrawal_total_payment_due": 93170,
                "withdrawal_outstanding_payment": 93170,
                "withdrawal_amount_paid": 0,
                "withdrawal_principal_paid": 0,
                "withdrawal_weekly_payment_amount": 3583.46,
                "withdrawal_origination_fee_percentage": 0.05,
                "withdrawal_factor_rate": 1.21,
                "withdrawal_early_payoff_amount": 82390,
                "withdrawal_early_payoff_benefit": 10780,
                "withdrawal_early_payoff_factor_rate": 1.07,
                "withdrawal_early_payoff_principal": 77000
            },
            {
                "withdrawal_id": "33d7f9d9-5012-4fd2-a123-5edb22d4b600",
                "withdrawal_name": null,
                "withdrawal_tags": null,
                "withdrawal_date": "2022-10-04T19:26:01.850513Z",
                "withdrawal_amount": 5000,
                "withdrawal_status": 0,
                "withdrawal_net_funded_amount": 4750,
                "withdrawal_total_payment_due": 6050,
                "withdrawal_outstanding_payment": 6050,
                "withdrawal_amount_paid": 0,
                "withdrawal_principal_paid": 0,
                "withdrawal_weekly_payment_amount": 232.69,
                "withdrawal_origination_fee_percentage": 0.05,
                "withdrawal_factor_rate": 1.21,
                "withdrawal_early_payoff_amount": 5350,
                "withdrawal_early_payoff_benefit": 700,
                "withdrawal_early_payoff_factor_rate": 1.07,
                "withdrawal_early_payoff_principal": 5000
            },
            {
                "withdrawal_id": "20251f3b-3195-409b-bb12-ec5c8b2a2f6f",
                "withdrawal_name": null,
                "withdrawal_tags": null,
                "withdrawal_date": "2022-10-04T19:33:00.159214Z",
                "withdrawal_amount": 15000,
                "withdrawal_status": 0,
                "withdrawal_net_funded_amount": 14250,
                "withdrawal_total_payment_due": 18150,
                "withdrawal_outstanding_payment": 18150,
                "withdrawal_amount_paid": 0,
                "withdrawal_principal_paid": 0,
                "withdrawal_weekly_payment_amount": 698.07,
                "withdrawal_origination_fee_percentage": 0.05,
                "withdrawal_factor_rate": 1.21,
                "withdrawal_early_payoff_amount": 16050.01,
                "withdrawal_early_payoff_benefit": 2100,
                "withdrawal_early_payoff_factor_rate": 1.07,
                "withdrawal_early_payoff_principal": 15000
            }
        ],
        "activities": [
            {
                "activity_type": 0,
                "activity_id": "0fc956e4-2a2e-4081-83dc-ad6a46ed568b",
                "activity_date": "2022-08-10T19:15:36.695405Z",
                "activity_amount": 5000,
                "status": 3,
                "net_funded_amount": 4750,
                "total_payment_due": 6050,
                "outstanding_payment": 1146.14,
                "amount_paid": 4203.86,
                "principal_paid": 3695.2,
                "weekly_payment_amount": 0,
                "origination_fee_percentage": 0.05,
                "factor_rate": 1.21,
                "withdrawal_name": null,
                "withdrawal_tags": null,
                "early_payoff_amount": 3488.48,
                "early_payoff_benefit": 700,
                "early_payoff_principal": 5000,
                "withdrawal_id": "0fc956e4-2a2e-4081-83dc-ad6a46ed568b"
            },
            {
                "activity_type": 0,
                "activity_id": "961133dc-a932-4454-a7c4-206f3b52784e",
                "activity_date": "2022-09-23T21:32:01.632731Z",
                "activity_amount": 79000,
                "status": 3,
                "net_funded_amount": 75050,
                "total_payment_due": 95590,
                "outstanding_payment": -3950,
                "amount_paid": 88480,
                "principal_paid": 79000,
                "weekly_payment_amount": 0,
                "origination_fee_percentage": 0.05,
                "factor_rate": 1.21,
                "withdrawal_name": null,
                "withdrawal_tags": null,
                "early_payoff_amount": 77176.94,
                "early_payoff_benefit": 11060,
                "early_payoff_principal": 79000,
                "withdrawal_id": "961133dc-a932-4454-a7c4-206f3b52784e"
            },
            {
                "activity_type": 0,
                "activity_id": "2cbdc814-ceaa-4866-aed2-91a5460b08a3",
                "activity_date": "2022-09-23T21:32:15.572874Z",
                "activity_amount": 39000,
                "status": 3,
                "net_funded_amount": 37050,
                "total_payment_due": 47190,
                "outstanding_payment": -1950,
                "amount_paid": 43680,
                "principal_paid": 39000,
                "weekly_payment_amount": 0,
                "origination_fee_percentage": 0.05,
                "factor_rate": 1.21,
                "withdrawal_name": null,
                "withdrawal_tags": null,
                "early_payoff_amount": 38100.02,
                "early_payoff_benefit": 5460,
                "early_payoff_principal": 39000,
                "withdrawal_id": "2cbdc814-ceaa-4866-aed2-91a5460b08a3"
            },
            {
                "activity_type": 0,
                "activity_id": "013035c8-5f76-48c4-b472-00767ef543a5",
                "activity_date": "2022-09-24T23:33:29.870929Z",
                "activity_amount": 10000,
                "status": 0,
                "net_funded_amount": 9500,
                "total_payment_due": 12100,
                "outstanding_payment": 12100,
                "amount_paid": 0,
                "principal_paid": 0,
                "weekly_payment_amount": 465.38,
                "origination_fee_percentage": 0.05,
                "factor_rate": 1.21,
                "withdrawal_name": null,
                "withdrawal_tags": null,
                "early_payoff_amount": 9769.24,
                "early_payoff_benefit": 1400,
                "early_payoff_principal": 10000,
                "withdrawal_id": "013035c8-5f76-48c4-b472-00767ef543a5"
            },
            {
                "activity_type": 0,
                "activity_id": "de9a73a1-728a-4075-b7b6-a98bd4858ba8",
                "activity_date": "2022-09-24T23:44:37.607392Z",
                "activity_amount": 5000,
                "status": 0,
                "net_funded_amount": 4750,
                "total_payment_due": 6050,
                "outstanding_payment": 6050,
                "amount_paid": 0,
                "principal_paid": 0,
                "weekly_payment_amount": 232.69,
                "origination_fee_percentage": 0.05,
                "factor_rate": 1.21,
                "withdrawal_name": null,
                "withdrawal_tags": null,
                "early_payoff_amount": 4884.62,
                "early_payoff_benefit": 700,
                "early_payoff_principal": 5000,
                "withdrawal_id": "de9a73a1-728a-4075-b7b6-a98bd4858ba8"
            },
            {
                "activity_type": 0,
                "activity_id": "7f0cdcc4-676c-46ab-b192-54243d977e1c",
                "activity_date": "2022-10-04T19:02:02.160494Z",
                "activity_amount": 77000,
                "status": 0,
                "net_funded_amount": 73150,
                "total_payment_due": 93170,
                "outstanding_payment": 93170,
                "amount_paid": 0,
                "principal_paid": 0,
                "weekly_payment_amount": 3583.46,
                "origination_fee_percentage": 0.05,
                "factor_rate": 1.21,
                "withdrawal_name": null,
                "withdrawal_tags": null,
                "early_payoff_amount": 82390,
                "early_payoff_benefit": 10780,
                "early_payoff_principal": 77000,
                "withdrawal_id": "7f0cdcc4-676c-46ab-b192-54243d977e1c"
            },
            {
                "activity_type": 0,
                "activity_id": "33d7f9d9-5012-4fd2-a123-5edb22d4b600",
                "activity_date": "2022-10-04T19:26:01.850513Z",
                "activity_amount": 5000,
                "status": 0,
                "net_funded_amount": 4750,
                "total_payment_due": 6050,
                "outstanding_payment": 6050,
                "amount_paid": 0,
                "principal_paid": 0,
                "weekly_payment_amount": 232.69,
                "origination_fee_percentage": 0.05,
                "factor_rate": 1.21,
                "withdrawal_name": null,
                "withdrawal_tags": null,
                "early_payoff_amount": 5350,
                "early_payoff_benefit": 700,
                "early_payoff_principal": 5000,
                "withdrawal_id": "33d7f9d9-5012-4fd2-a123-5edb22d4b600"
            },
            {
                "activity_type": 0,
                "activity_id": "20251f3b-3195-409b-bb12-ec5c8b2a2f6f",
                "activity_date": "2022-10-04T19:33:00.159214Z",
                "activity_amount": 15000,
                "status": 0,
                "net_funded_amount": 14250,
                "total_payment_due": 18150,
                "outstanding_payment": 18150,
                "amount_paid": 0,
                "principal_paid": 0,
                "weekly_payment_amount": 698.07,
                "origination_fee_percentage": 0.05,
                "factor_rate": 1.21,
                "withdrawal_name": null,
                "withdrawal_tags": null,
                "early_payoff_amount": 16050.01,
                "early_payoff_benefit": 2100,
                "early_payoff_principal": 15000,
                "withdrawal_id": "20251f3b-3195-409b-bb12-ec5c8b2a2f6f"
            },
            {
                "activity_type": 1,
                "activity_id": "c5442acc-2f4e-475e-95b6-531940897a60",
                "activity_date": "2022-08-10T00:00:00",
                "activity_amount": 250,
                "status": 2,
                "withdrawal_id": "0fc956e4-2a2e-4081-83dc-ad6a46ed568b",
                "payment_type": "origination_fee",
                "principal_payment": 0,
                "factor_rate": 1.21
            },
            {
                "activity_type": 1,
                "activity_id": "00d3ac73-20d5-4242-85bc-791e8a4fe830",
                "activity_date": "2022-08-17T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "0fc956e4-2a2e-4081-83dc-ad6a46ed568b",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "d0bb4a64-a57e-4124-b635-68618396dc0b",
                "activity_date": "2022-08-24T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "0fc956e4-2a2e-4081-83dc-ad6a46ed568b",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "6945c6c3-8741-4e6d-bda9-351bb0206aec",
                "activity_date": "2022-08-31T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "0fc956e4-2a2e-4081-83dc-ad6a46ed568b",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "c92cdeee-37eb-4e5e-b00c-8aa1fa7c888a",
                "activity_date": "2022-09-07T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "0fc956e4-2a2e-4081-83dc-ad6a46ed568b",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "25dd39c8-9ba9-4e54-abe6-3a8e0f20a772",
                "activity_date": "2022-09-14T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "0fc956e4-2a2e-4081-83dc-ad6a46ed568b",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "c77d5cda-a52e-4d1e-bd6b-9b594d789770",
                "activity_date": "2022-09-21T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "0fc956e4-2a2e-4081-83dc-ad6a46ed568b",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "653f7ba5-57f8-457f-8860-2f88237cc85b",
                "activity_date": "2022-09-23T00:00:00",
                "activity_amount": 3950,
                "status": 2,
                "withdrawal_id": "961133dc-a932-4454-a7c4-206f3b52784e",
                "payment_type": "origination_fee",
                "principal_payment": 0,
                "factor_rate": 1.21
            },
            {
                "activity_type": 1,
                "activity_id": "d445974e-40af-43a0-ba3f-25153908d883",
                "activity_date": "2022-09-23T00:00:00",
                "activity_amount": 1950,
                "status": 2,
                "withdrawal_id": "2cbdc814-ceaa-4866-aed2-91a5460b08a3",
                "payment_type": "origination_fee",
                "principal_payment": 0,
                "factor_rate": 1.21
            },
            {
                "activity_type": 1,
                "activity_id": "f4abbff5-dd7e-4ad8-9004-69cae0f9fd41",
                "activity_date": "2022-09-24T00:00:00",
                "activity_amount": 250,
                "status": 2,
                "withdrawal_id": "de9a73a1-728a-4075-b7b6-a98bd4858ba8",
                "payment_type": "origination_fee",
                "principal_payment": 0,
                "factor_rate": 1.21
            },
            {
                "activity_type": 1,
                "activity_id": "94d95e75-ddca-404b-8f14-561ae1cb5e99",
                "activity_date": "2022-09-24T00:00:00",
                "activity_amount": 3953.86,
                "status": 2,
                "withdrawal_id": "0fc956e4-2a2e-4081-83dc-ad6a46ed568b",
                "payment_type": "early",
                "principal_payment": 3695.2,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "b6c14c0d-cac5-40cd-b353-77d58ea3b8e2",
                "activity_date": "2022-09-24T00:00:00",
                "activity_amount": 84530,
                "status": 2,
                "withdrawal_id": "961133dc-a932-4454-a7c4-206f3b52784e",
                "payment_type": "early",
                "principal_payment": 79000,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "f0d87b09-057e-4fb7-89ab-2fd98ef3deba",
                "activity_date": "2022-09-24T00:00:00",
                "activity_amount": 41730,
                "status": 2,
                "withdrawal_id": "2cbdc814-ceaa-4866-aed2-91a5460b08a3",
                "payment_type": "early",
                "principal_payment": 39000,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "2288b26b-7921-4878-9675-5ef58552ed9a",
                "activity_date": "2022-09-24T00:00:00",
                "activity_amount": 500,
                "status": 2,
                "withdrawal_id": "013035c8-5f76-48c4-b472-00767ef543a5",
                "payment_type": "origination_fee",
                "principal_payment": 0,
                "factor_rate": 1.21
            },
            {
                "activity_type": 1,
                "activity_id": "05d980be-b42f-4e0e-8a1b-39ff189e6dfc",
                "activity_date": "2022-10-01T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "de9a73a1-728a-4075-b7b6-a98bd4858ba8",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "e6e38cd6-7dbc-4270-a4ab-d552f1a3271c",
                "activity_date": "2022-10-01T00:00:00",
                "activity_amount": 465.38,
                "status": 0,
                "withdrawal_id": "013035c8-5f76-48c4-b472-00767ef543a5",
                "payment_type": "periodic",
                "principal_payment": 384.61,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "d4b19b2b-774f-41bf-a51e-0034e9dc9ea3",
                "activity_date": "2022-10-04T00:00:00",
                "activity_amount": 750,
                "status": 2,
                "withdrawal_id": "20251f3b-3195-409b-bb12-ec5c8b2a2f6f",
                "payment_type": "origination_fee",
                "principal_payment": 0,
                "factor_rate": 1.21
            },
            {
                "activity_type": 1,
                "activity_id": "13853feb-fb00-4ac3-9fe5-f7d870b265e0",
                "activity_date": "2022-10-04T00:00:00",
                "activity_amount": 250,
                "status": 2,
                "withdrawal_id": "33d7f9d9-5012-4fd2-a123-5edb22d4b600",
                "payment_type": "origination_fee",
                "principal_payment": 0,
                "factor_rate": 1.21
            },
            {
                "activity_type": 1,
                "activity_id": "043af7fe-9d3e-452f-97ec-1210a8438a95",
                "activity_date": "2022-10-04T00:00:00",
                "activity_amount": 3850,
                "status": 2,
                "withdrawal_id": "7f0cdcc4-676c-46ab-b192-54243d977e1c",
                "payment_type": "origination_fee",
                "principal_payment": 0,
                "factor_rate": 1.21
            },
            {
                "activity_type": 1,
                "activity_id": "4ed64f4e-1a7a-4a43-8393-ddecbd749544",
                "activity_date": "2022-10-08T00:00:00",
                "activity_amount": 465.38,
                "status": 0,
                "withdrawal_id": "013035c8-5f76-48c4-b472-00767ef543a5",
                "payment_type": "periodic",
                "principal_payment": 384.61,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "f786f2a0-a624-4f7e-95fc-24526090043a",
                "activity_date": "2022-10-08T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "de9a73a1-728a-4075-b7b6-a98bd4858ba8",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "64453edd-7300-4d22-a13f-626e0d2f55f9",
                "activity_date": "2022-10-11T00:00:00",
                "activity_amount": 3583.46,
                "status": 0,
                "withdrawal_id": "7f0cdcc4-676c-46ab-b192-54243d977e1c",
                "payment_type": "periodic",
                "principal_payment": 2961.54,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "cd0855ef-6bb9-4212-9de3-9c4ae482565d",
                "activity_date": "2022-10-11T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "33d7f9d9-5012-4fd2-a123-5edb22d4b600",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "42c17faa-edcb-43e3-af2f-5fca9b6302f0",
                "activity_date": "2022-10-11T00:00:00",
                "activity_amount": 698.07,
                "status": 0,
                "withdrawal_id": "20251f3b-3195-409b-bb12-ec5c8b2a2f6f",
                "payment_type": "periodic",
                "principal_payment": 576.92,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "222a01dd-a389-40c8-8c4e-07e217f1fd05",
                "activity_date": "2022-10-15T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "de9a73a1-728a-4075-b7b6-a98bd4858ba8",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "b375a139-86e1-4f67-8ea9-b5d38975b44c",
                "activity_date": "2022-10-15T00:00:00",
                "activity_amount": 465.38,
                "status": 0,
                "withdrawal_id": "013035c8-5f76-48c4-b472-00767ef543a5",
                "payment_type": "periodic",
                "principal_payment": 384.61,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "f2040d25-0813-4ea0-be8e-bfdc07c3bcd4",
                "activity_date": "2022-10-18T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "33d7f9d9-5012-4fd2-a123-5edb22d4b600",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "9e223f39-72a3-4ad6-9d71-631842d8193d",
                "activity_date": "2022-10-18T00:00:00",
                "activity_amount": 698.07,
                "status": 0,
                "withdrawal_id": "20251f3b-3195-409b-bb12-ec5c8b2a2f6f",
                "payment_type": "periodic",
                "principal_payment": 576.92,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "45c597b8-6b88-4cdd-a47e-36a184f6b7a7",
                "activity_date": "2022-10-18T00:00:00",
                "activity_amount": 3583.46,
                "status": 0,
                "withdrawal_id": "7f0cdcc4-676c-46ab-b192-54243d977e1c",
                "payment_type": "periodic",
                "principal_payment": 2961.54,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "556d9e92-c51c-430d-9510-9092f437b044",
                "activity_date": "2022-10-22T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "de9a73a1-728a-4075-b7b6-a98bd4858ba8",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "895f8a6f-e68a-4d70-9e1e-01e9db56c40b",
                "activity_date": "2022-10-22T00:00:00",
                "activity_amount": 465.38,
                "status": 0,
                "withdrawal_id": "013035c8-5f76-48c4-b472-00767ef543a5",
                "payment_type": "periodic",
                "principal_payment": 384.61,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "5a975d8b-09d9-4571-a799-0a5d8004f101",
                "activity_date": "2022-10-25T00:00:00",
                "activity_amount": 3583.46,
                "status": 0,
                "withdrawal_id": "7f0cdcc4-676c-46ab-b192-54243d977e1c",
                "payment_type": "periodic",
                "principal_payment": 2961.54,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "451a1537-05de-4ac4-bdfa-f29d694cb51b",
                "activity_date": "2022-10-25T00:00:00",
                "activity_amount": 698.07,
                "status": 0,
                "withdrawal_id": "20251f3b-3195-409b-bb12-ec5c8b2a2f6f",
                "payment_type": "periodic",
                "principal_payment": 576.92,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "c50ea12d-7414-4eea-84e8-b38970c53288",
                "activity_date": "2022-10-25T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "33d7f9d9-5012-4fd2-a123-5edb22d4b600",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "2948dfd8-3f7a-41e1-a85e-d257be91cad1",
                "activity_date": "2022-10-29T00:00:00",
                "activity_amount": 465.38,
                "status": 0,
                "withdrawal_id": "013035c8-5f76-48c4-b472-00767ef543a5",
                "payment_type": "periodic",
                "principal_payment": 384.61,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "6cf1f7cc-8732-4e7f-9667-2c60fe44d4e4",
                "activity_date": "2022-10-29T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "de9a73a1-728a-4075-b7b6-a98bd4858ba8",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "8656c3cc-5a57-4c6e-b807-3f47e7157529",
                "activity_date": "2022-11-01T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "33d7f9d9-5012-4fd2-a123-5edb22d4b600",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "071e0fe2-3494-427e-ba29-4877c9eaef59",
                "activity_date": "2022-11-01T00:00:00",
                "activity_amount": 3583.46,
                "status": 0,
                "withdrawal_id": "7f0cdcc4-676c-46ab-b192-54243d977e1c",
                "payment_type": "periodic",
                "principal_payment": 2961.54,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "c81d29a3-ebe0-4ef8-9a24-ad6fadfab3ce",
                "activity_date": "2022-11-01T00:00:00",
                "activity_amount": 698.07,
                "status": 0,
                "withdrawal_id": "20251f3b-3195-409b-bb12-ec5c8b2a2f6f",
                "payment_type": "periodic",
                "principal_payment": 576.92,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "13d6fcf6-0c6d-4e04-a24b-4a2bfa80218f",
                "activity_date": "2022-11-05T00:00:00",
                "activity_amount": 465.38,
                "status": 0,
                "withdrawal_id": "013035c8-5f76-48c4-b472-00767ef543a5",
                "payment_type": "periodic",
                "principal_payment": 384.61,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "e40c1ebf-0a93-4d74-a646-3a6c12d7e248",
                "activity_date": "2022-11-05T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "de9a73a1-728a-4075-b7b6-a98bd4858ba8",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "3e9aae41-ff2b-436c-8f46-8ed19ee75d2c",
                "activity_date": "2022-11-08T00:00:00",
                "activity_amount": 3583.46,
                "status": 0,
                "withdrawal_id": "7f0cdcc4-676c-46ab-b192-54243d977e1c",
                "payment_type": "periodic",
                "principal_payment": 2961.54,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "97e1b8bb-ac39-4c85-927a-ce8cb3b1fa08",
                "activity_date": "2022-11-08T00:00:00",
                "activity_amount": 698.07,
                "status": 0,
                "withdrawal_id": "20251f3b-3195-409b-bb12-ec5c8b2a2f6f",
                "payment_type": "periodic",
                "principal_payment": 576.92,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "de67134d-5e5a-4b62-ac41-c6d56f2e3b3c",
                "activity_date": "2022-11-08T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "33d7f9d9-5012-4fd2-a123-5edb22d4b600",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "eab0ffc9-0a5c-409e-a4a4-81dd2a3d00ce",
                "activity_date": "2022-11-12T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "de9a73a1-728a-4075-b7b6-a98bd4858ba8",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "6ada1e52-29ed-4722-b62d-fe7b17b8d563",
                "activity_date": "2022-11-12T00:00:00",
                "activity_amount": 465.38,
                "status": 0,
                "withdrawal_id": "013035c8-5f76-48c4-b472-00767ef543a5",
                "payment_type": "periodic",
                "principal_payment": 384.61,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "d0e12879-0d19-4860-902c-3620a468d54e",
                "activity_date": "2022-11-15T00:00:00",
                "activity_amount": 698.07,
                "status": 0,
                "withdrawal_id": "20251f3b-3195-409b-bb12-ec5c8b2a2f6f",
                "payment_type": "periodic",
                "principal_payment": 576.92,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "ad57ff5d-d9cf-45d2-8402-54c64d3dad3e",
                "activity_date": "2022-11-15T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "33d7f9d9-5012-4fd2-a123-5edb22d4b600",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "ddc80259-f4f3-46ab-b746-0b64b781d2d2",
                "activity_date": "2022-11-15T00:00:00",
                "activity_amount": 3583.46,
                "status": 0,
                "withdrawal_id": "7f0cdcc4-676c-46ab-b192-54243d977e1c",
                "payment_type": "periodic",
                "principal_payment": 2961.54,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "3257ae18-37dc-4695-b79c-a26924416536",
                "activity_date": "2022-11-19T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "de9a73a1-728a-4075-b7b6-a98bd4858ba8",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "0db8997b-3e55-45c5-9f65-9b0dc41741fb",
                "activity_date": "2022-11-19T00:00:00",
                "activity_amount": 465.38,
                "status": 0,
                "withdrawal_id": "013035c8-5f76-48c4-b472-00767ef543a5",
                "payment_type": "periodic",
                "principal_payment": 384.61,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "f449980a-f84e-4e9d-bb58-cf6b045b8748",
                "activity_date": "2022-11-22T00:00:00",
                "activity_amount": 698.07,
                "status": 0,
                "withdrawal_id": "20251f3b-3195-409b-bb12-ec5c8b2a2f6f",
                "payment_type": "periodic",
                "principal_payment": 576.92,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "3bd01c01-2751-40f8-a7b9-fef3b3835507",
                "activity_date": "2022-11-22T00:00:00",
                "activity_amount": 3583.46,
                "status": 0,
                "withdrawal_id": "7f0cdcc4-676c-46ab-b192-54243d977e1c",
                "payment_type": "periodic",
                "principal_payment": 2961.54,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "aeabe61c-5996-4d8c-a0bd-843809487ebd",
                "activity_date": "2022-11-22T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "33d7f9d9-5012-4fd2-a123-5edb22d4b600",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "f7d964a7-5fd3-4f48-bbc5-729578231297",
                "activity_date": "2022-11-26T00:00:00",
                "activity_amount": 465.38,
                "status": 0,
                "withdrawal_id": "013035c8-5f76-48c4-b472-00767ef543a5",
                "payment_type": "periodic",
                "principal_payment": 384.61,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "eabefffb-2b3c-4579-ba7c-37649f79a7c4",
                "activity_date": "2022-11-26T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "de9a73a1-728a-4075-b7b6-a98bd4858ba8",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "4bb00661-3b27-4fdf-8719-a4ef0d6c8ab9",
                "activity_date": "2022-11-29T00:00:00",
                "activity_amount": 698.07,
                "status": 0,
                "withdrawal_id": "20251f3b-3195-409b-bb12-ec5c8b2a2f6f",
                "payment_type": "periodic",
                "principal_payment": 576.92,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "1c5e89ee-cb59-49b5-8b69-8e10ba488e10",
                "activity_date": "2022-11-29T00:00:00",
                "activity_amount": 3583.46,
                "status": 0,
                "withdrawal_id": "7f0cdcc4-676c-46ab-b192-54243d977e1c",
                "payment_type": "periodic",
                "principal_payment": 2961.54,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "d6f2338f-9434-422f-b159-9f0ca401ad2e",
                "activity_date": "2022-11-29T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "33d7f9d9-5012-4fd2-a123-5edb22d4b600",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "a5104d3c-b430-4a30-a551-edebf2795d12",
                "activity_date": "2022-12-03T00:00:00",
                "activity_amount": 465.38,
                "status": 0,
                "withdrawal_id": "013035c8-5f76-48c4-b472-00767ef543a5",
                "payment_type": "periodic",
                "principal_payment": 384.61,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "10ab06ea-5a31-4dd6-857e-a6fee457fa55",
                "activity_date": "2022-12-03T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "de9a73a1-728a-4075-b7b6-a98bd4858ba8",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "e930338e-5a81-4c8a-a0ca-99e7392408a5",
                "activity_date": "2022-12-06T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "33d7f9d9-5012-4fd2-a123-5edb22d4b600",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "eefbd5ce-bb5f-4c8f-a693-dede94508e1a",
                "activity_date": "2022-12-06T00:00:00",
                "activity_amount": 698.07,
                "status": 0,
                "withdrawal_id": "20251f3b-3195-409b-bb12-ec5c8b2a2f6f",
                "payment_type": "periodic",
                "principal_payment": 576.92,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "b42b73c0-9822-4faf-90c3-1acb0cb876dd",
                "activity_date": "2022-12-06T00:00:00",
                "activity_amount": 3583.46,
                "status": 0,
                "withdrawal_id": "7f0cdcc4-676c-46ab-b192-54243d977e1c",
                "payment_type": "periodic",
                "principal_payment": 2961.54,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "079e3ef9-76d9-4b22-a4de-1531bc5eb684",
                "activity_date": "2022-12-10T00:00:00",
                "activity_amount": 465.38,
                "status": 0,
                "withdrawal_id": "013035c8-5f76-48c4-b472-00767ef543a5",
                "payment_type": "periodic",
                "principal_payment": 384.61,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "46b47ca5-fe9a-403b-a2e8-406ced05c348",
                "activity_date": "2022-12-10T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "de9a73a1-728a-4075-b7b6-a98bd4858ba8",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "0e891511-2150-4bf6-8bd4-dae30b0551d2",
                "activity_date": "2022-12-13T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "33d7f9d9-5012-4fd2-a123-5edb22d4b600",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "f4908b1e-c0ef-436d-916d-9b4fc68028d6",
                "activity_date": "2022-12-13T00:00:00",
                "activity_amount": 698.07,
                "status": 0,
                "withdrawal_id": "20251f3b-3195-409b-bb12-ec5c8b2a2f6f",
                "payment_type": "periodic",
                "principal_payment": 576.92,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "602d36ce-337d-4954-9510-b2b78674717c",
                "activity_date": "2022-12-13T00:00:00",
                "activity_amount": 3583.46,
                "status": 0,
                "withdrawal_id": "7f0cdcc4-676c-46ab-b192-54243d977e1c",
                "payment_type": "periodic",
                "principal_payment": 2961.54,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "f2c916f2-80d7-4d42-bfa0-d12fad2d237e",
                "activity_date": "2022-12-17T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "de9a73a1-728a-4075-b7b6-a98bd4858ba8",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "b10d40a8-2831-44a8-b9b4-29913d6c0ad3",
                "activity_date": "2022-12-17T00:00:00",
                "activity_amount": 465.38,
                "status": 0,
                "withdrawal_id": "013035c8-5f76-48c4-b472-00767ef543a5",
                "payment_type": "periodic",
                "principal_payment": 384.61,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "b349f7f3-b9e0-4f37-bd89-ccf0b0653f2d",
                "activity_date": "2022-12-20T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "33d7f9d9-5012-4fd2-a123-5edb22d4b600",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "1a2b6a1e-1447-4b35-b4dc-40bda38db0c5",
                "activity_date": "2022-12-20T00:00:00",
                "activity_amount": 698.07,
                "status": 0,
                "withdrawal_id": "20251f3b-3195-409b-bb12-ec5c8b2a2f6f",
                "payment_type": "periodic",
                "principal_payment": 576.92,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "3ef0f47b-3380-49ba-9b25-6c7694971e81",
                "activity_date": "2022-12-20T00:00:00",
                "activity_amount": 3583.46,
                "status": 0,
                "withdrawal_id": "7f0cdcc4-676c-46ab-b192-54243d977e1c",
                "payment_type": "periodic",
                "principal_payment": 2961.54,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "084b8afb-2299-4549-a4ae-ef3f8fa92c4d",
                "activity_date": "2022-12-24T00:00:00",
                "activity_amount": 465.38,
                "status": 0,
                "withdrawal_id": "013035c8-5f76-48c4-b472-00767ef543a5",
                "payment_type": "periodic",
                "principal_payment": 384.61,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "e4ab90b9-2093-4106-bdee-225c68c67926",
                "activity_date": "2022-12-24T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "de9a73a1-728a-4075-b7b6-a98bd4858ba8",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "b613e40f-c71f-4a4b-87dd-fa99e9bc710c",
                "activity_date": "2022-12-27T00:00:00",
                "activity_amount": 3583.46,
                "status": 0,
                "withdrawal_id": "7f0cdcc4-676c-46ab-b192-54243d977e1c",
                "payment_type": "periodic",
                "principal_payment": 2961.54,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "8bcd964e-6e3c-482e-9135-af904c8bde2a",
                "activity_date": "2022-12-27T00:00:00",
                "activity_amount": 698.07,
                "status": 0,
                "withdrawal_id": "20251f3b-3195-409b-bb12-ec5c8b2a2f6f",
                "payment_type": "periodic",
                "principal_payment": 576.92,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "1f735597-a186-46db-a761-8b0f42d2276f",
                "activity_date": "2022-12-27T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "33d7f9d9-5012-4fd2-a123-5edb22d4b600",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "4d254c32-8f02-4a41-8054-7397322d8dac",
                "activity_date": "2022-12-31T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "de9a73a1-728a-4075-b7b6-a98bd4858ba8",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.125
            },
            {
                "activity_type": 1,
                "activity_id": "e6fd94a1-1991-4357-aded-90521d654af1",
                "activity_date": "2022-12-31T00:00:00",
                "activity_amount": 465.38,
                "status": 0,
                "withdrawal_id": "013035c8-5f76-48c4-b472-00767ef543a5",
                "payment_type": "periodic",
                "principal_payment": 384.61,
                "factor_rate": 1.125
            },
            {
                "activity_type": 1,
                "activity_id": "9b6f49f6-af8a-49eb-b403-5905b0ee8a40",
                "activity_date": "2023-01-03T00:00:00",
                "activity_amount": 3583.46,
                "status": 0,
                "withdrawal_id": "7f0cdcc4-676c-46ab-b192-54243d977e1c",
                "payment_type": "periodic",
                "principal_payment": 2961.54,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "183a746a-86b9-4fdb-8647-03a2b2233849",
                "activity_date": "2023-01-03T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "33d7f9d9-5012-4fd2-a123-5edb22d4b600",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "012147a0-4bf9-4367-ad79-d36b3d8f4b05",
                "activity_date": "2023-01-03T00:00:00",
                "activity_amount": 698.07,
                "status": 0,
                "withdrawal_id": "20251f3b-3195-409b-bb12-ec5c8b2a2f6f",
                "payment_type": "periodic",
                "principal_payment": 576.92,
                "factor_rate": 1.07
            },
            {
                "activity_type": 1,
                "activity_id": "b420553e-3691-44e6-81b0-abf1d58326f4",
                "activity_date": "2023-01-07T00:00:00",
                "activity_amount": 465.38,
                "status": 0,
                "withdrawal_id": "013035c8-5f76-48c4-b472-00767ef543a5",
                "payment_type": "periodic",
                "principal_payment": 384.61,
                "factor_rate": 1.125
            },
            {
                "activity_type": 1,
                "activity_id": "1a72af4f-44b0-43e3-9727-8fd439297469",
                "activity_date": "2023-01-07T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "de9a73a1-728a-4075-b7b6-a98bd4858ba8",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.125
            },
            {
                "activity_type": 1,
                "activity_id": "8fc5b0d6-33aa-4321-82c2-43b6e0e76ab2",
                "activity_date": "2023-01-10T00:00:00",
                "activity_amount": 698.07,
                "status": 0,
                "withdrawal_id": "20251f3b-3195-409b-bb12-ec5c8b2a2f6f",
                "payment_type": "periodic",
                "principal_payment": 576.92,
                "factor_rate": 1.125
            },
            {
                "activity_type": 1,
                "activity_id": "89f407c9-941d-4f7c-9944-c1fed40ddc0e",
                "activity_date": "2023-01-10T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "33d7f9d9-5012-4fd2-a123-5edb22d4b600",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.125
            },
            {
                "activity_type": 1,
                "activity_id": "b4fee16b-5ff6-4dd7-82c8-8b3ac31babb8",
                "activity_date": "2023-01-10T00:00:00",
                "activity_amount": 3583.46,
                "status": 0,
                "withdrawal_id": "7f0cdcc4-676c-46ab-b192-54243d977e1c",
                "payment_type": "periodic",
                "principal_payment": 2961.54,
                "factor_rate": 1.125
            },
            {
                "activity_type": 1,
                "activity_id": "9b7a0b0a-8bbf-467c-bbca-eceafce347e9",
                "activity_date": "2023-01-14T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "de9a73a1-728a-4075-b7b6-a98bd4858ba8",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.125
            },
            {
                "activity_type": 1,
                "activity_id": "f1798dce-be91-4645-b6fa-bb82a53bd285",
                "activity_date": "2023-01-14T00:00:00",
                "activity_amount": 465.38,
                "status": 0,
                "withdrawal_id": "013035c8-5f76-48c4-b472-00767ef543a5",
                "payment_type": "periodic",
                "principal_payment": 384.61,
                "factor_rate": 1.125
            },
            {
                "activity_type": 1,
                "activity_id": "306eddc6-fb60-4029-8217-ccf2fab168ea",
                "activity_date": "2023-01-17T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "33d7f9d9-5012-4fd2-a123-5edb22d4b600",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.125
            },
            {
                "activity_type": 1,
                "activity_id": "01dc7dde-dd8e-45d1-a0d2-ce463715852a",
                "activity_date": "2023-01-17T00:00:00",
                "activity_amount": 3583.46,
                "status": 0,
                "withdrawal_id": "7f0cdcc4-676c-46ab-b192-54243d977e1c",
                "payment_type": "periodic",
                "principal_payment": 2961.54,
                "factor_rate": 1.125
            },
            {
                "activity_type": 1,
                "activity_id": "2c7f0b91-bc40-4b8f-8911-70632bcb270b",
                "activity_date": "2023-01-17T00:00:00",
                "activity_amount": 698.07,
                "status": 0,
                "withdrawal_id": "20251f3b-3195-409b-bb12-ec5c8b2a2f6f",
                "payment_type": "periodic",
                "principal_payment": 576.92,
                "factor_rate": 1.125
            },
            {
                "activity_type": 1,
                "activity_id": "f4e92064-0c89-4f70-9867-75668c920335",
                "activity_date": "2023-01-21T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "de9a73a1-728a-4075-b7b6-a98bd4858ba8",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.125
            },
            {
                "activity_type": 1,
                "activity_id": "62832ee8-0a9c-4aaf-88d0-db0bceb5d4d9",
                "activity_date": "2023-01-21T00:00:00",
                "activity_amount": 465.38,
                "status": 0,
                "withdrawal_id": "013035c8-5f76-48c4-b472-00767ef543a5",
                "payment_type": "periodic",
                "principal_payment": 384.61,
                "factor_rate": 1.125
            },
            {
                "activity_type": 1,
                "activity_id": "ba90ff4e-463f-4ea6-9059-482c20567931",
                "activity_date": "2023-01-24T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "33d7f9d9-5012-4fd2-a123-5edb22d4b600",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.125
            },
            {
                "activity_type": 1,
                "activity_id": "494114cf-9d4e-4673-a6af-ba48ffc9f761",
                "activity_date": "2023-01-24T00:00:00",
                "activity_amount": 698.07,
                "status": 0,
                "withdrawal_id": "20251f3b-3195-409b-bb12-ec5c8b2a2f6f",
                "payment_type": "periodic",
                "principal_payment": 576.92,
                "factor_rate": 1.125
            },
            {
                "activity_type": 1,
                "activity_id": "f3e23001-e211-413c-8d26-065d1b71c208",
                "activity_date": "2023-01-24T00:00:00",
                "activity_amount": 3583.46,
                "status": 0,
                "withdrawal_id": "7f0cdcc4-676c-46ab-b192-54243d977e1c",
                "payment_type": "periodic",
                "principal_payment": 2961.54,
                "factor_rate": 1.125
            },
            {
                "activity_type": 1,
                "activity_id": "32d1b3b2-537e-4338-86e7-01e7bff50cae",
                "activity_date": "2023-01-28T00:00:00",
                "activity_amount": 465.38,
                "status": 0,
                "withdrawal_id": "013035c8-5f76-48c4-b472-00767ef543a5",
                "payment_type": "periodic",
                "principal_payment": 384.61,
                "factor_rate": 1.125
            },
            {
                "activity_type": 1,
                "activity_id": "29e5d971-e470-405d-ab45-9ed16370d983",
                "activity_date": "2023-01-28T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "de9a73a1-728a-4075-b7b6-a98bd4858ba8",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.125
            },
            {
                "activity_type": 1,
                "activity_id": "345cffa7-6a34-458e-bfa4-ea4a66df86ac",
                "activity_date": "2023-01-31T00:00:00",
                "activity_amount": 3583.46,
                "status": 0,
                "withdrawal_id": "7f0cdcc4-676c-46ab-b192-54243d977e1c",
                "payment_type": "periodic",
                "principal_payment": 2961.54,
                "factor_rate": 1.125
            },
            {
                "activity_type": 1,
                "activity_id": "12d5078a-fa2e-49b1-b110-30fa9aea9333",
                "activity_date": "2023-01-31T00:00:00",
                "activity_amount": 698.07,
                "status": 0,
                "withdrawal_id": "20251f3b-3195-409b-bb12-ec5c8b2a2f6f",
                "payment_type": "periodic",
                "principal_payment": 576.92,
                "factor_rate": 1.125
            },
            {
                "activity_type": 1,
                "activity_id": "3104b744-909d-4314-b500-3daf10f160af",
                "activity_date": "2023-01-31T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "33d7f9d9-5012-4fd2-a123-5edb22d4b600",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.125
            },
            {
                "activity_type": 1,
                "activity_id": "dfee8549-0978-42ee-8513-136f11e0b997",
                "activity_date": "2023-02-04T00:00:00",
                "activity_amount": 465.38,
                "status": 0,
                "withdrawal_id": "013035c8-5f76-48c4-b472-00767ef543a5",
                "payment_type": "periodic",
                "principal_payment": 384.61,
                "factor_rate": 1.125
            },
            {
                "activity_type": 1,
                "activity_id": "d25c7751-1083-461b-8bc7-c41e2e909935",
                "activity_date": "2023-02-04T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "de9a73a1-728a-4075-b7b6-a98bd4858ba8",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.125
            },
            {
                "activity_type": 1,
                "activity_id": "af1538cd-e1bf-475b-a375-9f892bcc2736",
                "activity_date": "2023-02-07T00:00:00",
                "activity_amount": 698.07,
                "status": 0,
                "withdrawal_id": "20251f3b-3195-409b-bb12-ec5c8b2a2f6f",
                "payment_type": "periodic",
                "principal_payment": 576.92,
                "factor_rate": 1.125
            },
            {
                "activity_type": 1,
                "activity_id": "b622d017-c2a9-42c7-95b1-3bca0c7ed504",
                "activity_date": "2023-02-07T00:00:00",
                "activity_amount": 3583.46,
                "status": 0,
                "withdrawal_id": "7f0cdcc4-676c-46ab-b192-54243d977e1c",
                "payment_type": "periodic",
                "principal_payment": 2961.54,
                "factor_rate": 1.125
            },
            {
                "activity_type": 1,
                "activity_id": "8e27d342-f26d-4dd3-885b-c279a0dae84c",
                "activity_date": "2023-02-07T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "33d7f9d9-5012-4fd2-a123-5edb22d4b600",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.125
            },
            {
                "activity_type": 1,
                "activity_id": "ffcde712-daee-460d-ba72-2c40e3fa3a52",
                "activity_date": "2023-02-11T00:00:00",
                "activity_amount": 465.38,
                "status": 0,
                "withdrawal_id": "013035c8-5f76-48c4-b472-00767ef543a5",
                "payment_type": "periodic",
                "principal_payment": 384.61,
                "factor_rate": 1.125
            },
            {
                "activity_type": 1,
                "activity_id": "fa049c88-5548-48cb-b9bf-68cfa15467e5",
                "activity_date": "2023-02-11T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "de9a73a1-728a-4075-b7b6-a98bd4858ba8",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.125
            },
            {
                "activity_type": 1,
                "activity_id": "72058344-2246-4edd-b601-fccbd0a2b260",
                "activity_date": "2023-02-14T00:00:00",
                "activity_amount": 3583.46,
                "status": 0,
                "withdrawal_id": "7f0cdcc4-676c-46ab-b192-54243d977e1c",
                "payment_type": "periodic",
                "principal_payment": 2961.54,
                "factor_rate": 1.125
            },
            {
                "activity_type": 1,
                "activity_id": "b0840125-5710-4d2a-9e8e-c063a7c6e9ab",
                "activity_date": "2023-02-14T00:00:00",
                "activity_amount": 698.07,
                "status": 0,
                "withdrawal_id": "20251f3b-3195-409b-bb12-ec5c8b2a2f6f",
                "payment_type": "periodic",
                "principal_payment": 576.92,
                "factor_rate": 1.125
            },
            {
                "activity_type": 1,
                "activity_id": "60721bbe-b393-49ca-a61f-4b6f56e5eb30",
                "activity_date": "2023-02-14T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "33d7f9d9-5012-4fd2-a123-5edb22d4b600",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.125
            },
            {
                "activity_type": 1,
                "activity_id": "5f507bab-32fb-40af-a3e5-c13c7973327e",
                "activity_date": "2023-02-18T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "de9a73a1-728a-4075-b7b6-a98bd4858ba8",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.21
            },
            {
                "activity_type": 1,
                "activity_id": "9db810eb-c986-45a0-a552-688c0e5f685d",
                "activity_date": "2023-02-18T00:00:00",
                "activity_amount": 465.38,
                "status": 0,
                "withdrawal_id": "013035c8-5f76-48c4-b472-00767ef543a5",
                "payment_type": "periodic",
                "principal_payment": 384.61,
                "factor_rate": 1.21
            },
            {
                "activity_type": 1,
                "activity_id": "f68db3fc-9664-4c71-af18-7506996cd713",
                "activity_date": "2023-02-21T00:00:00",
                "activity_amount": 698.07,
                "status": 0,
                "withdrawal_id": "20251f3b-3195-409b-bb12-ec5c8b2a2f6f",
                "payment_type": "periodic",
                "principal_payment": 576.92,
                "factor_rate": 1.125
            },
            {
                "activity_type": 1,
                "activity_id": "e29dcd39-454c-4f7d-b632-0fb628a7fc4e",
                "activity_date": "2023-02-21T00:00:00",
                "activity_amount": 3583.46,
                "status": 0,
                "withdrawal_id": "7f0cdcc4-676c-46ab-b192-54243d977e1c",
                "payment_type": "periodic",
                "principal_payment": 2961.54,
                "factor_rate": 1.125
            },
            {
                "activity_type": 1,
                "activity_id": "94176231-01d2-4385-83a4-3dd913a5bee3",
                "activity_date": "2023-02-21T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "33d7f9d9-5012-4fd2-a123-5edb22d4b600",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.125
            },
            {
                "activity_type": 1,
                "activity_id": "2037b9bb-494c-45e5-b6e7-03e289fc3931",
                "activity_date": "2023-02-25T00:00:00",
                "activity_amount": 465.38,
                "status": 0,
                "withdrawal_id": "013035c8-5f76-48c4-b472-00767ef543a5",
                "payment_type": "periodic",
                "principal_payment": 384.61,
                "factor_rate": 1.21
            },
            {
                "activity_type": 1,
                "activity_id": "22febd0e-afc0-41e1-b885-55cbcf129b18",
                "activity_date": "2023-02-25T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "de9a73a1-728a-4075-b7b6-a98bd4858ba8",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.21
            },
            {
                "activity_type": 1,
                "activity_id": "0d64d608-53dc-41fc-9fd4-d781cbfdf112",
                "activity_date": "2023-02-28T00:00:00",
                "activity_amount": 698.07,
                "status": 0,
                "withdrawal_id": "20251f3b-3195-409b-bb12-ec5c8b2a2f6f",
                "payment_type": "periodic",
                "principal_payment": 576.92,
                "factor_rate": 1.21
            },
            {
                "activity_type": 1,
                "activity_id": "f1f2703f-db54-4696-88fb-8a6d56601e2f",
                "activity_date": "2023-02-28T00:00:00",
                "activity_amount": 3583.46,
                "status": 0,
                "withdrawal_id": "7f0cdcc4-676c-46ab-b192-54243d977e1c",
                "payment_type": "periodic",
                "principal_payment": 2961.54,
                "factor_rate": 1.21
            },
            {
                "activity_type": 1,
                "activity_id": "58f69fda-cf68-4352-8c01-3e3f197726c5",
                "activity_date": "2023-02-28T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "33d7f9d9-5012-4fd2-a123-5edb22d4b600",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.21
            },
            {
                "activity_type": 1,
                "activity_id": "f7b672cf-803c-45fc-a17d-23fd426ec479",
                "activity_date": "2023-03-04T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "de9a73a1-728a-4075-b7b6-a98bd4858ba8",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.21
            },
            {
                "activity_type": 1,
                "activity_id": "2a6e5b4b-b224-479a-b5c5-084e4194e9c2",
                "activity_date": "2023-03-04T00:00:00",
                "activity_amount": 465.38,
                "status": 0,
                "withdrawal_id": "013035c8-5f76-48c4-b472-00767ef543a5",
                "payment_type": "periodic",
                "principal_payment": 384.61,
                "factor_rate": 1.21
            },
            {
                "activity_type": 1,
                "activity_id": "cae0aa29-0d3f-4351-b4e8-c2f25ed3df6d",
                "activity_date": "2023-03-07T00:00:00",
                "activity_amount": 3583.46,
                "status": 0,
                "withdrawal_id": "7f0cdcc4-676c-46ab-b192-54243d977e1c",
                "payment_type": "periodic",
                "principal_payment": 2961.54,
                "factor_rate": 1.21
            },
            {
                "activity_type": 1,
                "activity_id": "faf3d09d-c485-4907-ae96-b0e861ca34f9",
                "activity_date": "2023-03-07T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "33d7f9d9-5012-4fd2-a123-5edb22d4b600",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.21
            },
            {
                "activity_type": 1,
                "activity_id": "2faa2c64-b06f-42c4-95ce-080b5170b200",
                "activity_date": "2023-03-07T00:00:00",
                "activity_amount": 698.07,
                "status": 0,
                "withdrawal_id": "20251f3b-3195-409b-bb12-ec5c8b2a2f6f",
                "payment_type": "periodic",
                "principal_payment": 576.92,
                "factor_rate": 1.21
            },
            {
                "activity_type": 1,
                "activity_id": "63ee3d81-77aa-4922-95a6-78c0d37ece18",
                "activity_date": "2023-03-11T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "de9a73a1-728a-4075-b7b6-a98bd4858ba8",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.21
            },
            {
                "activity_type": 1,
                "activity_id": "51fa7f47-a896-4ad5-b5a6-c308de27dbfd",
                "activity_date": "2023-03-11T00:00:00",
                "activity_amount": 465.38,
                "status": 0,
                "withdrawal_id": "013035c8-5f76-48c4-b472-00767ef543a5",
                "payment_type": "periodic",
                "principal_payment": 384.61,
                "factor_rate": 1.21
            },
            {
                "activity_type": 1,
                "activity_id": "ccd835ca-0e32-481b-8a8f-dc47f61f5ed9",
                "activity_date": "2023-03-14T00:00:00",
                "activity_amount": 698.07,
                "status": 0,
                "withdrawal_id": "20251f3b-3195-409b-bb12-ec5c8b2a2f6f",
                "payment_type": "periodic",
                "principal_payment": 576.92,
                "factor_rate": 1.21
            },
            {
                "activity_type": 1,
                "activity_id": "326b1c96-f08d-4cdd-98b7-9972da1a9258",
                "activity_date": "2023-03-14T00:00:00",
                "activity_amount": 3583.46,
                "status": 0,
                "withdrawal_id": "7f0cdcc4-676c-46ab-b192-54243d977e1c",
                "payment_type": "periodic",
                "principal_payment": 2961.54,
                "factor_rate": 1.21
            },
            {
                "activity_type": 1,
                "activity_id": "256a5709-7b16-40dd-88cb-589ead61510e",
                "activity_date": "2023-03-14T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "33d7f9d9-5012-4fd2-a123-5edb22d4b600",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.21
            },
            {
                "activity_type": 1,
                "activity_id": "2401db4d-24aa-477b-a3d9-51eebc914cdc",
                "activity_date": "2023-03-18T00:00:00",
                "activity_amount": 465.38,
                "status": 0,
                "withdrawal_id": "013035c8-5f76-48c4-b472-00767ef543a5",
                "payment_type": "periodic",
                "principal_payment": 384.61,
                "factor_rate": 1.21
            },
            {
                "activity_type": 1,
                "activity_id": "26e915c0-af37-497b-9f51-f7ced4d9d6d9",
                "activity_date": "2023-03-18T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "de9a73a1-728a-4075-b7b6-a98bd4858ba8",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.21
            },
            {
                "activity_type": 1,
                "activity_id": "eba27272-1302-4d1d-9631-a8380b5b326a",
                "activity_date": "2023-03-21T00:00:00",
                "activity_amount": 698.07,
                "status": 0,
                "withdrawal_id": "20251f3b-3195-409b-bb12-ec5c8b2a2f6f",
                "payment_type": "periodic",
                "principal_payment": 576.92,
                "factor_rate": 1.21
            },
            {
                "activity_type": 1,
                "activity_id": "d218178d-76a5-4faf-a552-d6d90bfd2637",
                "activity_date": "2023-03-21T00:00:00",
                "activity_amount": 3583.46,
                "status": 0,
                "withdrawal_id": "7f0cdcc4-676c-46ab-b192-54243d977e1c",
                "payment_type": "periodic",
                "principal_payment": 2961.54,
                "factor_rate": 1.21
            },
            {
                "activity_type": 1,
                "activity_id": "1ffcc3dc-f70f-4689-9e9e-4705107286bc",
                "activity_date": "2023-03-21T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "33d7f9d9-5012-4fd2-a123-5edb22d4b600",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.21
            },
            {
                "activity_type": 1,
                "activity_id": "0ae6e8d4-ced1-485e-afa6-559ad8792393",
                "activity_date": "2023-03-25T00:00:00",
                "activity_amount": 232.75,
                "status": 0,
                "withdrawal_id": "de9a73a1-728a-4075-b7b6-a98bd4858ba8",
                "payment_type": "periodic",
                "principal_payment": 192.36,
                "factor_rate": 1.21
            },
            {
                "activity_type": 1,
                "activity_id": "a8231b89-cb30-4e2a-8917-a6de40793c01",
                "activity_date": "2023-03-25T00:00:00",
                "activity_amount": 465.5,
                "status": 0,
                "withdrawal_id": "013035c8-5f76-48c4-b472-00767ef543a5",
                "payment_type": "periodic",
                "principal_payment": 384.71,
                "factor_rate": 1.21
            },
            {
                "activity_type": 1,
                "activity_id": "7e74b2f2-7eec-401e-a266-a3cfe5be957b",
                "activity_date": "2023-03-28T00:00:00",
                "activity_amount": 3583.46,
                "status": 0,
                "withdrawal_id": "7f0cdcc4-676c-46ab-b192-54243d977e1c",
                "payment_type": "periodic",
                "principal_payment": 2961.54,
                "factor_rate": 1.21
            },
            {
                "activity_type": 1,
                "activity_id": "93199d7f-75c9-46f8-8e7c-0b6a08e2ce1b",
                "activity_date": "2023-03-28T00:00:00",
                "activity_amount": 232.69,
                "status": 0,
                "withdrawal_id": "33d7f9d9-5012-4fd2-a123-5edb22d4b600",
                "payment_type": "periodic",
                "principal_payment": 192.31,
                "factor_rate": 1.21
            },
            {
                "activity_type": 1,
                "activity_id": "e492891f-04d2-4e8e-a7b1-eab9b68db28e",
                "activity_date": "2023-03-28T00:00:00",
                "activity_amount": 698.07,
                "status": 0,
                "withdrawal_id": "20251f3b-3195-409b-bb12-ec5c8b2a2f6f",
                "payment_type": "periodic",
                "principal_payment": 576.92,
                "factor_rate": 1.21
            },
            {
                "activity_type": 1,
                "activity_id": "7f7bd9af-92fb-4858-b7c8-8906114bc546",
                "activity_date": "2023-04-04T00:00:00",
                "activity_amount": 3583.5,
                "status": 0,
                "withdrawal_id": "7f0cdcc4-676c-46ab-b192-54243d977e1c",
                "payment_type": "periodic",
                "principal_payment": 2961.57,
                "factor_rate": 1.21
            },
            {
                "activity_type": 1,
                "activity_id": "74dea4c2-e8e8-4d59-8727-a2c14c40cce3",
                "activity_date": "2023-04-04T00:00:00",
                "activity_amount": 232.75,
                "status": 0,
                "withdrawal_id": "33d7f9d9-5012-4fd2-a123-5edb22d4b600",
                "payment_type": "periodic",
                "principal_payment": 192.36,
                "factor_rate": 1.21
            },
            {
                "activity_type": 1,
                "activity_id": "719b9d40-f4f8-4c65-9758-0787ef875cfa",
                "activity_date": "2023-04-04T00:00:00",
                "activity_amount": 698.25,
                "status": 0,
                "withdrawal_id": "20251f3b-3195-409b-bb12-ec5c8b2a2f6f",
                "payment_type": "periodic",
                "principal_payment": 577.07,
                "factor_rate": 1.21
            }
        ]
    },
    "withdrawalData": {
        "withdrawal_amount": 0,
        "net_funded_amount": 0,
        "total_pmt": 0,
        "factor_rate": 1.21,
        "origination_fee_percentage": 0.05,
        "term_length": 180
    }
}