import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import { VendorForm } from './VendorForm';

interface VendorFormPopup {
    vendor: any;
    open: boolean;
    handleClose: any;
    handleSubmit: any;
}

export default function VendorFormPopup({
    vendor,
    open,
    handleClose,
    handleSubmit,
}: VendorFormPopup) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Dialog open={open} onClose={handleClose} fullScreen={fullScreen}>
            <DialogTitle>{vendor ? 'Edit Vendor' : 'Add a new Vendor'}</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 12,
                    top: 12,
                    color: theme => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <VendorForm vendor={vendor} callback={handleSubmit} handleClose={handleClose} />
            </DialogContent>
        </Dialog>
    );
}
