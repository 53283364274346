import { FC, useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import { ActivityCenter } from '@lendica/activity-center';
import { PayLater } from '@lendica/paylater';
import { DataConnection } from '@lendica/data-connection';
import { Wallet } from '@lendica/wallet';
import { SidebarContainer } from './SidebarContainer';
import { NotFound } from './views/NotFound';
import { NoApproval } from './views/NoApproval';
import { env } from './api/axiosInstance';
import { DrawDown } from '@lendica/drawdown2';
import { Account } from '@lendica/account';
import { Approvals } from '@lendica/approvals';
import { FundNow } from '@lendica/fundnow';
import { Reports } from '@lendica/reports';
import { FileUpload } from '@lendica/file-upload';
import { ErrorBoundary, LayoutPlaceholder } from '@lendica/components';
import { AppData } from './AppData';

const HandleInvalidRoute = () => {
    useEffect(() => {
        throw new Error('Portal landed on an invalid route.');
    }, []);
    return <Navigate to="/not-found" />;
};

export const AppContainer: FC = () => {
    const navigate = useNavigate();
    return (
        <ErrorBoundary fallback={<NotFound />}>
            <AppData>
                <Routes>
                    <Route path="/" element={<Navigate to="/wallet" />} />
                    <Route element={<SidebarContainer />}>
                        <Route
                            path="/account/*"
                            element={
                                <Account
                                    env={env}
                                    placeholder={
                                        <LayoutPlaceholder
                                            title="Hi there!"
                                            subtitle="Your invoice or deal details will appear here."
                                        />
                                    }
                                />
                            }
                        />
                        <Route
                            path="/drawdown/*"
                            element={
                                <DrawDown
                                    placeholder={
                                        <LayoutPlaceholder
                                            title="Hi there!"
                                            subtitle="Your invoice or deal details will appear here."
                                        />
                                    }
                                />
                            }
                        />
                        <Route
                            path="/paylater/*"
                            element={
                                <PayLater
                                    placeholder={
                                        <LayoutPlaceholder
                                            title="Hi there!"
                                            subtitle="Your invoice or deal details will appear here."
                                        />
                                    }
                                />
                            }
                        />
                        <Route path="/fundnow/*" element={<FundNow />} />
                        <Route
                            path="/wallet/*"
                            element={
                                <Wallet
                                    placeholder={
                                        <LayoutPlaceholder
                                            title="Hi there!"
                                            subtitle="Your invoice or deal details will appear here."
                                        />
                                    }
                                />
                            }
                        />
                        <Route
                            path="/my-approvals/*"
                            element={<Approvals onError={() => navigate('/wallet')} />}
                        />
                        <Route path="/reports/*" element={<Reports />} />
                        <Route path="/file-upload/*" element={<FileUpload env={env} />} />
                        <Route path="/activity-center/*" element={<ActivityCenter />} />
                        <Route path="/integrations/*" element={<DataConnection env={env} />} />
                        <Route path="/not-found" element={<NotFound />} />
                        <Route path="/no-approval" element={<NoApproval />} />
                        <Route path="*" element={<HandleInvalidRoute />} />
                    </Route>
                </Routes>
            </AppData>
        </ErrorBoundary>
    );
};
