import { FC } from 'react';
import { Box, Typography } from '@mui/material';

interface NoSchedulesProps {
    subtitle?: string;
}

export const NoSchedules: FC<NoSchedulesProps> = ({ subtitle = '' }) => (
    <Box
        sx={{
            height: '600px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }}
    >
        <Box
            sx={{
                width: '100%',
                height: '60%',
                background: `center / 40% no-repeat url("https://lendicapublic.s3.amazonaws.com/assets/illustrations/noschedule.svg")`,
            }}
        />
        <Typography variant="subtitle2" fontWeight={500} color="#4A4A68" paragraph>
            {subtitle}
        </Typography>
    </Box>
);
