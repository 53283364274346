import { FC, useState } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';

import {
    Box,
    Drawer as MuiDrawer,
    List,
    ListItemButton as MuiListItemButton,
    ListItemText,
    ListItemIcon,
    IconButton,
    Theme,
    Divider,
    ListSubheader,
    styled,
    useMediaQuery,
    useTheme,
    ListItemButtonBaseProps,
} from '@mui/material';

import DateRangeIcon from '@mui/icons-material/DateRange';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import MenuIcon from '@mui/icons-material/Menu';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import DataSaverOnIcon from '@mui/icons-material/DataSaverOn';
import ChatIcon from '@mui/icons-material/Chat';
import LogoutIcon from '@mui/icons-material/Logout';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import ApprovalIcon from '@mui/icons-material/Approval';

import { SystemStyleObject } from '@mui/system';

import { useAccountContext, logout } from '@lendica/auth';

import { Logo, useAsyncError } from '@lendica/components';
import { useAppDataContext } from './AppData';

declare global {
    interface Window {
        HubSpotConversations: any;
    }
}

const ListItemButton = styled(MuiListItemButton)(({ theme, disabled, selected }) => ({
    ...(disabled && {
        '&.Mui-disabled': { opacity: 'initial' },
        '& .MuiListItemIcon-root': {
            opacity: theme.palette.action.disabledOpacity,
        },
        '& .MuiListItemText-root': {
            opacity: theme.palette.action.disabledOpacity,
        },
    }),
    ...(selected && {
        '&.Mui-selected': { color: theme.palette.info.dark },
        '& .MuiListItemText-root .MuiTypography-root': {
            fontWeight: 500,
        },
        '& .MuiListItemIcon-root': { color: theme.palette.info.dark },
    }),
}));

const NavigationListItemButton: FC<{ path: string } & ListItemButtonBaseProps> = ({
    path,
    ...props
}) => {
    const navigate = useNavigate();
    const match = useMatch(path + '/*');

    return <ListItemButton {...props} dense selected={!!match} onClick={() => navigate(path)} />;
};

const drawerWidth = 240; // 304;
const openedMixin = (): SystemStyleObject<Theme> => ({
    width: drawerWidth,
    overflowX: 'hidden',
    pr: 2,
});
const closedMixin = (): SystemStyleObject<Theme> => ({
    overflowX: 'hidden',
    width: 96,
});
export const Sidebar: FC = () => {
    const theme = useTheme();

    const { clearAccounts } = useAccountContext();
    const initialOpen = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
    const [open, setOpen] = useState(initialOpen);
    const { company } = useAppDataContext();

    const navigate = useNavigate();

    const throwError = useAsyncError();

    const handleLogout = async () => {
        try {
            await logout('login');
            await clearAccounts();
        } catch (error: any) {
            console.log(error);
            if (error!.response && error!.response!.status === 401) {
                await clearAccounts();
                navigate('/login');
                return;
            }
            throwError(
                new Error(
                    `Portal error on logout: ${error}. CompanyId: ${company?.id}. CompanyName: ${company?.company_name}.`
                )
            );
        }
    };

    // let paylaterActivated = company?.paylater_status > 0 ? true : false;
    // let drawdownActivated = company?.drawdown_status > 0 ? true : false;
    // let fundnowActivated = company?.fundnow_status > 0 ? true : false;

    return (
        <MuiDrawer
            sx={[
                {
                    'width': drawerWidth,
                    'flexShrink': 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                        backgroundColor: 'background.default',
                        border: 'none',
                    },
                    '& .MuiListItemIcon-root': {
                        my: 0.5,
                        pl: 2,
                    },
                    '& .MuiListItemButton-root': {
                        px: 0,
                    },
                },
                open
                    ? {
                          ...openedMixin(),
                          '& .MuiDrawer-paper': openedMixin(),
                          '& .MuiListItemButton-root': {
                              borderTopRightRadius: 32,
                              borderBottomRightRadius: 32,
                          },
                      }
                    : {
                          ...closedMixin(),
                          '& .MuiDrawer-paper': closedMixin(),
                          '& .MuiListItemButton-root': {
                              flexWrap: 'wrap',
                              justifyContent: 'center',
                          },
                          '& .MuiListItemText-root': {
                              width: '100%',
                          },
                          '& .MuiListItemText-root .MuiTypography-root': {
                              textAlign: 'center',
                              fontSize: 'caption.fontSize',
                          },
                      },
            ]}
            variant="permanent"
            open={open}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: open ? 'space-between' : 'center',
                    alignItems: 'center',
                    pt: 2,
                    pb: 1,
                }}
            >
                {open && (
                    <IconButton
                        size="large"
                        onClick={() => {
                            navigate('/wallet');
                        }}
                        disableFocusRipple
                        disableRipple
                        disableTouchRipple
                    >
                        <Logo height={18} />
                    </IconButton>
                )}

                <IconButton
                    size="medium"
                    onClick={() => {
                        setOpen(!open);
                    }}
                >
                    {open ? <MenuOpenIcon fontSize="inherit" /> : <MenuIcon fontSize="inherit" />}
                </IconButton>
            </Box>
            <List>
                <NavigationListItemButton path="/wallet" data-testid="wallet">
                    <ListItemIcon>
                        <AccountBalanceWalletIcon />
                    </ListItemIcon>
                    <ListItemText primary="Wallet" />
                </NavigationListItemButton>
                <NavigationListItemButton path="/my-approvals" data-testid="my-approvals">
                    <ListItemIcon>
                        <ApprovalIcon />
                    </ListItemIcon>
                    <ListItemText primary="My Approvals" />
                </NavigationListItemButton>
                <NavigationListItemButton path="/reports" data-testid="reports">
                    <ListItemIcon>
                        <AnalyticsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Reports" />
                </NavigationListItemButton>
                <NavigationListItemButton path="/activity-center" data-testid="activity-center">
                    <ListItemIcon>
                        <DateRangeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Activity Center" />
                </NavigationListItemButton>
                {!open && <Divider />}
                {open && (
                    <ListSubheader component="div" sx={{ backgroundColor: 'inherit' }}>
                        Pay
                    </ListSubheader>
                )}
                <NavigationListItemButton path="/paylater" data-testid="paylater">
                    <ListItemIcon>
                        <TimelapseIcon />
                    </ListItemIcon>
                    <ListItemText primary="PayLater" />
                </NavigationListItemButton>

                <NavigationListItemButton path="/drawdown" data-testid="drawdown">
                    <ListItemIcon>
                        <DataSaverOnIcon />
                    </ListItemIcon>
                    <ListItemText primary="DrawDown" />
                </NavigationListItemButton>
                {open && (
                    <ListSubheader component="div" sx={{ backgroundColor: 'inherit' }}>
                        Collect
                    </ListSubheader>
                )}
                <NavigationListItemButton path="/fundnow" data-testid="fundnow">
                    <ListItemIcon>
                        <MonetizationOnIcon />
                    </ListItemIcon>
                    <ListItemText primary="FundNow" />
                </NavigationListItemButton>
                {/* </List> */}
                <Divider />
                <ListItemButton dense onClick={() => window.HubSpotConversations.widget.open()}>
                    <ListItemIcon>
                        <ChatIcon />
                    </ListItemIcon>
                    <ListItemText primary="Chat" />
                </ListItemButton>
                {/* </List> */}
                {/* <Divider /> */}
                {/* <List> */}
                {open && (
                    <ListSubheader component="div" sx={{ backgroundColor: 'inherit' }}>
                        Settings
                    </ListSubheader>
                )}
                <NavigationListItemButton path="/account" data-testid="account">
                    <ListItemIcon>
                        <AdminPanelSettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Account" />
                </NavigationListItemButton>
                <NavigationListItemButton path="/file-upload" data-testid="files">
                    <ListItemIcon>
                        <Inventory2Icon />
                    </ListItemIcon>
                    <ListItemText primary="File Upload" />
                </NavigationListItemButton>
                <NavigationListItemButton path="/integrations" data-testid="integrations">
                    <ListItemIcon>
                        <SyncAltIcon />
                    </ListItemIcon>
                    <ListItemText primary="Integrations" />
                </NavigationListItemButton>
                {/* <NavigationListItemButton path="/reset">
                    <ListItemIcon>
                        <LockOpenIcon />
                    </ListItemIcon>
                    <ListItemText primary="Change password" />
                </NavigationListItemButton> */}
                <ListItemButton
                    dense
                    id={'logout-button'}
                    onClick={handleLogout}
                    data-testid="logout-button"
                >
                    <ListItemIcon>
                        <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                </ListItemButton>
            </List>
        </MuiDrawer>
    );
};
