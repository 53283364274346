import { dateFormatter } from '@lendica/utils';

export const useActivity = (type, status) => {
    const categoriesMap = new Map();
    const statusMap = new Map();

    categoriesMap.set(0, 'Withdrawal');
    categoriesMap.set(1, 'Payment');

    statusMap.set(0, {
        0: 'pending',
        1: 'processing',
        2: 'funded',
        3: 'paid',
        4: 'rejected',
    });
    statusMap.set(1, {
        0: 'scheduled',
        1: 'processing',
        2: 'complete',
        3: 'rejected',
        4: 'overdue',
        5: 'cancelled',
    });

    const getActivityType = () => {
        return categoriesMap.get(type) ?? 'Transaction';
    };

    const getActivityStatus = () => {
        const map = statusMap.get(type);
        return map ? map[status] ?? '' : '';
    };

    const getDate = date => {
        // let currentdate = new Date(date.slice(0, 10));
        // return `${currentdate.toJSON().slice(0, 10).split('-').join('/')}`;
        return dateFormatter(date, false);
    };

    return {
        getActivityType,
        getActivityStatus,
        getDate,
    };
};
