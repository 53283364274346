import { Fragment } from 'react';
import { Box, Typography, Button, Divider } from '@mui/material';

import { makeStyles } from '@mui/styles';
import { useParams } from 'react-router-dom';

import { MoreInformation, FootNote } from '@lendica/components';
import { currencyFormatter, TestId } from '@lendica/utils';
import { BottomBar } from './BottomBar';
import { InvoiceSubtitle } from './InvoiceSubtitle';
import { PaymentOffer } from './PaymentOffer';

const useStyles = makeStyles(theme => ({
    terms: {
        '& > div:not(:first-child)': {
            marginTop: theme.spacing(2),
        },
    },
    moreInformation: {
        '& .MuiListSubheader-root': { backgroundColor: theme.palette.background.default },
    },
    button: {
        borderRadius: 30,
        boxShadow: 'none',
        width: '100%',
    },
}));

const OfferHeader = ({ overline, title }) => {
    return (
        <Box
            mt={1}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
        >
            <Typography variant="subtitle1" color="primary" gutterBottom>
                {overline}
            </Typography>
            <Typography variant="h5" color="text.primary">
                {title}
            </Typography>
        </Box>
    );
};

const OfferDetails = ({ bill, offers, onClick, offerTitle = 'Select one payment term' }) => {
    const classes = useStyles();
    const { offerId } = useParams();

    const getShippingCost = () => {
        const shipping = Math.abs(+(bill?.total - bill?.tax - bill?.subtotal).toFixed(2));
        if (!shipping) {
            return '--';
        }
        return currencyFormatter(shipping);
    };

    return (
        <>
            {!!bill?.total && (
                <Box display="flex" justifyContent="space-between" mt={4}>
                    <InvoiceSubtitle
                        subtitle="Invoice Total Incl. Tax"
                        value={currencyFormatter(bill?.total ?? '')}
                        moreInfo={
                            <Fragment>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    width="160px"
                                >
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="space-between"
                                        alignItems="baseline"
                                        width="100%"
                                    >
                                        <Typography
                                            variant="caption"
                                            color="text.secondary"
                                            align="left"
                                        >
                                            Subtotal:
                                        </Typography>
                                        <Typography
                                            variant="caption"
                                            color="text.secondary"
                                            align="right"
                                        >
                                            {currencyFormatter(bill?.subtotal)}
                                        </Typography>
                                    </Box>
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="space-between"
                                        alignItems="baseline"
                                        width="100%"
                                    >
                                        <Typography
                                            variant="caption"
                                            color="text.secondary"
                                            align="left"
                                        >
                                            Tax:
                                        </Typography>
                                        <Typography
                                            variant="caption"
                                            color="text.secondary"
                                            align="right"
                                        >
                                            {currencyFormatter(bill?.tax)}
                                        </Typography>
                                    </Box>
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="space-between"
                                        alignItems="baseline"
                                        width="100%"
                                    >
                                        <Typography
                                            variant="caption"
                                            color="text.secondary"
                                            align="left"
                                        >
                                            Shipping:
                                        </Typography>
                                        <Typography
                                            variant="caption"
                                            color="text.secondary"
                                            align="right"
                                        >
                                            {getShippingCost()}
                                        </Typography>
                                    </Box>
                                    <Divider
                                        sx={{
                                            my: '4px',
                                            width: '100%',
                                            bgcolor: 'rgba(0, 0, 0, 0.2)',
                                        }}
                                    />
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="space-between"
                                        alignItems="baseline"
                                        width="100%"
                                    >
                                        <Typography
                                            variant="caption"
                                            color="text.primary"
                                            fontWeight="bold"
                                            align="left"
                                        >
                                            Total:
                                        </Typography>
                                        <Typography
                                            variant="caption"
                                            color="text.primary"
                                            fontWeight="bold"
                                            align="right"
                                        >
                                            {currencyFormatter(bill?.total)}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Fragment>
                        }
                    />
                    <InvoiceSubtitle
                        subtitle="Payment Today"
                        value={currencyFormatter(
                            (offers[0]?.total_price ?? 0) -
                                (offers[0]?.advance_amount ?? 0) +
                                (offers[0]?.origination_fee ?? 0)
                        )}
                    />
                    <InvoiceSubtitle
                        subtitle="Amount Financed"
                        value={currencyFormatter(offers[0]?.advance_amount ?? '')}
                    />
                </Box>
            )}
            <Box mt={5}>
                <Typography
                    data-testid={TestId.Select_one_payment_term_TestId}
                    variant="subtitle1"
                    color="text.primary"
                >
                    {offerTitle}
                </Typography>
            </Box>
            <Box mt={2} className={classes.terms}>
                {offers.map(offer => (
                    <PaymentOffer
                        key={offer?.id}
                        checked={offerId === offer?.id}
                        onClick={() => onClick(offer)}
                        offer={offer}
                    />
                ))}
            </Box>
        </>
    );
};

const OfferNotes = () => {
    const classes = useStyles();
    return (
        <Box display="flex" flexDirection="column" justifyContent="center">
            <MoreInformation
                items={[
                    {
                        summary: 'Late Fee',
                        details:
                            'If the undersigned fails to make a payment, a fee will be applied for each day the payment is overdue.',
                    },
                    {
                        summary: 'First Payment',
                        details:
                            'The first payment will be scheduled for one calendar week after the undersigned and the seller confirm receipt of this order.',
                    },
                ]}
                className={classes.moreInformation}
                data-testid={TestId.MoreInformation}
            />
            <FootNote
                lines={['*Fees are subject to change with each invoice funded.']}
                data-testid={TestId.Fees_are_subject_to_change_TestId}
            />
        </Box>
    );
};

export const SelectPaymentTerms = ({ onTermSelected, onSelected, bill, offers }) => {
    const classes = useStyles();
    const { offerId } = useParams();

    return (
        <>
            <OfferHeader
                overline="Pay this invoice over time"
                title={
                    bill?.invoice_number
                        ? `Invoice No. ${bill?.invoice_number}`
                        : bill?.vendor_name
                        ? `Invoice from ${bill?.vendor_name}`
                        : `💝`
                }
            />
            <OfferDetails bill={bill} offers={offers} onClick={({ id }) => onTermSelected(id)} />
            <OfferNotes />
            <BottomBar
                action={
                    <Button
                        variant="contained"
                        color="primary"
                        size="medium"
                        className={classes.button}
                        disabled={!offerId}
                        onClick={() => onSelected(offerId)}
                    >
                        Activate
                    </Button>
                }
            />
        </>
    );
};

export const SelectFinalPaymentTerms = ({ onTermSelected, onSelected, bill, offers }) => {
    const classes = useStyles();
    const { offerId } = useParams();

    return (
        <>
            <OfferHeader overline="Congratulations!" title={`You've been approved 🎉`} />
            <OfferDetails
                bill={bill}
                offers={offers}
                onClick={({ id }) => onTermSelected(id)}
                offerTitle="Select your final offer"
            />
            <OfferNotes />
            <BottomBar
                action={
                    <Button
                        variant="contained"
                        color="primary"
                        size="medium"
                        className={classes.button}
                        disabled={!offerId}
                        onClick={() => onSelected(offerId)}
                    >
                        Select
                    </Button>
                }
            />
        </>
    );
};
