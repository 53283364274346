import { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
    FeatureLayout,
    FundsAvailableCard,
    ApprovalAccordion,
    HowItWorksAccordion,
    Flow,
    Status,
} from '@lendica/components';
import { Approval } from '@lendica/utils';
import { getApprovalSummary } from '@lendica/api';

export const MyApprovalsView = ({ onError }: { onError: () => void }) => {
    const [loading, setLoading] = useState(true);
    const [approvals, setApprovals] = useState<Approval[] | null>([]);

    useEffect(() => {
        (async () => {
            try {
                setApprovals(await getApprovalSummary());
            } catch (e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    return loading ? (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Flow />
        </Box>
    ) : (
        <FeatureLayout>
            {approvals && approvals.length > 0 ? (
                <Box
                    sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        boxSizing: 'border-box',
                    }}
                >
                    <Box
                        sx={{
                            px: 4,
                            pt: 2,
                            pb: 4,
                            flexGrow: 1,
                            overflowY: 'auto',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant="subtitle1" sx={{ mb: 2 }} textAlign="center">
                            My Approvals
                        </Typography>
                        <Box mb={3}>
                            <FundsAvailableCard approvals={approvals} />
                        </Box>

                        {approvals.map(approval => (
                            <Box mb={3} key={approval.id}>
                                <ApprovalAccordion approval={approval} />
                            </Box>
                        ))}
                        <HowItWorksAccordion approvals={approvals} />
                    </Box>
                </Box>
            ) : (
                <Status title="Can't retrieve your approvals right now :(" onClick={onError} />
            )}
        </FeatureLayout>
    );
};
