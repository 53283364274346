import { Box, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import { FeatureLayout } from '@lendica/components';
import { useAccountDataContext } from './AccountData';
import { useAccountElementContext } from './views/AccountView';
import AccountCard from './components/AccountCard';
import { BankAccount } from './views/BankAccount';

export const AccountContainer: FC<{ env: string; children?: ReactNode }> = ({ env, children }) => {
    const { data, isLoading } = useAccountDataContext();
    const { containerRef, AccountRef } = useAccountElementContext();

    const company_id = data!.company ? data!.company!.id : '';

    return (
        <FeatureLayout loading={isLoading}>
            <Box sx={{ px: 6, py: 2 }}>
                <Box
                    sx={{
                        mt: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="subtitle1">Account</Typography>
                </Box>
            </Box>
            <Box
                ref={containerRef}
                sx={{
                    overflowY: 'scroll',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    height: '100%',
                    px: 4,
                    pb: 4,
                    boxSizing: 'border-box',
                }}
            >
                <Typography variant="h4" fontSize={20} mb={2}>
                    Profile
                </Typography>
                <AccountCard />
                <BankAccount
                    company_id={company_id}
                    env={env}
                    company={data!.company!}
                    dataConnections={data!.dataConnections!}
                />
                {children}
            </Box>
        </FeatureLayout>
    );
};
