import axios from 'axios';

export const getDataConnections = async () => {
    const response = await axios.get('/company/connections')
    return response.data
}

export const getPlaidToken = async (baseURL: string) => {
    const response = await axios.get(baseURL + '/oauth/plaid/get-link-token')
    return response.data.link_token
}

export const postPlaidToken = async (token: string, company_id: string, baseURL: string) => {
    const response = await axios.post(baseURL + '/oauth/plaid/get_access_token', { public_token: token, company_id: company_id })
    return response.data
}