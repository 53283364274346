import { useNavigate } from 'react-router-dom';

export const useWithdraw = ({ withdraw }) => {
    const navigate = useNavigate();

    const handleConfirm = async withdrawalData => {
        try {
            navigate('/drawdown/withdrawal/success');
            await withdraw(withdrawalData);
        } catch {
            navigate('/drawdown/withdrawal/failure');
        }
    };

    return {
        handleConfirm,
    };
};
